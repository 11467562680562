import { FormEvent, useState } from 'react';
import { Button, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { copyAnswer, selectChannelList } from '../../../store/qasSlice';
import useTranslate from '../../../hooks/useTranslate';
import ModalFormWindow from '../../../HOC/ModalFormWindow/ModalFormWindow';
import { IFormCopyAnswerProps } from './FormCopyAnswer.props';

const FormCopyAnswer = ({ showModal, setShowModal, checkedAnswer, inputAnswer, setChangeFlg }: IFormCopyAnswerProps): JSX.Element => {
	const [selectChannelId, setSelectChannelId] = useState<string>(''); // id канала

	const dispatch = useAppDispatch();
	const channelList = useAppSelector(selectChannelList); // store - список каналов

	const translate = useTranslate(); // hook для перевода текста

	// обработчик копирования ответа
	const submitHandler = (e: FormEvent<HTMLFormElement>): void => {
		e.preventDefault();
		dispatch(copyAnswer({ checked: checkedAnswer, answer: inputAnswer, channel: selectChannelId })); // копирование ответа
		setChangeFlg(true); // ставим флаг о несохраненных данных
		setShowModal(false); // закрываем форму
	};

	return (
		<ModalFormWindow showModal={showModal} setShowModal={setShowModal} headerTitle='formCopyAnswer_header'>
			<form onSubmit={(e) => submitHandler(e)}>
				<FormControl fullWidth sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}>
					<InputLabel id="channel-label" sx={{ fontSize: 13 }}>{translate('formCopyAnswer_selectChannel')}</InputLabel>
					<Select
						required
						id="channel"
						labelId="channel-label"
						label={translate('formCopyAnswer_selectChannel')}
						value={selectChannelId}
						onChange={(e) => setSelectChannelId(e.target.value)}
						style={{ fontSize: 13, height: 33 }}
					>
						{channelList.data.map((channel) => (
							<MenuItem key={channel.id} value={channel.id} sx={{ fontSize: 13 }}>{channel.name}</MenuItem>
						))}
					</Select>
				</FormControl>
				<FormControl fullWidth margin='dense'>
					<Button
						variant="outlined"
						type="submit"
						sx={{ fontSize: 11 }}
					>
						{translate('formCopyAnswer_copyBtn')}
					</Button>
				</FormControl>
			</form>
		</ModalFormWindow>
	);
};

export default FormCopyAnswer;
