import { useEffect, useState } from 'react';
import { Fade } from '@mui/material';
import cn from 'classnames';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { clearModels, selectAllModels } from '../../store/modelSlice';
import { clearDeleteResponseCorpus, clearRenameResponseCorpus, clearState, getCorpusList, selectCorpus, selectCorpusDeleteStatus, selectCorpusList, selectCorpusPutStatus, selectCorpusRenameStatus } from '../../store/corpusSlice';
import useAccessRight from '../../hooks/useAccessRight';
import useTranslate from '../../hooks/useTranslate';
import { findServiceRightByType } from '../../helpers/findServiceRightByType';
import { CORPUS } from '../../constants/accessRights';
import { ICorpusData } from '../../types/corpusTypes';
import { RequestStatus, ResponseStatus } from '../../types/statusTypes';
import PageWrapper from '../../HOC/PageWrapper/PageWrapper';
import CorpusNavBar from '../../components/Navbars/CorpusNavBar/CorpusNavBar';
import CorpusControl from '../../components/CorpusControl/CorpusControl';
import CorpusInfo from '../../components/CorpusInfo/CorpusInfo';
import CorpusTable from '../../components/Tables/Corpus/Corpus';
import ProgressCircle from '../../components/ProgressCircle/ProgressCircle';
import ScreenLock from '../../components/ScreenLock/ScreenLock';
import Notification from '../../components/Notification/Notification';
import { ICorpusProps } from './Corpus.props';
import styles from './Corpus.module.scss';

const Corpus = ({ serviceType }: ICorpusProps): JSX.Element => {
	const [showPage, setShowPage] = useState<boolean>(true); // показ страницы
	const [changeFlg, setChangeFlg] = useState<boolean>(false); // флаг, уведомляющий об изменении данных в корпусе и возможности сохранить эти изменения
	const [showNotificationRename, setShowNotificationRename] = useState<boolean>(false); // показ уведомления о переименовании
	const [showNotificationDelete, setShowNotificationDelete] = useState<boolean>(false); // показ уведомления об удалении корпуса
	const [showScreenLock, setShowScreenLock] = useState<{ isShow: boolean, title: string }>({ isShow: false, title: '' }); // показ экрана блокировки и подпись
	const [showSidebar, setShowSidebar] = useState<boolean>(true); // показ боковой панели
	const [visibleTable, setVisibleTable] = useState<keyof ICorpusData>('data'); // отображение таблицы по табам

	const dispatch = useAppDispatch();
	const corpusList = useAppSelector(selectCorpusList); // store - список корпусов
	const corpus = useAppSelector(selectCorpus); // store - корпус
	const putStatus = useAppSelector(selectCorpusPutStatus); // store - статус об изменении корпуса
	const renameStatus = useAppSelector(selectCorpusRenameStatus); // store - статус о переименовании корпуса
	const allModels = useAppSelector(selectAllModels); // store - все модели

	const isAccess = useAccessRight(); // hook для проверки прав доступа
	const translate = useTranslate(); // hook для перевода текста

	useEffect(() => {
		isAccess(CORPUS.LIST) && dispatch(getCorpusList({ serviceType })); // если есть доступ - получаем список корпусов

		// при уходе со страницы
		return () => {
			allModels.models !== null && dispatch(clearModels()); // очищаем список моделей, если есть
			dispatch(clearState()); // очищаем state
		};
	}, []);

	// следим за именем корпуса - и сбрасываем табы
	useEffect(() => {
		visibleTable !== 'data' && setVisibleTable('data');
	}, [corpus.corpusName]);

	// следим за статусом изменения и переименования корпуса
	useEffect(() => {
		// если идет сохранения/переименовывание - включаем экран блокировки с подписью
		if (putStatus.status === RequestStatus.LOADING) setShowScreenLock({ isShow: true, title: 'screenLock_saveCorpusTitle' });
		else if (renameStatus.status === RequestStatus.LOADING) setShowScreenLock({ isShow: true, title: 'screenLock_renameCorpusTitle' });
		else setShowScreenLock({ isShow: false, title: '' }); // иначе выключаем

		// если сохранилось успешно - выключаем флаг сохранения данных
		putStatus.status === RequestStatus.IDLE && putStatus.error === ResponseStatus.SUCCESS && putStatus.message === 'success' && setChangeFlg(false);
	}, [putStatus, renameStatus]);

	return (
		<PageWrapper showPage={showPage} setShowPage={setShowPage} accessToService={[findServiceRightByType(serviceType), CORPUS.LIST]}>
			<>
				<CorpusControl changeFlg={changeFlg} setChangeFlg={setChangeFlg} serviceType={serviceType} setShowPage={setShowPage} />

				<div className={styles.bottomContainer}>
					<CorpusNavBar
						changeFlg={changeFlg}
						setChangeFlg={setChangeFlg}
						serviceType={serviceType}
						showSidebar={showSidebar}
						setShowSidebar={setShowSidebar}
						setShowPage={setShowPage}
					/>
					<div className={cn(styles.wrapper, {
						[styles.wrapperFullWidth]: !showSidebar,
					})}>
						{corpusList.data.length > 0 && Array.isArray(corpus.data.data) ?
							<>
								{(corpus.status === RequestStatus.LOADING || corpus.corpusName === null) &&
									<div className={styles.loading}>
										<ProgressCircle title={translate('progressCircle_corpusLoadingTitle')} />
									</div>
								}
								<CorpusInfo setShowNotificationRename={setShowNotificationRename} setShowNotificationDelete={setShowNotificationDelete} serviceType={serviceType} setChangeFlg={setChangeFlg} visibleTable={visibleTable} setVisibleTable={setVisibleTable} />
								{(corpus.corpusName && corpus.status === RequestStatus.IDLE) &&
									<Fade in={true} timeout={500}>
										<div className={styles.wrapperTable}>
											{visibleTable === 'data' && <CorpusTable setChangeFlg={setChangeFlg} serviceType={serviceType} typeCorpusData='data' />}
											{visibleTable === 'groups' && <CorpusTable setChangeFlg={setChangeFlg} serviceType={serviceType} typeCorpusData='groups' />}
										</div>
									</Fade>
								}
								{corpus.status === RequestStatus.FAILED &&
									<div className={styles.notFound}><div>{translate(corpus.message || 'notFound')}</div></div>
								}
							</>
							:
							<div className={styles.noData}><div>{translate('noData')}</div></div>
						}
					</div>
				</div>

				{showScreenLock.isShow && <ScreenLock title={translate(showScreenLock.title)} />}
				{showNotificationRename && <Notification showNotification={showNotificationRename} setShowNotification={setShowNotificationRename} selectDataResponse={selectCorpusRenameStatus} clearDataResponse={clearRenameResponseCorpus} titleFailed='noticeRename_failed' titleSuccess='noticeRename_success' />}
				{showNotificationDelete && <Notification showNotification={showNotificationDelete} setShowNotification={setShowNotificationDelete} selectDataResponse={selectCorpusDeleteStatus} clearDataResponse={clearDeleteResponseCorpus} titleFailed='noticeDelete_corpusFailed' titleSuccess='noticeDelete_corpusSuccess' />}
			</>
		</PageWrapper>
	);
};

export default Corpus;
