import { FormEvent, useEffect, useState } from 'react';
import { Button, FormControl, TextField } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { addCategory, clearCategoriesList, getCategoriesList, selectAddingCategory, selectCategoriesList } from '../../../store/qasSlice';
import useTranslate from '../../../hooks/useTranslate';
import { RequestStatus, ResponseStatus } from '../../../types/statusTypes';
import ModalFormWindow from '../../../HOC/ModalFormWindow/ModalFormWindow';
import ProgressCircle from '../../ProgressCircle/ProgressCircle';
import { IFormAddingCategoryProps } from './FormAddingCategory.props';

const FormAddingCategory = ({ showModal, setShowModal, setShowNotification }: IFormAddingCategoryProps): JSX.Element => {
	const [inputCategory, setInputCategory] = useState<string>(''); // название категории
	const [showErrorExistingCategory, setShowErrorExistingCategory] = useState<boolean>(false); // флаг существующего имени категории

	const dispatch = useAppDispatch();
	const categoriesList = useAppSelector(selectCategoriesList); // store - список категорий
	const addingCategoryStatus = useAppSelector(selectAddingCategory); // store - статус добавления категории

	const translate = useTranslate(); // hook для перевода текста

	// следим за статусом добавления категории
	useEffect(() => {
		// если добавление прошло успешно 
		if (addingCategoryStatus.error === ResponseStatus.SUCCESS && addingCategoryStatus.status === RequestStatus.IDLE && addingCategoryStatus.message !== '') {
			dispatch(clearCategoriesList()); // очищаем список категорий
			dispatch(getCategoriesList()); // получаем заново список категорий
		}
		// если были изменения в статусе добавления
		if (addingCategoryStatus.error !== ResponseStatus.SUCCESS || addingCategoryStatus.status === RequestStatus.FAILED || addingCategoryStatus.message !== '') {
			handleClose(); // закрываем форму
			setShowNotification(true); // включаем уведомление
		}
	}, [addingCategoryStatus]);

	// обработчик добавления категории
	const submitHandler = (e: FormEvent<HTMLFormElement>): void => {
		e.preventDefault();
		const existingCategoryName = categoriesList.data.find(category => category.name === inputCategory); // существующее имя категории
		if (existingCategoryName) {
			setShowErrorExistingCategory(true); // подсвечиваем ошибку
		} else {
			showErrorExistingCategory && setShowErrorExistingCategory(false); // убираем ошибку, если была
			dispatch(addCategory(inputCategory)); // добавление категории
		}
	};

	// обработчик закрытия формы
	const handleClose = (): void => {
		// если идет добавление категории - запрещаем покидать форму
		if (addingCategoryStatus.status === RequestStatus.LOADING) return;
		setShowModal(false);
	};

	return (
		<ModalFormWindow showModal={showModal} setShowModal={setShowModal} headerTitle='formAddingCategory_header' close={handleClose}>
			<form onSubmit={(e) => submitHandler(e)}>
				<FormControl fullWidth margin='dense'>
					<TextField
						autoFocus
						required
						id="categoryName"
						label={translate('formAddingCategory_inputCategory')}
						variant="outlined"
						value={inputCategory}
						onChange={(e) => setInputCategory(e.target.value)}
						disabled={addingCategoryStatus.status === RequestStatus.LOADING}
						error={showErrorExistingCategory}
						helperText={showErrorExistingCategory ? translate('formAddingCategory_categoryExists') : ''}
						InputProps={{
							style: {
								height: 33,
								fontSize: 13
							},
						}}
						InputLabelProps={{
							style: {
								fontSize: 13,
							},
						}}
						sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}
					/>
				</FormControl>
				<FormControl fullWidth margin='dense'>
					<Button
						variant="outlined"
						type="submit"
						disabled={addingCategoryStatus.status === RequestStatus.LOADING}
						sx={{ fontSize: 11 }}
					>
						{addingCategoryStatus.status === RequestStatus.LOADING ?
							<>
								{translate('formAddingCategory_btnAdding')}
								<ProgressCircle isBtnDisabled />
							</>
							:
							translate('formAddingCategory_btnAdd')
						}
					</Button>
				</FormControl>
			</form>
		</ModalFormWindow>
	);
};

export default FormAddingCategory;
