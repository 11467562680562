import { useState } from 'react';
import { useCookies } from 'react-cookie';
import { Fade } from '@mui/material';
import { useAppSelector } from '../../store/hooks';
import { selectWallpaper } from '../../store/langSlice';
import useAccessRight from '../../hooks/useAccessRight';
import { BACKGROUND_IMAGE } from '../../constants/cookieNames';
import NotFound from '../../pages/NotFound/NotFound';
import DockPanelCall from '../../components/DockPanelCall/DockPanelCall';
import { IPageWrapperProps } from './PageWrapper.props';
import styles from './PageWrapper.module.scss';

const PageWrapper = ({ children, showPage, setShowPage, accessToService, additionalFunctionToClickHandler, alternativeBackground }: IPageWrapperProps): JSX.Element => {
	const [showDockPanel, setShowDockPanel] = useState<boolean>(false); // показ dock-панели

	const wallpaper = useAppSelector(selectWallpaper); // store - обои

	const [cookies] = useCookies([BACKGROUND_IMAGE]); // hook для работы с cookie
	const isAccess = useAccessRight(); // hook для проверки прав доступа

	// обработчик нажатия клавишы мыши
	const clickHandler = (): void => {
		showDockPanel && setShowDockPanel(false); // закрываем dock-панель
		additionalFunctionToClickHandler && additionalFunctionToClickHandler(); // выполняем доп.функцию обработчика, если есть
	};

	if (!isAccess(accessToService)) return <NotFound />; // если нет доступа к сервису - страница 404

	return (
		<Fade in={showPage} timeout={500}>
			<div
				className={styles.container}
				style={wallpaper.dataUrl && (!cookies.backgroundImage || cookies.backgroundImage === 'true') ?
					{ backgroundImage: alternativeBackground || `url(${wallpaper.dataUrl})` }
					:
					{ background: 'none' }
				}
				onClick={clickHandler}
			>
				{children}
				<DockPanelCall showDockPanel={showDockPanel} setShowDockPanel={setShowDockPanel} setShowPage={setShowPage} />
			</div>
		</Fade>
	);
};

export default PageWrapper;
