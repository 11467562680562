import { useState } from 'react';
import { Fade, Slide } from '@mui/material';
import { useAppSelector } from '../../store/hooks';
import { selectVersion } from '../../store/authSlice';
import { selectWallpaper } from '../../store/langSlice';
import useTranslate from '../../hooks/useTranslate';
import { backgroundColorGradient } from '../../constants/colors';
import DockPanel from '../../components/DockPanel/DockPanel';
import styles from './Home.module.scss';

const Home = (): JSX.Element => {
	const [showPage, setShowPage] = useState<boolean>(true); // показ страницы
	const [showDockPanel, setShowDockPanel] = useState<boolean>(true); // показ dock-панели
	const version = useAppSelector(selectVersion); // store - информация о версии системы
	const wallpaper = useAppSelector(selectWallpaper); // store - обои
	const translate = useTranslate(); // hook для перевода текста

	return (
		<Fade in={showPage} timeout={{ enter: 1000, exit: 500 }}>
			<div
				className={styles.home}
				style={wallpaper.dataUrl ? { backgroundImage: `url(${wallpaper.dataUrl})` } : { background: backgroundColorGradient }}
			>
				<Slide direction="up" in={showDockPanel} mountOnEnter unmountOnExit timeout={{ enter: 1200, exit: 500 }}>
					<div><DockPanel setShowDockPanel={setShowDockPanel} setShowPage={setShowPage} /></div>
				</Slide>
				{version && 'version' in version && <p className={styles.homeVersion}>
					{translate('home_versionTitle')}: {version.version}
				</p>}
			</div>
		</Fade>
	);
};

export default Home;
