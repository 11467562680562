import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError, AxiosResponse } from 'axios';
import { RootState } from './store';
import axiosApi from '../helpers/axios';
import { downloadFile } from '../helpers/downloadFile';
import { ENDPOINTS_TTS, URL_TTS } from '../constants/api_endpoints';
import { IImportDictionaryProps, IPutDictionaryProps, IResponse, ISynthesizeSpeechProps, ITtsState } from '../types/ttsTypes';
import { RequestStatus, ResponseStatus } from '../types/statusTypes';

const initialState: ITtsState = {
  synthesis: {
    dataUrl: null,
    status: RequestStatus.IDLE,
  },
  dictionary: {
    data: [],
    status: RequestStatus.IDLE,
  },
  dictionaryImport: {
    status: RequestStatus.IDLE,
    error: ResponseStatus.SUCCESS,
    message: '',
  },
  dictionaryExport: {
    status: RequestStatus.IDLE,
    error: ResponseStatus.SUCCESS,
    message: '',
  },
  dictionaryPut: {
    status: RequestStatus.IDLE,
    error: ResponseStatus.SUCCESS,
    message: '',
  },
};

// синтез речи
export const synthesizeSpeech = createAsyncThunk(
  'tts/synthesizeSpeech',
  async ({ modelName, text, rate, pitch, volume }: ISynthesizeSpeechProps): Promise<Blob | IResponse> => {
    const response: AxiosResponse<Blob | IResponse> = await axiosApi.get(`${URL_TTS}/${ENDPOINTS_TTS.SYNTHESIZE}/${modelName}`, {
      responseType: 'blob',
      params: {
        text,
        rate,
        pitch,
        volume
      }
    });
    return response.data;
  }
);

// получение словаря ударений
export const getDictionary = createAsyncThunk(
  'tts/getDictionary',
  async (): Promise<[string, string][]> => {
    const response: AxiosResponse<[string, string][]> = await axiosApi.get(`${URL_TTS}/${ENDPOINTS_TTS.DICTIONARY_GET}`);
    return response.data;
  }
);

// экспорт словаря ударений (.csv файл)
export const exportDictionary = createAsyncThunk(
  'tts/exportDictionary',
  async (_, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<Blob | IResponse> = await axiosApi.get(`${URL_TTS}/${ENDPOINTS_TTS.DICTIONARY_EXPORT}`, {
        responseType: 'blob',
      });
      if (response.data instanceof Blob) {
        downloadFile(response.data, "stressDictionary.csv");
      } else return response.data;
    } catch (error) {
      if (error) {
        // возвращаем данные ошибки, пришедшие с бэка
        return rejectWithValue(error);
      }
    }
  }
);

// импорт словаря ударений
export const importDictionary = createAsyncThunk(
  'tts/importDictionary',
  async ({ formData }: IImportDictionaryProps): Promise<IResponse> => {
    const response: AxiosResponse<IResponse> = await axiosApi.post(`${URL_TTS}/${ENDPOINTS_TTS.DICTIONARY_IMPORT}`, { csv: formData.get('file') }, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    return response.data;
  }
);

// изменение словаря ударений
export const putDictionary = createAsyncThunk(
  'tts/putDictionary',
  async ({ text }: IPutDictionaryProps, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<IResponse> = await axiosApi.post(`${URL_TTS}/${ENDPOINTS_TTS.DICTIONARY_PUT}`, {
        json: JSON.stringify(text)
      });
      return response.data;
    } catch (error) {
      if (error) {
        // возвращаем данные ошибки, пришедшие с бэка
        return rejectWithValue(error);
      }
    }
  }
);

const ttsSlice = createSlice({
  name: 'tts',
  initialState,
  reducers: {
    // изменение ячейки таблицы словаря ударений (по факту изменение строки полностью)
    editDictionaryDataCell: (state, action: PayloadAction<{ index: number, row: string[] }>) => {
      state.dictionary.data.splice(action.payload.index, 1, action.payload.row);
    },
    // добавление строки в словарь ударений
    addRow: (state, action: PayloadAction<string[]>) => {
      state.dictionary.data.push(action.payload);
    },
    // удаление строк из словаря ударений
    deleteRows: (state, action: PayloadAction<number[]>) => {
      state.dictionary.data = state.dictionary.data.filter((_, idx) => !action.payload.includes(idx));
    },
    // очистка результата синтеза речи
    clearSynthesisData: (state) => {
      state.synthesis = initialState.synthesis;
    },
    // очистка словаря ударений
    clearDictionary: (state) => {
      state.dictionary = initialState.dictionary;
    },
    // очистка статуса экспорта словаря ударений
    clearExportDictionary: (state) => {
      state.dictionaryExport = initialState.dictionaryExport;
    },
    // очистка статуса импорта словаря ударений
    clearImportDictionary: (state) => {
      state.dictionaryImport = initialState.dictionaryImport;
    },
    // очистка статуса изменения словаря ударений
    clearPutDictionary: (state) => {
      state.dictionaryPut = initialState.dictionaryPut;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(synthesizeSpeech.pending, (state) => {
        state.synthesis.status = RequestStatus.LOADING;
      })
      .addCase(synthesizeSpeech.fulfilled, (state, action) => {
        if (action.payload instanceof Blob) {
          state.synthesis.status = RequestStatus.IDLE;
          const url = window.URL.createObjectURL(action.payload);
          state.synthesis.dataUrl = url;
        } else {
          state.synthesis.status = RequestStatus.FAILED;
        }
      })
      .addCase(synthesizeSpeech.rejected, (state) => {
        state.synthesis.status = RequestStatus.FAILED;
      })
      .addCase(getDictionary.pending, (state) => {
        state.dictionary.status = RequestStatus.LOADING;
      })
      .addCase(getDictionary.fulfilled, (state, action) => {
        state.dictionary.data = action.payload;
        if (Array.isArray(action.payload)) {
          state.dictionary.status = RequestStatus.IDLE;
        } else {
          state.dictionary.status = RequestStatus.FAILED;
        }
      })
      .addCase(getDictionary.rejected, (state) => {
        state.dictionary.status = RequestStatus.FAILED;
      })
      .addCase(exportDictionary.pending, (state) => {
        state.dictionaryExport.status = RequestStatus.LOADING;
      })
      .addCase(exportDictionary.fulfilled, (state, action) => {
        state.dictionaryExport.status = RequestStatus.IDLE;
        if (action.payload && 'error' in action.payload) {
          state.dictionaryExport.error = action.payload.error;
          state.dictionaryExport.message = action.payload.message;
        }
      })
      .addCase(exportDictionary.rejected, (state, action: PayloadAction<unknown>) => {
        state.dictionaryExport.status = RequestStatus.FAILED;
        if (action.payload instanceof AxiosError) {
          state.dictionaryExport.error = action.payload.response?.data.error;
          state.dictionaryExport.message = action.payload.response?.data.message;
        }
      })
      .addCase(importDictionary.pending, (state) => {
        state.dictionaryImport.status = RequestStatus.LOADING;
      })
      .addCase(importDictionary.fulfilled, (state, action) => {
        state.dictionaryImport.status = RequestStatus.IDLE;
        state.dictionaryImport.error = action.payload.error;
        state.dictionaryImport.message = action.payload.message;
      })
      .addCase(importDictionary.rejected, (state) => {
        state.dictionaryImport.status = RequestStatus.FAILED;
      })
      .addCase(putDictionary.pending, (state) => {
        state.dictionaryPut.status = RequestStatus.LOADING;
      })
      .addCase(putDictionary.fulfilled, (state, action) => {
        state.dictionaryPut.status = RequestStatus.IDLE;
        if (action.payload) {
          state.dictionaryPut.error = action.payload.error;
          state.dictionaryPut.message = action.payload.message;
        }
      })
      .addCase(putDictionary.rejected, (state, action: PayloadAction<unknown>) => {
        state.dictionaryPut.status = RequestStatus.FAILED;
        if (action.payload instanceof AxiosError) {
          state.dictionaryPut.error = action.payload.response?.data.error;
          state.dictionaryPut.message = action.payload.response?.data.message;
        }
      });
  },
});

export const { editDictionaryDataCell, addRow, deleteRows, clearSynthesisData, clearDictionary, clearExportDictionary, clearImportDictionary, clearPutDictionary } = ttsSlice.actions;

export const selectSynthesisData = (state: RootState) => state.tts.synthesis;
export const selectDictionary = (state: RootState) => state.tts.dictionary;
export const selectImportDictionaryStatus = (state: RootState) => state.tts.dictionaryImport;
export const selectExportDictionaryStatus = (state: RootState) => state.tts.dictionaryExport;
export const selectPutDictionaryStatus = (state: RootState) => state.tts.dictionaryPut;

export default ttsSlice.reducer;
