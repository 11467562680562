import { useState } from 'react';
import { Checkbox, FormControl, ListItemText, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { deleteConditionEntry, editConditionEntry, selectDataList } from '../../store/sesSlice';
import { selectActiveRobotVersion } from '../../store/sesRobotSlice';
import useAccessRight from '../../hooks/useAccessRight';
import useTranslate from '../../hooks/useTranslate';
import { SES } from '../../constants/accessRights';
import { colorPrimary, colorRed } from '../../constants/colors';
import { IConditionEntryProps } from './ConditionEntry.props';
import styles from './ConditionEntry.module.scss';

const ConditionEntry = ({ condition, setChangeFlg, idx }: IConditionEntryProps): JSX.Element => {
	const [inputDataElementIds, setInputDataElementIds] = useState<string[]>(condition); // список id элементов данных

	const dispatch = useAppDispatch();
	const dataElemList = useAppSelector(selectDataList); // список элементов данных
	const activeRobotVersion = useAppSelector(selectActiveRobotVersion); // store - версия активного робота

	const isAccess = useAccessRight(); // hook для проверки прав доступа
	const translate = useTranslate(); // hook для перевода текста

	// обработчик выбора элементов данных
	const handleChange = (event: SelectChangeEvent<typeof inputDataElementIds>): void => {
		const { target: { value } } = event;
		setInputDataElementIds(typeof value === 'string' ? value.split(',').sort() : value.sort());
	};

	// обработчик закрытия списка
	const closeHandler = (): void => {
		// если изменились данные - пишем в store
		if (condition.toString() !== inputDataElementIds.toString()) {
			dispatch(editConditionEntry({ idx, data: inputDataElementIds }));
			setChangeFlg(true); // ставим флаг о несохраненных данных
		}
	};

	// обработчик удаления условия
	const deleteConditionHandler = (): void => {
		dispatch(deleteConditionEntry(idx));
		setChangeFlg(true); // ставим флаг о несохраненных данных
	};

	return (
		<div className={styles.condition}>
			<FormControl fullWidth sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}>
				<Select
					required
					multiple
					value={inputDataElementIds}
					onChange={e => handleChange(e)}
					onClose={closeHandler}
					renderValue={(selected) => selected.map(categoryId => dataElemList.data.find(dataElem => dataElem.id === categoryId)?.name || categoryId).join(', ')}
					disabled={!isAccess(SES.ENDPOINT_EDIT) || activeRobotVersion !== 'draft'}
					style={{ fontSize: 13, height: 33, color: colorPrimary }}
				>
					{/* не найденные в списке элементов данных */}
					{condition.map(conditionElem => {
						if (!dataElemList.data.find(elem => elem.id === conditionElem)) {
							return (
								<MenuItem key={conditionElem} value={conditionElem} sx={{ textAlign: 'left', padding: 0, color: colorPrimary }}>
									<Checkbox checked={inputDataElementIds.indexOf(conditionElem) > -1} size='small' sx={{ height: 25, width: 25 }} />
									<ListItemText primary={conditionElem} primaryTypographyProps={{ fontSize: 11 }} />
								</MenuItem>
							);
						} else return undefined;
					})}
					{dataElemList.data.map((elem) => (
						<MenuItem key={elem.id} value={elem.id} sx={{ textAlign: 'left', padding: 0, color: colorPrimary }}>
							<Checkbox checked={inputDataElementIds.indexOf(elem.id) > -1} size='small' sx={{ height: 25, width: 25 }} />
							<ListItemText primary={elem.name} primaryTypographyProps={{ fontSize: 11 }} />
						</MenuItem>
					))}
				</Select>
			</FormControl>

			{isAccess(SES.ENDPOINT_EDIT) && activeRobotVersion === 'draft' &&
				<FontAwesomeIcon
					icon={faTrashCan}
					color={colorRed}
					size='lg'
					onClick={deleteConditionHandler}
					title={translate('condition_deleteConditionTitle')}
					style={{ cursor: 'pointer' }}
				/>
			}
		</div>
	);
};

export default ConditionEntry;
