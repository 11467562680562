import { ChangeEvent, useEffect, useState } from 'react';
import { Autocomplete, Button, Checkbox, Fade, FormControl, FormControlLabel, InputLabel, MenuItem, Select, Slide, TextField } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { addDataElem, changeForEndpointRunOnly, clearDataElem, deleteDataElem, editDataElem, editDataElemName, selectDataElem, selectEditingData } from '../../store/sesSlice';
import { selectActiveRobotId, selectActiveRobotVersion, } from '../../store/sesRobotSlice';
import { selectScriptList } from '../../store/sesScriptSlice';
import { clearModel, getInfoModel, selectFullModel } from '../../store/modelSlice';
import { clearClusterServer, getClusterServerModels, selectClusterServer } from '../../store/serverSlice';
import { selectChannelList } from '../../store/qasSlice';
import useAccessRight from '../../hooks/useAccessRight';
import useTranslate from '../../hooks/useTranslate';
import { MODEL, SEE, SERVER, SES, SMC } from '../../constants/accessRights';
import { DATA_ELEM_EVENT_LIST, DATA_ELEM_TYPE_LIST } from '../../constants/robotConfigLists';
import { colorPrimary } from '../../constants/colors';
import { DataElemActionEventType } from '../../types/sesTypes';
import { DataElemType } from '../../types/cloudTypes';
import { RequestStatus, ResponseStatus } from '../../types/statusTypes';
import SlaveData from '../SlaveData/SlaveData';
import FormAddingSlaveData from '../Forms/FormAddingSlaveData/FormAddingSlaveData';
import ManualCheckModal from '../ManualCheck/ManualCheckModal/ManualCheckModal';
import ActionEvent from '../ActionEvent/ActionEvent';
import FormAddingAction from '../Forms/FormAddingAction/FormAddingAction';
import AlertDialog from '../AlertDialog/AlertDialog';
import ProgressCircle from '../ProgressCircle/ProgressCircle';
import { IConfigDataElemProps } from './ConfigDataElem.props';
import styles from './ConfigDataElem.module.scss';

const ConfigDataElem = ({ showDataElem, setShowDataElem }: IConfigDataElemProps): JSX.Element => {
	const [inputDataName, setInputDataName] = useState<string>(''); // название элемента данных
	const [selectType, setSelectType] = useState<DataElemType>('smc'); // тип
	const [inputModelName, setInputModelName] = useState<string>(''); // название модели
	const [modelsList, setModelsList] = useState<string[]>([]); // список моделей
	const [inputClassname, setInputClassname] = useState<string>(''); // класс/сущность (param)
	const [classnamesList, setClassnamesList] = useState<string[]>([]); // список классов
	const [forEndpointRunOnlyFlg, setForEndpointRunOnlyFlg] = useState<boolean>(false); // флаг использования элемента данных только для конечной точки
	const [slaveData, setSlaveData] = useState<string[]>([]); // требуемые данные
	const [selectEvent, setSelectEvent] = useState<DataElemActionEventType>('alone'); // событие
	const [inputChannel, setInputChannel] = useState<string>('default'); // канал

	const [changeFlg, setChangeFlg] = useState<boolean>(false); // флаг, уведомляющий об изменении данных и возможности сохранить эти изменения
	const [showAlertDialogDel, setShowAlertDialogDel] = useState<boolean>(false); // показ диалогового окна при удалении элемента данных
	const [showManualCheck, setShowManualCheck] = useState<boolean>(false); // показ вкладки ручной проверки модели

	const dispatch = useAppDispatch();
	const activeRobotId = useAppSelector(selectActiveRobotId); // store - id активного робота
	const activeRobotVersion = useAppSelector(selectActiveRobotVersion); // store - версия активного робота
	const dataElem = useAppSelector(selectDataElem); // store - элемент данных
	const editingDataElem = useAppSelector(selectEditingData); // store - статус изменения элемента данных
	const clusterServer = useAppSelector(selectClusterServer); // store - список моделей на сервере cluster
	const fullModel = useAppSelector(selectFullModel); // store - модель
	const channelList = useAppSelector(selectChannelList); // store - список каналов
	const scriptList = useAppSelector(selectScriptList); // store - список скриптов

	const isAccess = useAccessRight(); // hook для проверки прав доступа
	const translate = useTranslate(); // hook для перевода текста

	// следим за открытием вкладки
	useEffect(() => {
		// при открытии
		if (showDataElem) {
			document.addEventListener('mousedown', closeHandler); // вешаем обработчик на нажатие клавиши мыши
			document.addEventListener('keydown', keyDownHandler); // вешаем обработчик на нажатие клавиши клавиатуры
		}
		// при закрытии
		else {
			dispatch(clearDataElem()); // очищаем данные элемента
			dispatch(clearClusterServer()); // очищаем список моделей серверов обработки
			setChangeFlg(false); // сбрасываем флаг возможности сохранения
			// очищаем поля
			setInputDataName('');
			setSelectType('smc');
			setInputModelName('');
			setModelsList([]);
			setForEndpointRunOnlyFlg(false);
			setSlaveData([]);
			setInputClassname('');
			setClassnamesList([]);
			setSelectEvent('alone');
			setInputChannel('default');
		}

		// при закрытии - удаляем обработчики
		return () => {
			document.removeEventListener('mousedown', closeHandler);
			document.removeEventListener('keydown', keyDownHandler);
		};
	}, [showDataElem]);

	// следим за получением данных элемента
	useEffect(() => {
		// если есть данные - вписываем в поля
		if (dataElem.element) {
			!inputDataName && setInputDataName(dataElem.element.name);
			setSelectType(dataElem.element.type);
			!inputModelName && setInputModelName(dataElem.element.model);
			!inputClassname && setInputClassname(dataElem.element.param);
			setForEndpointRunOnlyFlg(dataElem.element.forEndpointRunOnly);
			setSlaveData(dataElem.element.slaveData);
			if (dataElem.element && isAccess(SERVER.MODEL_LIST) && clusterServer.data === null && dataElem.element.type !== 'script') {
				dispatch(getClusterServerModels({ serviceType: dataElem.element.type })); // получаем список моделей, если указан тип
			}
		}
	}, [dataElem.element]);

	// следим за статусом получения продовых моделей с серверов cluster
	useEffect(() => {
		// если нет ошибок и есть данные
		if (clusterServer.status === RequestStatus.IDLE && clusterServer.data !== null && typeof clusterServer.data !== 'string' && Object.keys(clusterServer.data).length > 0) {
			const clusterServers = Object.keys(clusterServer.data); // пишем сервера cluster'а
			const arrModelNames: string[] = [];
			clusterServers.forEach(server => {
				clusterServer.data && clusterServer.data[server]?.forEach(modelName => {
					!arrModelNames.includes(modelName) && arrModelNames.push(modelName); // оставляем все уникальные модели
				});
			});
			setModelsList(arrModelNames); // пишем список моделей в state
		}
	}, [clusterServer]);

	// следим за выбором события
	useEffect(() => {
		// если есть событие и в нем отсутствует выбранный канал - выбираем первый из возможных
		if (dataElem.element && dataElem.element.actions[selectEvent] && !dataElem.element.actions[selectEvent][inputChannel]) {
			setInputChannel(Object.keys(dataElem.element.actions[selectEvent])[0]);
		}
		// если нет события - выбираем default канал
		else if (dataElem.element && !dataElem.element.actions[selectEvent]) {
			setInputChannel('default');
		}
	}, [selectEvent]);

	// следим за полем названия модели и списком моделей
	useEffect(() => {
		// через пол-секунды бездействия после окончания ввода названия модели
		const handler = setTimeout(() => {
			// если в списке моделей есть имя модели и есть тип сервиса
			if (modelsList.includes(inputModelName) && selectType !== 'script') {
				dispatch(clearModel()); // очищаем данные модели
				isAccess(MODEL.INFO) && dispatch(getInfoModel({ modelName: inputModelName.replace(/(-new)|(-planned)/, ''), serviceType: selectType })); // получаем инфо о модели
			}
		}, 500);

		return () => {
			clearTimeout(handler); // сбрасываем timeout, если продолжается ввод названия модели
		};
	}, [inputModelName, modelsList]);

	// следим за данными модели
	useEffect(() => {
		// если в списке моделей есть имя модели
		if (modelsList.includes(inputModelName)) {
			// пишем классы модели в зависимости от выбранного типа
			if (inputModelName.includes('-new') || inputModelName.includes('-planned')) setClassnamesList(fullModel.fullModel.future?.classes || []);
			else setClassnamesList(fullModel.fullModel.current?.classes || []);
		}
	}, [fullModel.fullModel]);

	// следим за статусом сохранения элемента данных
	useEffect(() => {
		// если изменение элемента данных прошло успешно
		if (editingDataElem.status === RequestStatus.IDLE && editingDataElem.error === ResponseStatus.SUCCESS && editingDataElem.message !== '') {
			dataElem.element && dataElem.element.name !== inputDataName && dispatch(editDataElemName({ dataElemId: dataElem.element.id, dataElemName: inputDataName })); // если изменилось имя - меняем в store
			setChangeFlg(false); // сбрасываем флаг о несохраненных данных
		}
	}, [editingDataElem]);

	// обработчик изменения флага использования только для конечной точки
	const changeForEndpointRunOnlyFlgHandler = (e: ChangeEvent<HTMLInputElement>): void => {
		setForEndpointRunOnlyFlg(e.target.checked);
		dispatch(changeForEndpointRunOnly(e.target.checked));
		setChangeFlg(true); // ставим флаг о несохраненных данных
	};

	// обработчик добавления элемента данных
	const addDataElemHandler = (): void => {
		activeRobotId && dispatch(addDataElem({
			robotId: activeRobotId,
			data: {
				name: inputDataName,
				type: selectType,
				model: inputModelName,
				param: inputClassname,
				actions: dataElem.element?.actions || {},
				forEndpointRunOnly: forEndpointRunOnlyFlg,
				slaveData,
			}
		})); // добавляем элемент
	};

	// обработчик сохранения элемента данных
	const editDataElemHandler = (): void => {
		dataElem.element && activeRobotId && dispatch(editDataElem({
			robotId: activeRobotId,
			dataId: dataElem.element.id,
			data: {
				name: inputDataName,
				type: selectType,
				model: inputModelName,
				param: inputClassname,
				actions: dataElem.element.actions,
				forEndpointRunOnly: forEndpointRunOnlyFlg,
				slaveData,
			}
		})); // изменяем элемент
	};

	// обработчик удаления элемента данных
	const deleteDataElemHandler = (): void => {
		setShowAlertDialogDel(false); // закрываем диалоговое окно
		dataElem.element && activeRobotId && dispatch(deleteDataElem({ robotId: activeRobotId, dataId: dataElem.element.id })); // удаление элемента данных
		closeHandler(); // закрываем вкладку
	};

	// обработчик нажатия клавиши
	const keyDownHandler = (e: KeyboardEvent): void => {
		if (e.code === 'Escape') closeHandler();
	};

	// обработчик закрытия вкладки
	const closeHandler = (): void => {
		setShowDataElem(false);
	};

	return (
		<Slide direction="left" in={showDataElem} mountOnEnter unmountOnExit>
			<div className={styles.data} onMouseDown={(e) => e.stopPropagation()}>

				{/* загрузка элемента данных */}
				{dataElem.status === RequestStatus.LOADING &&
					<div className={styles.loading}>
						<ProgressCircle title={translate('progressCircle_dataElemLoadingTitle')} />
					</div>
				}

				{/* ошибка получения элемента данных */}
				{(dataElem.status === RequestStatus.FAILED || dataElem.error === ResponseStatus.FAILED) &&
					<div className={styles.failed}>
						{translate(dataElem.message || 'configDataElem_failedTitle')}
					</div>
				}

				{/* элемент данных */}
				{dataElem.element &&
					<Fade in={true} timeout={500}>
						<div className={styles.dataWrapper}>
							<div className={styles.dataTop}>
								<div className={styles.dataTopMain}>
									<div className={styles.dataTopMainBlock}>
										{/* имя */}
										<FormControl fullWidth margin='dense'>
											<TextField
												required
												label={translate('configDataElem_inputName')}
												variant="outlined"
												disabled={!isAccess(SES.DATA_EDIT) || activeRobotVersion !== 'draft'}
												value={inputDataName}
												onChange={(e) => { setInputDataName(e.target.value); dataElem.element?.id && setChangeFlg(true); }}
												// error={showErrorExistingDataElem}
												// helperText={showErrorExistingDataElem ? translate('Элемент данных уже существует') : ''}
												InputProps={{
													style: {
														height: 33,
														fontSize: 13,
														color: colorPrimary
													},
												}}
												InputLabelProps={{
													style: {
														fontSize: 13,
													},
												}}
												sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}
											/>
										</FormControl>

										{/* тип */}
										<FormControl fullWidth margin='dense' sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}>
											<InputLabel sx={{ fontSize: 13 }}>{translate('configDataElem_selectType')}</InputLabel>
											<Select
												required
												label={translate('configDataElem_selectType')}
												value={selectType}
												onChange={(e) => { setSelectType(e.target.value as DataElemType); dataElem.element?.id && setChangeFlg(true); }}
												disabled
												style={{ fontSize: 13, height: 33, color: colorPrimary }}
											>
												{DATA_ELEM_TYPE_LIST.map(({ type, translation }) =>
													<MenuItem key={type} value={type} sx={{ fontSize: 13, color: colorPrimary }}>{translate(translation)}</MenuItem>
												)}
											</Select>
										</FormControl>
									</div>

									<div className={styles.dataTopMainBlock}>
										{selectType === 'script' ?
											// скрипт интеграции
											<FormControl fullWidth margin='dense' sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}>
												<InputLabel sx={{ fontSize: 13 }}>{translate('configDataElem_inputScript')}</InputLabel>
												<Select
													required
													label={translate('configDataElem_inputScript')}
													disabled={!isAccess(SES.DATA_EDIT) || activeRobotVersion !== 'draft'}
													value={inputModelName}
													onChange={e => { setInputModelName(e.target.value); dataElem.element?.id && setChangeFlg(true); }}
													style={{ fontSize: 13, height: 33, color: colorPrimary }}
												>
													{scriptList.data.map(({ id, name }) =>
														<MenuItem key={id} value={id} sx={{ fontSize: 13, color: colorPrimary }}>{name}</MenuItem>
													)}
												</Select>
											</FormControl>
											:
											// модель
											<FormControl fullWidth margin='dense'>
												<Autocomplete
													freeSolo
													options={modelsList}
													value={inputModelName}
													onChange={(_, value) => { setInputModelName(value ? value : ''); dataElem.element?.id && setChangeFlg(true); }}
													disabled={!isAccess(SES.DATA_EDIT) || activeRobotVersion !== 'draft'}
													renderInput={(params) =>
														<TextField
															{...params}
															label={translate('configDataElem_inputModel')}
															onChange={(e) => { setInputModelName(e.target.value); dataElem.element?.id && setChangeFlg(true); }}
															InputLabelProps={{
																style: {
																	fontSize: 13,
																},
															}}
															InputProps={{
																...params.InputProps, // важно прокинуть параметры
																startAdornment: (
																	<div style={{ marginTop: '-7px' }}>
																		{/* тестирование модели */}
																		{clusterServer.status === RequestStatus.IDLE && inputModelName !== '' &&
																			((selectType === 'smc' && (isAccess(SMC.CLASSIFY) || isAccess(SMC.COMPRESS) || isAccess(SMC.EMOTION) || isAccess(SMC.PUNCTUATE) || isAccess(SMC.CORRECT) || isAccess(SMC.NORMALIZE))) || (selectType === 'see' && isAccess(SEE.SEARCH_ENTITIES))) &&
																			<FontAwesomeIcon
																				icon={faCheck}
																				size="lg"
																				onClick={() => setShowManualCheck(prev => !prev)}
																				title={translate('configDataElem_testModelBtn')}
																				style={{ cursor: 'pointer' }}
																			/>
																		}
																	</div>
																),
																endAdornment: (
																	<div style={{ marginTop: '-7px' }}>
																		{clusterServer.status === RequestStatus.LOADING &&
																			<ProgressCircle isBtnDisabled />
																		}
																		{params.InputProps.endAdornment} {/* важно дописать параметры */}
																	</div>
																),
															}}
															sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}
														/>
													}
													sx={{
														".MuiInputBase-root": { height: 33, fontSize: 13, color: colorPrimary },
														".MuiInputBase-input": { marginTop: -1 },
													}}
													getOptionLabel={option => option}
													renderOption={(props, option) => {
														return (
															<span {...props} style={{ fontSize: 13, color: colorPrimary, textAlign: 'left' }}>
																{option}
															</span>
														);
													}}
												/>
											</FormControl>
										}

										{/* класс/сущность/параметр */}
										<FormControl fullWidth margin='dense'>
											<Autocomplete
												freeSolo
												options={classnamesList}
												value={inputClassname}
												onChange={(_, value) => { setInputClassname(value ? value : ''); dataElem.element?.id && setChangeFlg(true); }}
												disabled={!isAccess(SES.DATA_EDIT) || activeRobotVersion !== 'draft'}
												renderInput={(params) =>
													<TextField
														{...params}
														label={translate(selectType === 'smc' ? 'configDataElem_inputSmc' : selectType === 'see' ? 'configDataElem_inputSee' : 'configDataElem_inputParam')}
														onChange={(e) => { setInputClassname(e.target.value); dataElem.element?.id && setChangeFlg(true); }}
														InputLabelProps={{
															style: {
																fontSize: 13,
															},
														}}
														InputProps={{
															...params.InputProps, // важно прокинуть параметры
															endAdornment: (
																<div style={{ marginTop: '-7px' }}>
																	{fullModel.status === RequestStatus.LOADING &&
																		<ProgressCircle isBtnDisabled />
																	}
																	{params.InputProps.endAdornment} {/* важно дописать параметры */}
																</div>
															),
														}}
														sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}
													/>
												}
												sx={{
													".MuiInputBase-root": { height: 33, fontSize: 13, color: colorPrimary },
													".MuiInputBase-input": { marginTop: -1 },
												}}
												getOptionLabel={option => option}
												renderOption={(props, option) => {
													return (
														<span {...props} style={{ fontSize: 13, color: colorPrimary, textAlign: 'left' }}>
															{option}
														</span>
													);
												}}
											/>
										</FormControl>
									</div>

									{/* требуемые данные (slaveData) */}
									{selectType === 'script' && (activeRobotVersion === 'draft' || dataElem.element.slaveData.length > 0) &&
										<fieldset className={styles.dataTopMainFieldset}>
											<legend>{translate('configDataElem_slaveDataTitle')}</legend>
											{dataElem.element.slaveData.map((element, idx) => (
												<SlaveData key={element + idx} slaveData={element} idx={idx} setChangeFlg={setChangeFlg} />
											))}
											{isAccess(SES.DATA_EDIT) && activeRobotVersion === 'draft' &&
												<FormAddingSlaveData setChangeFlg={setChangeFlg} />
											}
										</fieldset>
									}

									<FormControlLabel sx={{ overflow: 'hidden', '.MuiTypography-root': { fontSize: 13, marginTop: '3px' } }} control={
										<Checkbox
											checked={forEndpointRunOnlyFlg}
											disabled={!isAccess(SES.DATA_EDIT) || activeRobotVersion !== 'draft'}
											onChange={changeForEndpointRunOnlyFlgHandler}
											size='small'
										/>
									} label={translate('configDataElem_checkboxForEndpointRunOnly')} />
								</div>

								<div className={styles.dataTopEvents}>
									<h3 className={styles.dataTopEventsTitle}>{translate('configDataElem_eventHeader')}</h3>

									<div className={styles.dataTopEventsBlock}>
										{/* событие */}
										<FormControl fullWidth sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}>
											<InputLabel sx={{ fontSize: 13 }}>{translate('configDataElem_selectType')}</InputLabel>
											<Select
												label={translate('configDataElem_selectType')}
												value={selectEvent}
												onChange={(e) => setSelectEvent(e.target.value as DataElemActionEventType)}
												style={{ fontSize: 13, height: 33, color: colorPrimary }}
											>
												{DATA_ELEM_EVENT_LIST.map(({ event, translation }) =>
													<MenuItem key={event} value={event} sx={{ fontSize: 13, color: colorPrimary }}>{translate(translation)}</MenuItem>
												)}
											</Select>
										</FormControl>

										{/* канал */}
										<FormControl fullWidth>
											<Autocomplete
												freeSolo={isAccess(SES.DATA_EDIT) && activeRobotVersion === 'draft'}
												options={Array.from(
													new Set(
														(Object.keys(dataElem.element.actions[selectEvent] || {}))
															.concat(channelList.data.map(channel => channel.name), 'default')
													))}
												value={inputChannel}
												onChange={(_, value) => setInputChannel(value ? value : '')}
												noOptionsText={<div className={styles.dataTopEventsNoDataTitle}>{translate('notFound')}</div>}
												renderInput={(params) =>
													<TextField
														{...params}
														label={translate('configDataElem_inputChannel')}
														onChange={(e) => isAccess(SES.DATA_EDIT) && setInputChannel(e.target.value)}
														InputLabelProps={{
															style: {
																fontSize: 13,
															},
														}}
														sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}
													/>
												}
												sx={{
													".MuiInputBase-root": { height: 33, fontSize: 13, color: colorPrimary },
													".MuiInputBase-input": { marginTop: -1 },
												}}
												getOptionLabel={option => option}
												renderOption={(props, option) => {
													return (
														<span {...props} style={{ fontSize: 13, color: colorPrimary }}>
															{option}
														</span>
													);
												}}
											/>
										</FormControl>
									</div>

									{/* действия */}
									<div className={styles.dataTopEventsActions}>
										{dataElem.element.actions[selectEvent] && dataElem.element.actions[selectEvent][inputChannel]?.length > 0 && dataElem.element.actions[selectEvent][inputChannel].map((action, idx) => (
											<ActionEvent
												key={`${action.action}${idx}${action.action === 'say' && action.type + (action.type === 'external' ? action.script : action.type === 'internal' ? action.service : action.text.toString())}`}
												action={action}
												idx={idx}
												actionEvent={selectEvent}
												channel={inputChannel}
												setChangeFlg={setChangeFlg}
												itsAction='dataElement'
											/>
										))}

										{isAccess(SES.DATA_EDIT) && activeRobotVersion === 'draft' &&
											<FormAddingAction
												event={selectEvent}
												channel={inputChannel}
												itsAction='dataElement'
												setChangeFlg={setChangeFlg}
											/>
										}
									</div>
								</div>
							</div>

							{activeRobotVersion === 'draft' &&
								<div className={styles.dataButtons}>
									{dataElem.element.id ?
										<>
											{isAccess(SES.DATA_EDIT) &&
												<FormControl fullWidth>
													<Button
														variant="outlined"
														disabled={!changeFlg}
														sx={{ fontSize: 11 }}
														onClick={editDataElemHandler}
													>
														{translate('configDataElem_saveBtn')}
													</Button>
												</FormControl>
											}
											{isAccess(SES.DATA_DELETE) &&
												<FormControl fullWidth>
													<Button
														variant="outlined"
														sx={{ fontSize: 11 }}
														onClick={() => setShowAlertDialogDel(true)}
														color='error'
													>
														{translate('configDataElem_deleteBtn')}
													</Button>
												</FormControl>
											}
										</>
										:
										isAccess(SES.DATA_ADD) &&
										<FormControl fullWidth>
											<Button
												variant="outlined"
												sx={{ fontSize: 11 }}
												onClick={addDataElemHandler}
											>
												{translate('configDataElem_addBtn')}
											</Button>
										</FormControl>
									}
								</div>
							}
						</div>
					</Fade>
				}

				{selectType !== 'script' &&
					<ManualCheckModal
						showManualCheck={showManualCheck}
						setShowManualCheck={setShowManualCheck}
						serviceType={selectType}
						modelName={inputModelName}
						modelNameAsIs
					/>
				}

				<AlertDialog
					showAlertDialog={showAlertDialogDel}
					setShowAlertDialog={setShowAlertDialogDel}
					submitHandler={deleteDataElemHandler}
					title='dialog_deleteDataElemTitle'
					description='dialog_deleteDataElemConfirm'
					name={dataElem.element?.name}
				/>
			</div>
		</Slide>
	);
};

export default ConfigDataElem;
