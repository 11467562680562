interface ITimeConversionProps {
	time: number;
	from: 'ms' | 'sec';
	to: 'withMs' | 'withoutMs';
}

// функция конвертации времени в человекочитаемый формат
export const timeConversion = ({ time, from, to }: ITimeConversionProps): string => {
	if (from === 'ms') {
		const milliseconds = Math.floor((time % 1000));
		let seconds: number | string = Math.floor((time / 1000) % 60);
		let minutes: number | string = Math.floor((time / (1000 * 60)) % 60);
		let hours: number | string = Math.floor((time / (1000 * 60 * 60)) % 24);

		hours = (hours < 10) ? "0" + hours : hours;
		minutes = (minutes < 10) ? "0" + minutes : minutes;
		seconds = (seconds < 10) ? "0" + seconds : seconds;

		if (to === 'withMs') return `${hours}:${minutes}:${seconds}.${milliseconds}`;
		else return `${hours}:${minutes}:${seconds}`;
	} else {
		let seconds: number | string = Math.floor(time % 60);
		let minutes: number | string = Math.floor((time / 60) % 60);
		let hours: number | string = Math.floor((time / (60 * 60)) % 24);

		hours = (hours < 10) ? "0" + hours : hours;
		minutes = (minutes < 10) ? "0" + minutes : minutes;
		seconds = (seconds < 10) ? "0" + seconds : seconds;

		return `${hours}:${minutes}:${seconds}`;
	}
};
