import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlugCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { commitConfigRobot, clearCommitConfigRobot, selectActiveRobotId, selectCommitConfigRobot } from '../../../../store/sesRobotSlice';
import useTranslate from '../../../../hooks/useTranslate';
import { backgroundColor } from '../../../../constants/colors';
import Notification from '../../../Notification/Notification';
import { IApplyConfigRobotProps } from './ApplyConfigRobot.props';

const ApplyConfigRobot = ({ styleNotActive, isAvailable }: IApplyConfigRobotProps): JSX.Element => {
	const [showNotification, setShowNotification] = useState<boolean>(false); // показ уведомления

	const dispatch = useAppDispatch();
	const activeRobotId = useAppSelector(selectActiveRobotId); // store - id активного робота

	const translate = useTranslate(); // hook для перевода текста

	// обработчик нажатия на кнопку
	const clickHandler = (): void => {
		if (isAvailable && activeRobotId) {
			dispatch(commitConfigRobot(activeRobotId));
			setShowNotification(true);
		}
	};

	return (
		<>
			<div
				className={isAvailable ? '' : styleNotActive}
				onClick={clickHandler}
				title={translate('controlsBtn_applyConfigRobot')}>
				<FontAwesomeIcon icon={faPlugCircleCheck} size="2xl" color={backgroundColor} />
			</div>

			<section style={{ position: 'absolute' }}>
				{showNotification &&
					<Notification
						showNotification={showNotification}
						setShowNotification={setShowNotification}
						selectDataResponse={selectCommitConfigRobot}
						clearDataResponse={clearCommitConfigRobot}
						titleFailed='noticeApplyConfigRobot_failed'
						titleSuccess='noticeApplyConfigRobot_success'
					/>
				}
			</section>
		</>
	);
};

export default ApplyConfigRobot;
