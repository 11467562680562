import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import useTranslate from '../../../../hooks/useTranslate';
import { backgroundColor } from '../../../../constants/colors';
import { IDeleteProps } from './Delete.props';

const Delete = ({ styleNotActive, isAvailable, title = 'controlsBtn_delete', setShowAlertDialog }: IDeleteProps): JSX.Element => {
	const translate = useTranslate(); // hook для перевода текста

	return (
		<div
			className={isAvailable ? '' : styleNotActive}
			onClick={() => isAvailable && setShowAlertDialog(true)}
			title={translate(title)}>
			<FontAwesomeIcon icon={faTrashCan} size="2xl" color={backgroundColor} />
		</div>
	);
};

export default Delete;
