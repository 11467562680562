// функция раскрашивания слова
export const colorizeWord = (value: number): string | undefined => {
	// const rgb = colorRedRGB;
	// const calc = (max: number): number => Math.round(255 - (255 - max) * (1 - value));
	// const r = calc(rgb[0]);
	// const g = calc(rgb[1]);
	// const b = calc(rgb[2]);
	// const color = `rgb(${r},${g},${b})`;
	// return color;
	if (value >= 0 && value <= 0.4) return 'red';
	else if (value > 0.4 && value <= 0.7) return '#ff7e94'; // что-то среднее между красным и розовым
};
