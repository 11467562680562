import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileZipper, faGaugeHigh, faPenToSquare, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import cn from 'classnames';
import useTranslate from '../../../../hooks/useTranslate';
import { backgroundColor } from '../../../../constants/colors';
import { IRobotVersionProps } from './RobotVersion.props';
import styles from './RobotVersion.module.scss';

const robotVersionButtons: Record<string, { icon: IconDefinition, title: string }> = {
	draft: { icon: faPenToSquare, title: 'modelControl_futureBtn' },
	prod: { icon: faGaugeHigh, title: 'modelControl_currentBtn' },
	backup: { icon: faFileZipper, title: 'modelControl_previousBtn' },
};

const RobotVersion = ({ styleNotActive, isAvailable, isActive, version, onClickFunction }: IRobotVersionProps): JSX.Element => {
	const translate = useTranslate(); // hook для перевода текста

	return (
		<div
			className={cn({
				[styleNotActive]: !isAvailable,
				[styles.active]: isActive,
			})}
			onClick={() => isAvailable && onClickFunction()}
			title={translate(robotVersionButtons[version].title)}
		>
			<FontAwesomeIcon
				icon={robotVersionButtons[version].icon}
				size="2xl"
				color={backgroundColor}
			/>
		</div>
	);
};

export default RobotVersion;
