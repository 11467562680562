import { FormEvent, Ref, forwardRef, useEffect, useRef, useState } from 'react';
import { Autocomplete, Box, Button, Chip, FormControl, InputAdornment, InputLabel, MenuItem, Popper, Select, TextField, createFilterOptions } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan, faXmark } from '@fortawesome/free-solid-svg-icons';
import cn from 'classnames';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { addClassToRows, addRows, deleteClass, deleteClassFromRows, deleteRows, selectCorpus } from '../../../../store/corpusSlice';
import useAccessRight from '../../../../hooks/useAccessRight';
import useTranslate from '../../../../hooks/useTranslate';
import { wordDeclination } from '../../../../helpers/wordDeclination';
import { CORPUS } from '../../../../constants/accessRights';
import { backgroundColor, colorPrimary, colorRed, colorSecondary } from '../../../../constants/colors';
import { IDialogAddPhrasesProps } from '../../../../types/classTreeTypes';
import { MethodCopyRow } from '../../../../types/tableTypes';
import TableCustomPagination from '../../TableCustomPagination/TableCustomPagination';
import AlertDialog from '../../../AlertDialog/AlertDialog';
import { ICustomFooterProps } from './CustomFooter.props';
import styles from './CustomFooter.module.scss';

const rowCopyMethodOptions: { method: MethodCopyRow, desc: string }[] = [
	{ method: 'simpleCopy', desc: 'corpusTable_simpleCopy' },
	{ method: 'copyWithAddition', desc: 'corpusTable_copyWithAddition' },
	{ method: 'copyWithDeletion', desc: 'corpusTable_copyWithDeletion' },
];

const CustomFooter = ({ setChangeFlg, table, rowSelection, tableContainerRef, serviceType, typeCorpusData }: ICustomFooterProps): JSX.Element => {
	const [inputClass, setInputClass] = useState<string>(''); // класс для добавления в корпус данных SEE
	const [inputClasses, setInputClasses] = useState<string[]>([]); // классы для добавления в корпус данных SMC
	const [inputPhrases, setInputPhrases] = useState<string>(''); // фраза (фразы) для добавления в корпус данных
	const [inputGroup, setInputGroup] = useState<string>(''); // группа для добавления в корпус данных
	const [inputCommonClasses, setInputCommonClasses] = useState<string[]>([]); // общие классы для добавления в несколько строк корпуса данных
	const [inputCommonClassesForDelete, setInputCommonClassesForDelete] = useState<string[]>([]); // классы для удаления из нескольких строк корпуса данных
	const [inputCommonClassesForCopy, setInputCommonClassesForCopy] = useState<string[]>([]); // доп.классы для новых копированных строк корпуса данных
	const [selectMethodForCopy, setSelectMethodForCopy] = useState<MethodCopyRow>('simpleCopy'); // выбор метода для копирования строк корпуса данных
	const [classListForDelete, setClassListForDelete] = useState<string[]>([]); // список классов для формы удаления из строк
	const inputClassRef = useRef<HTMLInputElement>(); // ссылка на input класса
	const inputGroupRef = useRef<HTMLInputElement>(); // ссылка на input группы
	const [showAlertDialogMarkup, setShowAlertDialogMarkup] = useState<IDialogAddPhrasesProps>({ isShow: false, desc: '', parents: [], quantityRows: 0 }); // показ диалогового окна после добавления строки с классами/фразами для доп.разметки

	const dispatch = useAppDispatch();
	const corpus = useAppSelector(selectCorpus); // store - корпус

	const isAccess = useAccessRight(); // hook для проверки прав доступа
	const translate = useTranslate(); // hook для перевода текста

	// следим за статусом получения корпуса данных и очищаем input'ы
	useEffect(() => {
		setInputClass('');
		setInputClasses([]);
		setInputPhrases('');
		setInputGroup('');
		setInputCommonClasses([]);
		setInputCommonClassesForDelete([]);
		setInputCommonClassesForCopy([]);
		setSelectMethodForCopy('simpleCopy');
	}, [corpus.status]);

	// следим за выбором метода копировния строки
	useEffect(() => {
		// если переключено на простое и были выбраны классы - очищаем
		selectMethodForCopy === 'simpleCopy' && inputCommonClassesForCopy.length > 0 && setInputCommonClassesForCopy([]);
	}, [selectMethodForCopy]);

	// следим за выбранными строками
	useEffect(() => {
		const list: string[] = [];
		const keys = Object.keys(rowSelection).map(value => +value); // пишем номера строк
		// перебираем корпус и пишем уникальные классы в список
		corpus.data[typeCorpusData].forEach((row, idx) => {
			keys.includes(idx) && row[0].forEach(classItem => !list.includes(classItem) && list.push(classItem));
		});
		setClassListForDelete(list);
	}, [rowSelection]);

	// функция, определяющая отфильтрованные параметры, которые будут отображаться при поиске
	const filter = createFilterOptions<string>();

	// обработчик добавления строк в корпус данных
	const submitAddRowsHandler = (e: FormEvent<HTMLFormElement>): void => {
		e.preventDefault();
		if (serviceType === 'smc' && inputClasses.length === 0) return; // если в поле классов вписан, но не выбран класс
		// для таблицы Классы-Фраза
		if (typeCorpusData === 'data') {
			const match = inputPhrases.match(/^.+$/gm); // разделяем фразы по строкам, если их несколько
			// создаем строки для добаления в корпус данных
			const rows: [string[], string][] | undefined = match?.map(phrase => {
				if (serviceType === 'smc') return [inputClasses.sort(), phrase];
				else return [[inputClass], phrase];
			});
			rows && dispatch(addRows({ data: rows, type: typeCorpusData })); // добавление строк в корпус данных в store
			// -- доп.разметка -- только для корпусов smc
			if (serviceType === 'smc') {
				const parents: string[] = []; // список родителей для доп.разметки
				corpus.data.classes.forEach(([children, parent]) => {
					// ищем совпадение добавляемых классов в потомках и не являющихся родителем
					const intersection = children.find(x => inputClasses.includes(x) && !inputClasses.includes(parent));
					intersection && !parents.includes(parent) && parents.push(parent); // добавляем не повторяющихся родителей в список
				});
				if (parents.length === 1) {
					setShowAlertDialogMarkup({
						isShow: true,
						desc: `${translate('dialog_murkupPhrasesAdd_1ShortConfirm')} ${translate('dialog_murkupPhrasesAdd_2Confirm')} "${parents[0]}" ${translate('dialog_murkupPhrasesAdd_3Confirm')}?`,
						parents,
						quantityRows: rows?.length || 0,
					}); // если есть один родитель - включаем оповещение
				} else if (parents.length > 1) {
					setShowAlertDialogMarkup({
						isShow: true,
						desc: `${translate('dialog_murkupPhrasesAdd_1ShortConfirm')} ${translate('dialog_murkupPhrasesAdd_2PluralConfirm')} "${parents.join('", "')}" ${translate('dialog_murkupPhrasesAdd_3Confirm')}?`,
						quantityRows: rows?.length || 0,
						parents,
					}); // если есть много родителей - включаем оповещение
				}
			}
		}
		// для таблицы Классы-Группа
		if (typeCorpusData === 'groups') {
			dispatch(addRows({ data: [[inputClasses.sort(), inputGroup]], type: typeCorpusData })); // добавление строк в корпус данных в store
		}
		setChangeFlg(true); // включаем флаг о не сохраненных данных
		setInputClass(''); // очищаем поле "Класс"
		setInputClasses([]); // очищаем поле "Классы"
		setInputPhrases(''); // очищаем поле "Фраза"
		setInputGroup(''); // очищаем поле "Группа"
		typeCorpusData === 'data' && inputClassRef.current?.focus(); // ставим фокус на поле "Класс"
		typeCorpusData === 'groups' && inputGroupRef.current?.focus(); // ставим фокус на поле "Группа"
	};

	// дополнение разметки родительскими классами
	const additionalMarkup = (): void => {
		// создаем массив из чисел - индексы новых строк в корпусе
		const arrayRowsIndex = [...Array(showAlertDialogMarkup.quantityRows)].map((_, i) => corpus.data.data.length - 1 - i);
		dispatch(addClassToRows({
			rows: arrayRowsIndex,
			classNames: showAlertDialogMarkup.parents,
			type: typeCorpusData
		})); // добавление родительских классов
		setShowAlertDialogMarkup(prev => {
			return { ...prev, isShow: false };
		}); // закрываем диалоговое окно
	};

	// обработчик нажатой клавиши
	const keyDownHandler = (e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
		// если нажата клавиша Tab - ставим фокус на поле "Класс"
		if (e.code === 'Tab') {
			e.preventDefault();
			inputClassRef.current?.focus();
		}

		// если комбинация Ctrl+Enter - добавляем строку
		if (e.ctrlKey && e.code === 'Enter') {
			// это могло бы работать, 6 элемент - button
			// @ts-ignore
			e.target.form[6]?.click();
		}
	};

	// подсчет классов в выделенных строках
	const countingClassesInSelectedRows = (): Record<string, number> => {
		const classAndCount: Record<string, number> = {}; // классы и количество для подсчета
		// перебираем номера строк таблицы
		Object.keys(rowSelection).map(value => +value).forEach(rowNumber => {
			// перебираем классы строк
			corpus.data.data[rowNumber][0].forEach(className => {
				// если в списке классов для подсчета содержится текущий класс - прибавляем количество
				if (classAndCount.hasOwnProperty(className)) classAndCount[className]++;
				else classAndCount[className] = 1; // иначе начинаем счет
			});
		});
		return classAndCount;
	};

	// обработчик удаления выделенных строк
	const deleteRowsHandler = (): void => {
		dispatch(deleteRows({ rows: Object.keys(rowSelection).map(value => +value), type: typeCorpusData })); // удаляем строки из корпуса данных в store
		if (typeCorpusData === 'data') {
			const classAndCountForDelete = countingClassesInSelectedRows(); // классы и количество для подсчета на удаление
			// перебираем классы на удаление какие были в последних числах
			for (const className in classAndCountForDelete) {
				if (classAndCountForDelete[className] === corpus.classAndCount[className]) dispatch(deleteClass(className)); // удаляем класс
			}
		}
		setChangeFlg(true); // включаем флаг о не сохраненных данных
	};

	// обработчик добавления класса в несколько строк
	const addClassToRowsHandler = (e: FormEvent<HTMLFormElement>): void => {
		e.preventDefault();
		dispatch(addClassToRows({ rows: Object.keys(rowSelection).map(value => +value), classNames: inputCommonClasses, type: typeCorpusData })); // добавление класса
		setInputCommonClasses([]); // очищаем поле
		setChangeFlg(true); // включаем флаг о не сохраненных данных
	};

	// обработчик удаления класса из нескольких строк
	const deleteClassFromRowsHandler = (): void => {
		dispatch(deleteClassFromRows({ rows: Object.keys(rowSelection).map(value => +value), classNames: inputCommonClassesForDelete, type: typeCorpusData })); // удаление класса

		// только для таблицы с данными
		if (typeCorpusData === 'data') {
			const classAndCountForDelete = countingClassesInSelectedRows(); // классы и количество для подсчета на удаление
			// перебираем классы на удаление какие были в последних числах
			for (const className in classAndCountForDelete) {
				if (classAndCountForDelete[className] === corpus.classAndCount[className] && inputCommonClassesForDelete.includes(className)) dispatch(deleteClass(className)); // удаляем класс
			}
		}

		setInputCommonClassesForDelete([]); // очищаем поле
		setChangeFlg(true); // включаем флаг о не сохраненных данных
	};

	// обработчик копирования строк в таблице
	const copyRowHandler = (e: FormEvent<HTMLFormElement>): void => {
		e.preventDefault();
		const copy: [string[], string][] = JSON.parse(JSON.stringify(corpus.data[typeCorpusData])); // копия данных
		const rowSelectionIds = Object.keys(rowSelection).map(value => +value); // список индексов выделенных строк
		const newRows: [string[], string][] = copy
			.filter((_row, idx) => rowSelectionIds.includes(idx))
			.map(row => {
				if (selectMethodForCopy === 'copyWithDeletion') {
					const difference = row[0].filter(classItem => !inputCommonClassesForCopy.includes(classItem));
					return [difference, row[1]];
				} else if (selectMethodForCopy === 'copyWithAddition') {
					row[0].push(...inputCommonClassesForCopy); // добавляем общие классы
					row[0].sort(); // восстанавливаем сортировку
					const newSet = new Set(row[0]); // убираем повторные классы
					const uniqueClasses = Array.from(newSet);
					return [uniqueClasses, row[1]];
				} else {
					return row;
				}
			});
		dispatch(addRows({ data: newRows, type: typeCorpusData })); // добавляем новые копированные строки
		setInputCommonClassesForCopy([]); // очищаем поле
		setChangeFlg(true); // включаем флаг о не сохраненных данных
	};

	return (
		<div className={cn(styles.footer, {
			[styles.footerBoxShadow]: tableContainerRef.current && tableContainerRef.current.scrollHeight - tableContainerRef.current.offsetHeight > Math.ceil(tableContainerRef.current.scrollTop) + 1
		})}>
			{Object.keys(rowSelection).length > 0 && isAccess(CORPUS.SAVE) &&
				<>
					<div className={styles.selectedRow}>
						<span>{translate('corpusTable_selectedTitle')} {Object.keys(rowSelection).length} {wordDeclination(Object.keys(rowSelection).length, [translate('corpusTable_rowTitle'), translate('corpusTable_rowsTitle1'), translate('corpusTable_rowsTitle2')])}</span>
						<FontAwesomeIcon icon={faTrashCan} color={colorRed} onClick={deleteRowsHandler} title={translate('corpusTable_deleteRowsBtn')} style={{ cursor: 'pointer' }} />
					</div>
					{serviceType === 'smc' &&
						<>
							<form className={styles.formAddCommonClass} onSubmit={e => addClassToRowsHandler(e)}>
								<Autocomplete
									multiple
									disableCloseOnSelect
									options={corpus.classes}
									freeSolo={typeCorpusData === 'data'}
									noOptionsText={<div className={styles.formAddCommonClassNoDataTitle}>{translate('notFound')}</div>}
									autoHighlight
									filterOptions={(options, state) => {
										const filtered = filter(options, state);
										if (state.inputValue.length > 0 && options.findIndex(className => className === state.inputValue) === -1 && typeCorpusData === 'data') filtered.push(state.inputValue);
										return filtered;
									}}
									value={inputCommonClasses}
									onChange={(_, value) => setInputCommonClasses(value.map(className => className.replace(/,/g, '')))}
									renderInput={(params) =>
										<TextField
											autoFocus
											multiline
											maxRows={2}
											{...params}
											variant="outlined"
										/>
									}
									sx={{
										".MuiInputBase-root": { minHeight: 20, fontSize: 12, color: colorPrimary },
										".MuiOutlinedInput-root": { padding: '0 0 0 3px', paddingRight: "5px!important" },
										".MuiAutocomplete-input.MuiAutocomplete-input": { padding: 0, color: colorSecondary },
										".Mui-focused .MuiOutlinedInput-notchedOutline": { border: `1px solid ${colorPrimary}!important`, borderRadius: '3px' },
										overflow: 'hidden',
										flexGrow: 1,
									}}
									getOptionLabel={option => option}
									renderOption={(props, option, _state, ownerState) => {
										const match = ownerState.options.filter(classItem => classItem === option);
										return (
											<li {...props} style={{ padding: '0 5px', textAlign: 'left', fontSize: 11, color: colorPrimary }}>
												{match.length === 0 ?
													<>{translate('corpusTable_addNewClassTitle')} "{option}"</>
													:
													<>{option}</>
												}
											</li>
										);
									}}
									PopperComponent={(props) =>
										<Popper {...props} placement={'top'} sx={{ ".MuiAutocomplete-listbox": { maxHeight: 130 }, boxShadow: `0 0 5px 1px ${colorPrimary}`, borderRadius: 3 }}>
											{props.children}
										</Popper>
									}
									ListboxComponent={forwardRef((props, ref: Ref<HTMLUListElement>) =>
										<ul {...props} ref={ref}>
											{props.children}
											<div className={styles.blockFillGradientTop} />
											<div className={styles.blockFillGradientBottom} />
										</ul>
									)}
									renderTags={(value: readonly string[], getTagProps) =>
										value.map((option: string, index: number) =>
											<span {...getTagProps({ index })} className={styles.tag}>
												{option}{(value.length - 1) !== index && ','}
											</span>
										)
									}
								/>
								<Button variant="outlined" type='submit' disabled={inputCommonClasses.length === 0} sx={{ flexShrink: 0, height: 20, fontSize: 11, overflow: 'hidden' }}>{translate('corpusTable_addCommonClassBtn')}</Button>
							</form>
							<div className={styles.formDeleteClasses}>
								<Autocomplete
									multiple
									disableCloseOnSelect
									options={classListForDelete}
									autoHighlight
									noOptionsText={<div className={styles.formDeleteClassesNoDataTitle}>{translate('notFound')}</div>}
									value={inputCommonClassesForDelete}
									onChange={(_, value) => setInputCommonClassesForDelete(value)}
									renderInput={(params) =>
										<TextField
											multiline
											maxRows={2}
											{...params}
											variant="outlined"
										/>
									}
									sx={{
										".MuiInputBase-root": { height: 20, fontSize: 12, color: colorPrimary },
										".MuiOutlinedInput-root": { padding: '0 0 0 3px', paddingRight: "5px!important" },
										".MuiAutocomplete-input.MuiAutocomplete-input": { padding: 0, color: colorSecondary },
										".Mui-focused .MuiOutlinedInput-notchedOutline": { border: `1px solid ${colorPrimary}!important`, borderRadius: '3px' },
										overflow: 'hidden',
										flexGrow: 1,
									}}
									renderOption={(props, option, _state) => (
										<li {...props} style={{ padding: '0 5px', textAlign: 'left', fontSize: 11, color: colorPrimary }}>
											{option}
										</li>
									)}
									PopperComponent={(props) =>
										<Popper {...props} placement={'top'} sx={{ ".MuiAutocomplete-listbox": { maxHeight: 130 }, boxShadow: `0 0 5px 1px ${colorPrimary}`, borderRadius: 3 }}>
											{props.children}
										</Popper>
									}
									ListboxComponent={forwardRef((props, ref: Ref<HTMLUListElement>) =>
										<ul {...props} ref={ref}>
											{props.children}
											<div className={styles.blockFillGradientTop} />
											<div className={styles.blockFillGradientBottom} />
										</ul>
									)}
									renderTags={(value: readonly string[], getTagProps) =>
										value.map((option: string, index: number) =>
											<span {...getTagProps({ index })} className={styles.tag}>
												{option}{(value.length - 1) !== index && ','}
											</span>
										)
									}
								/>
								<Button
									variant="outlined"
									disabled={inputCommonClassesForDelete.length === 0}
									onClick={deleteClassFromRowsHandler}
									sx={{ flexShrink: 0, height: 20, fontSize: 11, overflow: 'hidden' }}
								>
									{translate('corpusTable_deleteClassBtn')}
								</Button>
							</div>
							{typeCorpusData === 'groups' &&
								<form className={styles.formCopyRow} onSubmit={e => copyRowHandler(e)}>
									<Autocomplete
										multiple
										disableCloseOnSelect
										options={selectMethodForCopy === 'copyWithDeletion' ? classListForDelete : corpus.classes}
										noOptionsText={<div className={styles.formCopyRowNoDataTitle}>{translate('notFound')}</div>}
										disabled={selectMethodForCopy === 'simpleCopy'}
										autoHighlight
										value={inputCommonClassesForCopy}
										onChange={(_, value) => setInputCommonClassesForCopy(value)}
										renderInput={(params) =>
											<TextField
												multiline
												maxRows={2}
												{...params}
												variant="outlined"
											/>
										}
										sx={{
											".MuiInputBase-root": { minHeight: 20, fontSize: 12, color: colorPrimary },
											".MuiOutlinedInput-root": { padding: '0 0 0 3px', paddingRight: "5px!important" },
											".MuiAutocomplete-input.MuiAutocomplete-input": { padding: 0, color: colorSecondary },
											".Mui-focused .MuiOutlinedInput-notchedOutline": { border: `1px solid ${colorPrimary}!important`, borderRadius: '3px' },
											overflow: 'hidden',
											flexGrow: 1,
										}}
										getOptionLabel={option => option}
										renderOption={(props, option, _state, _ownerState) => {
											return (
												<li {...props} style={{ padding: '0 5px', textAlign: 'left', fontSize: 11, color: colorPrimary }}>
													{option}
												</li>
											);
										}}
										PopperComponent={(props) =>
											<Popper {...props} placement={'top'} sx={{ ".MuiAutocomplete-listbox": { maxHeight: 130 }, boxShadow: `0 0 5px 1px ${colorPrimary}`, borderRadius: 3 }}>
												{props.children}
											</Popper>
										}
										ListboxComponent={forwardRef((props, ref: Ref<HTMLUListElement>) =>
											<ul {...props} ref={ref}>
												{props.children}
												<div className={styles.blockFillGradientTop} />
												<div className={styles.blockFillGradientBottom} />
											</ul>
										)}
										renderTags={(value: readonly string[], getTagProps) =>
											value.map((option: string, index: number) =>
												<span {...getTagProps({ index })} className={styles.tag}>
													{option}{(value.length - 1) !== index && ','}
												</span>
											)
										}
									/>
									<Select
										id="selectCopy"
										value={selectMethodForCopy}
										onChange={e => setSelectMethodForCopy(e.target.value as MethodCopyRow)}
										style={{ fontSize: 12, height: 20, color: colorPrimary }}
									>
										{rowCopyMethodOptions.map(value =>
											<MenuItem key={value.method} value={value.method} sx={{ fontSize: 12, padding: '0 5px', color: colorPrimary }}>{translate(value.desc)}</MenuItem>
										)}
									</Select>
									<Button
										variant="outlined"
										type='submit'
										disabled={selectMethodForCopy !== 'simpleCopy' && inputCommonClassesForCopy.length === 0}
										sx={{ flexShrink: 0, height: 20, fontSize: 11, overflow: 'hidden' }}
									>
										{translate('corpusTable_copyRowsBtn')}
									</Button>
								</form>
							}
						</>
					}
				</>
			}
			<Box sx={{ display: 'flex', gap: '8px', alignItems: 'center', justifyContent: 'flex-end' }}>
				{isAccess(CORPUS.SAVE) &&
					<form className={styles.formAddRows} onSubmit={e => submitAddRowsHandler(e)}>
						{typeCorpusData === 'groups' &&
							<Autocomplete
								options={corpus.groups}
								freeSolo
								openOnFocus
								autoHighlight
								value={inputGroup}
								onChange={(_e, value) => {
									value === null ? setInputGroup('') : setInputGroup(value);
								}}
								filterOptions={(options, state) => {
									const filtered = filter(options, state);
									if (state.inputValue.length > 0 && options.findIndex(className => className === state.inputValue) === -1) filtered.push(state.inputValue);
									return filtered;
								}}
								renderInput={(params) =>
									<TextField
										{...params}
										inputRef={inputGroupRef}
										required
										label={translate('corpusTable_inputGroup')}
										onChange={(e) => setInputGroup(e.target.value)}
										variant="outlined"
										InputLabelProps={{
											style: {
												fontSize: 13,
											},
										}}
										sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8, padding: 0 } }}
									/>
								}
								sx={{
									width: 'calc((100% - 110px) * 0.4)',
									".MuiInputBase-root": { minHeight: 36, fontSize: 13, color: colorPrimary },
									".MuiOutlinedInput-root": { padding: '0 9px' },
								}}
								getOptionLabel={option => option}
								renderOption={(props, option, _state, ownerState) => {
									const match = ownerState.options.filter(classItem => classItem === option);
									return (
										<li {...props} style={{ padding: '2px 5px', textAlign: 'left', fontSize: 12, color: colorPrimary }}>
											{match.length === 0 ?
												<>{translate('corpusTable_addNewGroupTitle')} "{option}"</>
												:
												<>{option}</>
											}
										</li>
									);
								}}
								PopperComponent={(props) =>
									<Popper {...props} placement={'top'} /* sx={{ boxShadow: `0 0 5px 1px ${colorPrimary}`, borderRadius: 3 }} */>
										{props.children}
									</Popper>
								}
								ListboxComponent={forwardRef((props, ref: Ref<HTMLUListElement>) =>
									<ul {...props} ref={ref}>
										{props.children}
										<div className={styles.blockFillGradientTop} />
										<div className={styles.blockFillGradientBottom} />
									</ul>
								)}
							/>}
						<Autocomplete
							multiple={serviceType === 'smc'}
							// limitTags={1}
							disableCloseOnSelect={serviceType === 'smc'}
							options={corpus.classes}
							freeSolo={typeCorpusData !== 'groups'}
							openOnFocus
							autoHighlight
							noOptionsText={<div className={styles.blockNoOptions}>{translate('corpusTable_noOptionsTitle')}</div>}
							value={serviceType === 'smc' ? inputClasses : inputClass}
							onChange={(_e, value) => {
								if (Array.isArray(value)) {
									value === null ? setInputClasses([]) : setInputClasses(value.map(className => className.replace(/,/g, '')));
								} else {
									value === null ? setInputClass('') : setInputClass(value);
								}
							}}
							filterSelectedOptions={serviceType === 'smc'}
							filterOptions={(options, state) => {
								const filtered = filter(options, state);
								if (state.inputValue.length > 0 && options.findIndex(className => className === state.inputValue) === -1 && typeCorpusData !== 'groups') filtered.push(state.inputValue);
								return filtered;
							}}
							renderInput={(params) =>
								<TextField
									{...params}
									inputRef={inputClassRef}
									required={serviceType === 'see' || inputClasses.length === 0}
									label={translate(serviceType === 'smc' ? 'corpusTable_inputClasses' : 'corpusTable_inputClass')}
									onChange={(e) => setInputClass(e.target.value)}
									variant="outlined"
									InputLabelProps={{
										style: {
											fontSize: 13,
										},
									}}
									sx={{
										'.MuiInputLabel-root[data-shrink="false"]': { top: -8, padding: 0 },
										'.MuiInputLabel-root[data-shrink="true"]': { backgroundColor: backgroundColor, borderRadius: '3px', zIndex: 0 }
									}}
								/>
							}
							sx={{
								position: 'absolute',
								bottom: 0,
								width: typeCorpusData === 'data' ? 'calc((100% - 110px) * 0.4)' : 'calc((100% - 110px) * 0.6)',
								right: typeCorpusData === 'data' ? undefined : 100,
								'::WebkitBackdropFilter': (inputClass.length > 0 || inputClasses.length > 0) ? 'blur(8px)' : 'none',
								backdropFilter: (inputClass.length > 0 || inputClasses.length > 0) ? 'blur(8px)' : 'none',
								".MuiAutocomplete-popupIndicator": { display: 'none' },
								".MuiInputBase-root": { minHeight: 36, fontSize: 13, color: colorPrimary },
								".MuiOutlinedInput-root": { padding: '0 9px' },
							}}
							getOptionLabel={option => option}
							renderOption={(props, option, _state, ownerState) => {
								const match = ownerState.options.filter(classItem => classItem === option);
								return (
									<li {...props} style={{ padding: '2px 5px', textAlign: 'left', fontSize: 12, color: colorPrimary }}>
										{match.length === 0 ?
											<>{translate('corpusTable_addNewClassTitle')} "{option}"</>
											:
											<>{option}</>
										}
									</li>
								);
							}}
							renderTags={(value: readonly string[], getTagProps) =>
								value.map((option: string, index: number) => (
									<Chip label={option} {...getTagProps({ index })} sx={{ maxWidth: 'calc(100% - 50px)!important', height: 17, color: colorPrimary }} />
								))
							}
							PopperComponent={(props) =>
								<Popper {...props} placement={'top'} /* sx={{ boxShadow: `0 0 5px 1px ${colorPrimary}`, borderRadius: 3 }} */>
									{props.children}
								</Popper>
							}
							ListboxComponent={forwardRef((props, ref: Ref<HTMLUListElement>) =>
								<ul {...props} ref={ref}>
									{props.children}
									<div className={styles.blockFillGradientTop} />
									<div className={styles.blockFillGradientBottom} />
								</ul>
							)}
						/>
						{typeCorpusData !== 'groups' &&
							<TextField
								required
								label={translate('corpusTable_inputPhrase')}
								size="small"
								value={inputPhrases}
								onChange={e => setInputPhrases(e.target.value)}
								variant="outlined"
								multiline
								maxRows={10}
								InputProps={{
									onKeyDown: (e) => keyDownHandler(e),
									endAdornment: (
										<InputAdornment position="end" >
											{inputPhrases.length > 0 && <FontAwesomeIcon icon={faXmark} onClick={() => setInputPhrases('')} style={{ cursor: 'pointer ' }} />}
										</InputAdornment>
									),
									style: {
										fontSize: 13,
										color: colorPrimary,
									},
								}}
								InputLabelProps={{
									style: {
										fontSize: 13,
									},
								}}
								sx={{
									width: 'calc((100% - 110px) * 0.6)',
									position: 'absolute',
									right: 100,
									bottom: 0,
									'::WebkitBackdropFilter': inputPhrases.length ? 'blur(8px)' : 'none',
									backdropFilter: inputPhrases.length > 0 ? 'blur(8px)' : 'none',
									'.MuiInputLabel-root[data-shrink="false"]': { top: -2 },
									'.MuiInputLabel-root[data-shrink="true"]': { backgroundColor: backgroundColor, borderRadius: '3px' },
								}}
							/>
						}
						<Button
							variant="outlined"
							type='submit'
							disabled={
								(serviceType === 'see' && (inputClass === '' || inputPhrases === '')) ||
								(serviceType === 'smc' && typeCorpusData === 'data' && (inputClasses.length === 0 || inputPhrases === '')) ||
								(serviceType === 'smc' && typeCorpusData === 'groups' && (inputClasses.length === 0 || inputGroup === ''))
							}
							sx={{ position: 'absolute', right: 0, height: '100%', width: 90, fontSize: 11, overflow: 'hidden' }}
						>
							{translate('corpusTable_addBtn')}
						</Button>
					</form >
				}
				<div className={styles.pagination}>
					<FormControl sx={{ width: 120, '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}>
						<InputLabel id="demo-simple-select-label" sx={{ fontSize: 13 }}>{translate('corpusTable_selectCountRows')}</InputLabel>
						<Select
							labelId="demo-simple-select-label"
							id="simple-select"
							value={table.getState().pagination.pageSize}
							label={translate('corpusTable_selectCountRows')}
							onChange={e => table.setPageSize(Number(e.target.value))}
							style={{ fontSize: 13, height: 36, color: colorPrimary }}
						>
							{[10, 20, 50, 100, 500, 1000].map(value => <MenuItem key={value} value={value} sx={{ fontSize: 13, color: colorPrimary }}>{value}</MenuItem>)}
						</Select>
					</FormControl>
					<TableCustomPagination table={table} tableContainerRef={tableContainerRef} />
				</div>
			</Box>

			<AlertDialog
				showAlertDialog={showAlertDialogMarkup.isShow}
				closeHandler={() => setShowAlertDialogMarkup(prev => { return { ...prev, isShow: false }; })}
				submitHandler={additionalMarkup}
				title='dialog_murkupPhrasesTitle'
				description={showAlertDialogMarkup.desc}
			/>
		</div>
	);
};

export default CustomFooter;
