import { FormEvent, useEffect, useState } from 'react';
import { Button, Fade, Slide } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faPen, faTrashCan, faXmark } from '@fortawesome/free-solid-svg-icons';
import cn from 'classnames';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { clearAddingCategory, clearCategoriesList, clearDeletingCategory, clearEditingCategory, deleteCategory, editCategory, getCategoriesList, selectAddingCategory, selectCategoriesList, selectDeletingCategory, selectEditingCategory } from '../../store/qasSlice';
import useAccessRight from '../../hooks/useAccessRight';
import useTranslate from '../../hooks/useTranslate';
import { QAS } from '../../constants/accessRights';
import { colorGreen, colorPrimary, colorRed } from '../../constants/colors';
import { ICategory } from '../../types/qasTypes';
import { RequestStatus, ResponseStatus } from '../../types/statusTypes';
import FormAddingCategory from '../Forms/FormAddingCategory/FormAddingCategory';
import ScreenLock from '../ScreenLock/ScreenLock';
import AlertDialog from '../AlertDialog/AlertDialog';
import Notification from '../Notification/Notification';
import ProgressCircle from '../ProgressCircle/ProgressCircle';
import { ICategoriesProps } from './Categories.props';
import styles from './Categories.module.scss';

const Categories = ({ showCategories, setShowCategories }: ICategoriesProps): JSX.Element => {
	const [showModalAddCategory, setShowModalAddCategory] = useState<boolean>(false); // показ формы добавления категории
	const [inputRenameCategory, setInputRenameCategory] = useState<string>(''); // имя категории для переименования
	const [renameCategoryFlg, setRenameCategoryFlg] = useState<{ isOpen: boolean, id: null | string }>({ isOpen: false, id: null }); // флаг и индекс для формы переименования категории
	const [selectedCategoryToBeRemoved, setSelectedCategoryToBeRemoved] = useState<ICategory>({ id: '', name: '' }); // выбранная категория для удаления
	const [noticeExistingCategory, setNoticeExistingCategory] = useState<boolean>(false); // уведомление о существующей категории
	const [showAlertDialogDelCategory, setShowAlertDialogDelCategory] = useState<boolean>(false); // показ диалогового окна при удалении категории
	const [showNotificationAdd, setShowNotificationAdd] = useState<boolean>(false); // показ уведомления о добавлении категории
	const [showNotificationEdit, setShowNotificationEdit] = useState<boolean>(false); // показ уведомления об изменении категории
	const [showNotificationDelete, setShowNotificationDelete] = useState<boolean>(false); // показ уведомления об удалении категории
	const [showScreenLock, setShowScreenLock] = useState<{ isShow: boolean, title: string }>({ isShow: false, title: '' }); // показ экрана блокировки и подпись

	const dispatch = useAppDispatch();
	const categoriesList = useAppSelector(selectCategoriesList); // store - список категорий
	const addingCategoryStatus = useAppSelector(selectAddingCategory); // store - статус добавления категории
	const editingCategoryStatus = useAppSelector(selectEditingCategory); // store - статус изменения категории
	const deletingCategoryStatus = useAppSelector(selectDeletingCategory); // store - статус удаления категории

	const isAccess = useAccessRight(); // hook для проверки прав доступа
	const translate = useTranslate(); // hook для перевода текста

	// следим за статусом изменения/удаления категории
	useEffect(() => {
		// если идет изменение/удаление - включаем экран блокировки с подписью
		if (editingCategoryStatus.status === RequestStatus.LOADING) setShowScreenLock({ isShow: true, title: translate('screenLock_renameCategoryTitle') });
		else if (deletingCategoryStatus.status === RequestStatus.LOADING) setShowScreenLock({ isShow: true, title: translate('screenLock_deleteCategoryTitle') });
		else setShowScreenLock({ isShow: false, title: '' }); // иначе выключаем

		// если изменилось или удалилось успешно 
		if ((editingCategoryStatus.status === RequestStatus.IDLE && editingCategoryStatus.error === ResponseStatus.SUCCESS && editingCategoryStatus.message !== '') || (deletingCategoryStatus.error === ResponseStatus.SUCCESS && deletingCategoryStatus.status === RequestStatus.IDLE && deletingCategoryStatus.message !== '')) {
			dispatch(clearCategoriesList()); // очищаем список категорий
			dispatch(getCategoriesList()); // получаем заново список категорий
		}
	}, [editingCategoryStatus, deletingCategoryStatus]);

	// обработчик нажатия кнопки переименования категории
	const renameCategoryHandler = (categoryName: string, categoryId: string): void => {
		setRenameCategoryFlg({ isOpen: true, id: categoryId }); // открываем форму для переименования
		setInputRenameCategory(categoryName); // записываем в input название категории
	};

	// обработчик нажатия клавишы мыши
	const mouseDownHandler = (e: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
		e.stopPropagation();
		// закрываем уведомления и очищаем статусы добавления/изменения/удаления категории, если были
		if (addingCategoryStatus.status === RequestStatus.FAILED || addingCategoryStatus.error === ResponseStatus.FAILED || addingCategoryStatus.message !== '') {
			dispatch(clearAddingCategory());
			setShowNotificationAdd(false);
		}
		if (editingCategoryStatus.status === RequestStatus.FAILED || editingCategoryStatus.error === ResponseStatus.FAILED || editingCategoryStatus.message !== '') {
			dispatch(clearEditingCategory());
			setShowNotificationEdit(false);
		}
		if (deletingCategoryStatus.status === RequestStatus.FAILED || deletingCategoryStatus.error === ResponseStatus.FAILED || deletingCategoryStatus.message !== '') {
			dispatch(clearDeletingCategory());
			setShowNotificationDelete(false);
		}
	};

	// обработчик нажатия клавиши в поле переименования категории
	const keyDownRenameCategoryHandler = (e: React.KeyboardEvent<HTMLInputElement>): void => {
		if (e.code === 'Escape') setRenameCategoryFlg({ isOpen: false, id: null });
	};

	// отправка формы переименования категории
	const submitRenameCategory = (e: FormEvent<HTMLFormElement>): void => {
		e.preventDefault();
		// если имя не поменялось - закрываем форму
		if (categoriesList.data.find(categoryItem => categoryItem.id === renameCategoryFlg.id)?.name === inputRenameCategory) {
			setRenameCategoryFlg({ isOpen: false, id: null });
		}
		// если переименовываем на существующий набор данных - включаем предупреждение
		else if (categoriesList.data.find(categoryItem => categoryItem.name === inputRenameCategory)) {
			setNoticeExistingCategory(true);
			setTimeout(() => {
				setNoticeExistingCategory(false);
			}, 5000); // выключаем через 5сек
		}
		else {
			setShowNotificationEdit(true); // включаем уведомление
			renameCategoryFlg.id && dispatch(editCategory({ categoryId: renameCategoryFlg.id, categoryName: inputRenameCategory })); // переименовываем категорию
			setRenameCategoryFlg({ isOpen: false, id: null }); // закрываем форму переименования категории
		}
	};

	// обработчик удаления категории
	const deleteCategoryHandler = (): void => {
		setShowAlertDialogDelCategory(false); // закрываем диалоговое окно
		setShowNotificationDelete(true); // включаем уведомление
		dispatch(deleteCategory(selectedCategoryToBeRemoved.id)); // удаляем категорию
	};

	// функция формирования массива категорий в строку для сохранения в .csv файле
	const formatCategoriesInCsvFile = (): string => {
		let str: string = '';
		categoriesList.data.forEach(category => str += category.name + '\n');
		return str;
	};

	// обработчик закрытия вкладки
	const closeHandler = (): void => {
		setShowCategories(false);
		renameCategoryFlg.isOpen && setRenameCategoryFlg({ isOpen: false, id: null }); // закрываем форму переименования
	};

	return (
		<Slide direction="left" in={showCategories} mountOnEnter unmountOnExit>
			<div className={styles.modal} onMouseDown={closeHandler}>
				<div className={styles.categories} onMouseDown={(e) => mouseDownHandler(e)}>
					<div className={styles.categoriesInner}>
						<h3 className={styles.categoriesHeader}>{translate('categories_header')}</h3>

						{/* загрузка списка категорий */}
						{categoriesList.status === RequestStatus.LOADING &&
							<div className={styles.loading}>
								<ProgressCircle title={translate('progressCircle_categoriesListLoadingTitle')} />
							</div>
						}

						{/* ошибка получения списка категорий */}
						{(categoriesList.status === RequestStatus.FAILED || categoriesList.error === ResponseStatus.FAILED) &&
							<div className={styles.failed}>
								{translate(categoriesList.message || 'categories_failedCategoriesList')}
							</div>
						}

						{/* пустой список */}
						{categoriesList.data.length === 0 && categoriesList.status === RequestStatus.IDLE && categoriesList.error === ResponseStatus.SUCCESS &&
							<div className={styles.empty}>
								{translate('categories_emptyCategoriesList')}
							</div>
						}

						{/* список категорий */}
						{categoriesList.data.length > 0 &&
							<Fade in={true} timeout={500}>
								<div className={styles.categoriesList}>
									<ul>
										{categoriesList.data.map((category) => (
											<li className={styles.categoriesItem} key={category.id}>
												{renameCategoryFlg.isOpen && renameCategoryFlg.id === category.id ?
													<form className={styles.renameForm} onSubmit={e => submitRenameCategory(e)}>
														<input
															className={cn(styles.renameFormInput, {
																[styles.renameFormInputWarn]: noticeExistingCategory,
															})}
															autoFocus
															value={inputRenameCategory}
															onChange={e => setInputRenameCategory(e.target.value)}
															onKeyDown={(e) => keyDownRenameCategoryHandler(e)}
														/>
														<button className={styles.renameFormBtn} type="submit" title={translate('categories_renameCategoryBtn')}>
															<FontAwesomeIcon icon={faCheck} style={{ cursor: 'pointer' }} color={colorGreen} size="lg" />
														</button>
														<button className={styles.renameFormBtn} type="reset" title={translate('categories_cancelBtn')}>
															<FontAwesomeIcon icon={faXmark} style={{ cursor: 'pointer' }} color={colorRed} size="lg" onClick={() => setRenameCategoryFlg({ isOpen: false, id: null })} />
														</button>
													</form>
													:
													<>
														<p className={styles.categoriesItemName} title={category.name}>
															{category.name}
														</p>
														<div className={styles.categoriesItemEdit}>
															{isAccess(QAS.CATEGORY_EDIT) &&
																<FontAwesomeIcon
																	icon={faPen}
																	size="1x"
																	color={colorPrimary}
																	onClick={() => renameCategoryHandler(category.name, category.id)}
																	title={translate('categories_renameCategoryBtn')}
																	style={{ cursor: 'pointer' }}
																/>
															}
															{isAccess(QAS.CATEGORY_DELETE) &&
																<FontAwesomeIcon
																	icon={faTrashCan}
																	size="1x"
																	color={colorRed}
																	onClick={() => {
																		setShowAlertDialogDelCategory(true);
																		setSelectedCategoryToBeRemoved(category);
																	}}
																	title={translate('categories_deleteCategoryBtn')}
																	style={{ cursor: 'pointer' }}
																/>
															}
														</div>
													</>
												}
											</li>
										))}
									</ul>
									<div className={styles.categoriesBtns}>
										{isAccess(QAS.CATEGORY_ADD) &&
											<Button
												variant="outlined"
												sx={{ fontSize: 11, width: '50%', overflow: 'hidden' }}
												onClick={() => setShowModalAddCategory(true)}
											>
												{translate('categories_addCategoryBtn')}
											</Button>
										}
										<a
											href={'data:text/plain;charset=utf-8,' + encodeURIComponent(formatCategoriesInCsvFile())}
											target='_blank'
											rel="noreferrer"
											download='QAS_categories.csv'
											className={styles.categoriesBtnsExport}
										>
											<Button variant="outlined" sx={{ fontSize: 11, width: '100%' }}>
												{translate('categories_exportCategoriesBtn')}
											</Button>
										</a>
									</div>
								</div>
							</Fade>
						}

						{showModalAddCategory && <FormAddingCategory showModal={showModalAddCategory} setShowModal={setShowModalAddCategory} setShowNotification={setShowNotificationAdd} />}

						<AlertDialog
							showAlertDialog={showAlertDialogDelCategory}
							setShowAlertDialog={setShowAlertDialogDelCategory}
							submitHandler={deleteCategoryHandler}
							title='dialog_deleteCategoryTitle'
							description='dialog_deleteCategoryConfirm'
							name={selectedCategoryToBeRemoved.name}
						/>

						{showScreenLock.isShow && <ScreenLock title={translate(showScreenLock.title)} />}

						{showNotificationAdd && <Notification showNotification={showNotificationAdd} setShowNotification={setShowNotificationAdd} selectDataResponse={selectAddingCategory} clearDataResponse={clearAddingCategory} titleFailed='noticeAddCategory_failed' titleSuccess='noticeAddCategory_success' />}
						{showNotificationEdit && <Notification showNotification={showNotificationEdit} setShowNotification={setShowNotificationEdit} selectDataResponse={selectEditingCategory} clearDataResponse={clearEditingCategory} titleFailed='noticeEditCategory_failed' titleSuccess='noticeEditCategory_success' />}
						{showNotificationDelete && <Notification showNotification={showNotificationDelete} setShowNotification={setShowNotificationDelete} selectDataResponse={selectDeletingCategory} clearDataResponse={clearDeletingCategory} titleFailed='noticeDeleteCategory_failed' titleSuccess='noticeDeleteCategory_success' />}
					</div>
				</div>

				<div className={styles.tagClose} onClick={closeHandler}>
					{translate('tag_close')}
				</div>
			</div>
		</Slide>
	);
};

export default Categories;
