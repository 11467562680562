import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { Fade, FormControl, InputLabel, MenuItem, Select, Slide } from '@mui/material';
import cn from 'classnames';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { addModelName, changeActiveType, clearDeleteResponse, clearImportResponse, clearModel, deleteModel, getAllModels, getInfoModel, selectAllModels, selectDeleteStatus, selectFullModel, selectImportStatus } from '../../../store/modelSlice';
import useAccessRight from '../../../hooks/useAccessRight';
import useTranslate from '../../../hooks/useTranslate';
import { QUEUE, TRANSCRIPT } from '../../../constants/routes';
import { MODEL, SERVICE, SPR, TRANSCRIPTION } from '../../../constants/accessRights';
import { ModelStatus, RequestStatus, ResponseStatus } from '../../../types/statusTypes';
import FormImportModel from '../../Forms/FormImportModel/FormImportModel';
import FormSendAudioSpr from '../../Forms/FormSendAudioSpr/FormSendAudioSpr';
import ScreenLock from '../../ScreenLock/ScreenLock';
import AlertDialog from '../../AlertDialog/AlertDialog';
import ProgressCircle from '../../ProgressCircle/ProgressCircle';
import Notification from '../../Notification/Notification';
import { IModelNavbarSprProps } from './ModelNavbarSpr.props';
import styles from './ModelNavbarSpr.module.scss';

const ModelNavbarSpr = ({ serviceType, setShowPage }: IModelNavbarSprProps): JSX.Element => {
	const [modelList, setModelList] = useState<string[]>([]); // список моделей
	const [activeModel, setActiveModel] = useState<string>(''); // активная модель
	const [showModalImport, setShowModalImport] = useState<boolean>(false); // показ формы импортирования модели
	const [showScreenLock, setShowScreenLock] = useState<boolean>(false); // показ экрана блокировки
	const [showNotificationImport, setShowNotificationImport] = useState<boolean>(false); // показ уведомления импорта модели
	const [showNotificationDelete, setShowNotificationDelete] = useState<boolean>(false); // показ уведомления удаления модели
	const [showAlertDialog, setShowAlertDialog] = useState<boolean>(false); // показ диалогового окна для удаления модели
	const [showMenu, setShowMenu] = useState<boolean>(false); // показ кастомного контекстного меню
	const [positionMenu, setPositionMenu] = useState<{ x: number, y: number }>({ x: 0, y: 0 }); // позиция кастомного контекстного меню
	const [promiseGetInfoModel, setPromiseGetInfoModel] = useState<Promise<any> | null>(null); // промис для отмены запроса получения инфо модели

	const dispatch = useAppDispatch();
	const allModels = useAppSelector(selectAllModels); // store - все модели
	const fullModel = useAppSelector(selectFullModel); // store - информация о модели
	const importStatus = useAppSelector(selectImportStatus); // store - статус импортирования модели
	const deleteStatus = useAppSelector(selectDeleteStatus); // store - статус удаления модели

	const isAccess = useAccessRight(); // hook для проверки прав доступа
	const navigate = useNavigate(); // hook для навигации
	const translate = useTranslate(); // hook для перевода текста
	const [cookies, setCookie] = useCookies([`${serviceType}ModelName`]); // hook для работы с cookie

	// следим за статусом удаления модели
	useEffect(() => {
		if (deleteStatus.status === RequestStatus.LOADING) setShowScreenLock(true); // если идет удаление модели - включаем экран блокировки
		else setShowScreenLock(false); // иначе выключаем
		// если удаление прошло успешно
		if (deleteStatus.error === ResponseStatus.SUCCESS && deleteStatus.status === RequestStatus.IDLE && deleteStatus.message !== '') {
			dispatch(addModelName(null)); // очищаем из store имя активной модели
			dispatch(getAllModels({ serviceType })); // получаем заново все модели
		}
	}, [deleteStatus]);

	// следим за получением данных обо всех моделях
	useEffect(() => {
		// если есть данные и не в процессе загрузки - записываем в state список моделей, иначе обнуляем
		if (allModels.models !== null && allModels.status !== RequestStatus.LOADING && typeof (allModels.models) !== 'string') {
			setModelList(Object.keys(allModels.models));
		} else setModelList([]);
	}, [allModels]);

	// следим за списком моделей
	useEffect(() => {
		// только если список моделей не пустой
		if (modelList.length > 0) {
			// и если импортирование модели прошло успешно
			if (importStatus.error === ResponseStatus.SUCCESS && importStatus.message === 'success' && importStatus.status === RequestStatus.IDLE) {
				importStatus.newName && modelHandler(importStatus.newName); // передаем в обработчик выбора активной модели - имя импортируемой модели из store
				dispatch(clearImportResponse()); // очищаем статус импортирования модели
			} else {
				// иначе если есть запись в cookie и список моделей содержит эту запись, то передаем ее в обработчик выбора активной модели, иначе выбираем первую модель из списка
				modelList.includes(cookies[`${serviceType}ModelName`]) && cookies[`${serviceType}ModelName`] ?
					modelHandler(cookies[`${serviceType}ModelName`])
					:
					modelHandler(modelList[0]);
			}
		}
	}, [modelList]);

	// следим за статусом загрузки модели
	useEffect(() => {
		// если закончилась загрузка модели - сбрасываем промис для отмены запроса
		fullModel.status !== RequestStatus.LOADING && setPromiseGetInfoModel(null);
		// если ошибка и нет ответа от сервера (случай с отменой запроса) - запрашиваем
		fullModel.status === RequestStatus.FAILED && fullModel.error === ResponseStatus.SUCCESS && fullModel.message === '' && fullModel.modelName && modelHandler(fullModel.modelName);
	}, [fullModel.status]);

	// обработчик выбора активной модели
	const modelHandler = (modelName: string): void => {
		// если сохранен промис для отмены запроса
		if (promiseGetInfoModel) {
			// @ts-ignore
			promiseGetInfoModel.abort(); // прерываем запрос
			setPromiseGetInfoModel(null); // обнуляем промис
			dispatch(addModelName(modelName)); // добавляем имя модели в store
			return;
		}
		dispatch(clearModel()); // очищаем все данные о модели
		setActiveModel(modelName); // устанавливаем имя активной модели
		dispatch(addModelName(modelName)); // добавляем имя модели в store

		if (isAccess(MODEL.INFO)) {
			const promise = dispatch(getInfoModel({ modelName, serviceType })); // получаем модель
			setPromiseGetInfoModel(promise); // устанавливаем промис для случая с отменой запроса
		}

		setCookie(`${serviceType}ModelName`, modelName, { path: '/', maxAge: 2_592_000 }); // устанавливаем cookie на месяц
	};

	// кастомное контекстное меню
	const showCustomContextMenu = (e: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
		if (isAccess(MODEL.DELETE) && fullModel.fullModel.current === null && fullModel.fullModel.future === null && fullModel.fullModel.previous === null) {
			e.preventDefault();
			setShowMenu(false); // сбрасываем показ контекстного меню
			const newPosition = {
				x: e.pageX,
				y: e.pageY,
			};
			setPositionMenu(newPosition); // устанавливаем новые координаты контекстного меню
			setShowMenu(true); // открываем контекстное меню
		}
	};

	// обработчик удаления модели
	const deleteHandler = (): void => {
		setShowAlertDialog(false); // закрываем диалоговое окно
		setShowNotificationDelete(true); // включаем уведомление
		fullModel.modelName && dispatch(deleteModel({ modelName: fullModel.modelName, serviceType })); // удаление модели
	};

	// следим за информацией о модели и если есть список моделей и не загружаются данные модели
	useEffect(() => {
		if (allModels.models !== null && fullModel.status !== RequestStatus.LOADING) {
			fullModel.activeType !== 'future' && dispatch(changeActiveType('future')); // и если активный тип не черновик - ставим черновик
			fullModel.fullModel.future === null && dispatch(changeActiveType('current')); // и если нет данных черновика - ставим рабочую
			fullModel.fullModel.future === null && fullModel.fullModel.current === null && dispatch(changeActiveType('previous')); // и если нет данных черновика и рабочей модели - ставим архивную
		}
	}, [fullModel.fullModel]);

	// задержка для перехода на другую страницу
	const delayToHidePage = (route: string): void => {
		setShowPage && setShowPage(false); // уводим страницу в фон
		setTimeout(() => {
			navigate(route);
		}, 500);
	};

	return (
		<>
			<Slide direction="right" in={true} mountOnEnter unmountOnExit timeout={800}>
				<div className={styles.sidebar} onClick={() => setShowMenu(false)}>
					<div className={styles.navbar}>
						{/* ошибка загрузки списка моделей */}
						{(allModels.status === RequestStatus.FAILED || typeof (allModels.models) === 'string') &&
							<div className={styles.navbarFailedText}>
								<span>{translate(allModels.message || 'modelNavbar_loadFailed')}</span>
								{isAccess(MODEL.INFO) &&
									<span className={styles.navbarFailedUpdate} onClick={() => dispatch(getAllModels({ serviceType }))}>{translate('update')}</span>
								}
							</div>
						}

						{/* пустой список моделей */}
						{(allModels.status === RequestStatus.IDLE && allModels.models && typeof (allModels.models) !== 'string' && modelList.length === 0) &&
							<div className={styles.navbarNoModels}>{translate('modelNavbar_noModelsTitle')}</div>
						}

						{/* загрузка списка моделей */}
						{(allModels.status === RequestStatus.LOADING) &&
							<div className={cn(styles.navbarLoading, {
								[styles.navbarLoadingShort]: isAccess([SERVICE.SPR, SPR.QUEUE, SPR.ASYNC_RESULT, SPR.AUDIO]) || isAccess([SERVICE.SPR, TRANSCRIPTION.LIST, TRANSCRIPTION.GET_DATA, TRANSCRIPTION.GET_AUDIO]),
							})}><ProgressCircle title={translate('progressCircle_modelListLoadingTitle')} /></div>
						}

						{/* список моделей и форма распознавания */}
						<Fade in={allModels.status !== RequestStatus.LOADING && allModels.models !== null && modelList.length > 0} timeout={500}>
							<div className={styles.navbarModelList}>

								<FormControl fullWidth sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }} margin='dense'>
									<InputLabel id="modelName-label" sx={{ fontSize: 13 }}>{translate('modelNavbarSpr_inputModelName')}</InputLabel>
									<Select
										labelId="modelName-label"
										id="modelName"
										label={translate('modelNavbarSpr_inputModelName')}
										value={activeModel}
										onChange={(e) => modelHandler(e.target.value)}
										sx={{ fontSize: 13, height: 33, marginBottom: '8px' }}
										onContextMenu={e => fullModel.status === RequestStatus.IDLE && activeModel === fullModel.modelName && showCustomContextMenu(e)}
									>
										{modelList.sort().map(modelName => (
											<MenuItem key={modelName} value={modelName} sx={{ fontSize: 13 }}>{modelName}</MenuItem>
										))}
									</Select>
								</FormControl>

								{/* загрузка модели */}
								{((fullModel.status === RequestStatus.LOADING || fullModel.modelName === null)/*  && !fullModel.training && !fullModel.testing */) &&
									<ProgressCircle title={translate('progressCircle_modelLoadingTitle')} />
								}

								{/* ошибка или отсутствие модели */}
								{fullModel.status !== RequestStatus.LOADING && (fullModel.status === RequestStatus.FAILED || fullModel.fullModel?.[fullModel.activeType] === null) && <p className={styles.navbarModelNotFound}>{translate(fullModel.message || 'notFound')}</p>}

								{/* блок инфо и распознавания */}
								{fullModel.fullModel?.[fullModel.activeType]?.hasOwnProperty('status') &&
									<Fade in={fullModel.status !== RequestStatus.LOADING && fullModel.fullModel?.[fullModel.activeType] !== null /* || fullModel.training === true || fullModel.testing === true */} timeout={500}>
										<div>
											<div className={styles.navbarModelInfo}>
												<span className={styles.navbarModelInfoKey}>
													{translate('modelNavbarSpr_statusTitle')}:
												</span> {fullModel.activeType !== 'previous' ? translate(fullModel.fullModel?.[fullModel.activeType]?.status || '...') : translate('modelNavbarSpr_archiveTitle')}
											</div>
											{fullModel.fullModel?.[fullModel.activeType]?.modified &&
												<div className={cn(styles.navbarModelInfo, styles.navbarModelInfoModified)}>
													<span className={styles.navbarModelInfoKey}>
														{translate('modelNavbarSpr_modifiedTitle')}:
													</span>
													{fullModel.fullModel?.[fullModel.activeType]?.modified || '-'}
												</div>
											}
											{isAccess(MODEL.DELETE) &&
												<p className={styles.navbarModelInfoTrash}>
													<span onClick={() => fullModel.modelName && setShowAlertDialog(true)}>
														{translate('modelNavbarSpr_deleteModelTitle')}
													</span>
												</p>
											}
											{isAccess(SPR.RECOGNITION) && fullModel.activeType !== 'previous' && fullModel.fullModel[fullModel.activeType]?.status !== ModelStatus.EMPTY && fullModel.fullModel[fullModel.activeType]?.status !== ModelStatus.FAILED &&
												<FormSendAudioSpr />
											}
										</div>
									</Fade>
								}
							</div>
						</Fade>

						{/* контекстное меню для удаления модели */}
						{showMenu && (
							<div
								style={{ top: positionMenu.y, left: positionMenu.x }}
								className={styles.navbarModelContextMenu}
							>
								<div className={styles.navbarModelContextMenuOption} onClick={() => setShowAlertDialog(true)}>
									{translate('modelInfo_deleteBtn')}
								</div>
							</div>
						)}

						<div className={styles.functionButtons}>
							{/* добавление новой модели */}
							<Fade in={isAccess(MODEL.ADD) && (allModels.status === RequestStatus.IDLE || allModels.models !== null)} timeout={500}>
								<div className={styles.functionButtonsAddModel} onClick={() => setShowModalImport(true)}>
									{translate('modelNavbar_addNewModelBtn')}
								</div>
							</Fade>
							{/* табы */}
							{(isAccess([SERVICE.SPR, SPR.QUEUE]) || isAccess([SERVICE.SPR, TRANSCRIPTION.LIST])) &&
								<div className={styles.functionButtonsTabs}>
									<div className={styles.functionButtonsTab}>{translate('modelNavbar_modelsLink')}</div>
									{isAccess([SERVICE.SPR, SPR.QUEUE]) &&
										<div
											className={cn(styles.functionButtonsTab, styles.functionButtonsTabNonActive)}
											onClick={() => delayToHidePage(QUEUE)}>
											{translate('modelNavbarSpr_queueLink')}
										</div>
									}
									{isAccess([SERVICE.SPR, TRANSCRIPTION.LIST]) &&
										<div
											className={cn(styles.functionButtonsTab, styles.functionButtonsTabNonActive)}
											onClick={() => delayToHidePage(TRANSCRIPT)}>
											{translate('modelNavbarSpr_saveLink')}
										</div>
									}
								</div>
							}
						</div>
					</div>

					{/* модальная форма добавления новой модели */}
					{showModalImport && <FormImportModel showModal={showModalImport} setShowModal={setShowModalImport} create serviceType={serviceType} setShowNotification={setShowNotificationImport} />}
				</div>
			</Slide>

			{(showScreenLock) && <ScreenLock title={translate('screenLock_modelDeleteTitle')} />}

			{showNotificationImport && <Notification showNotification={showNotificationImport} setShowNotification={setShowNotificationImport} selectDataResponse={selectImportStatus} clearDataResponse={clearImportResponse} titleFailed='noticeImport_addModelFailed' titleSuccess='noticeImport_addModelSuccess' />}
			{showNotificationDelete && <Notification showNotification={showNotificationDelete} setShowNotification={setShowNotificationDelete} selectDataResponse={selectDeleteStatus} clearDataResponse={clearDeleteResponse} titleFailed='noticeDelete_modelFailed' titleSuccess='noticeDelete_modelSuccess' />}

			<AlertDialog showAlertDialog={showAlertDialog} setShowAlertDialog={setShowAlertDialog} submitHandler={deleteHandler} title='dialog_deleteModelTitle' description='dialog_deleteModelConfirm' name={String(fullModel.modelName)} />
		</>
	);
};

export default ModelNavbarSpr;
