import { Fade } from '@mui/material';
import ProgressCircle from '../ProgressCircle/ProgressCircle';
import { IScreenLockProps } from './ScreenLock.props';
import styles from './ScreenLock.module.scss';

const ScreenLock = ({ title }: IScreenLockProps): JSX.Element => {

	return (
		<Fade in={true} timeout={500}>
			<div className={styles.screenLock} onMouseDown={e => e.stopPropagation()}>
				<div className={styles.screenLockWrapper}>
					<ProgressCircle title={title} />
				</div>
			</div>
		</Fade>
	);
};

export default ScreenLock;
