import { useEffect, useState } from 'react';
import { TextField } from '@mui/material';
import useTranslate from '../../../../hooks/useTranslate';
import { colorPrimary } from '../../../../constants/colors';
import { IFilterProps } from './Filter.props';

const Filter = ({ column, }: IFilterProps) => {
	const [inputValue, setInputValue] = useState<string>(''); // значение для фильтрации
	const translate = useTranslate(); // hook для перевода текста

	// следим за значением для фильтрации
	useEffect(() => {

		// фильтруем через пол-секунды бездействия после окончания ввода значения для фильтрации
		const handler = setTimeout(() => {
			column.setFilterValue(inputValue);
		}, 500);

		return () => {
			clearTimeout(handler); // сбрасываем timeout, если продолжается ввод значения для фильтрации
		};
	}, [inputValue]);

	return (
		<TextField
			label={translate('userLogs_inputFilter')}
			value={inputValue}
			onChange={e => setInputValue(e.target.value)}
			variant="outlined"
			InputProps={{
				style: {
					height: 34,
					fontSize: 13,
					color: colorPrimary,
				},
			}}
			InputLabelProps={{
				style: {
					fontSize: 13,
				},
			}}
			sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 }, width: '100%' }}
		/>
	);
};

export default Filter;
