import { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { Button, Checkbox, FormControl, InputAdornment, InputLabel, ListItemText, MenuItem, Select, TextField } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { selectDatasetsList, selectRolesList } from '../../../store/authSlice';
import { addUser, addNewUserName, getUserList, selectAddUserStatus, selectUserList, clearUserList } from '../../../store/userSlice';
import useTranslate from '../../../hooks/useTranslate';
import { IAddUserProps } from '../../../types/userTypes';
import { RequestStatus, ResponseStatus } from '../../../types/statusTypes';
import ModalFormWindow from '../../../HOC/ModalFormWindow/ModalFormWindow';
import ProgressCircle from '../../ProgressCircle/ProgressCircle';
import { IFormAddingUserProps } from './FormAddingUser.props';
import styles from './FormAddingUser.module.scss';

const FormAddingUser = ({ showModal, setShowModal, setShowNotification }: IFormAddingUserProps): JSX.Element => {
	const [login, setLogin] = useState<string>(''); // логин
	const [password, setPassword] = useState<string>(''); // пароль
	const [userName, setUserName] = useState<string>(''); // имя пользователя
	const [userRoleId, setUserRoleId] = useState<string>(''); // id роли пользователя
	const [datasetIdDefault, setDatasetIdDefault] = useState<string>(''); // id набора данных по умолчанию
	const [datasetsListAvailable, setDatasetsListAvailable] = useState<string[]>([]); // список id доступных наборов данных
	const [avatar, setAvatar] = useState<File>(); // аватар
	const [avatarBase64, setAvatarBase64] = useState<string>(''); // аватар в base64
	const [showPassword, setShowPassword] = useState<boolean>(false); // флаг отображения пароля
	const [showErrorExistingUsername, setShowErrorExistingUsername] = useState<boolean>(false); // флаг существующего имени пользователя

	const dispatch = useAppDispatch();
	const addStatusUser = useAppSelector(selectAddUserStatus); // store - статус добавления пользователя
	const userList = useAppSelector(selectUserList); // store - список пользователей
	const rolesList = useAppSelector(selectRolesList); // store - список ролей
	const datasetsList = useAppSelector(selectDatasetsList); // store - список наборов данных

	const translate = useTranslate(); // hook для перевода текста

	// следим за набором данных по умолчанию и дублируем в доступные наборы данных
	useEffect(() => {
		datasetIdDefault && !datasetsListAvailable.includes(datasetIdDefault) && setDatasetsListAvailable(prev => [...prev, datasetIdDefault].sort());
	}, [datasetIdDefault]);

	// следим за статусом добавления пользователя
	useEffect(() => {
		// если нет ошибок
		if (addStatusUser.status === RequestStatus.IDLE && addStatusUser.error === ResponseStatus.SUCCESS && addStatusUser.message !== '') {
			dispatch(clearUserList()); // очищаем список пользователей
			dispatch(getUserList()); // получаем заново список пользователей
		}
		// если изменился статус добавления пользователя
		if (addStatusUser.status === RequestStatus.FAILED || addStatusUser.error !== ResponseStatus.SUCCESS || addStatusUser.message !== '') {
			handleClose(); // закрываем форму
			setShowNotification(true); // включаем уведомление
		}
	}, [addStatusUser]);

	// обработчик выбора аватара
	const avatarHandler = (file: File) => {
		if (file) {
			setAvatar(file);
			const reader = new FileReader();
			reader.readAsDataURL(file); // читаем файл
			reader.onload = () => {
				if (typeof reader.result === 'string') setAvatarBase64(reader.result);
				else console.error('File reading error.');
			};
			reader.onerror = () => console.error(reader.error);
		} else {
			setAvatar(undefined);
			setAvatarBase64('');
		}
	};

	// обработчик добавления пользователя
	const submitHandler = (e: FormEvent<HTMLFormElement>): void => {
		e.preventDefault();
		const existingUsername = Array.isArray(userList.data) && userList.data.find(user => user.username === login); // существующее имя пользователя
		if (existingUsername) {
			setShowErrorExistingUsername(true); // подсвечиваем ошибку
		} else {
			showErrorExistingUsername && setShowErrorExistingUsername(false); // убираем ошибку, если была
			dispatch(addNewUserName(login)); // добавляем в store имя добавляемого пользователя
			const newUserData: IAddUserProps = {
				username: login,
				password,
				fullname: userName,
				role: userRoleId,
				dataset: datasetIdDefault,
				datasets: datasetsListAvailable,
				image: avatarBase64,
			};
			dispatch(addUser(newUserData)); // добавляем пользователя
		}
	};

	// обработчик закрытия формы
	const handleClose = (): void => {
		// если идет добавление - запрещаем покидать форму
		if (addStatusUser.status === RequestStatus.LOADING) return;
		setShowModal(false);
	};

	return (
		<ModalFormWindow showModal={showModal} setShowModal={setShowModal} headerTitle='formAddingUser_title' close={handleClose}>
			<form onSubmit={(e) => submitHandler(e)}>
				<FormControl fullWidth margin='dense'>
					<TextField
						autoFocus
						required
						id="login"
						label={translate("formAddingUser_inputLogin")}
						variant="outlined"
						value={login}
						onChange={(e) => setLogin(e.target.value)}
						disabled={addStatusUser.status === RequestStatus.LOADING}
						error={showErrorExistingUsername}
						helperText={showErrorExistingUsername ? translate("formAddingUser_loginExists") : ''}
						InputProps={{
							style: {
								height: 33,
								fontSize: 13
							},
						}}
						InputLabelProps={{
							style: {
								fontSize: 13,
							},
						}}
						sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}
					/>
				</FormControl>
				<FormControl fullWidth margin='dense'>
					<TextField
						required
						id="password"
						label={translate("formAddingUser_inputPassword")}
						variant="outlined"
						type={showPassword ? 'text' : 'password'}
						value={password}
						onChange={(e) => setPassword(e.target.value)}
						disabled={addStatusUser.status === RequestStatus.LOADING}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end" >
									<FontAwesomeIcon
										icon={showPassword ? faEyeSlash : faEye}
										onClick={() => addStatusUser.status !== RequestStatus.LOADING && setShowPassword(prev => !prev)}
										style={{ cursor: 'pointer' }}
									/>
								</InputAdornment>
							),
							style: {
								height: 33,
								fontSize: 13
							},
						}}
						InputLabelProps={{
							style: {
								fontSize: 13,
							},
						}}
						sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}
					/>
				</FormControl>
				<FormControl fullWidth margin='dense'>
					<TextField
						required
						id="userName"
						label={translate("formAddingUser_inputName")}
						variant="outlined"
						value={userName}
						onChange={(e) => setUserName(e.target.value)}
						disabled={addStatusUser.status === RequestStatus.LOADING}
						InputProps={{
							style: {
								height: 33,
								fontSize: 13
							},
						}}
						InputLabelProps={{
							style: {
								fontSize: 13,
							},
						}}
						sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}
					/>
				</FormControl>
				<FormControl fullWidth margin='dense' sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}>
					<InputLabel id="userRoleId-label" sx={{ fontSize: 13 }}>{translate('formAddingUser_selectRole')}</InputLabel>
					<Select
						required
						id="userRoleId"
						labelId="userRoleId-label"
						label={translate('formAddingUser_selectRole')}
						value={userRoleId}
						onChange={(e) => setUserRoleId(e.target.value)}
						disabled={addStatusUser.status === RequestStatus.LOADING}
						style={{ fontSize: 13, height: 33 }}
					>
						{rolesList.data.map((role) => (
							<MenuItem key={role.id} value={role.id} sx={{ fontSize: 13 }}>{role.name}</MenuItem>
						))}
					</Select>
				</FormControl>
				<FormControl fullWidth margin='dense' sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}>
					<InputLabel id="datasetIdDefault-label" sx={{ fontSize: 13 }}>{translate('formAddingUser_selectDatasetDefault')}</InputLabel>
					<Select
						required
						id="datasetIdDefault"
						labelId="datasetIdDefault-label"
						label={translate('formAddingUser_selectDatasetDefault')}
						value={datasetIdDefault}
						onChange={(e) => setDatasetIdDefault(e.target.value)}
						disabled={addStatusUser.status === RequestStatus.LOADING}
						style={{ fontSize: 13, height: 33 }}
					>
						{datasetsList.data.map((dataset) => (
							<MenuItem key={dataset.id} value={dataset.id} sx={{ fontSize: 13 }}>{dataset.name}</MenuItem>
						))}
					</Select>
				</FormControl>
				<FormControl fullWidth margin='dense' sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}>
					<InputLabel id="datasetsList-label" sx={{ fontSize: 13 }}>{translate('formAddingUser_selectDatasetsList')}</InputLabel>
					<Select
						required
						id="datasetsList"
						labelId="datasetsList-label"
						label={translate('formAddingUser_selectDatasetsList')}
						multiple
						value={datasetsListAvailable}
						onChange={({ target: { value } }) => setDatasetsListAvailable(typeof value === 'string' ? value.split(',') : value.sort())}
						disabled={addStatusUser.status === RequestStatus.LOADING}
						renderValue={(selected) => selected.map(datasetId => datasetsList.data.find(dataset => dataset.id === datasetId)?.name || datasetId).join(', ')}
						style={{ fontSize: 13, height: 33 }}
					>
						{datasetsList.data.map((dataset) => (
							<MenuItem key={dataset.id} value={dataset.id} disabled={dataset.id === datasetIdDefault} sx={{ textAlign: 'left', padding: 0 }}>
								<Checkbox checked={datasetsListAvailable.indexOf(dataset.id) > -1} size='small' sx={{ height: 25, width: 25 }} />
								<ListItemText primary={dataset.name} primaryTypographyProps={{ fontSize: 11 }} />
							</MenuItem>
						))}
					</Select>
				</FormControl>
				<input
					className={styles.inputFile}
					type="file"
					id="image"
					accept="image/*"
					onChange={(e: ChangeEvent<HTMLInputElement>) => e.target.files && avatarHandler(e.target.files[0])}
					disabled={addStatusUser.status === RequestStatus.LOADING}
				/>
				<label htmlFor='image' className={styles.labelFile}>
					<div className={styles.labelFileName} title={avatar?.name}>
						{avatar ? avatar.name : translate('formAddingUser_inputImage')}
					</div>
				</label>
				{avatarBase64 && <img className={styles.image} src={avatarBase64} alt="" />}
				<FormControl fullWidth margin='dense'>
					<Button
						variant="outlined"
						type="submit"
						disabled={addStatusUser.status === RequestStatus.LOADING}
						sx={{ fontSize: 11 }}
					>
						{addStatusUser.status === RequestStatus.LOADING ?
							<>
								{translate('formAddingUser_addingBtn')}
								<ProgressCircle isBtnDisabled />
							</>
							:
							translate('formAddingUser_addBtn')
						}
					</Button>
				</FormControl>
			</form>
		</ModalFormWindow>
	);
};

export default FormAddingUser;
