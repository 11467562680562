import { useState } from 'react';
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { useAppDispatch, useAppSelector, } from '../../../store/hooks';
import { changeCategoryDataException, deleteCategoryDataException, selectActiveRobotVersion } from '../../../store/sesRobotSlice';
import { selectDataList } from '../../../store/sesSlice';
import useAccessRight from '../../../hooks/useAccessRight';
import useTranslate from '../../../hooks/useTranslate';
import { SES } from '../../../constants/accessRights';
import { colorPrimary, colorRed } from '../../../constants/colors';
import { ICategoryDataProps } from './CategoryData.props';
import styles from './CategoryData.module.scss';

const CategoryData = ({ categoryData, exception, categoryId, categoryIdx, setChangeFlg }: ICategoryDataProps): JSX.Element => {
	const [selectDataElemId, setSelectDataElemId] = useState<string>(categoryData.data); // id элемента данных
	const [inputParam, setInputParam] = useState<string>(categoryData.value); // параметр

	const dispatch = useAppDispatch();
	const dataElemList = useAppSelector(selectDataList); // список элементов данных
	const activeRobotVersion = useAppSelector(selectActiveRobotVersion); // store - версия активного робота

	const isAccess = useAccessRight(); // hook для проверки прав доступа
	const translate = useTranslate(); // hook для перевода текста

	// обработчик изменения элемента данных
	const changeDataElemHandler = (e: SelectChangeEvent<string>): void => {
		setSelectDataElemId(e.target.value);
		dispatch(changeCategoryDataException({
			exception,
			categoryId,
			categoryIdx,
			data: { data: e.target.value, value: inputParam },
		}));
		setChangeFlg(true); // ставим флаг о несохраненных данных
	};

	// обработчик изменения значения
	const changeValueHandler = (): void => {
		dispatch(changeCategoryDataException({
			exception,
			categoryId,
			categoryIdx,
			data: { data: selectDataElemId, value: inputParam },
		}));
		setChangeFlg(true); // ставим флаг о несохраненных данных
	};

	// обработчик удаления данных категории
	const deleteDataHandler = () => {
		dispatch(deleteCategoryDataException({
			exception,
			categoryId,
			categoryIdx,
		}));
		setChangeFlg(true); // ставим флаг о несохраненных данных
	};

	return (
		<div className={styles.categoryDataBlock}>
			{/* id элемента данных */}
			<FormControl fullWidth margin='dense' sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}>
				<InputLabel sx={{ fontSize: 13 }}>{translate('categoryData_selectDataElem')}</InputLabel>
				<Select
					required
					label={translate('categoryData_selectDataElem')}
					value={selectDataElemId}
					onChange={changeDataElemHandler}
					disabled={!isAccess(SES.ROBOT_EDIT) || activeRobotVersion !== 'draft'}
					style={{ fontSize: 13, height: 33, color: colorPrimary }}
				>
					{dataElemList.data.map(({ id, name }) =>
						<MenuItem key={id} value={id} sx={{ fontSize: 13, color: colorPrimary }}>{name}</MenuItem>
					)}
				</Select>
			</FormControl>

			{/* значение */}
			<FormControl fullWidth margin='dense'>
				<TextField
					label={translate('categoryData_inputValue')}
					variant="outlined"
					value={inputParam}
					onChange={(e) => setInputParam(e.target.value)}
					onBlur={changeValueHandler}
					disabled={!isAccess(SES.ROBOT_EDIT) || activeRobotVersion !== 'draft'}
					InputProps={{
						style: {
							height: 33,
							fontSize: 13,
							color: colorPrimary,
						},
					}}
					InputLabelProps={{
						style: {
							fontSize: 13,
						},
					}}
					sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}
				/>
			</FormControl>

			{/* удаление данных категории */}
			{isAccess(SES.ROBOT_EDIT) && activeRobotVersion === 'draft' &&
				<FontAwesomeIcon
					icon={faTrashCan}
					color={colorRed}
					size='lg'
					onClick={deleteDataHandler}
					title={translate('categoryData_deleteDataBtn')}
					style={{ cursor: 'pointer' }}
				/>
			}
		</div>
	);
};

export default CategoryData;
