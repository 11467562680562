import { useEffect, useState } from 'react';
import { Slide } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { addRightsToRole, clearRoleDelete, clearRoleEdit, clearRolesList, deleteRole, editRole, getRolesList, selectRoleDeleteStatus, selectRoleEditStatus, selectRolesList } from '../../../store/authSlice';
import useAccessRight from '../../../hooks/useAccessRight';
import useTranslate from '../../../hooks/useTranslate';
import { DATASET, ROLE, USER } from '../../../constants/accessRights';
import Save from '../Buttons/Save/Save';
import Delete from '../Buttons/Delete/Delete';
import UserMenu from '../Buttons/UserMenu/UserMenu';
import UserLogs from '../../UserLogs/UserLogs';
import Datasets from '../../Datasets/Datasets';
import AlertDialog from '../../AlertDialog/AlertDialog';
import ScreenLock from '../../ScreenLock/ScreenLock';
import Notification from '../../Notification/Notification';
import { RequestStatus, ResponseStatus } from '../../../types/statusTypes';
import { IRoleControlsProps } from './RoleControls.props';
import styles from './RoleControls.module.scss';

const RoleControls = ({ changeFlg, activeRole, rolesRights, setChangeFlg, setShowPage }: IRoleControlsProps) => {
	const [showAlertDialogSave, setShowAlertDialogSave] = useState<boolean>(false); // показ диалогового окна при изменении роли
	const [showAlertDialogDelete, setShowAlertDialogDelete] = useState<boolean>(false); // показ диалогового окна при удалении роли
	const [showNotificationSave, setShowNotificationSave] = useState<boolean>(false); // показ уведомления сохранения данных роли
	const [showNotificationDelete, setShowNotificationDelete] = useState<boolean>(false); // показ уведомления удаления роли
	const [showScreenLock, setShowScreenLock] = useState<{ isShow: boolean, title: string }>({ isShow: false, title: '' }); // показ экрана блокировки и подпись
	const [showDatasets, setShowDatasets] = useState<boolean>(false); // показ вкладки наборов данных
	const [showUserLogs, setShowUserLogs] = useState<boolean>(false); // показ вкладки логов пользователей

	const dispatch = useAppDispatch();
	const rolesList = useAppSelector(selectRolesList); // store - список ролей
	const editingRoleStatus = useAppSelector(selectRoleEditStatus); // store - статус изменения набора данных
	const deletingRoleStatus = useAppSelector(selectRoleDeleteStatus); // store - статус удаления набора данных

	const isAccess = useAccessRight(); // hook для проверки прав доступа
	const translate = useTranslate(); // hook для перевода текста

	// следим за статусом изменения/удаления роли
	useEffect(() => {
		// если идет изменение/удаление - включаем экран блокировки с подписью
		if (editingRoleStatus.status === RequestStatus.LOADING) setShowScreenLock({ isShow: true, title: translate('screenLock_editRoleTitle') });
		else if (deletingRoleStatus.status === RequestStatus.LOADING) setShowScreenLock({ isShow: true, title: translate('screenLock_deleteRoleTitle') });
		else setShowScreenLock({ isShow: false, title: '' }); // иначе выключаем

		// если изменения сохранились
		if (editingRoleStatus.error === ResponseStatus.SUCCESS && editingRoleStatus.status === RequestStatus.IDLE && editingRoleStatus.message === 'success') {
			setChangeFlg(false); // выключаем флаг измененных данных
			dispatch(addRightsToRole({ rights: rolesRights, idx: activeRole.idx })); // добавляем права в store
		}
		// если удалилось успешно 
		if (deletingRoleStatus.error === ResponseStatus.SUCCESS && deletingRoleStatus.status === RequestStatus.IDLE && deletingRoleStatus.message !== '') {
			dispatch(clearRolesList()); // очищаем список ролей
			dispatch(getRolesList()); // получаем заново список ролей
		}
	}, [editingRoleStatus, deletingRoleStatus]);

	// обработчик изменения роли
	const editRoleHandler = () => {
		setShowAlertDialogSave(false); // закрываем диалоговое окно
		setShowNotificationSave(true); // включаем уведомление
		dispatch(editRole({ roleId: activeRole.id, rights: rolesRights })); // изменяем роль
	};

	// обработчик удаления роли
	const deleteRoleHandler = () => {
		setShowAlertDialogDelete(false); // закрываем диалоговое окно
		setShowNotificationDelete(true); // включаем уведомление
		dispatch(deleteRole(activeRole.id)); // удаляем роль
	};

	return (
		<div className={styles.controls}>

			<Slide direction="down" in={true} mountOnEnter unmountOnExit timeout={500}>
				<div className={styles.controlsWrapper}>
					<div className={styles.controlsTitle}>{translate('roles')}</div>

					<section className={styles.controlsTypeAndFunc}>
						<div className={styles.controlsFunctions}></div>

						<div className={styles.controlsFunctions}>
							<div className={styles.controlsFunctionsCenterBlock}>
								{isAccess(ROLE.EDIT) &&
									<Save
										styleNotActive={styles.controlsFunctionBtnNotActive}
										changeFlg={changeFlg}
										showNotification={showNotificationSave}
										setShowNotification={setShowNotificationSave}
										dataResponse={selectRoleEditStatus}
										clearDataResponse={clearRoleEdit}
										setShowAlertDialog={setShowAlertDialogSave}
									/>
								}
								{isAccess(ROLE.DELETE) &&
									<Delete
										styleNotActive={styles.controlsFunctionBtnNotActive}
										isAvailable={rolesList.data.length > 0 && activeRole.id !== '0'}
										title='controlsBtn_deleteRole'
										setShowAlertDialog={setShowAlertDialogDelete}
									/>
								}
							</div>

							<div className={styles.controlsFunctionsRightBlock}>
								<UserMenu setShowPage={setShowPage} />
							</div>
						</div>
					</section>
				</div>
			</Slide>

			{/* бирки */}
			<Slide direction="left" in={true} mountOnEnter unmountOnExit timeout={1000}>
				<section className={styles.tags}>
					{isAccess(USER.LOGS) &&
						<div className={styles.tag} onClick={() => setShowUserLogs(prev => !prev)}>
							<span className={styles.tagTitle}>{translate('tag_logs')}</span>
						</div>
					}
					{isAccess(DATASET.LIST) &&
						<div className={styles.tag} onClick={() => setShowDatasets(prev => !prev)}>
							<span className={styles.tagTitle}>{translate('tag_datasets')}</span>
						</div>
					}
				</section>
			</Slide>

			<Datasets showDatasets={showDatasets} setShowDatasets={setShowDatasets} />
			<UserLogs showUserLogs={showUserLogs} setShowUserLogs={setShowUserLogs} />

			<AlertDialog
				showAlertDialog={showAlertDialogSave}
				setShowAlertDialog={setShowAlertDialogSave}
				submitHandler={editRoleHandler}
				title={translate('dialog_editRoleTitle')}
				description={translate('dialog_editRoleConfirm')}
				name={rolesList.data[activeRole.idx]?.name}
			/>
			<AlertDialog
				showAlertDialog={showAlertDialogDelete}
				setShowAlertDialog={setShowAlertDialogDelete}
				submitHandler={deleteRoleHandler}
				title={translate('dialog_deleteRoleTitle')}
				description={translate('dialog_deleteRoleConfirm')}
				name={rolesList.data[activeRole.idx]?.name}
			/>

			{showScreenLock.isShow && <ScreenLock title={translate(showScreenLock.title)} />}

			{/* уведомление для удаления роли */}
			{showNotificationDelete &&
				<Notification
					showNotification={showNotificationDelete}
					setShowNotification={setShowNotificationDelete}
					selectDataResponse={selectRoleDeleteStatus}
					clearDataResponse={clearRoleDelete}
					titleFailed='noticeDeleteRole_failed'
					titleSuccess='noticeDeleteRole_success'
				/>}
		</div>
	);
};

export default RoleControls;
