import { SERVICE } from '../constants/accessRights';

// функция поиска права доступа к сервису по типу группы
export const findServiceRightByType = (groupType: string): string => {
	switch (groupType) {
		case 'smc':
			return SERVICE.SMC;
		case 'see':
			return SERVICE.SEE;
		case 'spr':
			return SERVICE.SPR;
		case 'sbs':
			return SERVICE.SBS;
		case 'tts':
			return SERVICE.TTS;
		case 'qas':
			return SERVICE.QAS;
		case 'ses':
			return SERVICE.SES;
		case 'auth':
			return SERVICE.AUTH;
		default:
			return '';
	}
};
