import { FormEvent, useEffect, useState } from 'react';
import { Button, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { getCorpusList, selectCorpusList } from '../../../store/corpusSlice';
import { trainModelSmc } from '../../../store/smcSlice';
import { trainModelSee } from '../../../store/seeSlice';
import useTranslate from '../../../hooks/useTranslate';
import { NoiseType } from '../../../types/seeTypes';
import { RequestStatus } from '../../../types/statusTypes';
import ProgressCircle from '../../ProgressCircle/ProgressCircle';
import ModalFormWindow from '../../../HOC/ModalFormWindow/ModalFormWindow';
import { IFormExportProps } from './FormTrain.props';

const FormTrain = ({ showModal, setShowModal, modelName, serviceType = 'smc', setShowNotification }: IFormExportProps): JSX.Element => {
	const [corpusName, setCorpusName] = useState<string>(''); // имя корпуса для обучения
	const [noise, setNoise] = useState<NoiseType>(2); // шум

	const dispatch = useAppDispatch();
	const corpusList = useAppSelector(selectCorpusList); // store - список корпусов
	const translate = useTranslate(); // hook для перевода текста

	useEffect(() => {
		dispatch(getCorpusList({ serviceType })); // получение списка корпусов
	}, []);

	// обработчик обучения модели
	const submitHandlerModel = (e: FormEvent<HTMLFormElement>): void => {
		e.preventDefault();
		serviceType === 'smc' && modelName && corpusName && dispatch(trainModelSmc({ modelName, corpusName })); // обучение модели smc
		serviceType === 'see' && modelName && corpusName && dispatch(trainModelSee({ modelName, corpusName, noise })); // обучение модели see
		setShowModal(false); // закрываем форму
		setShowNotification(true); // включаем уведомления
	};

	return (
		<ModalFormWindow showModal={showModal} setShowModal={setShowModal} headerTitle={`${translate('formTrain_title')} "${modelName}"`}>
			{corpusList.status === RequestStatus.LOADING ?
				<ProgressCircle title={translate('progressCircle_formLoadingTitle')} />
				:
				<form onSubmit={(e) => submitHandlerModel(e)}>
					{(corpusList.status === RequestStatus.FAILED || corpusList.data.length < 1 || !Array.isArray(corpusList.data)) ?
						<div>{translate('formTrain_noCorpusesTitle')}</div>
						:
						<>
							<FormControl fullWidth margin='dense' sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}>
								<InputLabel id="corpusName-label" sx={{ fontSize: 13 }}>{translate('formTrain_inputCorpus')}</InputLabel>
								<Select
									required
									labelId="corpusName-label"
									id="corpusName"
									label={translate('formTrain_inputCorpus')}
									value={corpusName}
									onChange={(e) => setCorpusName(e.target.value)}
									style={{ fontSize: 13, height: 33 }}
								>
									{corpusList.data.map((corpus) => (
										<MenuItem key={corpus} value={corpus} sx={{ fontSize: 13 }}>{corpus}</MenuItem>
									))}
								</Select>
							</FormControl>

							{serviceType === 'see' &&
								<FormControl fullWidth margin='dense' sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}>
									<InputLabel id="noise-label" sx={{ fontSize: 13 }}>{translate('formTrain_inputNoise')}</InputLabel>
									<Select
										labelId="noise-label"
										id="noise"
										label={translate('formTrain_inputNoise')}
										value={noise}
										onChange={(e) => setNoise(e.target.value as NoiseType)}
										style={{ fontSize: 13, height: 33 }}
									>
										{[0, 1, 2, 3, 4, 5].map((value) => (
											<MenuItem key={value} value={value} sx={{ fontSize: 13 }}>{value}</MenuItem>
										))}
									</Select>
								</FormControl>
							}

							<FormControl fullWidth margin='dense'>
								<Button variant="outlined" type="submit" sx={{ fontSize: 11 }}>{translate('formTrain_trainBtn')}</Button>
							</FormControl>
						</>
					}
				</form>
			}
		</ModalFormWindow>
	);
};

export default FormTrain;
