import { ChangeEvent, FormEvent, useEffect, useRef, useState } from 'react';
import { useReactMediaRecorder } from 'react-media-recorder-2';
import { Button } from '@mui/material';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMicrophone } from '@fortawesome/free-solid-svg-icons';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { clearVerify, selectSpeakers, selectVerify, verify } from '../../store/sbsSlice';
import useTranslate from '../../hooks/useTranslate';
import { backgroundColor, colorRed } from '../../constants/colors';
import { RequestStatus, ResponseStatus } from '../../types/statusTypes';
import ProgressCircle from '../ProgressCircle/ProgressCircle';
import styles from './BioVerify.module.scss';

const BioVerify = (): JSX.Element => {
	const [file, setFile] = useState<File>(); // для отправки файла
	const [recordingFlg, setRecordingFlg] = useState<boolean>(false); // флаг записи с микрофона
	const [typeAudio, setTypeAudio] = useState<null | 'microphone' | 'file'>(null); // тип аудио для отправки
	const formRef = useRef<HTMLFormElement>(null);

	const dispatch = useAppDispatch();
	const speakersList = useAppSelector(selectSpeakers); // store - список спикеров
	const verifyData = useAppSelector(selectVerify); // store - соответствие спикера и аудио

	const { status, startRecording, stopRecording } = useReactMediaRecorder({
		audio: true,
		onStop: (_: string, blob: Blob) => {
			blob.size > 0 && submitHandler(blob); // запускаем обработчик отправки аудиозаписи, если есть запись с микрофона
		}
	}); // hook для записи аудио

	const translate = useTranslate(); // hook для перевода текста

	// следим за флагом и статусом записи микрофона
	useEffect(() => {
		// и если кнопка мыши не зажата в плашке в момент подготовки или начала записи - останавливаем запись
		(status === 'acquiring_media' || status === 'recording') && recordingFlg === false && stopRecording();
	}, [status, recordingFlg]);

	// следим за выбранным аудио-файлом и устанавливаем тип для отображения имени файла
	useEffect(() => {
		file && setTypeAudio('file');
	}, [file]);

	// обработчик отправки аудиозаписи
	const submitHandler = (audio: File | Blob | undefined, e?: FormEvent<HTMLFormElement>): void => {
		e?.preventDefault();
		if (verifyData.status === RequestStatus.LOADING) return; // если идет загрузка - выходим

		verifyData.data !== null && dispatch(clearVerify()); // если есть данные соответствия - очищаем

		const formData = new FormData();
		// если есть запись (файл или с микрофона) - создаем пару
		if (audio) {
			formData.append('file', audio);
		}

		// если отправка идет с микрофона
		if (!e) {
			formRef.current?.reset(); // сбрасываем форму
			setFile(undefined); // очищаем файл
			setTypeAudio('microphone'); // устанавливаем тип отправки
		}

		speakersList.activeSpeaker !== null && dispatch(verify({ speakerId: speakersList.activeSpeaker.id, formData })); // соответствие спикера и аудио
	};

	return (
		<div className={styles.verify}>
			<div className={styles.verifyInner}>

				<form className={styles.form} onSubmit={e => submitHandler(file, e)} ref={formRef}>
					<div className={cn(styles.formMicrophone, {
						[styles.formMicrophoneNotAllow]: verifyData.status === RequestStatus.LOADING
					})}
						onMouseDown={() => {
							if (verifyData.status === RequestStatus.LOADING) return;
							startRecording();
							setRecordingFlg(true);
						}}
						onMouseUp={() => setRecordingFlg(false)}
						onMouseLeave={() => setRecordingFlg(false)}
						title={translate('formSendAudio_microphoneTitle')}>
						<FontAwesomeIcon icon={faMicrophone} size="5x" color={status === 'recording' ? colorRed : backgroundColor} fade={status === 'recording'} />
					</div>

					<div>{translate('or')}</div>

					<div className={styles.formFileName}>
						{typeAudio === 'microphone' ?
							translate('formSendAudio_inputRecordMicrophone')
							:
							file && file.name
						}
					</div>

					<input
						className={styles.formFileInput}
						type="file"
						id='verify'
						required
						accept="audio/wav"
						onChange={(e: ChangeEvent<HTMLInputElement>) => e.target.files && setFile(e.target.files[0])}
					/>
					<label htmlFor='verify' className={styles.formFileLabel}>
						{translate(file ? 'bioVerify_chooseAnotherFileTitle' : 'bioVerify_chooseFileTitle')}
					</label>

					<Button
						variant="outlined"
						type="submit"
						disabled={!file || verifyData.status === RequestStatus.LOADING}
						sx={{ fontSize: 11 }}
					>
						{verifyData.status === RequestStatus.LOADING ?
							<>
								{translate('bioVerify_checkingBtn')}
								<ProgressCircle isBtnDisabled />
							</>
							:
							translate('bioVerify_checkBtn')
						}
					</Button>
				</form>

				<div className={styles.verifyResult}>
					{verifyData.status === RequestStatus.FAILED &&
						<p className={styles.failedText}>{translate('bioVerify_failedTitle')}</p>
					}
					{verifyData.data !== null &&
						<>
							{typeof verifyData.data !== 'string' && 'confidence' in verifyData.data &&
								<p className={styles.verifyResultPair}>
									<span className={styles.verifyResultKey}>{translate('bioVerify_confidenceTitle')}: </span>{verifyData.data.confidence?.toFixed(3)}
								</p>
							}
							{typeof verifyData.data !== 'string' && 'error' in verifyData.data && 'message' in verifyData.data && verifyData.data.error === ResponseStatus.FAILED &&
								<p className={styles.notFoundText}>{translate(String(verifyData.data.message) || 'bioVerify_failedTitle')}</p>
							}
						</>
					}
				</div>
			</div>
		</div>
	);
};

export default BioVerify;
