import { FormEvent, useEffect, useState } from 'react';
import { Button, ButtonGroup, Fade } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faPen, faTrashCan, faXmark } from '@fortawesome/free-solid-svg-icons';
import { useCookies } from 'react-cookie';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { addClassAndCount, addClasses, addFilterByClass, addGroups, deleteClass, deleteCorpus, deleteGroup, editCorpusName, renameClass, renameCorpus, renameGroup, selectCorpus, selectCorpusRenameStatus } from '../../store/corpusSlice';
import useAccessRight from '../../hooks/useAccessRight';
import useTranslate from '../../hooks/useTranslate';
import { CORPUS } from '../../constants/accessRights';
import { colorPrimary, colorRed, colorGreen } from '../../constants/colors';
import { RequestStatus, ResponseStatus } from '../../types/statusTypes';
import ClassTree from '../ClassTree/ClassTree';
import AlertDialog from '../AlertDialog/AlertDialog';
import { ICorpusInfoProps } from './CorpusInfo.props';
import styles from './CorpusInfo.module.scss';

const CorpusInfo = ({ setShowNotificationRename, setShowNotificationDelete, serviceType, setChangeFlg, visibleTable, setVisibleTable }: ICorpusInfoProps): JSX.Element => {
	const [showAlertDialogDelCorpus, setShowAlertDialogDelCorpus] = useState<boolean>(false); // показ диалогового окна при удалении корпуса
	const [showAlertDialogRenameClass, setShowAlertDialogRenameClass] = useState<boolean>(false); // показ диалогового окна при переименовании класса на существующий
	const [showAlertDialogDelClass, setShowAlertDialogDelClass] = useState<boolean>(false); // показ диалогового окна при удалении класса
	const [groups, setGroups] = useState<Record<string, number>>({}); // список групп и их количество
	const [renameCorpusFlg, setRenameCorpusFlg] = useState<boolean>(false); // флаг для формы переименования корпуса
	const [renameClassFlg, setRenameClassFlg] = useState<{ isOpen: boolean, idx: number }>({ isOpen: false, idx: -1 }); // флаг и индекс для формы переименования класса/группы
	const [inputRenameCorpus, setInputRenameCorpus] = useState<string>(''); // имя корпуса для переименования
	const [inputRenameClass, setInputRenameClass] = useState<string>(''); // имя класса/группы для переименования
	const [selectedClassToBeRemoved, setSelectedClassToBeRemoved] = useState<string>(''); // выбранный класс/группа для удаления

	const dispatch = useAppDispatch();
	const corpus = useAppSelector(selectCorpus); // store - корпус
	const renameStatus = useAppSelector(selectCorpusRenameStatus); // store - статус о переименовании корпуса
	const isAccess = useAccessRight(); // hook для проверки прав доступа
	const translate = useTranslate(); // hook для перевода текста
	const [_, setCookie, __] = useCookies([`${serviceType}CorpusName`]); // hook для работы с cookie

	// следим за корпусом данных
	useEffect(() => {
		counterClasses(); // пересчитываем количество классов
		counterGroups(); // пересчитываем количество групп
		// закрываем поля переименований, если открыты
		renameCorpusFlg && setRenameCorpusFlg(false);
		renameClassFlg.isOpen && setRenameClassFlg({ isOpen: false, idx: -1 });
	}, [corpus.data]);

	// следим за табами
	useEffect(() => {
		// закрываем поля переименований, если открыты
		renameCorpusFlg && setRenameCorpusFlg(false);
		renameClassFlg.isOpen && setRenameClassFlg({ isOpen: false, idx: -1 });
	}, [visibleTable]);

	// следим за статусом переименования
	useEffect(() => {
		// если переименование успешно
		if (corpus.corpusName && renameStatus.status === RequestStatus.IDLE && renameStatus.error === ResponseStatus.SUCCESS && renameStatus.message === 'success') {
			dispatch(editCorpusName({ prevName: corpus.corpusName, newName: inputRenameCorpus })); // изменяем имя корпуса в store (без перезагрузки страницы)
			setCookie(`${serviceType}CorpusName`, inputRenameCorpus, { path: '/', maxAge: 2_592_000 }); // меняем cookie на месяц
		}
	}, [renameStatus]);

	// обработчик нажатия кнопки переименования корпуса
	const renameCorpusHandler = (): void => {
		setRenameCorpusFlg(true); // открываем форму для переименования
		corpus.corpusName && setInputRenameCorpus(corpus.corpusName); // записываем в input текущее название корпуса
	};

	// обработчик нажатия клавиши в поле переименования корпуса
	const keyDownRenameCorpusHandler = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.code === 'Escape') setRenameCorpusFlg(false);
	};

	// обработчик формы переименования корпуса
	const submitRenameCorpusHandler = (e: FormEvent<HTMLFormElement>): void => {
		e.preventDefault();
		setRenameCorpusFlg(false); // закрываем форму переименования корпуса
		if (corpus.corpusName === inputRenameCorpus) return; // если имя не поменялось - выходим без изменений
		setShowNotificationRename(true); // включаем уведомление о переименовании корпуса
		corpus.corpusName && dispatch(renameCorpus({ corpusNameSource: corpus.corpusName, corpusNameDestination: inputRenameCorpus, serviceType })); // переименование корпуса
	};

	// обработчик удаления корпуса
	const deleteCorpusHandler = (): void => {
		setShowAlertDialogDelCorpus(false); // закрываем диалоговое окно
		setShowNotificationDelete(true); // включаем уведомление об удалении корпуса
		corpus.corpusName && dispatch(deleteCorpus({ corpusName: corpus.corpusName, serviceType })); // удаление корпуса
	};

	// подсчет классов
	const counterClasses = () => {
		const classesList: string[] = []; // список классов
		const classAndCount: Record<string, number> = {};  // список классов и их количество

		// перебираем данные корпуса
		corpus.data.data.forEach(сlassesOfCorpus => {
			// перебираем массив классов для каждой строки
			сlassesOfCorpus[0].forEach(classOfCorpus => {
				// если в списке классов содержится текущий класс - прибавляем количество
				if (classAndCount.hasOwnProperty(classOfCorpus)) classAndCount[classOfCorpus]++;
				else {
					classesList.push(classOfCorpus); // иначе добавляем класс
					classAndCount[classOfCorpus] = 1; // и начинаем счет
				}
			});
		});

		dispatch(addClasses(classesList.sort())); // добавляем классы в store с сортировкой по возрастанию
		dispatch(addClassAndCount(classAndCount)); // добавляем имена и количество классов
	};

	// подсчет групп
	const counterGroups = () => {
		const groupsList: string[] = []; // список групп
		const groupAndCount: Record<string, number> = {};  // список групп и их количество

		// перебираем данные групп
		corpus.data.groups.forEach(group => {
			// если в списке групп содержится текущая группа - прибавляем количество
			if (groupAndCount.hasOwnProperty(group[1])) groupAndCount[group[1]]++;
			else {
				groupsList.push(group[1]); // иначе добавляем группу
				groupAndCount[group[1]] = 1; // и начинаем счет
			}
		});

		dispatch(addGroups(groupsList.sort())); // добавляем классы в store с сортировкой по возрастанию
		setGroups(groupAndCount); // записываем количество групп в state
	};

	// обработчик нажатия кнопки переименования класса/группы
	const renameClassHandler = (className: string, idx: number): void => {
		setRenameClassFlg({ isOpen: true, idx }); // открываем форму для переименования
		setInputRenameClass(className); // записываем в input название класса
	};

	// обработчик нажатия клавиши в поле переименования класса/группы
	const keyDownRenameClassHandler = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.code === 'Escape') setRenameClassFlg({ isOpen: false, idx: -1 });
	};

	// обработчик формы переименования класса/группы
	const submitRenameClassHandler = (e: FormEvent<HTMLFormElement>): void => {
		e.preventDefault();
		// если имя не поменялось - закрываем форму
		if ((visibleTable === 'data' && corpus.classes[renameClassFlg.idx] === inputRenameClass) || (visibleTable === 'groups' && corpus.groups[renameClassFlg.idx] === inputRenameClass)) {
			setRenameClassFlg({ isOpen: false, idx: -1 });
		}
		// если переименовываем на существующий класс/группу - включаем предупреждение
		else if ((visibleTable === 'data' && corpus.classes.includes(inputRenameClass)) || (visibleTable === 'groups' && corpus.groups.includes(inputRenameClass))) {
			setShowAlertDialogRenameClass(true);
		}
		else renameClassFunc();
	};

	// переименование класса/группы
	const renameClassFunc = (): void => {
		visibleTable === 'data' && dispatch(renameClass({ oldClassName: corpus.classes[renameClassFlg.idx], newClassName: inputRenameClass })); // переименование класса
		visibleTable === 'groups' && dispatch(renameGroup({ oldGroupName: corpus.groups[renameClassFlg.idx], newGroupName: inputRenameClass })); // переименование группы
		setRenameClassFlg({ isOpen: false, idx: -1 }); // закрываем форму переименования класса
		setChangeFlg(true); // включаем флаг о не сохраненных данных
		showAlertDialogRenameClass && setShowAlertDialogRenameClass(false); // закрываем диалоговое окно если было открыто
	};

	// обработчик удаления класса/группы
	const deleteClassHandler = () => {
		setShowAlertDialogDelClass(false); // закрываем диалоговое окно
		visibleTable === 'data' && dispatch(deleteClass(selectedClassToBeRemoved)); // удаляем класс из store
		visibleTable === 'groups' && dispatch(deleteGroup(selectedClassToBeRemoved)); // удаляем группу из store
		setChangeFlg(true); // включаем флаг о не сохраненных данных
	};

	const countingUnmarkedPhrases = (): number => {
		let count = 0;
		corpus.data.data.forEach(([classes]) => {
			classes.length === 0 && count++;
		});
		return count;
	};

	return (
		<div className={styles.info}>
			{corpus.corpusName && corpus.status === RequestStatus.IDLE &&
				<Fade in={true} timeout={500}>
					<div className={styles.infoTop}>
						<div className={styles.infoTopTitleBlock}>
							<div className={renameCorpusFlg ? styles.infoTopTitleForm : styles.infoTopTitle}>
								<span className={styles.infoKey}>{translate('corpusInfo_corpusTitle')}:&nbsp;</span>
								{renameCorpusFlg ?
									<form className={styles.renameForm} onSubmit={e => submitRenameCorpusHandler(e)}>
										<input className={styles.renameFormInput} value={inputRenameCorpus} onChange={e => setInputRenameCorpus(e.target.value)} autoFocus onKeyDown={(e) => keyDownRenameCorpusHandler(e)} />
										<button className={styles.renameFormBtn} type="submit" title={translate('corpusInfo_renameCorpusBtn')}>
											<FontAwesomeIcon icon={faCheck} style={{ cursor: 'pointer' }} color={colorGreen} size="xl" />
										</button>
										<button className={styles.renameFormBtn} type="reset" title={translate('corpusInfo_renameCancelBtn')}>
											<FontAwesomeIcon icon={faXmark} style={{ cursor: 'pointer' }} color={colorRed} size="xl" onClick={() => setRenameCorpusFlg(false)} />
										</button>
									</form>
									:
									<span>{corpus.corpusName}</span>
								}
							</div>
							<div className={styles.infoTopTitleFunc}>
								{!renameCorpusFlg &&
									<>
										{isAccess(CORPUS.RENAME) && <FontAwesomeIcon icon={faPen} size="xs" color={colorPrimary} onClick={renameCorpusHandler} title={translate('corpusInfo_renameCorpusBtn')} style={{ cursor: 'pointer' }} />}
										{isAccess(CORPUS.DELETE) && <FontAwesomeIcon icon={faTrashCan} size="xs" color={colorRed} onClick={() => corpus.corpusName && setShowAlertDialogDelCorpus(true)} title={translate('corpusInfo_deleteCorpusBtn')} style={{ cursor: 'pointer' }} />}
									</>
								}
							</div>
						</div>
						<div className={styles.infoTopDescBlock}>
							<span className={styles.infoKey}>
								{translate('corpusInfo_quantityLinesTitle')}:
							</span> {corpus.data.data.length}
						</div>

						<div className={styles.infoTopDescBlock}>
							<span className={styles.infoKey}>
								{translate('corpusInfo_quantityClassesTitle')}:
							</span> {corpus.classes.length}
						</div>

						{serviceType === 'smc' && <div className={styles.infoTopDescBlock}>
							<span className={styles.infoKey}>
								{translate('corpusInfo_quantityGroupsTitle')}:
							</span> {corpus.groups.length}
						</div>}

						{serviceType === 'smc' && <div className={styles.infoTopDescBlock}>
							<span className={styles.infoKey}>
								{translate('corpusInfo_quantityUnmarkedPhrasesTitle')}:
							</span> {countingUnmarkedPhrases()}
						</div>}

						{serviceType === 'smc' &&
							<ButtonGroup fullWidth sx={{ marginTop: '8px' }}>
								<Button
									variant={visibleTable === 'data' ? "contained" : "outlined"}
									sx={{ width: '50%', overflow: 'hidden', fontSize: 11 }}
									onClick={() => setVisibleTable('data')}
								>
									{translate('corpusInfo_dataBtn')}
								</Button>
								<Button
									variant={visibleTable === 'groups' ? "contained" : "outlined"}
									sx={{ width: '50%', overflow: 'hidden', fontSize: 11 }}
									onClick={() => setVisibleTable('groups')}
								>
									{translate('corpusInfo_groupsBtn')}
								</Button>
							</ButtonGroup>
						}
					</div>
				</Fade>
			}

			{corpus.corpusName && corpus.status === RequestStatus.IDLE &&
				<Fade in={true} timeout={500}>
					<div className={styles.infoClasses}>
						<div className={styles.infoClassesTitle}>
							{translate(visibleTable === 'data' ? 'corpusInfo_classListTitle' : 'corpusInfo_groupListTitle')}
						</div>
						{corpus.data[visibleTable].length > 0 ?
							(visibleTable === 'data' && serviceType === 'smc') ?
								<ClassTree setChangeFlg={setChangeFlg} />
								:
								<ul className={styles.infoClassesList}>
									{corpus[visibleTable === 'data' ? 'classes' : 'groups'].map((className, idx) => (
										<li className={styles.infoClassesItem} key={className}>
											{renameClassFlg.isOpen && renameClassFlg.idx === idx ?
												<form className={styles.renameForm} onSubmit={e => submitRenameClassHandler(e)}>
													<input
														className={styles.renameFormInput}
														autoFocus
														value={inputRenameClass}
														onChange={e => setInputRenameClass(e.target.value.replace(/,/g, ''))}
														onKeyDown={(e) => keyDownRenameClassHandler(e)}
													/>
													<button className={styles.renameFormBtn} type="submit" title={translate(visibleTable === 'data' ? 'corpusInfo_renameClassBtn' : 'corpusInfo_renameGroupBtn')}>
														<FontAwesomeIcon icon={faCheck} style={{ cursor: 'pointer' }} color={colorGreen} size="lg" />
													</button>
													<button className={styles.renameFormBtn} type="reset" title={translate('corpusInfo_renameCancelBtn')}>
														<FontAwesomeIcon icon={faXmark} style={{ cursor: 'pointer' }} color={colorRed} size="lg" onClick={() => setRenameClassFlg({ isOpen: false, idx: -1 })} />
													</button>
												</form>
												:
												<>
													<p className={styles.infoClassesItemKey} title={className} onClick={() => visibleTable === 'data' && dispatch(addFilterByClass(className))}>
														{className}
													</p>
													<p className={styles.infoClassesItemValue}>
														{visibleTable === 'data' ? corpus.classAndCount[className] : groups[className]}
													</p>
													{isAccess(CORPUS.SAVE) && <div className={styles.infoClassesItemEdit}>
														<FontAwesomeIcon
															icon={faPen}
															size="sm"
															color={colorPrimary}
															onClick={() => renameClassHandler(className, idx)}
															title={translate(visibleTable === 'data' ? 'corpusInfo_renameClassBtn' : 'corpusInfo_renameGroupBtn')}
															style={{ cursor: 'pointer' }}
														/>
														<FontAwesomeIcon
															icon={faTrashCan}
															size="sm"
															color={colorRed}
															onClick={() => {
																setShowAlertDialogDelClass(true);
																setSelectedClassToBeRemoved(className);
															}}
															title={translate(visibleTable === 'data' ? 'corpusInfo_deleteClassBtn' : 'corpusInfo_deleteGroupBtn')}
															style={{ cursor: 'pointer' }}
														/>
													</div>}
												</>
											}
										</li>
									))}
								</ul>
							:
							<p className={styles.infoClassesNoData}>{translate(visibleTable === 'data' ? 'corpusInfo_classListNoDataTitle' : 'corpusInfo_groupListNoDataTitle')}</p>
						}
					</div>
				</Fade>
			}

			<AlertDialog
				showAlertDialog={showAlertDialogDelCorpus}
				setShowAlertDialog={setShowAlertDialogDelCorpus}
				submitHandler={deleteCorpusHandler}
				title='dialog_deleteCorpusTitle'
				description='dialog_deleteCorpusConfirm'
				name={String(corpus.corpusName)}
			/>
			<AlertDialog
				showAlertDialog={showAlertDialogRenameClass}
				setShowAlertDialog={setShowAlertDialogRenameClass}
				submitHandler={renameClassFunc}
				title={visibleTable === 'data' ? 'dialog_renameClassTitle' : 'dialog_renameGroupTitle'}
				description={visibleTable === 'data' ? 'dialog_renameClassConfirm' : 'dialog_renameGroupConfirm'}
				name={visibleTable === 'data' ? corpus.classes[renameClassFlg.idx] : corpus.groups[renameClassFlg.idx]}
				name2={inputRenameClass}
			/>
			<AlertDialog
				showAlertDialog={showAlertDialogDelClass}
				setShowAlertDialog={setShowAlertDialogDelClass}
				submitHandler={deleteClassHandler}
				title={visibleTable === 'data' ? 'dialog_deleteClassTitle' : 'dialog_deleteGroupTitle'}
				description={visibleTable === 'data' ? 'dialog_deleteClassConfirm' : 'dialog_deleteGroupConfirm'}
				name={selectedClassToBeRemoved}
			/>
		</div>
	);
};

export default CorpusInfo;
