import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestion } from '@fortawesome/free-solid-svg-icons';
import useTranslate from '../../../../hooks/useTranslate';
import { backgroundColor } from '../../../../constants/colors';
import { IQuestionProps } from './Question.props';

const Question = ({ styleNotActive, isAvailable, setShowAnswerToQuestion }: IQuestionProps): JSX.Element => {
	const translate = useTranslate(); // hook для перевода текста

	return (
		<div
			className={isAvailable ? '' : styleNotActive}
			onClick={() => isAvailable && setShowAnswerToQuestion(prev => !prev)}
			title={translate('controlsBtn_question')}>
			<FontAwesomeIcon icon={faQuestion} size="2xl" color={backgroundColor} />
		</div>
	);
};

export default Question;
