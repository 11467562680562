import { useState } from 'react';
import { Slider as SliderMUI } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { changeTimeInterval, deleteTimeInterval, selectActiveRobotVersion } from '../../../../store/sesRobotSlice';
import useAccessRight from '../../../../hooks/useAccessRight';
import useTranslate from '../../../../hooks/useTranslate';
import { SES } from '../../../../constants/accessRights';
import { colorRed } from '../../../../constants/colors';
import { ISliderProps } from './Slider.props';
import styles from './Slider.module.scss';

// функция перевода минут в формат HH:MM
// const formatToHoursAndMinutes = (value: number): string => {
// 	let hours: string | number = Math.trunc(value / 60);
// 	let minutes: string | number = value % 60;
// 	minutes = minutes < 10 ? '0' + minutes : minutes;
// 	if (hours < 10) hours = '0' + hours;
// 	else if (hours === 24) hours = '00';
// 	// hours = hours < 10 ? '0' + hours : hours;
// 	// hours = ho
// 	// console.log(hours + ':' + minutes);
// 	return hours + ':' + minutes;
// };

// функция перевода формата HH:MM в минуты
// const formatToMinutes = (value: string, endTime?: boolean): number => {
// 	const hours = +(value[0] + value[1]);
// 	const minutes = +(value[3] + value[4]);
// 	const times = hours * 60 + minutes;
// 	// перевод конечного времени, если равен 0
// 	return (endTime && times === 0) ? 1440 : times;
// };

const Slider = ({ intervalName, time, idx, array, setChangeFlg }: ISliderProps): JSX.Element => {
	const [timeInterval, setTimeInterval] = useState<[number, number]>(time); // временная метка
	// const [startTime, setStartTime] = useState<string>(formatToHoursAndMinutes(time[0])); // время начала логов
	// const [endTime, setEndTime] = useState<string>(formatToHoursAndMinutes(time[1])); // время конца логов

	const dispatch = useAppDispatch();
	const activeRobotVersion = useAppSelector(selectActiveRobotVersion); // store - версия активного робота

	const translate = useTranslate(); // hook для перевода текста
	const isAccess = useAccessRight(); // hook для проверки прав доступа

	// следим за временем
	// useEffect(() => {
	// 	// через секунду бездействия после окончания ввода
	// 	const handler = setTimeout(() => {
	// 		if (formatToHoursAndMinutes(time[0]) !== startTime || formatToHoursAndMinutes(time[1]) !== endTime) {
	// 			dispatch(changeTimeInterval({
	// 				intervalName,
	// 				data: [formatToMinutes(startTime), formatToMinutes(endTime, true)],
	// 				idx
	// 			}));
	// 			setChangeFlg(true);
	// 		}
	// 	}, 1000);

	// 	return () => {
	// 		clearTimeout(handler); // сбрасываем timeout, если продолжается ввод
	// 	};
	// }, [startTime, endTime]);

	// обработчик изменения времени
	const changeTimeHandler = (value: number | number[]): void => {
		dispatch(changeTimeInterval({ intervalName, data: value as [number, number], idx }));
		setChangeFlg(true); // ставим флаг о несохраненных данных
	};

	// обработчик удаления времени
	const deleteTimeHandler = (): void => {
		dispatch(deleteTimeInterval({ intervalName, idx }));
		setChangeFlg(true); // ставим флаг о несохраненных данных
	};

	// функция перевода минут в формат HH:MM
	const formatMinutes = (value: number): string => {
		const hours = Math.trunc(value / 60);
		let minutes: string | number = value % 60;
		minutes = minutes < 10 ? '0' + minutes : minutes;
		return hours + ':' + minutes;
	};

	return (
		<div className={styles.sliderBlock}>
			<span className={styles.time}>{formatMinutes(timeInterval[0])}</span>
			<SliderMUI
				size="small"
				min={0}
				max={1440}
				step={5}
				// marks={[{ value: 0, label: '00:00' }, { value: 720, label: '12:00' }, { value: 1440, label: '24:00' }]}
				valueLabelDisplay='off'
				disabled={!isAccess(SES.ROBOT_EDIT) || activeRobotVersion !== 'draft'}
				value={timeInterval}
				onChange={(_e, value) => setTimeInterval(value as [number, number])}
				onChangeCommitted={(_e, value) => changeTimeHandler(value)}
				valueLabelFormat={formatMinutes}
			/>
			<span className={styles.time}>{formatMinutes(timeInterval[1])}</span>

			{/* <FormControl fullWidth margin='dense'>
				<TextField
					required
					// label={translate('Время')}
					variant="outlined"
					type='time'
					value={startTime}
					onChange={(e) => setStartTime(e.target.value)}
					disabled={!isAccess(SES.ROBOT_EDIT)}
					InputProps={{
						style: {
							height: 33,
							fontSize: 13,
							color: colorPrimary,
						},
					}}
					InputLabelProps={{
						style: {
							fontSize: 13,
						},
					}}
					sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}
				/>
			</FormControl> */}

			{/* <FormControl fullWidth margin='dense'>
				<TextField
					required
					// label={translate('Время')}
					variant="outlined"
					type='time'
					value={endTime}
					onChange={(e) => setEndTime(e.target.value)}
					disabled={!isAccess(SES.ROBOT_EDIT)}
					InputProps={{
						style: {
							height: 33,
							fontSize: 13,
							color: colorPrimary,
						},
					}}
					InputLabelProps={{
						style: {
							fontSize: 13,
						},
					}}
					sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}
				/>
			</FormControl> */}

			{/* удаление периода */}
			{isAccess(SES.ROBOT_EDIT) && array.length > 1 &&
				<FontAwesomeIcon
					icon={faTrashCan}
					color={colorRed}
					size='lg'
					onClick={deleteTimeHandler}
					title={translate('slider_deleteTimeTitle')}
					style={{ cursor: 'pointer' }}
				/>
			}
		</div>
	);
};

export default Slider;
