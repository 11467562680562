import CircularProgress from '@mui/material/CircularProgress';
import { IProgressCircleProps } from './ProgressCircle.props';
import styles from './ProgressCircle.module.scss';

const ProgressCircle = ({ isBtnDisabled = false, title, inLine = false }: IProgressCircleProps): JSX.Element => {

	return (
		<div>
			{isBtnDisabled ?
				<CircularProgress
					color="inherit"
					size={12}
					sx={{ marginLeft: 2 }}
				/>
				:
				<div className={inLine ? styles.containerInLine : styles.containerToColumn}>
					<CircularProgress size={32} />
					{title && <p className={styles.description}>{title}</p>}
				</div>
			}
		</div>
	);
};

export default ProgressCircle;
