import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRotateLeft } from '@fortawesome/free-solid-svg-icons';
import useTranslate from '../../../../hooks/useTranslate';
import { backgroundColor } from '../../../../constants/colors';
import Notification from '../../../Notification/Notification';
import { IRestoreRobotProps } from './RestoreRobot.props';

const RestoreRobot = ({ styleNotActive, isAvailable, showNotification, setShowNotification, dataResponse, clearDataResponse, setShowAlertDialog }: IRestoreRobotProps): JSX.Element => {
	const translate = useTranslate(); // hook для перевода текста

	return (
		<>
			<div
				className={isAvailable ? '' : styleNotActive}
				onClick={() => isAvailable && setShowAlertDialog(true)}
				title={translate('controlsBtn_restoreRobot')}>
				<FontAwesomeIcon icon={faRotateLeft} size="2xl" color={backgroundColor} />
			</div>

			<section style={{ position: 'absolute' }}>
				{showNotification &&
					<Notification
						showNotification={showNotification}
						setShowNotification={setShowNotification}
						selectDataResponse={dataResponse}
						clearDataResponse={clearDataResponse}
						titleFailed='noticeRestoreRobot_failed'
						titleSuccess='noticeRestoreRobot_success'
					/>
				}
			</section>
		</>
	);
};

export default RestoreRobot;
