import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFloppyDisk } from '@fortawesome/free-solid-svg-icons';
import useTranslate from '../../../../hooks/useTranslate';
import { backgroundColor } from '../../../../constants/colors';
import Notification from '../../../Notification/Notification';
import { ISaveProps } from './Save.props';

const Save = ({ styleNotActive, changeFlg, showNotification, setShowNotification, dataResponse, clearDataResponse, setShowAlertDialog, titleBtn = 'controlsBtn_save', titleFailed = 'noticeSave_failed', titleSuccess = 'noticeSave_success' }: ISaveProps): JSX.Element => {
	const translate = useTranslate(); // hook для перевода текста

	return (
		<>
			<div
				className={changeFlg ? '' : styleNotActive}
				onClick={() => changeFlg && setShowAlertDialog(true)}
				title={translate(titleBtn)}>
				<FontAwesomeIcon icon={faFloppyDisk} size="2xl" color={backgroundColor} />
			</div>

			<section style={{ position: 'absolute' }}>
				{showNotification &&
					<Notification
						showNotification={showNotification}
						setShowNotification={setShowNotification}
						selectDataResponse={dataResponse}
						clearDataResponse={clearDataResponse}
						titleFailed={titleFailed}
						titleSuccess={titleSuccess}
					/>
				}
			</section>
		</>
	);
};

export default Save;
