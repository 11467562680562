import { useEffect } from 'react';
import { useAppSelector } from '../../../store/hooks';
import { selectAddSpeaker, selectEditSpeaker, selectSpeakers } from '../../../store/sbsSlice';
import useTranslate from '../../../hooks/useTranslate';
import { RequestStatus } from '../../../types/statusTypes';
import ModalFormWindow from '../../../HOC/ModalFormWindow/ModalFormWindow';
import FormSendAudio from '../FormSendAudio/FormSendAudio';
import { IFormAddingSpeakerProps } from './FormAddingSpeaker.props';

const FormAddingSpeaker = ({ showModal, setShowModal, setShowNotification, isAddition = false }: IFormAddingSpeakerProps): JSX.Element => {
	const speakersList = useAppSelector(selectSpeakers); // store - список спикеров
	const addSpeakerStatus = useAppSelector(selectAddSpeaker); // store - статус добавления спикера
	const editSpeakerStatus = useAppSelector(selectEditSpeaker); // store - статус дополнения спикера

	const translate = useTranslate(); // hook для перевода текста

	// следим за статусом добавления/дополнения спикера
	useEffect(() => {
		// и при добавлении или дополнении
		if (addSpeakerStatus.status === RequestStatus.LOADING || (isAddition && editSpeakerStatus.status === RequestStatus.LOADING)) {
			setShowNotification(true); // включаем уведомление
			setShowModal(false); // закрываем форму
		}
	}, [addSpeakerStatus.status, editSpeakerStatus.status]);

	return (
		<ModalFormWindow
			showModal={showModal}
			setShowModal={setShowModal}
			headerTitle={isAddition ?
				`${translate('formAddingSpeaker_additionTitle')} "${speakersList.activeSpeaker?.name}"`
				:
				'formAddingSpeaker_addingTitle'}
		>
			<FormSendAudio
				formFor={isAddition ? 'additionSpeaker' : 'addingSpeaker'}
				speakerId={isAddition ? speakersList.activeSpeaker?.id : undefined}
			/>
		</ModalFormWindow>
	);
};

export default FormAddingSpeaker;
