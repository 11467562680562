import { useEffect } from 'react';
import { Fade, FormControl, TextField } from '@mui/material';
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-python";
import "ace-builds/src-noconflict/theme-iplastic";
import "ace-builds/src-noconflict/ext-language_tools";
import 'brace/ext/searchbox'; // для команд ctrl+f и ctrl+h
import { useAppSelector } from '../../../store/hooks';
import { selectActiveRobotVersion } from '../../../store/sesRobotSlice';
import { selectScript } from '../../../store/sesScriptSlice';
import useAccessRight from '../../../hooks/useAccessRight';
import useTranslate from '../../../hooks/useTranslate';
import { SES } from '../../../constants/accessRights';
import { colorPrimary } from '../../../constants/colors';
import { RequestStatus, ResponseStatus } from '../../../types/statusTypes';
import ProgressCircle from '../../ProgressCircle/ProgressCircle';
import { IScriptProps } from './Script.props';
import styles from './Script.module.scss';

const Script = ({ changeFlg, setChangeFlg, name, setName, code, setCode }: IScriptProps): JSX.Element => {
	const script = useAppSelector(selectScript); // store - скрипт
	const activeRobotVersion = useAppSelector(selectActiveRobotVersion); // store - версия активного робота

	const isAccess = useAccessRight(); // hook для проверки прав доступа
	const translate = useTranslate(); // hook для перевода текста

	// следим за полученным скриптом
	useEffect(() => {
		setName(script.name); // вписываем в поле имени
		setCode(script.script); // вписываем в поле для кода
	}, [script]);

	return (
		<div className={styles.scriptCode}>
			{/* загрузка скрипта */}
			{script.status === RequestStatus.LOADING &&
				<div className={styles.loading}>
					<ProgressCircle title={translate('progressCircle_scriptLoadingTitle')} />
				</div>
			}

			{/* ошибка получения скрипта */}
			{(script.status === RequestStatus.FAILED || script.error === ResponseStatus.FAILED) &&
				<div className={styles.failed}>
					{translate(script.message || 'script_failedTitle')}
				</div>
			}

			{/* скрипт */}
			{script.status === RequestStatus.IDLE &&
				<Fade in={true} timeout={500}>
					<div className={styles.scriptCodeWrapper}>
						{/* название скрипта */}
						<FormControl fullWidth margin='dense'>
							<TextField
								label={translate('script_inputName')}
								variant="outlined"
								disabled={!isAccess(SES.SCRIPT_EDIT) || activeRobotVersion !== 'draft'}
								value={name}
								onChange={(e) => { setName(e.target.value); !changeFlg && setChangeFlg(true); }}
								// error={showErrorExistingDataElem}
								// helperText={showErrorExistingDataElem ? translate('Элемент данных уже существует') : ''}
								InputProps={{
									style: {
										height: 33,
										fontSize: 13,
										color: colorPrimary,
									},
								}}
								InputLabelProps={{
									style: {
										fontSize: 13,
									},
								}}
								sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}
							/>
						</FormControl>

						{/* код */}
						<AceEditor
							name="script"
							placeholder={translate('script_emptyCodeTitle')}
							mode="python"
							theme="iplastic"
							width='100%'
							fontSize={11}
							value={code}
							onChange={value => { setCode(value); !changeFlg && setChangeFlg(true); }}
							editorProps={{ $blockScrolling: true }}
							setOptions={{
								enableBasicAutocompletion: true,
								enableLiveAutocompletion: true,
								// enableSnippets: true
							}}
							style={{ flexGrow: 1 }}
							readOnly={!isAccess(SES.SCRIPT_EDIT) || activeRobotVersion !== 'draft'}
						/>
					</div>
				</Fade>
			}
		</div>
	);
};

export default Script;
