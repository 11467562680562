import { BIOMETRY, CLASSIFIER, ENTITIES, QUESTIONNAIRE, RECOGNIZER, SYNTHESIS } from '../constants/routes';
import { ServiceType } from '../types/modelTypes';

// функция для перевода маршрутов
export const routeTranslation = (serviceType: ServiceType): string => {
	switch (serviceType) {
		case 'smc':
			return CLASSIFIER;
		case 'see':
			return ENTITIES;
		case 'spr':
			return RECOGNIZER;
		case 'sbs':
			return BIOMETRY;
		case 'tts':
			return SYNTHESIS;
		case 'qas':
			return QUESTIONNAIRE;
		default:
			return '/404';
	};
};
