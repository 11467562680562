import { useEffect } from 'react';
import Pagination from '@mui/material/Pagination';
import { colorPrimary } from '../../../constants/colors';
import { ITableCustomPaginationProps } from './TableCustomPagination.props';

const TableCustomPagination = <T,>({ table, tableContainerRef }: ITableCustomPaginationProps<T>) => {

	useEffect(() => {
		// если количество страниц таблицы больше нуля и количество страниц меньше активной страницы - устанавливаем на последнюю страницу из возможных
		if (table.getPageCount() > 0 && table.getPageCount() < table.getState().pagination.pageIndex + 1) {
			table.setPageIndex(table.getPageCount() - 1);
			table.initialState.pagination.pageIndex = table.getPageCount() - 1;
		}
	});

	return (
		<Pagination
			color="primary"
			count={table.getPageCount()}
			page={table.getState().pagination.pageIndex + 1}
			onChange={(_, value) => {
				const page = value ? Number(value) - 1 : 0;
				table.setPageIndex(page);
				table.initialState.pagination.pageIndex = page;
				tableContainerRef.current?.scrollTo(0, 0); // поднимаем скролл наверх
			}}
			sx={{ button: { color: colorPrimary, fontSize: 11 } }}
			size='small'
		/>
	);
};

export default TableCustomPagination;
