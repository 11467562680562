import { Slide } from '@mui/material';
import ManualCheckSmc from '../ManualCheckSmc';
import ManualCheckSee from '../ManualCheckSee';
import { IManualCheckModalProps } from './ManualCheckModal.props';
import styles from './ManualCheckModal.module.scss';

const ManualCheckModal = ({ showManualCheck, setShowManualCheck, serviceType, modelName, modelNameAsIs = false }: IManualCheckModalProps): JSX.Element => {
	return (
		<Slide direction="left" in={showManualCheck} mountOnEnter unmountOnExit>
			<div className={styles.modal} onMouseDown={() => setShowManualCheck(false)}>
				{serviceType === 'smc' && <ManualCheckSmc showManualCheck={showManualCheck} modelName={modelName} modelNameAsIs={modelNameAsIs} />}
				{serviceType === 'see' && <ManualCheckSee showManualCheck={showManualCheck} modelName={modelName} modelNameAsIs={modelNameAsIs} />}
			</div>
		</Slide>
	);
};

export default ManualCheckModal;
