import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError, AxiosResponse } from 'axios';
import { RootState } from './store';
import axiosApi from '../helpers/axios';
import { ENDPOINTS_SPR, URL_SPR } from '../constants/api_endpoints';
import { IAsyncModeResultDialog, IAsyncModeResultFailed, IAsyncModeResultMonolog, IAsyncModeStatus, IRecognizeSpeechProps, IResponseAudioPeaks, IResponseFailed, IResponseSuccessAsyncDialog, IResponseSuccessAsyncMonolog, IResponseSuccessSync, IResponseTaskId, IResponseText, ISpeakerModified, ISprState, QueueResponseType } from '../types/sprTypes';
import { RequestStatus, ResponseStatus } from '../types/statusTypes';

const initialState: ISprState = {
  recognition: {
    data: null,
    audioUrl: null,
    timestamp: 0,
    speakerList: [],
    status: RequestStatus.IDLE,
  },
  asyncMode: {
    taskId: null,
    requestStatus: RequestStatus.IDLE,
    responseStatus: null,
  },
  deletionTask: {
    status: RequestStatus.IDLE,
    error: ResponseStatus.SUCCESS,
    message: '',
  },
  queue: {
    data: null,
    status: RequestStatus.IDLE,
    error: ResponseStatus.SUCCESS,
    message: '',
  },
  audio: {
    audioUrl: null,
    audioPeaks: [],
    audioStatus: RequestStatus.IDLE,
    audioPeakStatus: RequestStatus.IDLE,
  },
  recognitionWebSocket: {
    webSocketStatus: 3, // CLOSED
    webSocketError: false,
    recordStatus: 'inactive',
    recordError: null,
    fileReaderError: false,
    data: [],
  },
};

// распознавание речи
export const recognizeSpeech = createAsyncThunk(
  'spr/recognizeSpeech',
  async ({ modelName, formData, speakers, asyncMode, punctuation, vadType, presetVad, denoise }: IRecognizeSpeechProps): Promise<IResponseSuccessSync | IResponseSuccessAsyncMonolog | IResponseSuccessAsyncDialog | IResponseFailed | IResponseTaskId> => {
    const response: AxiosResponse<IResponseSuccessSync | IResponseSuccessAsyncMonolog | IResponseSuccessAsyncDialog | IResponseFailed | IResponseTaskId> = await axiosApi.post(`${URL_SPR}/${ENDPOINTS_SPR.RECOGNITION}/${modelName}`, {
      wav: formData.get('file'),
      speakers,
      async: asyncMode,
      punctuation,
      vad: vadType,
      preset: presetVad,
      denoise,
    }, {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
    });
    return response.data;
  }
);

// очередь асинхронного распознавания
export const getQueue = createAsyncThunk(
  'spr/getQueue',
  async (_, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<QueueResponseType | IResponseFailed> = await axiosApi.get(`${URL_SPR}/${ENDPOINTS_SPR.QUEUE}`);
      return response.data;
    } catch (error) {
      if (error) {
        // возвращаем данные ошибки, пришедшие с бэка
        return rejectWithValue(error);
      }
    }
  }
);

// получение аудио файла асинхронного распознавания речи
export const getAudio = createAsyncThunk(
  'spr/getAudio',
  async (taskId: string, { signal }) => {
    const response: AxiosResponse<Blob | IResponseFailed> = await axiosApi.get(`${URL_SPR}/${ENDPOINTS_SPR.AUDIO}/${taskId}`, {
      responseType: 'blob',
      signal,
    });
    return response.data;
  }
);

// получение пиков аудио файла асинхронного распознавания речи
export const getAudioPeaks = createAsyncThunk(
  'spr/getAudioPeaks',
  async (taskId: string) => {
    const response: AxiosResponse<IResponseAudioPeaks | IResponseFailed> = await axiosApi.get(`${URL_SPR}/${ENDPOINTS_SPR.WAVEFORM}/${taskId}`);
    return response.data;
  }
);

// получение результата асинхронного распознавания речи
export const getAsyncResult = createAsyncThunk(
  'spr/getAsyncResult',
  async (taskId: string): Promise<IAsyncModeStatus | IAsyncModeResultMonolog | IAsyncModeResultDialog | IAsyncModeResultFailed> => {
    const response: AxiosResponse<IAsyncModeStatus | IAsyncModeResultMonolog | IAsyncModeResultDialog | IAsyncModeResultFailed> = await axiosApi.get(`${URL_SPR}/${ENDPOINTS_SPR.ASYNC_RESULT}/${taskId}`);
    return response.data;
  }
);

// удаление задачи
export const deleteTask = createAsyncThunk(
  'spr/deleteTask',
  async (taskId: string, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<IResponseFailed> = await axiosApi.delete(`${URL_SPR}/${ENDPOINTS_SPR.QUEUE}/${taskId}`);
      return response.data;
    } catch (error) {
      if (error) {
        // возвращаем данные ошибки, пришедшие с бэка
        return rejectWithValue(error);
      }
    }
  }
);

const sprSlice = createSlice({
  name: 'spr',
  initialState,
  reducers: {
    // добавление ссылки на аудио-файл
    addDataUrl: (state, action: PayloadAction<string>) => {
      state.recognition.audioUrl = action.payload;
    },
    // изменение временной метки
    changeTimestamp: (state, action: PayloadAction<number>) => {
      state.recognition.timestamp = action.payload;
    },
    // добавление имен спикеров
    addSpeakerList: (state, action: PayloadAction<string[]>) => {
      state.recognition.speakerList = action.payload;
    },
    // очистка state
    clearState: (state) => {
      state.recognition = initialState.recognition;
      state.asyncMode = initialState.asyncMode;
      state.deletionTask = initialState.deletionTask;
      state.queue = initialState.queue;
      state.audio = initialState.audio;
      state.recognitionWebSocket = initialState.recognitionWebSocket;
    },
    // очистка результата распознавания речи
    clearRecognitionData: (state) => {
      state.recognition = initialState.recognition;
      state.asyncMode = initialState.asyncMode;
      state.audio = initialState.audio;
    },
    // очистка результата удаления задачи
    clearDeletionTask: (state) => {
      state.deletionTask = initialState.deletionTask;
    },
    // очистка очереди распознавания
    clearQueue: (state) => {
      state.queue = initialState.queue;
    },
    // очистка результата распознавания речи по WebSocket
    clearRecognitionDataWebSocket: (state) => {
      state.recognitionWebSocket = initialState.recognitionWebSocket;
    },
    // установка статуса WebSocket
    setWebSocketStatus: (state, action: PayloadAction<number>) => {
      state.recognitionWebSocket.webSocketStatus = action.payload;
    },
    // установка ошибки WebSocket
    setWebSocketError: (state, action: PayloadAction<boolean>) => {
      state.recognitionWebSocket.webSocketError = action.payload;
    },
    // установка статуса записи с микрофона
    setRecordStatus: (state, action: PayloadAction<RecordingState>) => {
      state.recognitionWebSocket.recordStatus = action.payload;
    },
    // установка ошибки записи с микрофона
    setRecordError: (state, action: PayloadAction<null | string>) => {
      state.recognitionWebSocket.recordError = action.payload;
    },
    // установка ошибки чтения файла записи с микрофона
    setFileReaderError: (state, action: PayloadAction<boolean>) => {
      state.recognitionWebSocket.fileReaderError = action.payload;
    },
    // добавление данных распознавания по webSocket-соединению
    addRecognitionDataWebSocket: (state, action: PayloadAction<IResponseFailed | IResponseText>) => {
      state.recognitionWebSocket.data.push(action.payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(recognizeSpeech.pending, (state) => {
        state.recognition.status = RequestStatus.LOADING;
      })
      .addCase(recognizeSpeech.fulfilled, (state, action) => {
        state.recognition.status = RequestStatus.IDLE;
        if ('taskID' in action.payload) {
          state.asyncMode.taskId = action.payload.taskID;
        } else {
          if ('speakers' in action.payload) {
            // задаем имена спикерам, если нет и отрезок самого длинного фрагмента для прослушивания
            const renamedSpeakers: ISpeakerModified[] = action.payload.speakers.map((speaker, idx) => {
              speaker.id = speaker.id || `unknown_${idx}`;
              const foundLongestFragment = action.payload && 'speakers' in action.payload && action.payload.splitted.filter(fragment => fragment.speaker === idx).reduce((accumulator, currentValue) => accumulator.duration_ms > currentValue.duration_ms ? accumulator : currentValue);
              return {
                ...speaker,
                startLongestFragment: foundLongestFragment && foundLongestFragment.start_ms,
                stopLongestFragment: foundLongestFragment && foundLongestFragment.stop_ms,
              } as ISpeakerModified;
            });
            state.recognition.data = { ...action.payload, speakers: renamedSpeakers };
          } else state.recognition.data = action.payload;
        }
      })
      .addCase(recognizeSpeech.rejected, (state) => {
        state.recognition.status = RequestStatus.FAILED;
      })
      .addCase(getQueue.pending, (state) => {
        state.queue.status = RequestStatus.LOADING;
      })
      .addCase(getQueue.fulfilled, (state, action) => {
        if (action.payload && typeof action.payload === 'object' && !('error' in action.payload)) {
          state.queue.status = RequestStatus.IDLE;
          state.queue.data = action.payload;
        } else state.queue.status = RequestStatus.FAILED;
      })
      .addCase(getQueue.rejected, (state, action: PayloadAction<unknown>) => {
        state.queue.status = RequestStatus.FAILED;
        if (action.payload instanceof AxiosError) {
          state.queue.error = action.payload.response?.data.error;
          state.queue.message = action.payload.response?.data.message;
        }
      })
      .addCase(getAsyncResult.pending, (state) => {
        state.asyncMode.requestStatus = RequestStatus.LOADING;
      })
      .addCase(getAsyncResult.fulfilled, (state, action) => {
        // если ошибка
        if (typeof action.payload === 'string') {
          state.asyncMode.requestStatus = RequestStatus.FAILED;
          state.asyncMode.taskId = null;
        } else {
          state.asyncMode.requestStatus = RequestStatus.IDLE;
          // если только статус
          if (action.payload.status === 'waiting' || action.payload.status === 'not found') state.asyncMode.responseStatus = action.payload.status;
          // если готово
          else if (action.payload.status === 'ready') {
            // если диалог со спикерами
            if ('speakers' in action.payload) {
              const { status, ...result } = action.payload;
              // задаем имена спикерам, если нет и отрезок самого длинного фрагмента для прослушивания
              const renamedSpeakers = result.speakers.map((speaker, idx) => {
                speaker.id = speaker.id || `unknown_${idx}`;
                const foundLongestFragment = action.payload && 'speakers' in action.payload && action.payload.splitted.filter(fragment => fragment.speaker === idx).reduce((accumulator, currentValue) => accumulator.duration_ms > currentValue.duration_ms ? accumulator : currentValue);
                return {
                  ...speaker,
                  startLongestFragment: foundLongestFragment && foundLongestFragment.start_ms,
                  stopLongestFragment: foundLongestFragment && foundLongestFragment.stop_ms,
                } as ISpeakerModified;
              });
              state.recognition.data = { ...result, speakers: renamedSpeakers };
              // если монолог
            } else {
              const { status, ...result } = action.payload;
              state.recognition.data = { ...result };
            }
            state.asyncMode.responseStatus = null;
            state.asyncMode.taskId = null;
          } else if (action.payload.status === 'failed') {
            state.recognition.data = action.payload;
            state.asyncMode.responseStatus = null;
            state.asyncMode.taskId = null;
          }
        }
      })
      .addCase(getAsyncResult.rejected, (state) => {
        state.asyncMode.requestStatus = RequestStatus.FAILED;
      })
      .addCase(getAudio.pending, (state) => {
        state.audio.audioStatus = RequestStatus.LOADING;
      })
      .addCase(getAudio.fulfilled, (state, action) => {
        if (action.payload instanceof Blob && action.payload.type.includes('audio')) {
          state.audio.audioStatus = RequestStatus.IDLE;
          const url = window.URL.createObjectURL(action.payload);
          state.audio.audioUrl = url;
        } else {
          state.audio.audioStatus = RequestStatus.FAILED;
        }
      })
      .addCase(getAudio.rejected, (state) => {
        state.audio.audioStatus = RequestStatus.FAILED;
      })
      .addCase(getAudioPeaks.pending, (state) => {
        state.audio.audioPeakStatus = RequestStatus.LOADING;
      })
      .addCase(getAudioPeaks.fulfilled, (state, action) => {
        if (action.payload && typeof action.payload === 'object' && 'waveform' in action.payload && Array.isArray(action.payload.waveform)) {
          state.audio.audioPeakStatus = RequestStatus.IDLE;
          state.audio.audioPeaks = action.payload.waveform;
        } else state.audio.audioPeakStatus = RequestStatus.FAILED;
      })
      .addCase(getAudioPeaks.rejected, (state) => {
        state.audio.audioPeakStatus = RequestStatus.FAILED;
        console.error('Peak loading error. Peaks will be built on the client side.');
      })
      .addCase(deleteTask.pending, (state) => {
        state.deletionTask.status = RequestStatus.LOADING;
      })
      .addCase(deleteTask.fulfilled, (state, action) => {
        state.deletionTask.status = RequestStatus.IDLE;
        if (action.payload) {
          state.deletionTask.error = action.payload.error;
          state.deletionTask.message = action.payload.message;
        }
      })
      .addCase(deleteTask.rejected, (state, action: PayloadAction<unknown>) => {
        state.deletionTask.status = RequestStatus.FAILED;
        if (action.payload instanceof AxiosError) {
          state.deletionTask.error = action.payload.response?.data.error;
          state.deletionTask.message = action.payload.response?.data.message;
        }
      });
  },
});

export const { addDataUrl, changeTimestamp, addSpeakerList, clearState, clearRecognitionData, clearDeletionTask, clearQueue, clearRecognitionDataWebSocket, setWebSocketStatus, setWebSocketError, setRecordStatus, setRecordError, setFileReaderError, addRecognitionDataWebSocket } = sprSlice.actions;

export const selectRecognitionData = (state: RootState) => state.spr.recognition;
export const selectRecognitionAsyncData = (state: RootState) => state.spr.asyncMode;
export const selectAudio = (state: RootState) => state.spr.audio;
export const selectDeletionTaskStatus = (state: RootState) => state.spr.deletionTask;
export const selectQueue = (state: RootState) => state.spr.queue;
export const selectRecognitionDataWebSocket = (state: RootState) => state.spr.recognitionWebSocket;

export default sprSlice.reducer;
