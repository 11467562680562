import { ChangeEvent, useEffect, useRef } from 'react';
import { useVirtualizer } from '@tanstack/react-virtual';
import { useAppSelector } from '../../../store/hooks';
import { selectQuestion } from '../../../store/qasSlice';
import Alias from '../Alias/Alias';
import { IAliasesListProps } from './AliasesList.props';
import styles from './AliasesList.module.scss';

const AliasesList = ({ filterByQuestion, setChangeFlg, setInputQuestion, selectedListAliasIdx, setSelectedListAliasIdx, listType }: IAliasesListProps): JSX.Element => {
	const listRef = useRef<HTMLDivElement>(null); // ссылка на список алиасов

	const question = useAppSelector(selectQuestion); // store - вопрос/ответ

	useEffect(() => {
		const indexAlias = question[listType].findIndex(alias => alias.toLowerCase().includes(filterByQuestion.toLowerCase()) && filterByQuestion !== '');
		virtualizer.scrollToIndex(indexAlias, { align: 'center' }); // скролл до первого из фильтрованных алиасов в центре списка с имеющейся полосой прокрутки
		// listRef.current?.children[question.aliases.findIndex(alias => alias.toLowerCase().includes(filterByQuestion.toLowerCase()) && filterByQuestion !== '')]?.scrollIntoView({ block: "center" }); // показ первого из фильтрованных алиасов в центре списка с имеющейся полосой прокрутки
	}, []);

	// виртуализация списка алиасов
	const virtualizer = useVirtualizer({
		count: question[listType].length,
		getScrollElement: () => listRef.current,
		estimateSize: () => 33,
		// overscan: 5,
	});

	const items = virtualizer.getVirtualItems();

	// обработчик изменения checkbox (выделение алиасов)
	const checkboxChangeHandler = (e: ChangeEvent<HTMLInputElement>): void => {
		// если выбран checkbox - добавляем в список
		if (e.target.checked) setSelectedListAliasIdx(prevState => [...prevState, +e.target.id]);
		// иначе убираем его из этого списка
		else setSelectedListAliasIdx(prevState => prevState.filter(idx => idx !== +e.target.id));
	};

	return (
		<div className={styles.questionAliases} ref={listRef}>
			<div
				style={{
					height: `${virtualizer.getTotalSize()}px`,
					width: '100%',
					position: 'relative',
				}}
			>
				<div
					style={{
						position: 'absolute',
						top: 0,
						left: 0,
						width: '100%',
						transform: `translateY(${items[0]?.start ?? 0}px)`,
					}}
				>
					{items.map((virtualItem) => {
						const alias = question[listType][virtualItem.index];
						return (
							<div
								key={alias + virtualItem.index}
								data-index={virtualItem.index}
								ref={virtualizer.measureElement}
							>
								<Alias
									question={alias}
									idx={virtualItem.index}
									setChangeFlg={setChangeFlg}
									filterByQuestion={filterByQuestion}
									setInputQuestion={setInputQuestion}
									selectedListAliasIdx={selectedListAliasIdx}
									checkboxChangeHandler={checkboxChangeHandler}
									listType={listType}
								/>
							</div>
						);
					})}
				</div>

				{/* {question.aliases.map((alias, idx, questionsArray) => {
					// оставляем алиасы, которые не дублируют основной вопрос
					if (inputQuestion !== alias) {
						return (
							<Alias
								key={alias + idx}
								question={alias}
								idx={idx}
								questionsArray={questionsArray}
								setChangeFlg={setChangeFlg}
								filterByQuestion={filterByQuestion}
								setInputQuestion={setInputQuestion}
							/>
						);
					} else return undefined;
				})} */}
			</div>
		</div>
	);
};

export default AliasesList;
