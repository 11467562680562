import { memo, useState } from 'react';
import { FormControl, InputAdornment, TextField } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import cn from 'classnames';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { changePhraseInActionDataElem, changePhraseInActionEndpoint, deletePhraseInActionDataElem, deletePhraseInActionEndpoint } from '../../../store/sesSlice';
import { changePhraseInActionRobot, deletePhraseInActionRobot, selectActiveRobotVersion } from '../../../store/sesRobotSlice';
import useAccessRight from '../../../hooks/useAccessRight';
import useTranslate from '../../../hooks/useTranslate';
import { SES } from '../../../constants/accessRights';
import { colorPrimary, colorRed } from '../../../constants/colors';
import { DataElemActionEventType } from '../../../types/sesTypes';
import { RobotActionEventType } from '../../../types/sesRobotTypes';
import { IPhraseProps } from './Phrase.props';
import styles from './Phrase.module.scss';

const Phrase = ({ phrase, idxPhrase, arrayPhrases, itsAction, actionEvent, channel, idxAction, setChangeFlg }: IPhraseProps): JSX.Element => {
	const [inputPhrase, setInputPhrase] = useState<string>(phrase); // фраза

	const dispatch = useAppDispatch();
	const activeRobotVersion = useAppSelector(selectActiveRobotVersion); // store - версия активного робота

	const isAccess = useAccessRight(); // hook для проверки прав доступа
	const translate = useTranslate(); // hook для перевода текста

	// обработчик увода фокуса с поля фразы
	const blurHandler = (): void => {
		if (inputPhrase !== phrase) {
			itsAction === 'robot' && dispatch(changePhraseInActionRobot({
				actionEvent: actionEvent as RobotActionEventType,
				channel,
				idxAction,
				idxPhrase,
				text: inputPhrase,
			})); // изменяем фразу в действии робота
			itsAction === 'dataElement' && dispatch(changePhraseInActionDataElem({
				actionEvent: actionEvent as DataElemActionEventType,
				channel,
				idxAction,
				idxPhrase,
				text: inputPhrase,
			})); // изменяем фразу в действии элемента данных
			itsAction === 'endpoint' && dispatch(changePhraseInActionEndpoint({
				channel,
				idxAction,
				idxPhrase,
				text: inputPhrase,
			})); // изменяем фразу в действии конечной точки
			setChangeFlg(true); // ставим флаг о несохраненных данных
		}
	};

	// обработчик удаления фразы
	const deletePhraseHandler = (): void => {
		itsAction === 'robot' && dispatch(deletePhraseInActionRobot({
			actionEvent: actionEvent as RobotActionEventType,
			channel,
			idxAction,
			idxPhrase,
		})); // удаляем фразу в действии робота
		itsAction === 'dataElement' && dispatch(deletePhraseInActionDataElem({
			actionEvent: actionEvent as DataElemActionEventType,
			channel,
			idxAction,
			idxPhrase,
		})); // удаляем фразу в действии элемента данных
		itsAction === 'endpoint' && dispatch(deletePhraseInActionEndpoint({
			channel,
			idxAction,
			idxPhrase,
		})); // удаляем фразу в действии конечной точки
		setChangeFlg(true); // ставим флаг о несохраненных данных
	};

	return (
		<div className={cn(styles.phraseBlock, {
			[styles.phraseBlockOpacity]: arrayPhrases.length - 1 === idxPhrase && inputPhrase === '' && arrayPhrases.length > 1,
		})}>
			<FormControl fullWidth margin='dense'>
				<TextField
					required={arrayPhrases.length - 1 !== idxPhrase || arrayPhrases.length === 1}
					multiline
					disabled={(itsAction === 'robot' && !isAccess(SES.ROBOT_EDIT)) || (itsAction === 'dataElement' && !isAccess(SES.DATA_EDIT)) || (itsAction === 'endpoint' && !isAccess(SES.ENDPOINT_EDIT)) || activeRobotVersion !== 'draft'}
					label={`${translate('actionPhrase_inputPhrase')} ${idxPhrase + 1}`}
					variant="outlined"
					maxRows={2}
					value={inputPhrase}
					onChange={(e) => setInputPhrase(e.target.value)}
					onBlur={blurHandler}
					InputProps={{
						style: {
							padding: '8px 13px',
							fontSize: 13,
							color: colorPrimary,
						},
						endAdornment: (
							<InputAdornment position="end">
								{((itsAction === 'robot' && isAccess(SES.ROBOT_EDIT)) || (itsAction === 'dataElement' && isAccess(SES.DATA_EDIT)) || (itsAction === 'endpoint' && isAccess(SES.ENDPOINT_EDIT))) && arrayPhrases.length > 1 && arrayPhrases.length - 1 !== idxPhrase && activeRobotVersion === 'draft' &&
									<div className={styles.deletePhraseButton}>
										<FontAwesomeIcon
											icon={faTrashCan}
											color={colorRed}
											size='sm'
											onClick={deletePhraseHandler}
											title={translate('actionPhrase_deletePhraseTitle')}
											style={{ cursor: 'pointer' }}
										/>
									</div>
								}
							</InputAdornment>
						),
					}}
					InputLabelProps={{
						style: {
							fontSize: 13,
						},
					}}
					sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}
				/>
			</FormControl>
		</div>
	);
};

export default memo(Phrase);
