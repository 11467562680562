import { FormEvent, useEffect, useState } from 'react';
import { Button, FormControl, TextField } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { addDataset, clearDatasetsList, getDatasetsList, selectDatasetAddStatus, selectDatasetsList } from '../../../store/authSlice';
import useTranslate from '../../../hooks/useTranslate';
import { RequestStatus, ResponseStatus } from '../../../types/statusTypes';
import ModalFormWindow from '../../../HOC/ModalFormWindow/ModalFormWindow';
import ProgressCircle from '../../ProgressCircle/ProgressCircle';
import { IFormAddingDatasetProps } from './FormAddingDataset.props';

const FormAddingDataset = ({ showModal, setShowModal, setShowNotification }: IFormAddingDatasetProps): JSX.Element => {
	const [inputDataset, setInputDataset] = useState<string>(''); // название набора данных
	const [showErrorExistingDataset, setShowErrorExistingDataset] = useState<boolean>(false); // флаг существующего имени набора данных

	const dispatch = useAppDispatch();
	const datasetsList = useAppSelector(selectDatasetsList); // store - список наборов данных
	const addingDatasetStatus = useAppSelector(selectDatasetAddStatus); // store - статус добавления набора данных

	const translate = useTranslate(); // hook для перевода текста

	// следим за статусом добавления набора данных
	useEffect(() => {
		// если добавление прошло успешно 
		if (addingDatasetStatus.error === ResponseStatus.SUCCESS && addingDatasetStatus.status === RequestStatus.IDLE && addingDatasetStatus.message !== '') {
			dispatch(clearDatasetsList()); // очищаем список наборов данных
			dispatch(getDatasetsList()); // получаем заново список наборов данных
		}
		// если были изменения в статусе добавления
		if (addingDatasetStatus.error !== ResponseStatus.SUCCESS || addingDatasetStatus.status === RequestStatus.FAILED || addingDatasetStatus.message !== '') {
			handleClose(); // закрываем форму
			setShowNotification(true); // включаем уведомление
		}
	}, [addingDatasetStatus]);

	// обработчик добавления набора данных
	const submitHandler = (e: FormEvent<HTMLFormElement>): void => {
		e.preventDefault();
		const existingDatasetName = datasetsList.data.find(dataset => dataset.name === inputDataset); // существующее имя набора данных
		if (existingDatasetName) {
			setShowErrorExistingDataset(true); // подсвечиваем ошибку
		} else {
			showErrorExistingDataset && setShowErrorExistingDataset(false); // убираем ошибку, если была
			dispatch(addDataset(inputDataset)); // добавление набора данных
		}
	};

	// обработчик закрытия формы
	const handleClose = (): void => {
		// если идет добавление набора данных - запрещаем покидать форму
		if (addingDatasetStatus.status === RequestStatus.LOADING) return;
		setShowModal(false);
	};

	return (
		<ModalFormWindow showModal={showModal} setShowModal={setShowModal} headerTitle='formAddingDataset_header' close={handleClose}>
			<form onSubmit={(e) => submitHandler(e)}>
				<FormControl fullWidth margin='dense'>
					<TextField
						autoFocus
						required
						id="datasetName"
						label={translate('formAddingDataset_inputDatasetName')}
						variant="outlined"
						value={inputDataset}
						onChange={(e) => setInputDataset(e.target.value)}
						disabled={addingDatasetStatus.status === RequestStatus.LOADING}
						error={showErrorExistingDataset}
						helperText={showErrorExistingDataset ? translate('formAddingDataset_datasetExists') : ''}
						InputProps={{
							style: {
								height: 33,
								fontSize: 13
							},
						}}
						InputLabelProps={{
							style: {
								fontSize: 13,
							},
						}}
						sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}
					/>
				</FormControl>
				<FormControl fullWidth margin='dense'>
					<Button
						variant="outlined"
						type="submit"
						disabled={addingDatasetStatus.status === RequestStatus.LOADING}
						sx={{ fontSize: 11 }}
					>
						{addingDatasetStatus.status === RequestStatus.LOADING ?
							<>
								{translate('formAddingDataset_addingBtn')}
								<ProgressCircle isBtnDisabled />
							</>
							:
							translate('formAddingDataset_addBtn')
						}
					</Button>
				</FormControl>
			</form>
		</ModalFormWindow>
	);
};

export default FormAddingDataset;
