import { useEffect, useState } from 'react';
import { Fade } from '@mui/material';
import cn from 'classnames';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { clearState, getAllModels, getInfoModel, selectAllModels, selectApplyStatus, selectFullModel, selectImportStatus, selectInstallStatus, selectRestoreStatus } from '../../store/modelSlice';
import { clearDataServers, getDataServers } from '../../store/serverSlice';
import { selectAudio, selectRecognitionAsyncData, selectRecognitionData, selectRecognitionDataWebSocket } from '../../store/sprSlice';
import useAccessRight from '../../hooks/useAccessRight';
import useTranslate from '../../hooks/useTranslate';
import { MODEL, SERVER, SERVICE } from '../../constants/accessRights';
import { RequestStatus, ResponseStatus } from '../../types/statusTypes';
import PageWrapper from '../../HOC/PageWrapper/PageWrapper';
import ModelNavbarSpr from '../../components/Navbars/ModelNavbarSpr/ModelNavbarSpr';
import ModelControl from '../../components/ModelControl/ModelControl';
import AudioPlayer from '../../components/AudioPlayer/AudioPlayer';
import ResultRecognition from '../../components/ResultRecognition/ResultRecognition';
import Speakers from '../../components/ResultRecognition/Speakers/Speakers';
import ResultRecognitionWebSocket from '../../components/ResultRecognitionWebSocket/ResultRecognitionWebSocket';
import ProgressCircle from '../../components/ProgressCircle/ProgressCircle';
import ScreenLock from '../../components/ScreenLock/ScreenLock';
import NoticeSingleAction from '../../components/Notification/NoticeSingleAction/NoticeSingleAction';
import { IRecognizerProps } from './Recognizer.props';
import styles from './Recognizer.module.scss';

const Recognizer = ({ serviceType }: IRecognizerProps): JSX.Element => {
	const [showPage, setShowPage] = useState<boolean>(true); // показ страницы
	const [showScreenLock, setShowScreenLock] = useState<{ isShow: boolean, title: string }>({ isShow: false, title: '' }); // показ экрана блокировки и подпись
	const [showNotification, setShowNotification] = useState<boolean>(false); // показ уведомления

	const isAccess = useAccessRight(); // hook для проверки прав доступа
	const translate = useTranslate(); // hook для перевода текста
	const dispatch = useAppDispatch();

	const allModels = useAppSelector(selectAllModels); // store - все модели
	const fullModel = useAppSelector(selectFullModel); // store - модель
	const installStatus = useAppSelector(selectInstallStatus); // store - статус установки модели
	const applyStatus = useAppSelector(selectApplyStatus); // store - статус применения модели
	const restoreStatus = useAppSelector(selectRestoreStatus); // store - статус восстановления модели
	const importStatus = useAppSelector(selectImportStatus); // store - статус импортирования модели
	const recognitionData = useAppSelector(selectRecognitionData); // store - распознавание речи
	const recognitionAsyncData = useAppSelector(selectRecognitionAsyncData); // store - данные асинхронного режима
	const audio = useAppSelector(selectAudio); // store - аудио-файл асинхронного распознавания речи
	const recognitionDataWebSocket = useAppSelector(selectRecognitionDataWebSocket); // store - данные распознавания речи webSocket
	// const trainStatus = useAppSelector(selectTrainModelResponse); // store - статус обучения модели
	// const testStatus = useAppSelector(selectTestModelResponse); // store - статус тестирования модели
	// const stopTrainStatus = useAppSelector(selectStopTrainModelResponse); // store - статус остановки обучения модели

	useEffect(() => {
		isAccess(MODEL.INFO) && dispatch(getAllModels({ serviceType })); // получаем все модели
		isAccess(SERVER.ADDRESSES) && dispatch(getDataServers({ serviceType })); // получаем данные о серверах

		// при уходе со страницы
		return () => {
			dispatch(clearDataServers()); // очищаем данные по серверам
			dispatch(clearState()); // очищаем state моделей
		};
	}, []);

	// следим за статусом импорта модели 
	useEffect(() => {
		// автозапрос списка серверов каждые 30 сек
		const interval = setInterval(() => {
			isAccess(SERVER.ADDRESSES) && dispatch(getDataServers({ serviceType }));
		}, 30000);

		// если идет импорт - удаляем автозапрос получения данных о серверах
		importStatus.status === RequestStatus.LOADING && clearInterval(interval);

		// при уходе со страницы
		return () => {
			clearInterval(interval); // удаляем автозапрос получения данных о серверах
		};
	}, [importStatus.status]);

	// следим за статусами [обучения, остановки обучения, тестирования,] установки, применения, восстановления модели и при изменении заново запрашиваем инфо о модели
	useEffect(() => {
		// trainStatus.status !== RequestStatus.LOADING && trainStatus.message !== '' && fullModel.modelName && dispatch(getInfoModel({ modelName: fullModel.modelName, serviceType }));
		// stopTrainStatus.status === RequestStatus.IDLE && stopTrainStatus.error === ResponseStatus.SUCCESS && stopTrainStatus.message !== '' && fullModel.modelName && dispatch(getInfoModel({ modelName: fullModel.modelName, serviceType }));
		// testStatus.status !== RequestStatus.LOADING && testStatus.message !== '' && fullModel.modelName && dispatch(getInfoModel({ modelName: fullModel.modelName, serviceType }));
		installStatus.status === RequestStatus.IDLE && installStatus.error === ResponseStatus.SUCCESS && installStatus.message !== '' && fullModel.modelName && dispatch(getInfoModel({ modelName: fullModel.modelName, serviceType }));
		((applyStatus.status === RequestStatus.IDLE && applyStatus.message !== '') || applyStatus.status === RequestStatus.FAILED) && fullModel.modelName && dispatch(getInfoModel({ modelName: fullModel.modelName, serviceType }));
		restoreStatus.status === RequestStatus.IDLE && restoreStatus.error === ResponseStatus.SUCCESS && restoreStatus.message !== '' && fullModel.modelName && dispatch(getInfoModel({ modelName: fullModel.modelName, serviceType }));

		// если идет установка/применение/восстановление модели - включаем экран блокировки с подписью
		if (installStatus.status === RequestStatus.LOADING) setShowScreenLock({ isShow: true, title: 'screenLock_installModelTitle' });
		else if (applyStatus.status === RequestStatus.LOADING) setShowScreenLock({ isShow: true, title: 'screenLock_applyModelTitle' });
		else if (restoreStatus.status === RequestStatus.LOADING) setShowScreenLock({ isShow: true, title: 'screenLock_restoreModelTitle' });
		else setShowScreenLock({ isShow: false, title: '' }); // иначе выключаем
	}, [/* trainStatus, stopTrainStatus, testStatus, */ installStatus, applyStatus, restoreStatus]);

	// следим за id задачи асинхронного распознавания
	useEffect(() => {
		recognitionAsyncData.taskId && setShowNotification(true); // если есть задача - включаем уведомление
	}, [recognitionAsyncData.taskId]);

	return (
		<PageWrapper showPage={showPage} setShowPage={setShowPage} accessToService={[SERVICE.SPR, MODEL.INFO]}>
			<>
				<ModelNavbarSpr serviceType={serviceType} setShowPage={setShowPage} />
				<ModelControl serviceType={serviceType} setShowPage={setShowPage} />

				<Fade in={true} timeout={700} style={{ transitionDelay: '500ms' }}>
					<div className={styles.wrapper}>
						{allModels.models !== null && typeof (allModels.models) !== 'string' && Object.keys(allModels.models).length !== 0 ?
							<>
								{((fullModel.status === RequestStatus.LOADING || fullModel.modelName === null)/*  && !fullModel.training && !fullModel.testing */) &&
									<div className={styles.loading}><ProgressCircle title={translate('progressCircle_modelLoadingTitle')} /></div>
								}
								{fullModel.status !== RequestStatus.FAILED && fullModel.fullModel?.[fullModel.activeType] !== null &&
									<>
										{(recognitionData.data && typeof recognitionData.data === 'object' && !recognitionData.data.hasOwnProperty('message') && recognitionData.audioUrl) &&
											<Fade in={true} timeout={500}>
												<div className={cn(styles.wrapperAudioTrack, {
													[styles.shadowFuture]: fullModel.activeType === 'future',
													[styles.shadowCurrent]: fullModel.activeType === 'current',
													[styles.shadowPrevious]: fullModel.activeType === 'previous'
												})}>
													<AudioPlayer url={recognitionData.audioUrl} sendTimestamp='recognition' timestamp={recognitionData.timestamp} />
												</div>
											</Fade>
										}

										{(recognitionData.data !== null || recognitionData.status !== RequestStatus.IDLE) &&
											<Fade in={true} timeout={500}>
												<div className={cn(styles.wrapperResultRecognition, {
													[styles.shadowFuture]: fullModel.activeType === 'future',
													[styles.shadowCurrent]: fullModel.activeType === 'current',
													[styles.shadowPrevious]: fullModel.activeType === 'previous'
												})}>
													<ResultRecognition />
												</div>
											</Fade>
										}

										{recognitionData.data && typeof recognitionData.data === 'object' && 'speakers' in recognitionData.data && Array.isArray(recognitionData.data.speakers) && recognitionData.data.speakers.length > 0 &&
											<Fade in={true} timeout={500}>
												<div className={cn(styles.wrapperSpeakers, {
													[styles.shadowFuture]: fullModel.activeType === 'future',
													[styles.shadowCurrent]: fullModel.activeType === 'current',
													[styles.shadowPrevious]: fullModel.activeType === 'previous'
												})}>
													<Speakers speakers={recognitionData.data.speakers} speakerNameList={recognitionData.speakerList} from='recognition' audioUrl={audio.audioUrl ? audio.audioUrl : undefined} />
												</div>
											</Fade>
										}

										{(recognitionDataWebSocket.webSocketStatus !== 3 || recognitionDataWebSocket.webSocketError || recognitionDataWebSocket.recordError !== null || recognitionDataWebSocket.fileReaderError || recognitionDataWebSocket.data.length > 0) &&
											<Fade in={true} timeout={500}>
												<div className={cn(styles.wrapperResultRecognition, {
													[styles.shadowFuture]: fullModel.activeType === 'future',
													[styles.shadowCurrent]: fullModel.activeType === 'current',
													[styles.shadowPrevious]: fullModel.activeType === 'previous'
												})}>
													<ResultRecognitionWebSocket />
												</div>
											</Fade>
										}
									</>
								}
								{fullModel.status !== RequestStatus.LOADING && (fullModel.status === RequestStatus.FAILED || fullModel.fullModel?.[fullModel.activeType] === null) &&
									<div className={styles.notFound}><div>{translate(fullModel.message || 'notFound')}</div></div>
								}
							</>
							:
							<div className={styles.noData}><div>{translate('noData')}</div></div>
						}
					</div>
				</Fade>

				{showScreenLock.isShow && <ScreenLock title={translate(showScreenLock.title)} />}
				{showNotification && <NoticeSingleAction showNotification={showNotification} setShowNotification={setShowNotification} title='noticeSendToQueue_success' severity='info' />}
			</>
		</PageWrapper>
	);
};

export default Recognizer;
