import { forwardRef, SyntheticEvent, useEffect, useRef, useState } from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import useTranslate from '../../hooks/useTranslate';
import { colorSecondary } from '../../constants/colors';
import { RequestStatus, ResponseStatus } from '../../types/statusTypes';
import { INotificationProps } from './Notification.props';

const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(
	props,
	ref,
) {
	return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Notification = ({ showNotification, setShowNotification, selectDataResponse, clearDataResponse, titleFailed, titleSuccess }: INotificationProps): JSX.Element => {
	const [data, setData] = useState<{ error: ResponseStatus, message: string, status: RequestStatus }>(); // копия данных ответа
	const noticeRef = useRef<HTMLDivElement>(null); // ссылка на Notification

	const dispatch = useAppDispatch();
	const dataResponse = useAppSelector(selectDataResponse); // store - данные ответа
	const translate = useTranslate(); // hook для перевода текста

	// следим за данными и записываем в state один раз
	useEffect(() => {
		if (dataResponse.status !== RequestStatus.LOADING) {
			!data && setData(dataResponse);
		}
	}, [dataResponse]);

	// следим за данными в state
	useEffect(() => {
		// подписываемся на событие нажатия мыши, если появились данные
		data && document.addEventListener('mousedown', handleClick);
		// при выходе отписываемся
		return () => {
			document.removeEventListener('mousedown', handleClick);
		};
	}, [data]);

	// обработчик нажатия кнопки мыши
	const handleClick = (e: MouseEvent): void => {
		if (noticeRef.current && !noticeRef.current?.contains(e.target as Node)) {
			handleClose();
		}
	};

	// обработчик закрытия уведомления
	const handleClose = (_?: SyntheticEvent | Event, reason?: string) => {
		if (reason === 'clickaway') {
			return;
		}
		setShowNotification(false); // выключаем уведомление
		dispatch(clearDataResponse()); // очищаем данные ответа
	};

	return (
		<div ref={noticeRef}>
			{(data?.status === RequestStatus.FAILED || data?.error === ResponseStatus.FAILED) &&
				<Snackbar
					open={showNotification}
					autoHideDuration={7000}
					onClose={handleClose}
					anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
				>
					<Alert onClose={handleClose} severity='error' sx={{ width: '100%', fontSize: 13, padding: '2px 12px' }}>{translate(data?.message || titleFailed)}</Alert>
				</Snackbar>}
			{(data?.status === RequestStatus.IDLE && data?.error === ResponseStatus.SUCCESS) &&
				<Snackbar
					open={showNotification}
					autoHideDuration={7000}
					onClose={handleClose}
					anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
				>
					<Alert onClose={handleClose} severity='info' sx={{ width: '100%', backgroundColor: colorSecondary, fontSize: 13, padding: '2px 12px' }}>{translate(titleSuccess)}</Alert>
				</Snackbar>}
		</div>
	);
};

export default Notification;
