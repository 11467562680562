import React from 'react';
import { connect } from 'react-redux';
import $ from 'jquery';
import ProgressCircle from '../ProgressCircle/ProgressCircle';
import ZoomWrapper from "./zoomwrapper";
import { colorPrimary, colorSecondaryRGB } from '../../constants/colors';
import './_matrix.scss';

class MatrixBlock extends React.Component {
  constructor(props) {
    super(props);

    this.i = -1; // hovered td i
    this.j = -1; // hovered td j

    const p = this.primary = getComputedStyle(document.documentElement)
      .getPropertyValue('--primary').trim();
    const r = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(p);
    if (!r) {
      this.rgb = colorSecondaryRGB;
    } else {
      this.rgb = [parseInt(r[1], 16), parseInt(r[2], 16), parseInt(r[3], 16)];
    }

    this.zw = null;

    this.isBigClass = 'soloMatrix';
    // this.isBigClass = props.block.blockCoordinates.bottom - props.block.blockCoordinates.top > 1 ? 'soloMatrix' : '';

    this.wrapRef = React.createRef();
    this.tableRef = React.createRef();
    this.horRef = React.createRef();
    this.verRef = React.createRef();
    this.popupRef = React.createRef();
    this.verb1Ref = React.createRef();
    this.verb2Ref = React.createRef();
    this.cntRef = React.createRef();
    this.pcntRef = React.createRef();

    this.onMouseEnter = this.onMouseEnter.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);
    this.notFoundMatrix = this.notFoundMatrix.bind(this);
    this.notFoundMatrixStatus = false;
  }

  // componentDidMount() {
  // super.componentDidMount();
  // if (!this.wrapRef.current) return;
  // let rect = this.wrapRef.current.getBoundingClientRect();
  // console.log(this.sx, this.sy, this.sw, this.sh);
  // }

  setBars(i, j, td = null) {
    const hb = this.horRef.current;
    const vb = this.verRef.current;
    if (i < 0 || j < 0 || !td) {
      hb.style.display = "none";
      vb.style.display = "none";
      return;
    }
    //let r = this.props.block.blockData.result;
    const dC = window.chrome ? 1 : 0;
    const cx = td.offsetLeft - 158;
    const cy = td.offsetTop - 1 + dC;


    hb.style.display = "block";
    hb.style.width = /*(32*(1+(+j)))*/cx + 4 + "px";
    hb.style.top = /*(32*(i-1))*/ cy + "px";
    vb.style.display = "block";
    vb.style.left = /*(229+32*j)*/ (157 + dC + cx) + "px";
    vb.style.top = /*(32*(i-1))*/ cy + "px";
    vb.style.height = /*(r.length-i)*32*/ (td.offsetParent.offsetHeight - cy - 92) + "px";
  }

  movePopup(i, j, l, t, r) {
    const p = this.popupRef.current;
    if (!p) return;
    const tbl = this.tableRef.current;
    if (!tbl) return;
    const $tbl = $(tbl);
    if (i < 0 || j < 0) {
      p.style.display = "none";
      p.style.opacity = 0;
      $tbl.find("th.active").removeClass("active");
      return;
    }
    l = +l;
    t = +t;
    const i1 = 1 - (-i);
    const j1 = 2 - (-j); // lois explicit conversions
    $tbl.find("tr:nth-child(" + i1 + ") th:first-child").addClass("active");
    $tbl.find("tr:last-child th:nth-child(" + j1 + ")").addClass("active");
    const rect = this.wrapRef.current.getBoundingClientRect();
    const sr = Math.round(rect.right);
    const st = Math.round(rect.y);
    p.style.display = "block";
    p.style.opacity = 1;
    if (t - 56 < st) t = t + 26;
    else t = t - 57;
    p.style.top = t + "px";
    if (270 + l > sr) {
      // l -= 150
      p.style.left = 'auto';
      // p.style.right = (r - 270) + 'px';
      p.style.right = (document.documentElement.clientWidth - r) + 'px';
    } else {
      p.style.left = l + "px";
      p.style.right = 'auto';
    }

    // p.style.right = l + "px";
    // let r = matrixTrain;
    // let r = this.props.block.blockData.result;
    if (!this.verb1Ref.current) return;
    const activeMatrix = this.props.selectMatrix === 'train' ? this.props.classesMatrixTrain : this.props.classesMatrixTest;
    this.verb1Ref.current.innerText = activeMatrix[i];
    this.verb2Ref.current.innerText = activeMatrix[j];
    // this.cntRef.current.innerHTML = r[i]["col" + j][1];
    // this.pcntRef.current.innerHTML = r[i]["col" + j][0] + "%";
    /*  <td className="mp-verb1" ref={this.verb1Ref}>{}</td>
        <td className="cnt" ref={this.cntRef}>{r[i]["col"+j][1]}</td>
      </tr>
      <tr>
        <td className="mp-verb2" ref={this.verb1Ref}>{r[0]["col"+j]}</td>
        <td className="pcnt" ref={this.cntRef}>{r[i]["col"+j][0]}%</td>*/
  }

  onMouseEnter(evt) {
    if (!evt) return;
    if (evt.ctrlKey) return;
    const i = evt.target.dataset.i;
    if (!i) return;
    const j = evt.target.dataset.j;
    const rect = evt.target.getBoundingClientRect();
    this.setBars(i, j, evt.target.offsetParent);
    this.movePopup(i, j, Math.round(rect.x), Math.round(rect.y), Math.round(rect.right));
    this.i = i;
    this.j = j;
    this.renderPopup();
  }
  onMouseLeave(evt) {
    const i = evt.target.dataset.i;
    if (!i) return;
    const j = evt.target.dataset.j;
    if (this.i !== i || this.j !== j) return;
    this.setBars(-1, -1);
    this.movePopup(-1, -1, 0, 0, 0);
    // this.i = this.j = -1;
  }

  // v = от 0% до 100%
  cellStyle(v) {
    const rgb90per = this.rgb.map(color => Math.round(color + (255 - color) / 3));
    const rgb10per = this.rgb.map(color => Math.round(255 - (255 - color) / 3));

    function c(v, idx) {
      if (v > 90) {
        return Math.round(rgb90per[idx] - (rgb90per[idx] - colorSecondaryRGB[idx]) * ((v - 90) * 10 / 100.0));
      } else if (v >= 10 && v <= 90) {
        return Math.round(rgb10per[idx] - (rgb10per[idx] - rgb90per[idx]) * (v / 100.0));
      } else if (v < 10) {
        return Math.round(255 - (255 - rgb10per[idx]) * (v * 10 / 100.0));
      }
    }

    const r = c(v, 0);
    const g = c(v, 1);
    const b = c(v, 2);

    const w = 0.7 * g + 0.2 * r + 0.1 * b;
    const t = w > 201 ? colorPrimary : "#fff";
    const st = { backgroundColor: "rgb(" + r + "," + g + "," + b + ")", "color": t };
    return st;
  }

  renderRow(row, i) {
    const tds = [];
    const activeMatrix = this.props.selectMatrix === 'train' ? this.props.classesMatrixTrain : this.props.classesMatrixTest;
    tds.push(<th key={"" + i + "_col"} className={i === this.i ? "active" : ""}><div>{activeMatrix?.[i]}</div></th>);
    for (let idx = 0; idx < row.length; idx++) {
      tds.push(<td key={"" + i + "_" + idx} style={this.cellStyle(+row[idx])}><div data-i={i}
        data-j={idx} data-cnt={+row[idx]}
        onMouseLeave={(evt) => this.onMouseLeave(evt)}
        onMouseEnter={(evt) => this.onMouseEnter(evt)}
      >{row[idx]}</div></td>);
    }
    return <tr key={i}>{tds}</tr>;
  }

  // резерв
  // (со второго элемента массива, i = 1)
  // data-i начинается c 1 
  // data-j начинается c 0
  // data-cnt - значение ячейки
  // renderRow(row, i) {
  //   let tds = [];
  //   tds.push(<th key={"" + i + "_col"} className={i == this.i ? "active" : ""}><div>{row["col"]}</div></th>);
  //   for (let k in row) {
  //     if (!row.hasOwnProperty(k)) continue;
  //     if (k == "col") continue;
  //     tds.push(<td key={"" + i + "_" + k} style={this.cellStyle(+row[k][0])}><div data-i={i}
  //       data-j={+k.replace("col", "")} data-cnt={+row[k][0]}
  //     // data-j={+k.replace("col", "")} data-cnt={+row[k][1]}
  //     // onMouseLeave={(evt) => this.onMouseLeave(evt)}
  //     // onMouseEnter={(evt) => this.onMouseEnter(evt)}
  //     >{row[k][0]}</div></td>);
  //     // >{Math.round(row[k][0])}</div></td>);
  //   }
  //   return <tr key={i}>{tds}</tr>;
  // }

  renderFoot(row) {
    const tds = [];
    tds.push(<th key="angle"></th>);
    for (const k in row) {
      if (!row.hasOwnProperty(k)) continue;
      if (k === "col") continue;
      tds.push(<th key={`foot_${k}`} className={k === "col" + this.j ? "active" : ""} title={row[k]}>
        <div><span><span>{row[k]}</span></span></div></th>);
    }
    return <tr key="footer">{tds}</tr>;
  }

  notFoundMatrix(boolean) {
    this.notFoundMatrixStatus = boolean;
  }

  renderMatrix() {
    // let r = this.props.block.blockData.result;
    const r = this.props.selectMatrix === 'train' ? this.props.matrixTrain : this.props.matrixTest;
    if (!r) return "";
    if (!Array.isArray(r)) {
      /* eslint-disable */
      console.error("Matrix expects array, got", r);
      return <div className="notFoundMatrix">{this.props.translate('notFound')}</div>;
    }
    // if (r.length < 2) {
    //   this.notFoundMatrix(true);
    //   return <div className="notFoundMatrix">Не найдено</div>
    // }
    let trs = [];
    for (let i = 0; i < r.length; ++i) {
      // передаем matrixTrain[x] или matrixTest[x]
      trs.push(this.renderRow(r[i], i));
    }
    // let st = {
    //   "width": (200 + 32 * r.length) + "px",
    //   // "width": (198 + 32 * r.length) + "px",
    //   "height": (100 + 32 * r.length) + "px"
    //   // "height": (178 + 32 * r.length) + "px"
    // }
    let activeMatrix = this.props.selectMatrix === 'train' ? this.props.classesMatrixTrain : this.props.classesMatrixTest;
    return <table className="matrix-table" ref={this.tableRef}>
      <tbody>{trs}</tbody>
      <tfoot>{this.renderFoot(activeMatrix)}</tfoot>
    </table>;
  }

  renderGradusnik() {
    let r = this.props.matrixTrain;
    // let r = this.props.block.blockData.result;
    if (!r) return <div className="gradusnik"></div>;
    return <div className="gradusnik"><ul className="graduscale">
      <li>100</li>
      <li>80</li>
      <li>60</li>
      <li>40</li>
      <li>20</li>
      <li>0</li>
    </ul>
    </div>;
  }

  renderPopup() {
    // let i = this.i;
    // let j = this.j;
    let content = "";
    // console.log(this.i, this.j, 'из рендер ПОПАап')
    // let r = matrixTrain;
    // if (i >= 0 && j >= 0) {
    //   content = <table><tbody>
    //     <tr>
    //       <td className="mp-verb1" ref={this.verb1Ref}>{r[i][i]} ij больше 0 </td>
    //       {/* <td className="cnt" ref={this.cntRef}>{r[i]["col" + j][1]}</td> */}
    //     </tr>
    //     <tr>
    //       <td className="mp-verb2" ref={this.verb2Ref}>{r[0][i]} ij больше 0</td>
    //       {/* <td className="pcnt" ref={this.pcntRef}>{r[i]["col" + j][0]}%</td> */}
    //     </tr>
    //   </tbody></table>;
    // } else {
    content = <div>
      <div className="mp-verb1" ref={this.verb1Ref}></div>
      <div className="mp-verb2" ref={this.verb2Ref}></div>
    </div>;
    // content = <table><tbody>
    //   <tr>
    //     <td className="mp-verb1" ref={this.verb1Ref}></td>
    //     {/* <td className="cnt" ref={this.cntRef}></td> */}
    //   </tr>
    //   <tr>
    //     <td className="mp-verb2" ref={this.verb2Ref}></td>
    //     {/* <td className="pcnt" ref={this.pcntRef}>%</td> */}
    //   </tr>
    // </tbody></table>;
    // }

    return <div className="matrix-popup" ref={this.popupRef}>
      {content}
      {/* <div >%%%</div> */}
    </div>;
  }

  render() {
    let content = <div style={{ height: "calc(100vh - 120px)", display: 'flex', justifyContent: 'center', alignItems: 'center' }}><ProgressCircle /></div>;
    let r = this.props.selectMatrix === 'train' ? this.props.matrixTrain : this.props.matrixTest;
    // let r = this.props.block.blockData.result;
    if (/* !this.state.isLoading && */ r) {
      content = this.renderMatrix();
    } else return content;
    this.zw = <ZoomWrapper classes={this.props.classesForPopper} matrixTestFull={this.props.matrixTestFull} selectMatrix={this.props.selectMatrix} setSelectMatrix={this.props.setSelectMatrix} notFoundMatrixStatus={this.notFoundMatrixStatus} testingStatus={this.props.testingStatus} translate={this.props.translate} testAccuracy={this.props.testAccuracy} tableRef={this.tableRef}>
      <div className="matrix-bg">
        {content}
        <div className="horbar" ref={this.horRef}></div>
        <div className="verbar" ref={this.verRef}></div>
      </div>
    </ZoomWrapper>;
    return (
      <div className={"matrix-wrap " + this.isBigClass} ref={this.wrapRef}>
        {this.renderGradusnik()}
        {this.zw}
        {this.renderPopup()}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { model } = state;
  return {
    classesForPopper: model.fullModel.fullModel?.[model.fullModel.activeType]?.classes,
    classesMatrixTrain: model.fullModel.fullModel?.[model.fullModel.activeType]?.matrix?.train?.classes,
    classesMatrixTest: model.fullModel.fullModel?.[model.fullModel.activeType]?.matrix?.test?.classes,
    fullModel: model.fullModel.fullModel,
    activeType: model.fullModel.activeType,
    matrixTrain: model.fullModel.fullModel[model.fullModel.activeType]?.matrix?.train?.matrix,
    matrixTest: model.fullModel.fullModel[model.fullModel.activeType]?.matrix?.test?.matrix,
    matrixTestFull: model.fullModel.fullModel[model.fullModel.activeType]?.matrix?.test,
    testAccuracy: model.fullModel.fullModel[model.fullModel.activeType]?.testAccuracy,
    testingStatus: model.fullModel.testing
  }
}

export default connect(mapStateToProps)(MatrixBlock);
