import { useState } from 'react';
import { FormControl, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { deleteConditionRun, editConditionRun, selectDataList } from '../../store/sesSlice';
import { selectActiveRobotVersion } from '../../store/sesRobotSlice';
import useAccessRight from '../../hooks/useAccessRight';
import useTranslate from '../../hooks/useTranslate';
import { SES } from '../../constants/accessRights';
import { colorPrimary, colorRed } from '../../constants/colors';
import { IConditionRunProps } from './ConditionRun.props';
import styles from './ConditionRun.module.scss';

const ConditionRun = ({ condition, setChangeFlg, idx }: IConditionRunProps): JSX.Element => {
	const [inputDataElement, setInputDataElement] = useState<string>(condition); // элемент данных

	const dispatch = useAppDispatch();
	const dataElemList = useAppSelector(selectDataList); // список элементов данных
	const activeRobotVersion = useAppSelector(selectActiveRobotVersion); // store - версия активного робота

	const isAccess = useAccessRight(); // hook для проверки прав доступа
	const translate = useTranslate(); // hook для перевода текста

	// обработчик выбора элементов данных
	const handleChange = (event: SelectChangeEvent<string>): void => {
		const { target: { value } } = event;
		setInputDataElement(value);
		dispatch(editConditionRun({ idx, data: value }));
		setChangeFlg(true); // ставим флаг о несохраненных данных
	};

	// обработчик удаления условия
	const deleteConditionHandler = (): void => {
		dispatch(deleteConditionRun(idx));
		setChangeFlg(true); // ставим флаг о несохраненных данных
	};

	return (
		<div className={styles.condition}>
			<FormControl fullWidth sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}>
				<Select
					required
					value={inputDataElement}
					onChange={e => handleChange(e)}
					disabled={!isAccess(SES.ENDPOINT_EDIT) || activeRobotVersion !== 'draft'}
					style={{ fontSize: 13, height: 33, color: colorPrimary }}
				>
					{/* не найденный элемент данных из списка */}
					{!dataElemList.data.find(elem => elem.id === condition) &&
						<MenuItem value={condition} sx={{ fontSize: 13, color: colorPrimary }}>{condition}</MenuItem>
					}
					{dataElemList.data.map((elem) => (
						<MenuItem key={elem.id} value={elem.id} sx={{ fontSize: 13, color: colorPrimary }}>{elem.name}</MenuItem>
					))}
				</Select>
			</FormControl>

			{isAccess(SES.ENDPOINT_EDIT) && activeRobotVersion === 'draft' &&
				<FontAwesomeIcon
					icon={faTrashCan}
					color={colorRed}
					size='lg'
					onClick={deleteConditionHandler}
					title={translate('condition_deleteConditionTitle')}
					style={{ cursor: 'pointer' }}
				/>
			}
		</div>
	);
};

export default ConditionRun;
