import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import useTranslate from '../../../hooks/useTranslate';
import { IHideSidebarProps } from './HideSidebar.props';
import styles from './HideSidebar.module.scss';

const HideSidebar = ({ showSidebar, setShowSidebar }: IHideSidebarProps): JSX.Element => {
	const translate = useTranslate(); // hook для перевода текста

	return (
		<div className={styles.sidebarTagWrapper}>
			<div
				className={styles.sidebarTagInner}
				onClick={() => setShowSidebar(prev => !prev)}
				title={translate(showSidebar ? 'hideSidebar_hiddenTitle' : 'hideSidebar_showTitle')}
			>
				{showSidebar ?
					<FontAwesomeIcon icon={faChevronLeft} size='sm' />
					:
					<FontAwesomeIcon icon={faChevronRight} size='sm' />
				}
			</div>
		</div>
	);
};

export default HideSidebar;
