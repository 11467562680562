import { useEffect, useRef, useState } from 'react';
import { useAppSelector } from '../../store/hooks';
import { selectTranscriptionAudio, selectTranscriptionData } from '../../store/transcriptionSlice';
import useTranslate from '../../hooks/useTranslate';
import Replica from '../Replica/Replica';
import ProgressCircle from '../ProgressCircle/ProgressCircle';
import { RequestStatus } from '../../types/statusTypes';
import { IStenographyProps } from './Stenography.props';
import styles from './Stenography.module.scss';

const Stenography = ({ setChangeFlg, showContextMenu, setShowContextMenu }: IStenographyProps): JSX.Element => {
	const [activeLineIndexList, setActiveLineIndexList] = useState<number[]>([0]); // список индексов активных реплик
	const listRef = useRef<HTMLUListElement>(null); // ссылка на список реплик

	const translate = useTranslate(); // hook для перевода текста

	const transcription = useAppSelector(selectTranscriptionData); // store - данные сохранненной записи распознавания
	const audio = useAppSelector(selectTranscriptionAudio); // store - аудио-файл сохраненной записи распознавания

	// следим за списком индексов активной реплики и ставим в центр страницы последнюю
	useEffect(() => {
		listRef.current?.children[activeLineIndexList[activeLineIndexList.length - 1]]?.scrollIntoView({ block: "center", behavior: 'smooth' });
	}, [activeLineIndexList]);

	// сбрасываем список активных индексов при смене статуса
	useEffect(() => {
		setActiveLineIndexList([]);
	}, [transcription.status]);

	return (
		<div className={styles.checkResult}>
			{transcription.status === RequestStatus.LOADING &&
				<div className={styles.loading}><ProgressCircle title={translate('progressCircle_dataRecognitionLoadingTitle')} /></div>
			}

			{transcription.status === RequestStatus.FAILED && transcription.data === null &&
				<p className={styles.failedText}>{translate('stenography_failedLoadingTitle')}</p>
			}

			{transcription.data !== null &&
				<>
					{'message' in transcription.data ?
						<p className={styles.failedText}>{translate(transcription.data.message || 'stenography_failedLoadingTitle')}</p>
						:
						<div className={styles.checkResultEditorWrapper}>
							{transcription.data && typeof transcription.data === 'object' && 'splitted' in transcription.data && transcription.data.splitted.length > 0 ?
								<ul className={styles.wrapper} ref={listRef}>
									{transcription.data.splitted.map((fragment, idx, array) => {
										// если список не содержал активную запись и временная метка подходит для этого фрагмента - записывааем индекс в state
										if (!activeLineIndexList.includes(idx) && audio.timestamp >= fragment.start_ms && audio.timestamp <= fragment.stop_ms) {
											activeLineIndexList.pop(); // ??
											setActiveLineIndexList(prev => [...prev, idx]);
										}
										return (
											<Replica
												fragment={fragment}
												fragmentIdx={idx}
												fragmentsArray={array}
												speakerList={transcription.speakerList}
												timestamp={fragment.start_ms <= audio.timestamp && audio.timestamp <= fragment.stop_ms ? audio.timestamp : -1} // для кэширования (не перерисовывается)
												from='transcript'
												setChangeFlg={setChangeFlg}
												showContextMenu={showContextMenu}
												setShowContextMenu={setShowContextMenu}
												key={idx}
											/>
										);
									})}
								</ul>
								:
								<p className={styles.notFoundText}>{translate('stenography_noDataTitle')}</p>
							}
						</div>
					}
				</>
			}
		</div>
	);
};

export default Stenography;
