import { useEffect, useState } from 'react';
import { Slide } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { addUserName, clearDeleteUserStatus, clearModifyUserStatus, clearUserList, deleteUser, getCurrentUserInfo, getUserList, modifyMyself, modifyUser, selectCurrentUserInfo, selectDeleteUserStatus, selectModifyUserStatus, selectUserInfo, updateUserInfo } from '../../../store/userSlice';
import useAccessRight from '../../../hooks/useAccessRight';
import useTranslate from '../../../hooks/useTranslate';
import { DATASET, USER } from '../../../constants/accessRights';
import Save from '../Buttons/Save/Save';
import Delete from '../Buttons/Delete/Delete';
import UserMenu from '../Buttons/UserMenu/UserMenu';
import UserLogs from '../../UserLogs/UserLogs';
import Datasets from '../../Datasets/Datasets';
import AlertDialog from '../../AlertDialog/AlertDialog';
import ScreenLock from '../../ScreenLock/ScreenLock';
import Notification from '../../Notification/Notification';
import { IModifyUserData } from '../../../types/userTypes';
import { RequestStatus, ResponseStatus } from '../../../types/statusTypes';
import { IUserControlsProps } from './UserControls.props';
import styles from './UserControls.module.scss';

const UserControls = ({ userName, password, setPassword, userRoleId, datasetIdDefault, datasetsListAvailable, avatarBase64, changeFlg, setChangeFlg, setShowPage }: IUserControlsProps) => {
	const [showAlertDialogSave, setShowAlertDialogSave] = useState<boolean>(false); // показ диалогового окна при изменении информации пользователя
	const [showAlertDialogDelUser, setShowAlertDialogDelUser] = useState<boolean>(false); // показ диалогового окна при удалении пользователя
	const [showNotificationSave, setShowNotificationSave] = useState<boolean>(false); // показ уведомления сохранения данных пользователя
	const [showNotificationDelete, setShowNotificationDelete] = useState<boolean>(false); // показ уведомления удаления пользователя
	const [showScreenLock, setShowScreenLock] = useState<{ isShow: boolean, title: string }>({ isShow: false, title: '' }); // показ экрана блокировки и подпись
	const [showDatasets, setShowDatasets] = useState<boolean>(false); // показ вкладки наборов данных
	const [showUserLogs, setShowUserLogs] = useState<boolean>(false); // показ вкладки логов пользователей

	const dispatch = useAppDispatch();
	const userInfo = useAppSelector(selectUserInfo); // store - информация о пользователе
	const currentUserInfo = useAppSelector(selectCurrentUserInfo); // store - информация о текущем пользователе
	const modifyUserStatus = useAppSelector(selectModifyUserStatus); // store - статус изменения информации пользователя
	const deleteUserStatus = useAppSelector(selectDeleteUserStatus); // store - статус об удалении пользователя

	const isAccess = useAccessRight(); // hook для проверки прав доступа
	const translate = useTranslate(); // hook для перевода текста

	// следим за статусом изменения/удаления информации пользователя
	useEffect(() => {
		if (modifyUserStatus.status === RequestStatus.LOADING) setShowScreenLock({ isShow: true, title: translate('screenLock_saveUserDataTitle') }); // если идет изменение инфо пользователя
		else if (deleteUserStatus.status === RequestStatus.LOADING) setShowScreenLock({ isShow: true, title: translate('screenLock_deleteUserTitle') }); // если идет удаление пользователя
		else setShowScreenLock({ isShow: false, title: '' }); // иначе выключаем экран блокировки

		// если изменилось успешно 
		if (modifyUserStatus.status === RequestStatus.IDLE && modifyUserStatus.error === ResponseStatus.SUCCESS && modifyUserStatus.message === 'success') {
			setChangeFlg(false); // выключаем флаг изменения данных
			if (modifyUserStatus.myself) dispatch(getCurrentUserInfo()); // если изменяли свои данные - получаем заново
			else {
				setPassword(''); // сбрасываем пароль
				dispatch(updateUserInfo({
					userName,
					userRoleId,
					datasetIdDefault,
					datasetsListAvailable,
					avatarBase64
				})); // иначе обновляем данные в store
			}
		}
		// если удаление прошло успешно
		if (deleteUserStatus.error === ResponseStatus.SUCCESS && deleteUserStatus.status === RequestStatus.IDLE && deleteUserStatus.message === 'success') {
			dispatch(addUserName(null)); // очищаем из store имя активного пользователя
			dispatch(clearUserList()); // очищаем список пользователей
			dispatch(getUserList()); // получаем заново список пользователей
		}
	}, [modifyUserStatus, deleteUserStatus]);

	// обработчик сохранения данных пользователя
	const saveHandler = (): void => {
		setShowAlertDialogSave(false); // закрываем диалоговое окно
		setShowNotificationSave(true); // включаем уведомление
		if (userInfo.data && 'username' in userInfo.data) {
			const userModifyData: IModifyUserData = {}; // измененные данные пользователя
			if (userName !== userInfo.data.fullname) userModifyData.fullname = userName; // если изменилось имя пользователя
			if (password !== "") userModifyData.password = password; // если изменился пароль
			if (userRoleId !== userInfo.data.role) userModifyData.role = userRoleId; // если изменилась роль
			if (datasetIdDefault !== userInfo.data.dataset) userModifyData.dataset = datasetIdDefault; // если изменился набор данных по умолчанию
			if (JSON.stringify(datasetsListAvailable) !== JSON.stringify(userInfo.data.datasets)) userModifyData.datasets = datasetsListAvailable; // если изменился список доступных наборов данных
			if (avatarBase64 !== userInfo.data.image) userModifyData.image = avatarBase64; // если изменился аватар
			// если изменяем данные самого себя - ставим флаг в store, для следующего обновления данных
			if (userInfo.data.id === currentUserInfo.id) dispatch(modifyMyself(true));
			dispatch(modifyUser({
				data: userModifyData,
				id: userInfo.data.id,
			})); // изменение данных пользователя
		}
	};

	// обработчик удаления пользователя
	const deleteUserHandler = (): void => {
		setShowAlertDialogDelUser(false); // закрываем диалоговое окно
		setShowNotificationDelete(true); // включаем уведомление об удалении пользователя
		userInfo.data && 'username' in userInfo.data && dispatch(deleteUser(userInfo.data.id)); // удаление пользователя
	};

	return (
		<div className={styles.controls}>

			<Slide direction="down" in={true} mountOnEnter unmountOnExit timeout={500}>
				<div className={styles.controlsWrapper}>
					<div className={styles.controlsTitle}>{translate('users')}</div>

					<section className={styles.controlsTypeAndFunc}>
						<div className={styles.controlsFunctions}></div>

						<div className={styles.controlsFunctions}>
							<div className={styles.controlsFunctionsCenterBlock}>
								{isAccess(USER.MODIFY) &&
									<Save
										styleNotActive={styles.controlsFunctionBtnNotActive}
										changeFlg={changeFlg}
										showNotification={showNotificationSave}
										setShowNotification={setShowNotificationSave}
										dataResponse={selectModifyUserStatus}
										clearDataResponse={clearModifyUserStatus}
										setShowAlertDialog={setShowAlertDialogSave}
									/>
								}
								{isAccess(USER.DELETE) &&
									<Delete
										styleNotActive={styles.controlsFunctionBtnNotActive}
										isAvailable={userInfo.status !== RequestStatus.LOADING && userInfo.userName !== null && userInfo.data !== null && 'username' in userInfo.data && currentUserInfo.userName !== userInfo.data.username && userInfo.data.username !== 'admin'}
										title='controlsBtn_deleteUser'
										setShowAlertDialog={setShowAlertDialogDelUser}
									/>
								}
							</div>

							<div className={styles.controlsFunctionsRightBlock}>
								<UserMenu setShowPage={setShowPage} />
							</div>
						</div>
					</section>
				</div>
			</Slide>

			{/* бирки */}
			<Slide direction="left" in={true} mountOnEnter unmountOnExit timeout={1000}>
				<section className={styles.tags}>
					{isAccess(USER.LOGS) &&
						<div className={styles.tag} onClick={() => setShowUserLogs(prev => !prev)}>
							<span className={styles.tagTitle}>{translate('tag_logs')}</span>
						</div>
					}
					{isAccess(DATASET.LIST) &&
						<div className={styles.tag} onClick={() => setShowDatasets(prev => !prev)}>
							<span className={styles.tagTitle}>{translate('tag_datasets')}</span>
						</div>
					}
				</section>
			</Slide>

			<Datasets showDatasets={showDatasets} setShowDatasets={setShowDatasets} />
			<UserLogs showUserLogs={showUserLogs} setShowUserLogs={setShowUserLogs} />

			<AlertDialog
				showAlertDialog={showAlertDialogSave}
				setShowAlertDialog={setShowAlertDialogSave}
				submitHandler={saveHandler}
				title={translate('dialog_modifyUserDataTitle')}
				description={translate('dialog_modifyUserDataConfirm')}
				name={String(userInfo.userName)}
			/>
			<AlertDialog
				showAlertDialog={showAlertDialogDelUser}
				setShowAlertDialog={setShowAlertDialogDelUser}
				submitHandler={deleteUserHandler}
				title={translate('dialog_deleteUserTitle')}
				description={translate('dialog_deleteUserConfirm')}
				name={String(userInfo.userName)}
			/>

			{showScreenLock.isShow && <ScreenLock title={translate(showScreenLock.title)} />}

			{/* уведомление для удаления пользователя */}
			{showNotificationDelete &&
				<Notification
					showNotification={showNotificationDelete}
					setShowNotification={setShowNotificationDelete}
					selectDataResponse={selectDeleteUserStatus}
					clearDataResponse={clearDeleteUserStatus}
					titleFailed={translate('noticeDeleteUser_failed')}
					titleSuccess={translate('noticeDeleteUser_success')}
				/>
			}
		</div>
	);
};

export default UserControls;
