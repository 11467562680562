import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { Button, Checkbox, FormControl, FormControlLabel, InputLabel, MenuItem, Select, Slider } from '@mui/material';
import { useReactMediaRecorder } from "react-media-recorder-2";
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsis, faMicrophone } from '@fortawesome/free-solid-svg-icons';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import useAccessRight from '../../../hooks/useAccessRight';
import useTranslate from '../../../hooks/useTranslate';
import { selectActiveType, selectAllModels, selectModelName } from '../../../store/modelSlice';
import { addDataUrl, clearRecognitionData, recognizeSpeech, selectRecognitionAsyncData, selectRecognitionData, selectRecognitionDataWebSocket } from '../../../store/sprSlice';
import { clearClusterServer, getClusterServerModels, selectClusterServer } from '../../../store/serverSlice';
import { SERVER } from '../../../constants/accessRights';
import { backgroundColor, colorRed } from '../../../constants/colors';
import { PresetVadType, VadType } from '../../../types/sprTypes';
import { RequestStatus } from '../../../types/statusTypes';
import ProgressCircle from '../../ProgressCircle/ProgressCircle';
import styles from './FormSendAudioSpr.module.scss';

const FormSendAudioSpr = (): JSX.Element => {
	const [file, setFile] = useState<File | null>(null); // для отправки файла
	const [recordingFlg, setRecordingFlg] = useState<boolean>(false); // флаг записи с микрофона
	const [definitionOfSpeakers, setDefinitionOfSpeakers] = useState<boolean>(false); // флаг определения говорящих
	const [punctuation, setPunctuation] = useState<boolean>(true); // флаг расстановки знаков препинания
	const [vadType, setVadType] = useState<VadType>('webrtc'); // тип vad
	const [presetVad, setPresetVad] = useState<PresetVadType>('call'); // преднастроенная модель vad
	const [denoise, setDenoise] = useState<number>(0); // шумоподавление
	const [advancedMode, setAdvancedMode] = useState<boolean>(false); // флаг для расширенного режима
	const [modelNamesProd, setModelNamesProd] = useState<string[]>([]); // список моделей из прода для расширенного режима
	const [inputModelName, setInputModelName] = useState<string>(''); // имя продовой модели для расширенного режима

	const formRef = useRef<HTMLFormElement>(null);
	const { status, startRecording, stopRecording } = useReactMediaRecorder({
		audio: true,
		onStop: (_: string, blob: Blob) => {
			blob.size > 0 && submitHandler(blob, 'sync'); // запускаем обработчик отправки аудиозаписи, если есть запись с микрофона
		}
	}); // hook для записи аудио
	const translate = useTranslate(); // hook для перевода текста
	const isAccess = useAccessRight(); // hook для проверки прав доступа

	const dispatch = useAppDispatch();
	const allModels = useAppSelector(selectAllModels); // все модели
	const modelName = useAppSelector(selectModelName); // store - имя активной модели
	const activeType = useAppSelector(selectActiveType); // store - активный тип модели
	const recognitionData = useAppSelector(selectRecognitionData); // store - распознавание речи
	const recognitionAsyncData = useAppSelector(selectRecognitionAsyncData); // store - данные асинхронного режима
	const recognitionDataWebSocket = useAppSelector(selectRecognitionDataWebSocket); // store - данные распознавания речи webSocket
	const clusterServer = useAppSelector(selectClusterServer); // store - список моделей на сервере cluster

	// следим за именем активной модели и типом модели и сбрасываем данные формы
	useEffect(() => {
		formRef.current?.reset();
		setTimeout(() => setFile(null), 100);
		advancedMode && setAdvancedMode(false);
		definitionOfSpeakers && setDefinitionOfSpeakers(false);
		!punctuation && setPunctuation(true);
		vadType !== 'webrtc' && setVadType('webrtc');
		presetVad !== 'call' && setPresetVad('call');
		denoise !== 0 && setDenoise(0);
	}, [modelName, activeType]);

	// следим за расширенным режимом
	useEffect(() => {
		// если расширенный режим включен и нет данных и нет ошибки получения - получаем продовые модели с сервера cluster
		if (advancedMode) {
			clusterServer.data === null && clusterServer.status !== RequestStatus.FAILED && dispatch(getClusterServerModels({ serviceType: 'spr' }));
		} else {
			setInputModelName(''); // очищаем имя продовой модели
			(clusterServer.data !== null || clusterServer.status === RequestStatus.FAILED) && dispatch(clearClusterServer()); // если есть список продовых моделей или не удалось их получить - очищаем
		}
	}, [advancedMode]);

	// следим за статусом получения продовых моделей с серверов cluster
	useEffect(() => {
		// если нет ошибок и есть данные
		if (clusterServer.status === RequestStatus.IDLE && clusterServer.data !== null && typeof clusterServer.data !== 'string' && Object.keys(clusterServer.data).length > 0) {
			const clusterServers = Object.keys(clusterServer.data); // пишем сервера cluster'а
			const arrModelNames: string[] = [];
			clusterServers.forEach(server => {
				clusterServer.data && clusterServer.data[server]?.forEach(modelName => {
					!arrModelNames.includes(modelName) && arrModelNames.push(modelName); // пишем все уникальные модели
				});
			});
			setModelNamesProd(arrModelNames); // пишем список моделей в state
		}
	}, [clusterServer.status]);

	// следим за флагом и статусом записи микрофона
	useEffect(() => {
		// и если кнопка мыши не зажата в плашке в момент подготовки или начала записи - останавливаем запись
		(status === 'acquiring_media' || status === 'recording') && recordingFlg === false && stopRecording();
	}, [status, recordingFlg]);

	// обработчик отправки аудиозаписи
	const submitHandler = (audio: File | Blob | null, is: 'async' | 'sync'): void => {
		if (recognitionData.status === RequestStatus.LOADING || recognitionDataWebSocket.webSocketStatus !== 3) return; // если идет загрузка - выходим
		(recognitionData.data !== null || recognitionAsyncData.responseStatus || recognitionAsyncData.requestStatus !== RequestStatus.IDLE) && dispatch(clearRecognitionData()); // если есть данные распознавания - очищаем
		const formData = new FormData();
		// если есть запись (файл или с микрофона) 
		if (audio) {
			formData.append('file', audio); // создаем пару
			const url = window.URL.createObjectURL(audio); // создаем ссылку на файл
			is === 'sync' && dispatch(addDataUrl(url)); // добавляем ссылку в store, если синхронное распознавание
		}
		dispatch(recognizeSpeech({
			modelName: (advancedMode && inputModelName.length > 0) ? // если включен расширенный режим и выбрано имя модели
				inputModelName
				:
				(activeType === 'future' ? `${modelName}-new` : (modelName || '')),
			formData,
			asyncMode: is === 'async' ? 1 : 0,
			speakers: definitionOfSpeakers ? 1 : 0,
			punctuation: punctuation ? 1 : 0,
			vadType,
			presetVad: vadType === 'neuro' ? presetVad : undefined,
			denoise,
		})); // распознавание речи
	};

	return (
		<form className={styles.form} ref={formRef}>
			<div className={styles.formDenoise}>
				<div>{translate('formSendAudioSpr_sliderDenoise')}</div>
				<Slider
					size="small"
					min={0}
					max={100}
					valueLabelDisplay='auto'
					value={denoise}
					onChange={(_e, value) => typeof value === 'number' && setDenoise(value)}
				/>
			</div>
			<FormControlLabel sx={{ width: '100%', overflow: 'hidden', marginTop: '-10px', /* marginBottom: '8px', */ '.MuiTypography-root': { fontSize: 13, marginTop: '3px' } }} control={
				<Checkbox
					checked={definitionOfSpeakers}
					onChange={e => setDefinitionOfSpeakers(e.target.checked)}
					size='small'
					disabled={recognitionData.status === RequestStatus.LOADING || recognitionDataWebSocket.webSocketStatus !== 3}
				/>
			} label={translate('formSendAudioSpr_checkboxDefinitionOfSpeakers')} />
			<FormControlLabel sx={{ width: '100%', overflow: 'hidden', marginTop: '-10px', /* marginBottom: '8px', */ '.MuiTypography-root': { fontSize: 13, marginTop: '3px' } }} control={
				<Checkbox
					checked={punctuation}
					onChange={e => setPunctuation(e.target.checked)}
					size='small'
					disabled={recognitionData.status === RequestStatus.LOADING || recognitionDataWebSocket.webSocketStatus !== 3}
				/>
			} label={translate('formSendAudioSpr_checkboxPunctuation')} />
			<FormControl
				fullWidth
				sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 }, marginBottom: (vadType === 'neuro' || !isAccess(SERVER.MODEL_LIST)) ? '8px' : 0 }}
				margin='dense'
			>
				<InputLabel id="vad-label" sx={{ fontSize: 13 }}>{translate('formSendAudioSpr_selectVadType')}</InputLabel>
				<Select
					labelId="vad-label"
					id="vad"
					label={translate('formSendAudioSpr_selectVadType')}
					value={vadType}
					onChange={(e) => setVadType(e.target.value as VadType)}
					style={{ fontSize: 13, height: 33 }}
					disabled={recognitionData.status === RequestStatus.LOADING || recognitionDataWebSocket.webSocketStatus !== 3}
				>
					<MenuItem value='webrtc' sx={{ fontSize: 13 }}>webrtc</MenuItem>
					<MenuItem value='neuro' sx={{ fontSize: 13 }}>neuro</MenuItem>
				</Select>
			</FormControl>
			{vadType === 'neuro' &&
				<FormControl
					fullWidth
					sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 }, marginBottom: !isAccess(SERVER.MODEL_LIST) ? '8px' : 0 }}
					margin='dense'
				>
					<InputLabel id="presetVad-label" sx={{ fontSize: 13 }}>{translate('formSendAudioSpr_selectModelVad')}</InputLabel>
					<Select
						labelId="presetVad-label"
						id="presetVad"
						label={translate('formSendAudioSpr_selectModelVad')}
						value={presetVad}
						onChange={(e) => setPresetVad(e.target.value as PresetVadType)}
						style={{ fontSize: 13, height: 33 }}
						disabled={recognitionData.status === RequestStatus.LOADING || recognitionDataWebSocket.webSocketStatus !== 3}
					>
						<MenuItem value='call' sx={{ fontSize: 13 }}>call</MenuItem>
						<MenuItem value='microphone' sx={{ fontSize: 13 }}>microphone</MenuItem>
					</Select>
				</FormControl>
			}

			{isAccess(SERVER.MODEL_LIST) &&
				<div className={styles.formAdvancedMode}>
					{!advancedMode && <div className={styles.formAdvancedModeTitle} onClick={() => (recognitionData.status !== RequestStatus.LOADING && recognitionDataWebSocket.webSocketStatus === 3) && setAdvancedMode(true)}>{translate('formSendAudioSpr_advancedModeTitle')}</div>}
					{advancedMode &&
						<>
							{clusterServer.status === RequestStatus.LOADING && <div className={styles.loading}><ProgressCircle title={translate('progressCircle_modelListLoadingTitle')} inLine /></div>}
							{clusterServer.status === RequestStatus.FAILED && <div className={styles.failedText}>{translate('formSendAudioSpr_advancedModeNotAvailable')}</div>}
							{clusterServer.status === RequestStatus.IDLE &&
								<>
									{modelNamesProd.length > 0 ?
										<FormControl fullWidth sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}>
											<InputLabel id="modelName-label" sx={{ fontSize: 13 }}>{translate('formSendAudioSpr_selectModelNameProd')}</InputLabel>
											<Select
												labelId="modelName-label"
												id="modelName"
												label={translate('formSendAudioSpr_selectModelNameProd')}
												value={inputModelName}
												onChange={(e) => setInputModelName(e.target.value)}
												style={{ fontSize: 13, height: 33 }}
												disabled={recognitionData.status === RequestStatus.LOADING || recognitionDataWebSocket.webSocketStatus !== 3}
											>
												{modelNamesProd.map((modelName) => (
													<MenuItem key={modelName} value={modelName} sx={{ fontSize: 13 }}>
														{modelName.includes('-new') ? `${modelName.slice(0, -4)} (${translate('future')})`
															:
															modelName.includes('-planned') ? `${modelName.slice(0, -8)} (${translate('futureInstall')})`
																:
																allModels.models && Object.keys(allModels.models).includes(modelName) ? `${modelName} (${translate('current')})`
																	:
																	modelName
														}
													</MenuItem>
												))}
											</Select>
										</FormControl>
										:
										<div>{translate('formSendAudioSpr_noDataModelProd')}</div>
									}
								</>
							}
						</>
					}
				</div>
			}

			<div className={styles.formAudioSelection}>
				<input
					className={styles.formInputFile}
					type="file"
					id='fileSpr'
					required
					accept="audio/wav, audio/mp3"
					onChange={(e: ChangeEvent<HTMLInputElement>) => e.target.files && setFile(e.target.files[0])}
					disabled={recognitionData.status === RequestStatus.LOADING || recognitionDataWebSocket.webSocketStatus !== 3}
				/>

				<div className={cn(styles.formMicrophone, {
					[styles.formMicrophoneNotAllow]: recognitionData.status === RequestStatus.LOADING || recognitionDataWebSocket.webSocketStatus !== 3
				})}
					onMouseDown={() => {
						if (recognitionData.status === RequestStatus.LOADING || recognitionDataWebSocket.webSocketStatus !== 3) return;
						startRecording();
						setRecordingFlg(true);
					}}
					onMouseUp={() => setRecordingFlg(false)}
					onMouseLeave={() => setRecordingFlg(false)}
					title={translate('formSendAudioSpr_microphoneTitle')}>
					<FontAwesomeIcon icon={faMicrophone} size="2x" color={status === 'recording' ? colorRed : backgroundColor} fade={status === 'recording'} />
				</div>

				<label htmlFor='fileSpr' className={styles.formLabelFile}>
					<div className={cn(styles.formLabelFileName, {
						[styles.formLabelFileNameNotActive]: recognitionData.status === RequestStatus.LOADING || recognitionDataWebSocket.webSocketStatus !== 3
					})} title={file?.name}>
						{file ? file.name : translate('formSendAudioSpr_inputFile')}
					</div>
					<div className={cn(styles.formLabelFileIcon, {
						[styles.formLabelFileIconNotActive]: recognitionData.status === RequestStatus.LOADING || recognitionDataWebSocket.webSocketStatus !== 3
					})} title={translate('formSendAudioSpr_inputFile')}>
						<FontAwesomeIcon icon={faEllipsis} color={backgroundColor} size="2xl" />
					</div>
				</label>
			</div>

			<FormControl fullWidth margin='dense'>
				{(recognitionData.status === RequestStatus.LOADING || recognitionDataWebSocket.webSocketStatus !== 3) ?
					<Button variant="outlined" disabled sx={{ fontSize: 11, overflow: 'hidden' }}>
						{translate('formSendAudioSpr_recognitionBtn')}
						<ProgressCircle isBtnDisabled />
					</Button>
					:
					<Button
						variant="outlined"
						sx={{ fontSize: 11, overflow: 'hidden' }}
						id='recognition'
						disabled={!file}
						onClick={() => file && submitHandler(file, 'async')}
					>
						{translate('formSendAudioSpr_recognizeBtn')}
					</Button>
				}
			</FormControl>

			{/* <Fade in={fullModel.status !== RequestStatus.LOADING && fullModel.fullModel?.[fullModel.activeType] !== null} timeout={500}>
				<div className={cn(styles.infoFormWebSocket, {
					[styles.shadowFuture]: fullModel.activeType === 'future',
					[styles.shadowCurrent]: fullModel.activeType === 'current',
				})}>
					<FormSendAudioByWebSocket />
				</div>
			</Fade> */}
		</form>
	);
};

export default FormSendAudioSpr;
