import { FormEvent, useEffect, useState } from 'react';
import { Button, Fade, FormControl, Slide } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faPen, faTrashCan, faXmark } from '@fortawesome/free-solid-svg-icons';
import cn from 'classnames';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { clearDatasetAdd, clearDatasetDelete, clearDatasetEdit, clearDatasetsList, deleteDataset, editDataset, getDatasetsList, selectDatasetAddStatus, selectDatasetDeleteStatus, selectDatasetEditStatus, selectDatasetsList } from '../../store/authSlice';
import useAccessRight from '../../hooks/useAccessRight';
import useTranslate from '../../hooks/useTranslate';
import { DATASET } from '../../constants/accessRights';
import { colorGreen, colorPrimary, colorRed } from '../../constants/colors';
import { IDataset } from '../../types/authTypes';
import { RequestStatus, ResponseStatus } from '../../types/statusTypes';
import FormAddingDataset from '../Forms/FormAddingDataset/FormAddingDataset';
import ScreenLock from '../ScreenLock/ScreenLock';
import AlertDialog from '../AlertDialog/AlertDialog';
import Notification from '../Notification/Notification';
import ProgressCircle from '../ProgressCircle/ProgressCircle';
import { IDatasetsProps } from './Datasets.props';
import styles from './Datasets.module.scss';

const Datasets = ({ showDatasets, setShowDatasets }: IDatasetsProps): JSX.Element => {
	const [showModalAddDataset, setShowModalAddDataset] = useState<boolean>(false); // показ формы добавления набора данных
	const [inputRenameDataset, setInputRenameDataset] = useState<string>(''); // имя набора данных для переименования
	const [renameDatasetFlg, setRenameDatasetFlg] = useState<{ isOpen: boolean, id: null | string }>({ isOpen: false, id: null }); // флаг и индекс для формы переименования набора данных
	const [selectedDatasetToBeRemoved, setSelectedDatasetToBeRemoved] = useState<IDataset>({ id: '', name: '' }); // выбранный набор данных для удаления
	const [noticeExistingDataset, setNoticeExistingDataset] = useState<boolean>(false); // уведомление о существующем наборе данных
	const [showAlertDialogDelDataset, setShowAlertDialogDelDataset] = useState<boolean>(false); // показ диалогового окна при удалении набора данных
	const [showNotificationAdd, setShowNotificationAdd] = useState<boolean>(false); // показ уведомления о добавлении набора данных
	const [showNotificationEdit, setShowNotificationEdit] = useState<boolean>(false); // показ уведомления об изменении набора данных
	const [showNotificationDelete, setShowNotificationDelete] = useState<boolean>(false); // показ уведомления об удалении набора данных
	const [showScreenLock, setShowScreenLock] = useState<{ isShow: boolean, title: string }>({ isShow: false, title: '' }); // показ экрана блокировки и подпись

	const dispatch = useAppDispatch();
	const datasetsList = useAppSelector(selectDatasetsList); // store - список наборов данных
	const addingDatasetStatus = useAppSelector(selectDatasetAddStatus); // store - статус добавления набора данных
	const editingDatasetStatus = useAppSelector(selectDatasetEditStatus); // store - статус изменения набора данных
	const deletingDatasetStatus = useAppSelector(selectDatasetDeleteStatus); // store - статус удаления набора данных

	const isAccess = useAccessRight(); // hook для проверки прав доступа
	const translate = useTranslate(); // hook для перевода текста

	// следим за статусом изменения/удаления набора данных
	useEffect(() => {
		// если идет изменение/удаление - включаем экран блокировки с подписью
		if (editingDatasetStatus.status === RequestStatus.LOADING) setShowScreenLock({ isShow: true, title: translate('screenLock_editDatasetTitle') });
		else if (deletingDatasetStatus.status === RequestStatus.LOADING) setShowScreenLock({ isShow: true, title: translate('screenLock_deleteDatasetTitle') });
		else setShowScreenLock({ isShow: false, title: '' }); // иначе выключаем

		// если изменилось или удалилось успешно 
		if ((editingDatasetStatus.status === RequestStatus.IDLE && editingDatasetStatus.error === ResponseStatus.SUCCESS && editingDatasetStatus.message !== '') || (deletingDatasetStatus.error === ResponseStatus.SUCCESS && deletingDatasetStatus.status === RequestStatus.IDLE && deletingDatasetStatus.message !== '')) {
			dispatch(clearDatasetsList()); // очищаем список наборов данных
			dispatch(getDatasetsList()); // получаем заново список наборов данных
		}
	}, [editingDatasetStatus, deletingDatasetStatus]);

	// обработчик нажатия кнопки переименования набора данных
	const renameDatasetHandler = (datasetName: string, datasetId: string): void => {
		setRenameDatasetFlg({ isOpen: true, id: datasetId }); // открываем форму для переименования
		setInputRenameDataset(datasetName); // записываем в input название набора данных
	};

	// обработчик нажатия клавишы мыши
	const mouseDownHandler = (e: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
		e.stopPropagation();
		// закрываем уведомления и очищаем статусы добавления/изменения/удаления набора данных, если были
		if (addingDatasetStatus.status === RequestStatus.FAILED || addingDatasetStatus.error === ResponseStatus.FAILED || addingDatasetStatus.message !== '') {
			dispatch(clearDatasetAdd());
			setShowNotificationAdd(false);
		}
		if (editingDatasetStatus.status === RequestStatus.FAILED || editingDatasetStatus.error === ResponseStatus.FAILED || editingDatasetStatus.message !== '') {
			dispatch(clearDatasetEdit());
			setShowNotificationEdit(false);
		}
		if (deletingDatasetStatus.status === RequestStatus.FAILED || deletingDatasetStatus.error === ResponseStatus.FAILED || deletingDatasetStatus.message !== '') {
			dispatch(clearDatasetDelete());
			setShowNotificationDelete(false);
		}
	};

	// обработчик нажатия клавиши в поле переименования набора данных
	const keyDownRenameDatasetHandler = (e: React.KeyboardEvent<HTMLInputElement>): void => {
		if (e.code === 'Escape') setRenameDatasetFlg({ isOpen: false, id: null });
	};

	// отправка формы переименования набора данных
	const submitRenameDataset = (e: FormEvent<HTMLFormElement>): void => {
		e.preventDefault();
		// если имя не поменялось - закрываем форму
		if (datasetsList.data.find(dataset => dataset.id === renameDatasetFlg.id)?.name === inputRenameDataset) {
			setRenameDatasetFlg({ isOpen: false, id: null });
		}
		// если переименовываем на существующий набор данных - включаем предупреждение
		else if (datasetsList.data.find(dataset => dataset.name === inputRenameDataset)) {
			setNoticeExistingDataset(true);
			setTimeout(() => {
				setNoticeExistingDataset(false);
			}, 5000); // выключаем через 5сек
		}
		else {
			setShowNotificationEdit(true); // включаем уведомление
			renameDatasetFlg.id && dispatch(editDataset({ datasetId: renameDatasetFlg.id, datasetName: inputRenameDataset })); // изменяем набор данных
			setRenameDatasetFlg({ isOpen: false, id: null }); // закрываем форму переименования набора данных
		}
	};

	// обработчик удаления набора данных
	const deleteDatasetHandler = () => {
		setShowAlertDialogDelDataset(false); // закрываем диалоговое окно
		setShowNotificationDelete(true); // включаем уведомление
		dispatch(deleteDataset(selectedDatasetToBeRemoved.id)); // удаляем набор данных
	};

	// обработчик закрытия вкладки
	const closeHandler = (): void => {
		setShowDatasets(false);
		renameDatasetFlg.isOpen && setRenameDatasetFlg({ isOpen: false, id: null }); // закрываем форму переименования
	};

	return (
		<Slide direction="left" in={showDatasets} mountOnEnter unmountOnExit>
			<div className={styles.modal} onMouseDown={closeHandler}>
				<div className={styles.datasets} onMouseDown={(e) => mouseDownHandler(e)}>
					<div className={styles.datasetsInner}>
						<h3 className={styles.datasetsHeader}>{translate('datasets_header')}</h3>

						{/* загрузка списка наборов данных */}
						{datasetsList.status === RequestStatus.LOADING &&
							<div className={styles.loading}>
								<ProgressCircle title={translate('progressCircle_datasetsListLoadingTitle')} />
							</div>
						}

						{/* ошибка получения списка наборов данных */}
						{(datasetsList.status === RequestStatus.FAILED || datasetsList.error === ResponseStatus.FAILED) &&
							<div className={styles.failed}>
								{translate(datasetsList.message || 'datasets_failedDatasetsList')}
							</div>
						}

						{/* пустой список */}
						{datasetsList.data.length === 0 && datasetsList.status === RequestStatus.IDLE && datasetsList.error === ResponseStatus.SUCCESS &&
							<div className={styles.empty}>
								{translate('datasets_emptyDatasetsList')}
							</div>
						}

						{/* список наборов данных */}
						{datasetsList.data.length > 0 &&
							<Fade in={true} timeout={500}>
								<div className={styles.datasetsList}>
									<ul>
										{datasetsList.data.map((dataset) => (
											<li className={styles.datasetsItem} key={dataset.id}>
												{renameDatasetFlg.isOpen && renameDatasetFlg.id === dataset.id ?
													<form className={styles.renameForm} onSubmit={e => submitRenameDataset(e)}>
														<input
															className={cn(styles.renameFormInput, {
																[styles.renameFormInputWarn]: noticeExistingDataset,
															})}
															autoFocus
															value={inputRenameDataset}
															onChange={e => setInputRenameDataset(e.target.value)}
															onKeyDown={(e) => keyDownRenameDatasetHandler(e)}
														/>
														<button className={styles.renameFormBtn} type="submit" title={translate('datasets_renameBtn')}>
															<FontAwesomeIcon icon={faCheck} style={{ cursor: 'pointer' }} color={colorGreen} size="lg" />
														</button>
														<button className={styles.renameFormBtn} type="reset" title={translate('datasets_cancelBtn')}>
															<FontAwesomeIcon icon={faXmark} style={{ cursor: 'pointer' }} color={colorRed} size="lg" onClick={() => setRenameDatasetFlg({ isOpen: false, id: null })} />
														</button>
													</form>
													:
													<>
														<p className={styles.datasetsItemName} title={dataset.name}>
															{dataset.name}
														</p>
														<div className={styles.datasetsItemEdit}>
															{isAccess(DATASET.EDIT) &&
																<FontAwesomeIcon
																	icon={faPen}
																	size="1x"
																	color={colorPrimary}
																	onClick={() => renameDatasetHandler(dataset.name, dataset.id)}
																	title={translate('datasets_renameBtn')}
																	style={{ cursor: 'pointer' }}
																/>
															}
															{isAccess(DATASET.DELETE) &&
																<FontAwesomeIcon
																	icon={faTrashCan}
																	size="1x"
																	color={colorRed}
																	onClick={() => {
																		setShowAlertDialogDelDataset(true);
																		setSelectedDatasetToBeRemoved(dataset);
																	}}
																	title={translate('datasets_deleteBtn')}
																	style={{ cursor: 'pointer' }}
																/>
															}
														</div>
													</>
												}
											</li>
										))}
									</ul>
									{isAccess(DATASET.ADD) &&
										<FormControl fullWidth margin='dense'>
											<Button
												variant="outlined"
												sx={{ fontSize: 11 }}
												onClick={() => setShowModalAddDataset(true)}
											>
												{translate('datasets_addBtn')}
											</Button>
										</FormControl>
									}
								</div>
							</Fade>
						}

						{showModalAddDataset && <FormAddingDataset showModal={showModalAddDataset} setShowModal={setShowModalAddDataset} setShowNotification={setShowNotificationAdd} />}

						<AlertDialog
							showAlertDialog={showAlertDialogDelDataset}
							setShowAlertDialog={setShowAlertDialogDelDataset}
							submitHandler={deleteDatasetHandler}
							title='dialog_deleteDatasetTitle'
							description='dialog_deleteDatasetConfirm'
							name={selectedDatasetToBeRemoved.name}
						/>

						{showScreenLock.isShow && <ScreenLock title={translate(showScreenLock.title)} />}

						{showNotificationAdd && <Notification showNotification={showNotificationAdd} setShowNotification={setShowNotificationAdd} selectDataResponse={selectDatasetAddStatus} clearDataResponse={clearDatasetAdd} titleFailed='noticeAddDataset_failed' titleSuccess='noticeAddDataset_success' />}

						{showNotificationEdit && <Notification showNotification={showNotificationEdit} setShowNotification={setShowNotificationEdit} selectDataResponse={selectDatasetEditStatus} clearDataResponse={clearDatasetEdit} titleFailed='noticeEditDataset_failed' titleSuccess='noticeEditDataset_success' />}

						{showNotificationDelete && <Notification showNotification={showNotificationDelete} setShowNotification={setShowNotificationDelete} selectDataResponse={selectDatasetDeleteStatus} clearDataResponse={clearDatasetDelete} titleFailed='noticeDeleteDataset_failed' titleSuccess='noticeDeleteDataset_success' />}

						<div className={styles.tagClose} onClick={closeHandler}>
							{translate('tag_close')}
						</div>
					</div>
				</div>
			</div>
		</Slide>
	);
};

export default Datasets;
