import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios, { AxiosResponse } from 'axios';
import { RootState } from './store';
import { ENDPOINTS_LANG, URL_LANG } from '../constants/api_endpoints';
import { ILangState, LangType } from '../types/langTypes';
import { RequestStatus } from '../types/statusTypes';

const initialState: ILangState = {
  translate: null,
  status: RequestStatus.IDLE,
  wallpaper: {
    status: RequestStatus.IDLE,
    dataUrl: null,
  },
};

// получение словаря для перевода текста
export const getTranslate = createAsyncThunk(
  'lang/getTranslate',
  async (): Promise<LangType> => {
    const response: AxiosResponse<LangType> = await axios.get(URL_LANG);
    return response.data;
  }
);

// получение случайных обоев
export const getRandomWallpaper = createAsyncThunk(
  'lang/getRandomWallpaper',
  async (): Promise<Blob> => {
    const response: AxiosResponse<Blob> = await axios.get(`${URL_LANG}/${ENDPOINTS_LANG.WALLPAPER}`, {
      responseType: 'blob',
      // headers: {
      // 'Cache-Control': 'no-cache, no-store, must-revalidate',
      // 'Cache-Control': 'public, max-age=10',
      // },
    });
    return response.data;
  }
);

const langSlice = createSlice({
  name: 'lang',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getTranslate.pending, (state) => {
        state.status = RequestStatus.LOADING;
      })
      .addCase(getTranslate.fulfilled, (state, action) => {
        state.status = RequestStatus.IDLE;
        state.translate = action.payload;
      })
      .addCase(getTranslate.rejected, (state) => {
        state.status = RequestStatus.FAILED;
      })
      .addCase(getRandomWallpaper.pending, (state) => {
        state.wallpaper.status = RequestStatus.LOADING;
      })
      .addCase(getRandomWallpaper.fulfilled, (state, action) => {
        if (action.payload instanceof Blob && action.payload.type.includes('image')) {
          state.wallpaper.status = RequestStatus.IDLE;
          const url = window.URL.createObjectURL(action.payload);
          state.wallpaper.dataUrl = url;
        } else state.wallpaper.status = RequestStatus.FAILED;
      })
      .addCase(getRandomWallpaper.rejected, (state) => {
        state.wallpaper.status = RequestStatus.FAILED;
      });
  },
});

export const selectTranslate = (state: RootState) => state.lang.translate;
export const selectTranslateStatus = (state: RootState) => state.lang.status;
export const selectWallpaper = (state: RootState) => state.lang.wallpaper;

export default langSlice.reducer;
