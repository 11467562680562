import { useEffect } from 'react';
import { Slide } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { clearAnalyze, selectAnalyze } from '../../store/sbsSlice';
import useTranslate from '../../hooks/useTranslate';
import { RequestStatus, ResponseStatus } from '../../types/statusTypes';
import FormSendAudio from '../Forms/FormSendAudio/FormSendAudio';
import ProgressCircle from '../ProgressCircle/ProgressCircle';
import { IAnalysisProps } from './Analysis.props';
import styles from './Analysis.module.scss';

const Analysis = ({ showAnalysis, setShowAnalysis }: IAnalysisProps): JSX.Element => {
	const dispatch = useAppDispatch();
	const analyzeData = useAppSelector(selectAnalyze); // store - анализ речи

	const translate = useTranslate(); // hook для перевода текста

	// следим за данными анализа и очищаем при открытии/закрытии вкладки
	useEffect(() => {
		(analyzeData.data || analyzeData.status === RequestStatus.FAILED) && dispatch(clearAnalyze());
	}, [showAnalysis]);

	// обработчик закрытия вкладки
	const closeHandler = (): void => {
		setShowAnalysis(false);
	};

	return (
		<Slide direction="left" in={showAnalysis} mountOnEnter unmountOnExit>
			<div className={styles.modal} onMouseDown={closeHandler}>
				<div className={styles.analysis} onMouseDown={e => e.stopPropagation()}>
					<h3 className={styles.analysisTitle}>{translate('analysis_title')}</h3>

					<FormSendAudio formFor='analysis' />

					<div className={styles.analysisResult}>
						{analyzeData.status === RequestStatus.LOADING &&
							<div className={styles.loading}><ProgressCircle title={translate('progressCircle_analysisTitle')} /></div>
						}
						{analyzeData.status === RequestStatus.FAILED &&
							<p className={styles.failedText}>{translate('analysis_failedTitle')}</p>
						}
						{analyzeData.data !== null &&
							<>
								{typeof analyzeData.data !== 'string' && 'age' in analyzeData.data && 'emotion' in analyzeData.data && 'gender' in analyzeData.data &&
									<div>
										<div className={styles.analysisResultPair}>
											<p>
												<span className={styles.analysisResultKey}>{translate('analysis_ageTitle')}: </span>{analyzeData.data.age.class}
											</p>
											<p>
												<span className={styles.analysisResultKey}>{translate('confidence')}: </span>{analyzeData.data.age.confidence.toFixed(3)}
											</p>
										</div>
										<div className={styles.analysisResultPair}>
											<p>
												<span className={styles.analysisResultKey}>{translate('analysis_emotionTitle')}: </span>{translate(analyzeData.data.emotion.class)}
											</p>
											<p>
												<span className={styles.analysisResultKey}>{translate('confidence')}: </span>{analyzeData.data.emotion.confidence.toFixed(3)}
											</p>
										</div>
										<div className={styles.analysisResultPair}>
											<p>
												<span className={styles.analysisResultKey}>{translate('analysis_genderTitle')}: </span>{translate(analyzeData.data.gender.class)}
											</p>
											<p>
												<span className={styles.analysisResultKey}>{translate('confidence')}: </span>{analyzeData.data.gender.confidence.toFixed(3)}
											</p>
										</div>
									</div>
								}
								{typeof analyzeData.data !== 'string' && 'error' in analyzeData.data && 'message' in analyzeData.data && analyzeData.data.error === ResponseStatus.FAILED &&
									<p className={styles.notFoundText}>{translate(analyzeData.data.message || 'analysis_failedTitle')}</p>
								}
							</>
						}
					</div>
				</div>

				<div className={styles.tagClose} onClick={closeHandler}>
					{translate('tag_close')}
				</div>
			</div>
		</Slide>
	);
};

export default Analysis;
