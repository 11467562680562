import { memo, useEffect, useState } from 'react';
import { Button, ButtonGroup, FormControl, InputLabel, MenuItem, Select, Slide, TextField } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { addAlias, clearQuestionManipulation, moveCandidatesToAliases, selectAppendAliasList, selectCategoriesList, selectQuestion } from '../../store/qasSlice';
import useAccessRight from '../../hooks/useAccessRight';
import useTranslate from '../../hooks/useTranslate';
import { QAS } from '../../constants/accessRights';
import { colorPrimary, colorSecondaryLight3 } from '../../constants/colors';
import { RequestStatus, ResponseStatus } from '../../types/statusTypes';
import AliasesList from './AliasesList/AliasesList';
import FormMoveAliases from '../Forms/FormMoveAliases/FormMoveAliases';
import Notification from '../Notification/Notification';
import { IQuestionParametersProps } from './QuestionParameters.props';
import styles from './QuestionParameters.module.scss';

const QuestionParameters = ({ showQuestionParams, setShowQuestionParams, changeFlg, setChangeFlg, inputQuestion, setInputQuestion, selectCategoryId, setSelectCategoryId, foundQuestion, filterByQuestion, setShowAlertDialogSave, setShowAlertDialogDelete }: IQuestionParametersProps): JSX.Element => {
	const [visibleAliasBlock, setVisibleAliasBlock] = useState<'aliases' | 'candidates'>('aliases'); // отображение алиасов/кандитатов
	const [selectedListAliasIdx, setSelectedListAliasIdx] = useState<number[]>([]); // список индексов выбранных алиасов
	const [selectedListCandidateIdx, setSelectedListCandidateIdx] = useState<number[]>([]); // список индексов выбранных кандидатов
	const [showFormMoveAliases, setShowFormMoveAliases] = useState<boolean>(false); // показ формы перемещения алиасов
	const [showNotificationMoveAliases, setShowNotificationMoveAliases] = useState<boolean>(false); // показ уведомления перемещения алиасов

	const dispatch = useAppDispatch();
	const question = useAppSelector(selectQuestion); // store - вопрос/ответ
	const categoriesList = useAppSelector(selectCategoriesList); // store - список категорий
	const appendAliasListStatus = useAppSelector(selectAppendAliasList); // store - статус добавления алиасов

	const isAccess = useAccessRight(); // hook для проверки прав доступа
	const translate = useTranslate(); // hook для перевода текста

	// следим за вкладкой
	useEffect(() => {
		// при закрыти
		if (!showQuestionParams) {
			setSelectedListAliasIdx([]); // очищаем выбранные алиасы
			setSelectedListCandidateIdx([]); // очищаем выбранные кандидаты
			setVisibleAliasBlock('aliases'); // сбрасываем tab
		}
	}, [showQuestionParams]);

	// следим за вопросом и заполняем поля вопроса и категории
	useEffect(() => {
		if (foundQuestion) {
			setInputQuestion(foundQuestion.question);
			setSelectCategoryId(foundQuestion.category || '');
		}
	}, [foundQuestion]);

	// обработчик увода фокуса с поля с вопросом
	const blurHandler = (): void => {
		foundQuestion?.question !== inputQuestion && setChangeFlg(true); // ставим флаг о несохраненных данных, если изменилось поле вопроса
	};

	// обработчик добавления алиаса
	const addAliasHandler = (): void => {
		// если последний алиас не пустой
		if (question.aliases[question.aliases.length - 1] !== '') {
			dispatch(addAlias()); // добавляем алиас
			setChangeFlg(true); // ставим флаг о несохраненных данных
		}
	};

	// обработчик перемещения кандидатов в алиасы текущего вопроса
	const movingCandidatesToAliasesHandler = (): void => {
		dispatch(moveCandidatesToAliases(selectedListCandidateIdx)); // перемещаем выбранные кандидаты в алиасы текущего вопроса
		setSelectedListCandidateIdx([]); // очищаем список выбранных кандидатов
		setChangeFlg(true); // ставим флаг о несохраненных данных
	};

	// обработчик нажатия мыши по вкладке
	const mouseDownHandler = (e: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
		e.stopPropagation();
		// если были изменения в статусе добавления алиасов
		if (appendAliasListStatus.error === ResponseStatus.FAILED || appendAliasListStatus.status === RequestStatus.FAILED || appendAliasListStatus.message !== '') {
			setShowNotificationMoveAliases(false); // выключаем уведомление
			dispatch(clearQuestionManipulation()); // очищаем данные и статусы перемещения кандидатов
		}
	};

	// обработчик закрытия вкладки
	const closeHandler = (): void => {
		setShowQuestionParams(false);
	};

	return (
		<Slide direction="left" in={showQuestionParams} mountOnEnter unmountOnExit>
			<div className={styles.modal} onMouseDown={closeHandler}>
				<div className={styles.question} onMouseDown={mouseDownHandler}>
					<div className={styles.questionInner}>
						{/* категория */}
						<FormControl fullWidth sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 }, padding: '0 16px' }}>
							<InputLabel sx={{ fontSize: 12, paddingLeft: '20px' }}>{translate('questionParameters_inputCategory')}</InputLabel>
							<Select
								label={translate('questionParameters_inputCategory')}
								disabled={!isAccess(QAS.QUESTION_EDIT)}
								value={selectCategoryId}
								onChange={(e) => { setSelectCategoryId(e.target.value); setChangeFlg(true); }}
								style={{ fontSize: 12, height: 33, color: colorPrimary }}
							>
								{/* на случай, если не найдена категория */}
								{(categoriesList.data.length === 0 || !categoriesList.data.find(categoryItem => categoryItem.id === foundQuestion?.category)) &&
									<MenuItem key={selectCategoryId} value={selectCategoryId} sx={{ fontSize: 12, color: colorPrimary }}>{selectCategoryId} ({translate('notFound')})</MenuItem>
								}
								{categoriesList.data.map((category) => (
									<MenuItem key={category.id} value={category.id} sx={{ fontSize: 12, color: colorPrimary }}>{category.name}</MenuItem>
								))}
							</Select>
						</FormControl>
						{/* основной вопрос */}
						<FormControl fullWidth sx={{ padding: '0 16px' }}>
							<TextField
								variant="outlined"
								multiline
								maxRows={3}
								label={translate('questionParameters_inputMainQuestion')}
								value={inputQuestion}
								onChange={(e) => setInputQuestion(e.target.value)}
								onBlur={blurHandler}
								disabled={!isAccess(QAS.QUESTION_EDIT)}
								InputProps={{
									style: {
										padding: 8,
										fontSize: 12,
										color: colorPrimary,
										backgroundColor: (inputQuestion.toLowerCase().includes(filterByQuestion.toLowerCase()) && filterByQuestion !== '') ? colorSecondaryLight3 : undefined,
									},
								}}
								InputLabelProps={{
									style: {
										fontSize: 12,
									},
								}}
								sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}
							/>
						</FormControl>

						{/* табы алиасы/кандидаты */}
						<ButtonGroup fullWidth sx={{ padding: '0px 16px' }}>
							<Button
								variant={visibleAliasBlock === 'aliases' ? "contained" : "outlined"}
								sx={{ width: '51%', overflow: 'hidden', fontSize: 11 }}
								onClick={() => setVisibleAliasBlock('aliases')}
							>
								{translate('questionParameters_tabAliases')} ({question.aliases.length})
							</Button>
							<Button
								variant={visibleAliasBlock === 'candidates' ? "contained" : "outlined"}
								sx={{ width: '51%', overflow: 'hidden', fontSize: 11 }}
								onClick={() => setVisibleAliasBlock('candidates')}
							>
								{translate('questionParameters_tabCandidates')} ({question.candidates.length})
							</Button>
						</ButtonGroup>

						{visibleAliasBlock === 'aliases' &&
							<AliasesList
								filterByQuestion={filterByQuestion}
								setChangeFlg={setChangeFlg}
								setInputQuestion={setInputQuestion}
								selectedListAliasIdx={selectedListAliasIdx}
								setSelectedListAliasIdx={setSelectedListAliasIdx}
								listType='aliases'
							/>
						}

						{visibleAliasBlock === 'candidates' &&
							<AliasesList
								filterByQuestion={filterByQuestion}
								setChangeFlg={setChangeFlg}
								setInputQuestion={setInputQuestion}
								selectedListAliasIdx={selectedListCandidateIdx}
								setSelectedListAliasIdx={setSelectedListCandidateIdx}
								listType='candidates'
							/>
						}

						{isAccess(QAS.QUESTION_EDIT) &&
							<div className={styles.questionButtons}>
								{visibleAliasBlock === 'aliases' &&
									<>
										{/* добавление алиасов */}
										<FormControl fullWidth>
											<Button
												variant="outlined"
												sx={{ fontSize: 11 }}
												disabled={question.aliases[question.aliases.length - 1] === ''}
												onClick={addAliasHandler}
											>
												{translate('questionParameters_addAliasQuestionBtn')}
											</Button>
										</FormControl>
										{/* перемещение алиасов в другой вопрос */}
										{isAccess(QAS.QUESTION_APPEND) && question.aliases.length > 0 &&
											<FormControl fullWidth>
												<Button
													variant="outlined"
													sx={{ fontSize: 11 }}
													disabled={selectedListAliasIdx.length === 0}
													onClick={() => setShowFormMoveAliases(true)}
												>
													{translate('questionParameters_moveAliasBtn')}
												</Button>
											</FormControl>
										}
									</>
								}
								{visibleAliasBlock === 'candidates' && question.candidates.length > 0 &&
									<>
										{/* перемещение кандидатов в алиасы */}
										<FormControl fullWidth>
											<Button
												variant="outlined"
												sx={{ fontSize: 11 }}
												disabled={selectedListCandidateIdx.length === 0}
												onClick={movingCandidatesToAliasesHandler}
											>
												{translate('questionParameters_approveBtn')}
											</Button>
										</FormControl>
										{/* перемещение кандидатов в другой вопрос*/}
										{isAccess(QAS.QUESTION_APPEND) &&
											<FormControl fullWidth>
												<Button
													variant="outlined"
													sx={{ fontSize: 11 }}
													disabled={selectedListCandidateIdx.length === 0}
													onClick={() => setShowFormMoveAliases(true)}
												>
													{translate('questionParameters_moveAliasBtn')}
												</Button>
											</FormControl>
										}
									</>
								}
							</div>
						}
					</div>

					<div className={styles.questionButtons}>
						{/* сохранение изменений */}
						{isAccess(QAS.QUESTION_EDIT) &&
							<FormControl fullWidth>
								<Button
									variant="outlined"
									sx={{ fontSize: 11 }}
									disabled={!changeFlg}
									onClick={() => setShowAlertDialogSave(true)}
								>
									{translate('questionParameters_saveBtn')}
								</Button>
							</FormControl>
						}
						{/* удаление вопроса */}
						{isAccess(QAS.QUESTION_DELETE) &&
							<FormControl fullWidth>
								<Button
									variant="outlined"
									sx={{ fontSize: 11 }}
									onClick={() => setShowAlertDialogDelete(true)}
									color='error'
								>
									{translate('questionParameters_questionBtn')}
								</Button>
							</FormControl>
						}
					</div>

					{/* форма перемещения алиасов/кандидитов */}
					{showFormMoveAliases &&
						<FormMoveAliases
							showModal={showFormMoveAliases}
							setShowModal={setShowFormMoveAliases}
							selectedAliasIdxList={visibleAliasBlock === 'aliases' ? selectedListAliasIdx : selectedListCandidateIdx}
							setSelectedListAliasIdx={visibleAliasBlock === 'aliases' ? setSelectedListAliasIdx : setSelectedListCandidateIdx}
							setChangeFlg={setChangeFlg}
							setShowNotificationMoveAliases={setShowNotificationMoveAliases}
							listType={visibleAliasBlock}
						/>
					}

					{/* уведомление для перемещения алиасов/кандидитов в другой вопрос */}
					{showNotificationMoveAliases &&
						<Notification
							showNotification={showNotificationMoveAliases}
							setShowNotification={setShowNotificationMoveAliases}
							selectDataResponse={selectAppendAliasList}
							clearDataResponse={clearQuestionManipulation}
							titleFailed='noticeMoveAlias_failed'
							titleSuccess='noticeMoveAlias_success'
						/>
					}
				</div>

				<div className={styles.tagClose} onClick={closeHandler}>
					{translate('tag_close')}
				</div>
			</div>
		</Slide>
	);
};

export default memo(QuestionParameters);
