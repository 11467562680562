import { useEffect, useRef } from 'react';
import { Fade, Slide } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { clearLogs, getLogsModel, selectFullModel, selectLogsModel } from '../../store/modelSlice';
import useTranslate from '../../hooks/useTranslate';
import { RequestStatus, ResponseStatus } from '../../types/statusTypes';
import ProgressCircle from '../ProgressCircle/ProgressCircle';
import { ILogProps } from './Log.props';
import styles from './Log.module.scss';

const Log = ({ showLog, setShowLog, serviceType }: ILogProps): JSX.Element => {
	const dispatch = useAppDispatch();
	const fullModel = useAppSelector(selectFullModel); // store - модель
	const logs = useAppSelector(selectLogsModel); // store - логи модели
	const listRef = useRef<HTMLUListElement | null>(null); // ссылка на список логов
	const translate = useTranslate(); // hook для перевода текста

	// следим за показом вкладки логов
	useEffect(() => {
		((Array.isArray(logs.logs) && logs.logs.length > 0) || !Array.isArray(logs.logs)) && dispatch(clearLogs()); // если есть данные логов - очищаем логи
		showLog && fullModel.modelName && dispatch(getLogsModel({ modelName: fullModel.modelName, modelType: fullModel.activeType, serviceType })); // если вкладка логов открыта - получаем логи модели
		// автозапрос на получение логов модели каждые 10 сек
		const interval = setInterval(() => {
			fullModel.modelName && dispatch(getLogsModel({ modelName: fullModel.modelName, modelType: fullModel.activeType, serviceType }));
		}, 10000);
		showLog === false && clearInterval(interval); // если вкладка логов закрыта - удаляем автозапрос на получение логов
		!fullModel.training && !fullModel.testing && clearInterval(interval); // если нет обучения или тестирования - удаляем автозапрос на получение логов

		return () => {
			clearInterval(interval); // при выходе со страницы - удаляем автозапрос на получение логов
		};
	}, [showLog]);

	// следим за логами и скроллим вниз, если идет обучение или тестирование
	useEffect(() => {
		(fullModel.training || fullModel.testing) && Array.isArray(logs.logs) && listRef.current?.children[logs.logs.length - 1]?.scrollIntoView({ block: "start", behavior: 'smooth' });
	}, [logs.logs]);

	return (
		<Slide direction="left" in={showLog} mountOnEnter unmountOnExit>
			<div className={styles.modal} onMouseDown={() => setShowLog(false)}>
				<div className={styles.consoleWrapper} onMouseDown={(e) => e.stopPropagation()}>
					{logs.status === RequestStatus.LOADING && Array.isArray(logs.logs) && logs.logs.length === 0 && <div className={styles.loading}><ProgressCircle title={translate('progressCircle_logLoadingTitle')} /></div>}
					<div className={styles.console}>
						{logs.status === RequestStatus.IDLE && (Array.isArray(logs.logs) && logs.logs.length === 0) && <div className={styles.noData}>{translate('noData')}</div>}
						{logs.status === RequestStatus.IDLE && !Array.isArray(logs.logs) && logs.logs.error === ResponseStatus.FAILED && <div className={styles.noData}>{translate(logs.logs.message || 'loadFailed')}</div>}
						{logs.status === RequestStatus.FAILED && <div className={styles.failed}>{translate('loadFailed')}</div>}
						{Array.isArray(logs.logs) &&
							<ul ref={listRef}>
								{logs.logs.map((log, idx) => (
									<Fade in={logs.status !== RequestStatus.FAILED} key={idx} timeout={500}>
										<li><pre>-- {log}</pre></li>
									</Fade>
								))}
							</ul>
						}
					</div>
					<div className={styles.tagClose} onClick={() => setShowLog(false)}>
						{translate('tag_close')}
					</div>
				</div>
			</div>
		</Slide>
	);
};

export default Log;
