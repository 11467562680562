import { useEffect, useState } from 'react';
import { FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import cn from 'classnames';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import useAccessRight from '../../../hooks/useAccessRight';
import useTranslate from '../../../hooks/useTranslate';
import { addingRobotException } from '../../../store/sesRobotSlice';
import { selectScriptList } from '../../../store/sesScriptSlice';
import { ACTION_EXTENDED_LIST, ACTION_TYPE_EXTENDED_LIST, ActionExtended, ActionTypeExtended, SERVICE_TYPE_EXTENDED_LIST, ServiceTypeExtended } from '../../../constants/robotConfigLists';
import { SES } from '../../../constants/accessRights';
import { colorPrimary } from '../../../constants/colors';
import { ActionVariantExceptionType } from '../../../types/sesRobotTypes';
import { IFormAddingExceptionProps } from './FormAddingException.props';
import styles from './FormAddingException.module.scss';

const FormAddingException = ({ exception, setChangeFlg }: IFormAddingExceptionProps): JSX.Element => {
	const [maxNumberExceptions, setMaxNumberExceptions] = useState<number>(0); // максимальное количество исключений подряд
	const [selectAction, setSelectAction] = useState<ActionExtended>('chooseAction'); // действие
	const [selectActionType, setSelectActionType] = useState<ActionTypeExtended>('chooseActionType'); // тип действия
	// const [inputPhrase, setInputPhrase] = useState<string>(''); // фраза
	const [selectScript, setSelectScript] = useState<string>('chooseScript'); // скрипт
	const [selectService, setSelectService] = useState<ServiceTypeExtended>('chooseService'); // сервис

	const dispatch = useAppDispatch();
	const scriptList = useAppSelector(selectScriptList); // store - список скриптов

	const isAccess = useAccessRight(); // hook для проверки прав доступа
	const translate = useTranslate(); // hook для перевода текста

	// следим за исключением и сбрасываем поля
	useEffect(() => {
		resetInputAndSelect();
	}, [exception]);

	// следим за действием, типом действия, скриптом и сервисом, и создаем и передаем объект действия в функцию добавления
	useEffect(() => {
		if (selectAction === 'end') {
			addActionHandler({
				max: maxNumberExceptions,
				action: selectAction,
			});
		} else if (selectAction === 'say' && (selectActionType === 'linear' || selectActionType === 'random')) {
			addActionHandler({
				max: maxNumberExceptions,
				action: selectAction,
				type: selectActionType,
				text: [''],
			});
		} else if (selectAction === 'say' && selectActionType === 'external' && selectScript !== 'chooseScript') {
			addActionHandler({
				max: maxNumberExceptions,
				action: selectAction,
				type: selectActionType,
				script: selectScript,
			});
		} else if (selectAction === 'say' && selectActionType === 'internal' && selectService !== 'chooseService') {
			addActionHandler({
				max: maxNumberExceptions,
				action: selectAction,
				type: selectActionType,
				service: selectService,
				categories: {},
				useCache: 'checked',
				useGPT: 'no',
				creative: 'no',
				addAlias: 'no',
			});
		}
	}, [selectAction, selectActionType, selectScript, selectService]);

	// следим за фразой
	// useEffect(() => {
	// 	// через 0.5 сек добавлем объект события в обработчик добавления
	// 	const timeout = setTimeout(() => {
	// 		if (selectAction === 'say' && (selectActionType === 'linear' || selectActionType === 'random') && inputPhrase !== '') {
	// 			addActionHandler({
	// 				max: maxNumberExceptions,
	// 				action: selectAction,
	// 				type: selectActionType,
	// 				text: [inputPhrase],
	// 			});
	// 		}
	// 	}, 500);

	// 	return () => {
	// 		clearTimeout(timeout);
	// 	};
	// }, [inputPhrase]);

	// функция сброса всех полей
	const resetInputAndSelect = (): void => {
		setMaxNumberExceptions(0);
		setSelectAction('chooseAction');
		setSelectActionType('chooseActionType');
		// setInputPhrase('');
		setSelectScript('chooseScript');
		setSelectService('chooseService');
	};

	// обработчик добавления действия исключения
	const addActionHandler = (actionObj: ActionVariantExceptionType): void => {
		dispatch(addingRobotException({ exception, actionObj }));
		setChangeFlg(true); // ставим флаг о несохраненных данных
		resetInputAndSelect(); // очищаем поля
	};

	return (
		<div className={cn(styles.container, {
			[styles.containerOpacity]: maxNumberExceptions === 0
		})}>
			{/* макс. кол-во исключений подряд */}
			<FormControl fullWidth margin='dense'>
				<TextField
					required
					label={translate('formAddingException_inputMaxException')}
					variant="outlined"
					type='number'
					value={maxNumberExceptions}
					onChange={(e) => setMaxNumberExceptions(+e.target.value)}
					InputProps={{
						style: {
							height: 33,
							fontSize: 13,
							color: colorPrimary,
						},
						inputProps: { min: 1 }
					}}
					InputLabelProps={{
						style: {
							fontSize: 13,
						},
					}}
					sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}
				/>
			</FormControl>

			<div className={styles.action}>
				{/* действие */}
				{maxNumberExceptions > 0 &&
					<FormControl fullWidth margin='dense' sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 }, opacity: selectAction === 'chooseAction' ? 0.5 : 1 }}>
						<InputLabel sx={{ fontSize: 13 }}>{translate('formAddingException_selectAction')}</InputLabel>
						<Select
							required
							label={translate('formAddingException_selectAction')}
							value={selectAction}
							onChange={(e) => setSelectAction(e.target.value as ActionExtended)}
							style={{ fontSize: 13, height: 33, color: colorPrimary }}
						>
							{ACTION_EXTENDED_LIST.map(({ action, translation }) =>
								<MenuItem key={action} value={action} sx={{ fontSize: 13, color: colorPrimary }} disabled={action === 'chooseAction'}>
									{translate(translation)}
								</MenuItem>
							)}
						</Select>
					</FormControl>
				}

				{/* тип действия */}
				{selectAction === 'say' &&
					<FormControl fullWidth margin='dense' sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 }, opacity: selectActionType === 'chooseActionType' ? 0.5 : 1 }}>
						<InputLabel sx={{ fontSize: 13 }}>{translate('formAddingException_selectActionType')}</InputLabel>
						<Select
							required
							label={translate('formAddingException_selectActionType')}
							value={selectActionType}
							onChange={(e) => setSelectActionType(e.target.value as ActionTypeExtended)}
							style={{ fontSize: 13, height: 33, color: colorPrimary }}
						>
							{ACTION_TYPE_EXTENDED_LIST.map(({ actionType, translation }) =>
								<MenuItem key={actionType} value={actionType} disabled={(actionType === 'external' && !isAccess(SES.SCRIPT_LIST)) || actionType === 'chooseActionType'} sx={{ fontSize: 13, color: colorPrimary }}>
									{translate(translation)}
								</MenuItem>
							)}
						</Select>
					</FormControl>
				}
			</div>

			{/* фраза */}
			{/* {selectAction === 'say' && (selectActionType === 'linear' || selectActionType === 'random') &&
				<FormControl fullWidth margin='dense' sx={{ opacity: inputPhrase === '' ? 0.5 : 1 }}>
					<TextField
						required
						multiline
						maxRows={2}
						label={translate('formAddingException_inputPhrase')}
						variant="outlined"
						value={inputPhrase}
						onChange={e => setInputPhrase(e.target.value)}
						InputProps={{
							style: {
								padding: '8px 13px',
								fontSize: 13,
								color: colorPrimary,
							},
						}}
						InputLabelProps={{
							style: {
								fontSize: 13,
							},
						}}
						sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}
					/>
				</FormControl>
			} */}

			{/* скрипт */}
			{selectAction === 'say' && selectActionType === 'external' &&
				<FormControl fullWidth margin='dense' sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 }, opacity: selectScript === 'chooseScript' ? 0.5 : 1 }}>
					<InputLabel sx={{ fontSize: 13 }}>{translate('formAddingException_selectScript')}</InputLabel>
					<Select
						required
						label={translate('formAddingException_selectScript')}
						value={selectScript}
						onChange={(e) => setSelectScript(e.target.value)}
						style={{ fontSize: 13, height: 33, color: colorPrimary }}
					>
						<MenuItem value='chooseScript' sx={{ fontSize: 13, color: colorPrimary }} disabled>
							{translate('script_choose')}
						</MenuItem>
						{scriptList.data.map(({ id, name }) => (
							<MenuItem key={id} value={id} sx={{ fontSize: 13, color: colorPrimary }}>{name}</MenuItem>
						))}
					</Select>
				</FormControl>
			}

			{/* сервис */}
			{selectAction === 'say' && selectActionType === 'internal' &&
				<FormControl fullWidth margin='dense' sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 }, opacity: selectService === 'chooseService' ? 0.5 : 1 }}>
					<InputLabel sx={{ fontSize: 13 }}>{translate('formAddingException_selectService')}</InputLabel>
					<Select
						required
						label={translate('formAddingException_selectService')}
						value={selectService}
						onChange={(e) => setSelectService(e.target.value as ServiceTypeExtended)}
						style={{ fontSize: 13, height: 33, color: colorPrimary }}
					>
						{SERVICE_TYPE_EXTENDED_LIST.map(({ serviceType, translation }) =>
							<MenuItem key={serviceType} value={serviceType} sx={{ fontSize: 13, color: colorPrimary }} disabled={serviceType === 'chooseService'}>
								{translate(translation)}
							</MenuItem>
						)}
					</Select>
				</FormControl>
			}
		</div>
	);
};

export default FormAddingException;
