import { useAppSelector } from '../store/hooks';
import { selectAccessRights } from '../store/authSlice';
import { selectCurrentUserInfo } from '../store/userSlice';

// hook для проверки прав доступа
const useAccessRight = () => {
	const { data } = useAppSelector(selectAccessRights); // store - список всех прав доступа
	const { rights: userRights, userName } = useAppSelector(selectCurrentUserInfo); // store - права доступа текущего пользователя

	return (accessRights: string | string[]): boolean => {
		// если несколько обязательных прав доступа
		if (Array.isArray(accessRights)) {
			// ищем до первого отсутствия в правах пользователя и общедоступных правах
			const accessIsDenied = accessRights.find(right => !userRights.includes(right) && !data?.nopassword.includes(right));
			// если есть хотя бы одно отсутствие права или пользователь admin
			return !accessIsDenied || userName === 'admin';
		} else {
			// иначе если один - ищем совпадение в правах пользователя или общедоступных правах или это admin
			return userRights.includes(accessRights) || data?.nopassword.includes(accessRights) || userName === 'admin';
		}
	};
};

export default useAccessRight;
