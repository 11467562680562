// функция скачивания файла
export const downloadFile = (data: string | Blob, name: string): void => {
	let url: string = '';

	if (typeof data === 'string') {
		const file = new File([data], name);
		url = window.URL.createObjectURL(file);
	} else {
		url = window.URL.createObjectURL(data);
	}

	const link = document.createElement("a");
	link.href = url;
	link.setAttribute("download", name);
	document.body.appendChild(link);
	link.click();
	document.body.removeChild(link);
};
