import { memo, useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import Layer from './Layer/Layer';
import { ICloudImageProps } from './CloudImage.props';
import styles from './CloudImage.module.scss';

const CloudImage = ({ position }: ICloudImageProps): JSX.Element => {
	const [transformStyles, setTransformStyles] = useState<string[]>([]); // стили трансформации для базы облака
	const worldRef = useRef<HTMLDivElement>(null); // ссылка на контейнер облаков

	// следим за блоком world и создаем стили
	useEffect(() => {
		if (worldRef.current) {
			const width = worldRef.current.offsetWidth;
			const height = worldRef.current.offsetHeight;
			const count = (width * height / 9000) > 100 ? 100 : (width * height / 5000); // кол-во облаков исходя из площади блока, но не больше 100
			const arr: string[] = [];
			for (let index = 0; index < count; index++) {
				arr.push(getTransformStyle(width, height));
			}
			setTransformStyles(arr);
		}
	}, [worldRef]);

	// функция формирования стиля трансформации
	const getTransformStyle = (offsetWidth: number, offsetHeight: number): string => {
		const x = offsetWidth / 2 - (Math.random() * offsetWidth);
		const y = offsetHeight / 2 - (Math.random() * offsetHeight);
		const z = 100 - (Math.random() * 200);
		const t = 'translateX( ' + x + 'px ) translateY( ' + y + 'px ) translateZ( ' + z + 'px )';
		return t;
	};

	return (
		<div className={styles.viewport}>
			<div className={cn(styles.world, {
				[styles.worldLeft]: position === 'left',
				[styles.worldRight]: position === 'right',
			})} ref={worldRef}>
				{transformStyles.map((style, idx) => (
					<div className={cn(styles.cloudBase, {
						[styles.cloudBaseLeft]: position === 'left',
						[styles.cloudBaseRight]: position === 'right',
					})} style={{ transform: style }} key={style + idx}>
						{new Array(2).fill(1).map((item, idx) =>
							<Layer key={item + idx} />
						)}
					</div>
				))}
			</div>
		</div>
	);
};

export default memo(CloudImage);
