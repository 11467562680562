import axios, { AxiosResponse } from 'axios';
import { Cookies } from 'react-cookie';
import { ACCESS_TOKEN, REFRESH_TOKEN } from '../constants/cookieNames';
import { ENDPOINTS_AUTH, URL_AUTH } from '../constants/api_endpoints';
import { IRefreshTokenResponse, IResponse } from '../types/authTypes';

const removeCookieAndReload = (): void => {
	new Cookies().remove(ACCESS_TOKEN, { path: '/' });
	new Cookies().remove(REFRESH_TOKEN, { path: '/' });
	window.location.reload();
};

const axiosApi = axios.create();

// перехватчик запроса с добавлением токена в заголовке
axiosApi.interceptors.request.use(config => {
	config.headers['x-access-token'] = new Cookies().get(ACCESS_TOKEN);
	return config;
});

// перехватчик ответа
axiosApi.interceptors.response.use(
	config => {
		return config;
	},
	async (error) => {
		const originalRequest = error.config;
		// если ошибка 401 - обновляем токен
		if (error.response.status === 401 && error.config && !error.config._retry) {
			originalRequest._retry = true;
			try {
				const response: AxiosResponse<IRefreshTokenResponse | IResponse> = await axios.get(`${URL_AUTH}/${ENDPOINTS_AUTH.REFRESH}`, {
					headers: {
						'x-refresh-token': new Cookies().get(REFRESH_TOKEN),
					}
				});
				if (typeof response.data !== 'string') {
					if ('x-access-token' in response.data) {
						new Cookies().set(ACCESS_TOKEN, response.data['x-access-token'], { path: '/', maxAge: 86_400 }); // на сутки
						new Cookies().set(REFRESH_TOKEN, response.data['x-refresh-token'], { path: '/', maxAge: 2_592_000 }); // на месяц
					}
					if ('error' in response.data) {
						removeCookieAndReload();
					}
				} else {
					removeCookieAndReload();
				}
				return axiosApi.request(originalRequest);
			} catch (error) {
				console.error(error);
				removeCookieAndReload();
			}
		}
		throw error;
	},
);

export default axiosApi;
