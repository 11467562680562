import { Link } from 'react-router-dom';
import { Fade } from '@mui/material';
import useTranslate from '../../hooks/useTranslate';
import styles from './NotFound.module.scss';

const NotFound = (): JSX.Element => {
	const translate = useTranslate();

	return (
		<Fade in={true} timeout={500}>
			<div className={styles.pageNotFound}>
				<h3 className={styles.header}>{translate('pageNotFound')}</h3>
				<Link to='/' className={styles.linkToHome}>
					{translate('toHome')}
				</Link>
			</div>
		</Fade>
	);
};

export default NotFound;
