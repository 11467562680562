import { ChangeEvent, FormEvent, useEffect, useRef, useState } from 'react';
import { FormControl, TextField } from '@mui/material';
import { useReactMediaRecorder } from "react-media-recorder-2";
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsis, faMicrophone, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import useTranslate from '../../../hooks/useTranslate';
import { addSpeaker, analyze, clearAnalyze, clearSearchSpeaker, editSpeaker, searchSpeaker, selectAnalyze, selectSearchSpeaker } from '../../../store/sbsSlice';
import { backgroundColor, colorRed } from '../../../constants/colors';
import { RequestStatus } from '../../../types/statusTypes';
import { IFormSendAudioProps } from './FormSendAudio.props';
import styles from './FormSendAudio.module.scss';

const FormSendAudio = ({ formFor, speakerId }: IFormSendAudioProps): JSX.Element => {
	const [file, setFile] = useState<File>(); // для отправки файла
	const [recordingFlg, setRecordingFlg] = useState<boolean>(false); // флаг записи с микрофона
	const [speakerNameForAdd, setSpeakerNameForAdd] = useState<string>(''); // имя спикера для добавления
	const [threshold, setThreshold] = useState<number>(70); // порог достоверности для поиска спикера
	const [typeAudio, setTypeAudio] = useState<null | 'microphone' | 'file'>(null); // тип аудио для отправки
	const formRef = useRef<HTMLFormElement>(null);

	const { status, startRecording, stopRecording } = useReactMediaRecorder({
		audio: true,
		onStop: (_: string, blob: Blob) => {
			blob.size > 0 && submitHandler(blob); // запускаем обработчик отправки аудиозаписи, если есть запись с микрофона
		}
	}); // hook для записи аудио
	const translate = useTranslate(); // hook для перевода текста

	const dispatch = useAppDispatch();
	const analyzeData = useAppSelector(selectAnalyze); // store - анализ речи
	const searchSpeakerData = useAppSelector(selectSearchSpeaker); // store - поиск спикера

	// следим за флагом и статусом записи микрофона
	useEffect(() => {
		// и если кнопка мыши не зажата в плашке в момент подготовки или начала записи - останавливаем запись
		(status === 'acquiring_media' || status === 'recording') && recordingFlg === false && stopRecording();
	}, [status, recordingFlg]);

	// следим за выбранным аудио-файлом и устанавливаем тип для отображения имени файла
	useEffect(() => {
		file && setTypeAudio('file');
	}, [file]);

	// обработчик отправки аудиозаписи
	const submitHandler = (audio: File | Blob | undefined, e?: FormEvent<HTMLFormElement>): void => {
		e?.preventDefault();

		if ((formFor === 'analysis' && analyzeData.status === RequestStatus.LOADING) ||
			(formFor === 'search' && searchSpeakerData.status === RequestStatus.LOADING) ||
			!audio
		) return; // если идет загрузка или нет аудио-файла - выходим

		formFor === 'analysis' && analyzeData.data !== null && dispatch(clearAnalyze()); // если есть данные анализа речи - очищаем
		formFor === 'search' && searchSpeakerData.data !== null && dispatch(clearSearchSpeaker()); // если есть данные поиска спикера - очищаем

		const formData = new FormData();
		// если есть запись (файл или с микрофона) - создаем пару
		if (audio) {
			formData.append('file', audio);
		}

		// если отправка идет с микрофона
		if (!e) {
			formRef.current?.reset(); // сбрасываем форму
			setFile(undefined); // очищаем файл
			setTypeAudio('microphone'); // устанавливаем тип отправки
		}

		formFor === 'addingSpeaker' && dispatch(addSpeaker({ speakerName: speakerNameForAdd, formData })); // добавление спикера
		formFor === 'additionSpeaker' && speakerId && dispatch(editSpeaker({ speakerId, formData })); // дополнение спикера
		formFor === 'analysis' && dispatch(analyze(formData)); // анализ речи
		formFor === 'search' && dispatch(searchSpeaker({ threshold, formData })); // поиск спикера
	};

	return (
		<>
			<form className={styles.form} onSubmit={e => submitHandler(file, e)} ref={formRef}>
				{formFor === 'addingSpeaker' &&
					<FormControl fullWidth margin='normal' sx={{ marginTop: '5px' }}>
						<TextField
							autoFocus
							required
							id="speakerNameForAdd"
							label={translate('formSendAudio_inputSpeakerName')}
							variant="outlined"
							value={speakerNameForAdd}
							onChange={(e) => setSpeakerNameForAdd(e.target.value)}
							InputProps={{
								style: {
									height: 33,
									fontSize: 13
								},
							}}
							InputLabelProps={{
								style: {
									fontSize: 13,
								},
							}}
							sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}
						/>
					</FormControl>
				}

				<div className={styles.formInner}>
					<div className={cn(styles.formMicrophone, {
						[styles.formMicrophoneNotAllow]: (formFor === 'addingSpeaker' && speakerNameForAdd.length === 0) ||
							(formFor === 'analysis' && analyzeData.status === RequestStatus.LOADING) ||
							(formFor === 'search' && searchSpeakerData.status === RequestStatus.LOADING)
					})}
						onMouseDown={() => {
							if ((formFor === 'addingSpeaker' && speakerNameForAdd.length === 0) ||
								(formFor === 'analysis' && analyzeData.status === RequestStatus.LOADING) ||
								(formFor === 'search' && searchSpeakerData.status === RequestStatus.LOADING)) return;
							startRecording();
							setRecordingFlg(true);
						}}
						onMouseUp={() => setRecordingFlg(false)}
						onMouseLeave={() => setRecordingFlg(false)}
						title={translate('formSendAudio_microphoneTitle')}
					>
						<FontAwesomeIcon icon={faMicrophone} size="2x" color={status === 'recording' ? colorRed : backgroundColor} fade={status === 'recording'} />
					</div>

					<div>{translate('or')}</div>

					<input
						className={styles.formFileInput}
						type="file"
						id={formFor}
						required
						accept="audio/wav"
						onChange={(e: ChangeEvent<HTMLInputElement>) => e.target.files && setFile(e.target.files[0])}
					/>

					<label htmlFor={formFor} className={styles.formFileLabel}>
						<div className={styles.formFileLabelIcon} title={translate('formSendAudio_inputFile')}>
							<FontAwesomeIcon icon={faEllipsis} color={backgroundColor} size="2xl" />
						</div>
						<div className={styles.formFileLabelName} title={file?.name}>
							{typeAudio === 'microphone' ?
								translate('formSendAudio_inputRecordMicrophone')
								:
								file ?
									file.name
									:
									translate('formSendAudio_inputFile')
							}
						</div>
					</label>

					{formFor === 'search' &&
						<FormControl fullWidth>
							<TextField
								id="threshold"
								label={translate('formSendAudio_inputThreshold')}
								variant="outlined"
								type='number'
								value={threshold}
								onChange={(e) => setThreshold(Number(e.target.value))}
								InputProps={{
									style: {
										height: 32,
										fontSize: 13
									},
									inputProps: { step: 1, min: 0, max: 100 }
								}}
								InputLabelProps={{
									style: {
										fontSize: 13,
									},
								}}
								sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}
							/>
						</FormControl>
					}

					<button className={cn(styles.formSubmitBtn, {
						[styles.formSubmitBtnNotActive]: !file ||
							(formFor === 'addingSpeaker' && speakerNameForAdd.length === 0) ||
							(formFor === 'analysis' && analyzeData.status === RequestStatus.LOADING) ||
							(formFor === 'search' && searchSpeakerData.status === RequestStatus.LOADING)
					})} type="submit" title={translate('formSendAudio_sendBtnTitle')}>
						<FontAwesomeIcon icon={faPaperPlane} color={backgroundColor} size="lg" />
					</button>
				</div>
			</form>
		</>
	);
};

export default FormSendAudio;
