import React from "react";
import { Box, Popover } from '@mui/material';
import { backgroundColor, colorPrimary } from '../../constants/colors';
import './_zoom.scss';

function zoomPreventer(evt) {
  if (!evt) return;
  const ctrl = evt.ctrlKey;
  if (!ctrl) return;
  evt.preventDefault();
}

export default class ZoomWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.factors = [0.1, 0.125, 0.15, 0.2, 0.25, 0.333, 0.4, 0.5, 0.666, 0.75, 0.9, 1, 1.25, 1.5, 2, 2.5, 3, 4, 5, 7.5, 10];
    this.state = {
      ...this.state,
      zoom: 1,
      posX: 0,
      posY: 0,
      anchorEl: null,
      zoomedPlaneInitialHeight: null
    };
    this.cx = 0;
    this.cy = 0;
    this.wrap = React.createRef();
    this.pane = React.createRef();
    this.dragger = React.createRef();

    this.onWheel = this.onWheel.bind(this);
    this.zoomIn = this.zoomIn.bind(this);
    this.zoomOut = this.zoomOut.bind(this);
    this.onMouseDown = this.onMouseDown.bind(this);
    this.onMouseUp = this.onMouseUp.bind(this);
    this.onMouseMove = this.onMouseMove.bind(this);

    this.dx = this.dy = -1;
  }

  handleClick(event) {
    this.setState({ anchorEl: event.currentTarget });
  }
  handleClose() {
    this.setState({ anchorEl: null });
  }
  componentDidMount() {
    this.wrap.current.addEventListener("wheel", zoomPreventer, { passive: false });
    this.setState({ zoomedPlaneInitialHeight: this.pane.current?.clientHeight });
    // window.addEventListener("wheel", zoomPreventer, { passive: false });
  }
  componentDidUpdate(prevProps) {
    if (prevProps.children !== this.props.children) {
      this.setState({ zoom: 1 });
    }
  }
  componentWillUnmount() {
    this.wrap.current.removeEventListener("wheel", zoomPreventer);
    // window.removeEventListener("wheel", zoomPreventer);
  }
  setZoom(z) {
    const wrapper = this.wrap.current;
    if (!wrapper) return;
    const ox = this.cx + wrapper.scrollLeft;
    const oy = this.cy + wrapper.scrollTop;
    const rx = Math.round(ox / this.state.zoom);
    const ry = Math.round(oy / this.state.zoom);
    const nx = Math.round(rx * z);
    const ny = Math.round(ry * z);
    let nsx = nx - this.cx;
    if (nsx < 0) nsx = 0;
    let nsy = ny - this.cy;
    if (nsy < 0) nsy = 0;
    this.setState({ zoom: z }, () => {
      wrapper.scrollLeft = nsx;
      wrapper.scrollTop = nsy;
    });
  }
  getPaneStyle() {
    return {
      transform: "scale(" + this.state.zoom + ")",
      width: this.props.notFoundMatrixStatus === true ? '100%' : '',
      // height: this.pane.current?.clientHeight >= this.props.tableRef.current?.clientHeight ? '100%' : ''
    };
  }
  onMouseDown(evt) {
    if (!evt) return;
    if (!evt.ctrlKey) return;
    this.dx = evt.clientX;
    this.dy = evt.clientY;
    const p = this.wrap.current;
    p.classList.add("dragging");
    evt.preventDefault();
  }
  maybeScroll(ndx, ndy) {
    if (this.dx < 0 || this.dy < 0) return;
    const ddx = ndx - this.dx;
    const ddy = ndy - this.dy;
    const p = this.wrap.current;
    let nsx = p.scrollLeft - ddx;
    if (nsx < 0) nsx = 0;
    let nsy = p.scrollTop - ddy;
    if (nsy < 0) nsy = 0;
    p.scrollTo(nsx, nsy);
    // p.scrollTop = nsy;
    // p.scrollLeft = nsx;
    this.dx = ndx;
    this.dy = ndy;
  }
  onMouseUp(evt) {
    if (!evt) return;
    if (!evt.ctrlKey) return;
    const ndx = evt.clientX;
    const ndy = evt.clientY;
    evt.preventDefault();
    this.maybeScroll(ndx, ndy);
    this.dx = this.dy = -1;
    const p = this.wrap.current;
    p.classList.remove("dragging");
  }
  onMouseMove(evt) {
    if (!evt) return;
    if (!evt.ctrlKey) return;
    const ndx = evt.clientX;
    const ndy = evt.clientY;
    evt.preventDefault();
    this.maybeScroll(ndx, ndy);
  }
  onWheel(evt) {
    if (!evt) return;
    const ctrl = evt.ctrlKey;
    if (!ctrl) return;
    const dy = evt.deltaY;
    if (!dy) return;
    if (!this.wrap.current) return;
    //evt.preventDefault(); 
    const rect = this.wrap.current.getBoundingClientRect();
    this.cx = Math.round(evt.clientX - rect.x);
    this.cy = Math.round(evt.clientY - rect.y);
    //evt.preventDefault();
    //evt.stopImmediatePropagation();
    if (dy < 0) this.zoomIn();
    else this.zoomOut();
  }
  zoomIn(evt) {
    if (evt) {
      this.cx = this.cy = 0;
      evt.preventDefault();
    }
    let i = this.factors.indexOf(this.state.zoom);
    if (i > this.factors.length - 2) i = this.factors.length - 2;
    this.setZoom(this.factors[i + 1]);
  }
  zoomOut(evt) {
    if (evt) {
      this.cx = this.cy = 0;
      evt.preventDefault();
    }
    let i = this.factors.indexOf(this.state.zoom);
    if (i <= 0) i = 1;
    this.setZoom(this.factors[i - 1]);
  }
  renderZL() {
    return Math.round(1000 * this.state.zoom) / 10 + "%";
  }
  render() {
    return (
      <div className="zoom-wrapper">

        <div className="zoomer">
          <span className="zoom-out" onClick={(evt) => this.zoomOut(evt)}>–</span>
          <span className="zoom-level">{this.renderZL()}</span>
          <span className="zoom-in" onClick={(evt) => this.zoomIn(evt)}>+</span>
        </div>
        <div className="btnClasses" onClick={(e) => this.handleClick(e)}>
          {this.props.translate('matrix_classesBtn')}
        </div>
        <Popover
          open={Boolean(this.state.anchorEl)}
          anchorEl={this.state.anchorEl}
          onClose={() => this.handleClose()}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          sx={{
            '& .MuiPaper-root': {
              borderRadius: '6px',
            }
          }}
        >
          <Box sx={{
            padding: '13px 16px',
            bgcolor: backgroundColor,
            overflow: 'auto',
            maxHeight: 'calc(100vh - 130px)',
            boxShadow: '0 0 4px #808080',
            border: '1px solid #dfdfdf',
            color: colorPrimary
          }}>
            {this.props.classes?.map((classItem) => (
              <div className='classItem' key={classItem}>{classItem}</div>
            ))}
          </Box>
        </Popover>

        {(this.props.matrixTestFull !== null && !this.props.testingStatus) &&
          <div className='wrapper-buttons-matrix'>
            <div className={this.props.selectMatrix === 'train' ? "matrixBtn matrixBtnActive" : 'matrixBtn'} onClick={() => this.props.setSelectMatrix('train')}>
              {this.props.translate('matrix_trainBtn')}
            </div>
            <div className={this.props.selectMatrix === 'test' ? "matrixBtn matrixBtnActive" : 'matrixBtn'} onClick={() => this.props.setSelectMatrix('test')}>
              {this.props.translate('matrix_testBtn')}
            </div>
          </div>
        }

        <div className={this.props.testAccuracy > 0 ? 'plane-wrap plane-wrap-testMatrix' : 'plane-wrap'} ref={this.wrap}
          onWheel={evt => this.onWheel(evt)}
          onMouseDown={evt => this.onMouseDown(evt)}
          onMouseUp={evt => this.onMouseUp(evt)}
          onMouseMove={evt => this.onMouseMove(evt)}
        >
          <div className={this.state.zoomedPlaneInitialHeight >= this.props.tableRef.current?.clientHeight ? "zoomed-plane zoomed-plane-height" : "zoomed-plane"} ref={this.pane} style={this.getPaneStyle()}>
            {this.props.children}
          </div>
        </div>

      </div>
    );
  }
}
