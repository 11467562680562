import { useEffect, useState } from 'react';
import { Autocomplete, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { useAppDispatch, useAppSelector, } from '../../../store/hooks';
import { changeModelnameInModelConfig, changeThresholdInModelConfig, deleteModelConfig, selectActiveRobotVersion } from '../../../store/sesRobotSlice';
import useAccessRight from '../../../hooks/useAccessRight';
import useTranslate from '../../../hooks/useTranslate';
import { SES } from '../../../constants/accessRights';
import { DATA_ELEM_TYPE_LIST } from '../../../constants/robotConfigLists';
import { colorPrimary, colorRed } from '../../../constants/colors';
import { ServiceTypeRobot } from '../../../types/cloudTypes';
import { IModelsProps } from './Models.props';
import styles from './Models.module.scss';

const Models = ({ serviceType, modelName, configData, modelsList, setChangeFlg }: IModelsProps): JSX.Element => {
	const [selectType, setSelectType] = useState<ServiceTypeRobot>(serviceType); // тип
	const [inputModelName, setInputModelName] = useState<string>(modelName); // название модели
	const [threshold, setThreshold] = useState<number>(0); // порог доверия

	const dispatch = useAppDispatch();
	const activeRobotVersion = useAppSelector(selectActiveRobotVersion); // store - версия активного робота

	const isAccess = useAccessRight(); // hook для проверки прав доступа
	const translate = useTranslate(); // hook для перевода текста

	// настройками модели данными и вписываем значения в поля
	useEffect(() => {
		if ('threshold' in configData) setThreshold(configData.threshold);
		else if ('confidenceThreshold' in configData) setThreshold(configData.confidenceThreshold);
	}, [configData]);

	// следим за полем порога доверия
	useEffect(() => {
		// через пол-секунды бездействия после окончания ввода
		const handler = setTimeout(() => {
			if ('threshold' in configData && configData.threshold !== threshold) {
				dispatch(changeThresholdInModelConfig({ serviceType, modelName, threshold })); // изменяем порог доверия
				setChangeFlg(true); // ставим флаг о несохраненных данных
			} else if ('confidenceThreshold' in configData && configData.confidenceThreshold !== threshold) {
				dispatch(changeThresholdInModelConfig({ serviceType, modelName, threshold })); // изменяем порог доверия
				setChangeFlg(true); // ставим флаг о несохраненных данных
			}
		}, 500);

		return () => {
			clearTimeout(handler); // сбрасываем timeout, если продолжается ввод
		};
	}, [threshold]);

	// обработчик изменения модели 
	const changeModelHandler = (): void => {
		if (modelName !== inputModelName) {
			dispatch(changeModelnameInModelConfig({ serviceType, oldModelName: modelName, newModelName: inputModelName }));
			setChangeFlg(true); // ставим флаг о несохраненных данных
		}
	};

	// обработчик удаления настроек модели
	const deleteModelConfigHandler = () => {
		dispatch(deleteModelConfig({ serviceType, modelName }));
		setChangeFlg(true); // ставим флаг о несохраненных данных
	};

	return (
		<div className={styles.container}>
			<div className={styles.modelsBlock}>
				{/* тип */}
				<FormControl fullWidth margin='dense' sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}>
					<InputLabel sx={{ fontSize: 13 }}>{translate('configDataElem_selectType')}</InputLabel>
					<Select
						required
						label={translate('configDataElem_selectType')}
						value={selectType}
						onChange={e => setSelectType(e.target.value as ServiceTypeRobot)}
						disabled
						style={{ fontSize: 13, height: 33, color: colorPrimary }}
					>
						{DATA_ELEM_TYPE_LIST.map(({ type, translation }) =>
							<MenuItem key={type} value={type} sx={{ fontSize: 13, color: colorPrimary }}>{translate(translation)}</MenuItem>
						)}
					</Select>
				</FormControl>

				{/* удаление настройки модели */}
				{isAccess(SES.ROBOT_EDIT) && activeRobotVersion === 'draft' &&
					<FontAwesomeIcon
						icon={faTrashCan}
						color={colorRed}
						size='lg'
						onClick={deleteModelConfigHandler}
						title={translate('serviceData_deleteDataTitle')}
						style={{ cursor: 'pointer' }}
					/>
				}
			</div>

			{/* модель */}
			<FormControl fullWidth margin='dense'>
				<Autocomplete
					freeSolo
					options={modelsList}
					value={inputModelName}
					onChange={(_, value) => { setInputModelName(value ? value : ''); setChangeFlg(true); }}
					onBlur={changeModelHandler}
					disabled={!isAccess(SES.ROBOT_EDIT) || activeRobotVersion !== 'draft'}
					renderInput={(params) =>
						<TextField
							{...params}
							label={translate('configDataElem_inputModel')}
							onChange={(e) => { setInputModelName(e.target.value); setChangeFlg(true); }}
							InputLabelProps={{
								style: {
									fontSize: 13,
								},
							}}
							// InputProps={{
							// 	...params.InputProps, // важно прокинуть параметры
							// 	endAdornment: (
							// 		<div style={{ marginTop: '-7px' }}>
							// 			{clusterServer.status === RequestStatus.LOADING &&
							// 				<ProgressCircle isBtnDisabled />
							// 			}
							// 			{params.InputProps.endAdornment} {/* важно дописать параметры */}
							// 		</div>
							// 	),
							// }}
							sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}
						/>
					}
					sx={{
						".MuiInputBase-root": { height: 33, fontSize: 13, color: colorPrimary },
						".MuiInputBase-input": { marginTop: -1 },
					}}
					getOptionLabel={option => option}
					renderOption={(props, option) => {
						return (
							<span {...props} style={{ fontSize: 13, color: colorPrimary, textAlign: 'left' }}>
								{option}
							</span>
						);
					}}
				/>
			</FormControl>

			<FormControl fullWidth margin='dense'>
				<TextField
					label={translate('manualCheck_inputСonfidenceThreshold')}
					variant="outlined"
					type='number'
					value={threshold}
					onChange={(e) => setThreshold(Number(e.target.value))}
					disabled={!isAccess(SES.ROBOT_EDIT) || activeRobotVersion !== 'draft'}
					InputProps={{
						style: {
							height: 33,
							fontSize: 13,
							color: colorPrimary,
						},
						inputProps: { step: 1, min: 0, max: 100 }
					}}
					InputLabelProps={{
						style: {
							fontSize: 13,
						},
					}}
					sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}
				/>
			</FormControl>
		</div>
	);
};

export default Models;
