import { FormEvent, useEffect, useRef, useState } from 'react';
import ReactJson from 'react-json-view';
import Draggable, { DraggableData, DraggableEvent } from 'react-draggable';
import { Autocomplete, Fade, FormControl, InputAdornment, TextField } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCode, faPaperPlane, faRotate, faXmark } from '@fortawesome/free-solid-svg-icons';
import cn from 'classnames';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { addReplicaInDialog, clearDebuggerAnswer, clearDebuggerSession, getDebuggerAnswer, getDebuggerSession, selectDebuggerAnswer, selectDebuggerSession } from '../../store/sesSlice';
import { selectActiveRobotId, selectActiveRobotVersion, selectRobot } from '../../store/sesRobotSlice';
import { selectChannelList } from '../../store/qasSlice';
import useAccessRight from '../../hooks/useAccessRight';
import useTranslate from '../../hooks/useTranslate';
import { SES } from '../../constants/accessRights';
import { backgroundColor, colorPrimary } from '../../constants/colors';
import { RequestStatus } from '../../types/statusTypes';
import { IChatWidgetProps } from './ChatWidget.props';
import styles from './ChatWidget.module.scss';

const ChatWidget = ({ showChatWidget, setShowChatWidget }: IChatWidgetProps): JSX.Element => {
	const [inputChannel, setInputChannel] = useState<string>('default'); // канал
	const [inputMessage, setInputMessage] = useState<string>(''); // сообщение
	const inputMessageRef = useRef<HTMLInputElement>(null); // ссылка на поле для ввода сообщения
	const messageListRef = useRef<HTMLInputElement>(null); // ссылка на блок сообщений
	const [showJsonBlock, setShowJsonBlock] = useState<boolean>(false); // показ JSON блока
	const [deltaPosition, setDeltaPosition] = useState<{ x: number, y: number }>({ x: 0, y: 0 }); // позиция чата

	const dispatch = useAppDispatch();
	const robotInfo = useAppSelector(selectRobot); // store - информация о роботе
	const activeRobotId = useAppSelector(selectActiveRobotId); // store - id активного робота
	const activeRobotVersion = useAppSelector(selectActiveRobotVersion); // store - версия активного робота
	const debuggerAnswer = useAppSelector(selectDebuggerAnswer); // store - ответ робота
	const debuggerSession = useAppSelector(selectDebuggerSession); // store - сессия робота
	const channelList = useAppSelector(selectChannelList); // store - список каналов

	const isAccess = useAccessRight(); // hook для проверки прав доступа
	const translate = useTranslate(); // hook для перевода текста

	// следим за id и версией робота 
	useEffect(() => {
		clearDebuggerData(); // очищаем данные ответа и сессии
		setShowChatWidget(false); // закрываем чат
		setShowJsonBlock(false); // закрываем JSON блок
		setDeltaPosition({ x: 0, y: 0 }); // обнуляем позицию
	}, [activeRobotId, activeRobotVersion]);

	// следим за открытием чата
	useEffect(() => {
		showChatWidget && inputMessageRef.current?.focus(); // ставим фокус в поле сообщения
	}, [showChatWidget]);

	// следим за диалогом
	useEffect(() => {
		messageListRef.current?.scrollTo({
			top: messageListRef.current.scrollHeight,
			behavior: "smooth",
		}); // скролл в конец диалога
		inputMessageRef.current?.focus(); // ставим фокус в поле сообщения
	}, [debuggerAnswer.dialog]);

	// следим за статусом ответа
	useEffect(() => {
		// при ошибке - добавляем сообщение об ошибке
		if (debuggerAnswer.status === RequestStatus.FAILED) {
			dispatch(addReplicaInDialog({ who: 'response', message: translate(debuggerAnswer.message || 'chatWidget_errorTitle') })); // добавление реплики в диалог
		}
	}, [debuggerAnswer.status]);

	// следим за ответом
	useEffect(() => {
		// если пустой ответ - добавляем сообщение об этом
		if (debuggerAnswer.answer?.length === 0) {
			dispatch(addReplicaInDialog({ who: 'response', message: translate('chatWidget_emptyAnswerTitle') })); // добавление реплики в диалог
		} else {
			debuggerAnswer.answer?.forEach(answerItem => {
				answerItem.messages.forEach(message => {
					dispatch(addReplicaInDialog({ who: 'response', message })); // добавление реплики в диалог
				});
			});
			// если есть доступ, id робота, есть ответ, id сессии
			isAccess(SES.SESSION) && activeRobotId && debuggerAnswer.answer && debuggerAnswer.session && dispatch(getDebuggerSession({ robotId: activeRobotId, sessionId: debuggerAnswer.session })); // обновляем данные сессии
		}
	}, [debuggerAnswer.answer]);

	// перетаскивание чата
	const handleDrag = (_e: DraggableEvent, ui: DraggableData) => {
		setDeltaPosition(prev => ({
			x: prev.x + ui.deltaX,
			y: prev.y + ui.deltaY,
		}));
	};

	// отправка запроса
	const submitHandler = (e: FormEvent<HTMLFormElement>): void => {
		e.preventDefault();
		if (activeRobotId) {
			dispatch(addReplicaInDialog({ who: 'client', message: inputMessage })); // добавление реплики в диалог
			dispatch(getDebuggerAnswer({
				robotId: activeRobotId,
				sessionId: debuggerAnswer.session ? debuggerAnswer.session : undefined,
				channelId: inputChannel,
				text: inputMessage,
			})); // отправка запроса
			setInputMessage(''); // очистка поля сообщения
		}
	};

	// очистка данных ответа и сессии
	const clearDebuggerData = (): void => {
		dispatch(clearDebuggerAnswer());
		dispatch(clearDebuggerSession());
	};

	// максимальная ширина JSON блока
	const getMaxWidthJsonBlock = (): number => {
		const windowWidth = window.innerWidth; // ширина окна
		const chatWidth = 400; // ширина чата
		const marginChat = 40; // отступ чата от края
		const marginJsonBock = 10; // отступ JSON блока от края
		const gap = 10; // интервал между чатом и JSON блоком
		const deltaPositionChat = deltaPosition.x < 0 ? Math.abs(deltaPosition.x) : -deltaPosition.x; // позиция сдвига чата
		if ((Math.abs(deltaPosition.x) + marginChat) > (window.innerWidth / 2 - chatWidth / 2)) {
			return windowWidth - (windowWidth - deltaPositionChat) + marginChat - gap - marginJsonBock; // JSON блок справа
		} else {
			return windowWidth - chatWidth - marginChat - gap - marginJsonBock - deltaPositionChat; // JSON блок слева
		}
	};

	return (
		<Draggable
			defaultClassName={styles.container}
			handle='h2' // за что таскаем
			bounds='html' // границы перетаскивания
			position={{ x: deltaPosition.x, y: deltaPosition.y }}
			onDrag={handleDrag}
		>
			<Fade in={showChatWidget} timeout={300}>
				<div>
					<div className={styles.headerWrapper}>
						{/* кнопка закрытия чата */}
						<button
							className={styles.buttonCloseChat}
							onClick={() => setShowChatWidget(false)}
							title={translate('chatWidget_closeTitle')}
						>
							<FontAwesomeIcon icon={faXmark} size='2x' color='#fff' />
						</button>
						<h2 className={styles.header}>{robotInfo.data?.name || translate('chatWidget_headerTitle')}</h2>
					</div>

					<div className={styles.setting}>
						{/* кнопка открытия данных сессии */}
						<button
							className={cn(styles.button)}
							type='button'
							title={translate('chatWidget_sessionDataTitle')}
							onClick={() => setShowJsonBlock(prev => !prev)}
						>
							<FontAwesomeIcon icon={faCode} color={backgroundColor} size="lg" />
						</button>

						{/* канал */}
						<FormControl fullWidth>
							<Autocomplete
								options={channelList.data.map(channel => channel.name)}
								value={inputChannel}
								onChange={(_, value) => setInputChannel(value ? value : '')}
								disabled={debuggerAnswer.status === RequestStatus.LOADING}
								renderInput={(params) =>
									<TextField
										{...params}
										label={translate('chatWidget_inputChannel')}
										onChange={(e) => setInputChannel(e.target.value)}
										InputLabelProps={{
											style: {
												fontSize: 13,
											},
										}}
										sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}
									/>
								}
								sx={{
									".MuiInputBase-root": { height: 33, fontSize: 13, color: colorPrimary },
									".MuiInputBase-input": { marginTop: -1 },
								}}
								getOptionLabel={option => option}
								renderOption={(props, option) => {
									return (
										<span {...props} style={{ fontSize: 13, color: colorPrimary }}>
											{option}
										</span>
									);
								}}
							/>
						</FormControl>

						{/* кнопка перезагрузки сессии */}
						<button
							className={cn(styles.button, {
								[styles.buttonNotActive]: debuggerAnswer.status === RequestStatus.LOADING || debuggerSession.status === RequestStatus.LOADING,
							})}
							type='button'
							title={translate('chatWidget_clearSessionBtn')}
							disabled={debuggerAnswer.status === RequestStatus.LOADING || debuggerSession.status === RequestStatus.LOADING}
							onClick={clearDebuggerData}
						>
							<FontAwesomeIcon icon={faRotate} color={backgroundColor} size="lg" />
						</button>
					</div>

					<div className={styles.messageList} ref={messageListRef}>
						{debuggerAnswer.dialog.map((replica, idx) => (
							<div key={replica.message + replica.who + idx} className={cn(styles.messageItem, {
								[styles.messageItemClient]: replica.who === 'client',
							})}>
								<div className={cn(styles.messageText, {
									[styles.messageTextClient]: replica.who === 'client',
								})}>
									{replica.message}
								</div>
							</div>
						))}

						{debuggerAnswer.status === RequestStatus.LOADING &&
							<div className={cn(styles.messageItem)}>
								<div className={cn(styles.messageText)}>
									<span className={styles.messageLoaderDots}></span>
									<span className={styles.messageLoaderDots}></span>
									<span className={styles.messageLoaderDots}></span>
								</div>
							</div>
						}
					</div>

					<form className={styles.sender} onSubmit={submitHandler}>
						{/* сообщение */}
						<FormControl fullWidth>
							<TextField
								inputRef={inputMessageRef}
								variant="outlined"
								placeholder={translate('chatWidget_inputMessagePlaceholder')}
								value={inputMessage}
								onChange={(e) => setInputMessage(e.target.value)}
								disabled={debuggerAnswer.status === RequestStatus.LOADING}
								InputProps={{
									style: {
										height: 33,
										fontSize: 13,
										color: colorPrimary,
									},
									endAdornment: (
										<InputAdornment position="end" >
											{inputMessage !== '' &&
												<FontAwesomeIcon
													icon={faXmark}
													onClick={() => {
														setInputMessage('');
														inputMessageRef.current?.focus();
													}}
													style={{ cursor: 'pointer ' }}
												/>
											}
										</InputAdornment>
									),
								}}
								InputLabelProps={{
									style: {
										fontSize: 13,
									},
								}}
								sx={{
									'.MuiInputLabel-root[data-shrink="false"]': { top: -8 },
									'.MuiInputBase-input': { padding: '0 14px' },
								}}
							/>
						</FormControl>

						{/* кнопка отправки сообщения */}
						<button
							className={cn(styles.button, {
								[styles.buttonNotActive]: debuggerAnswer.status === RequestStatus.LOADING || inputMessage === '',
							})}
							type='submit'
							title={translate('chatWidget_sendTitle')}
							disabled={debuggerAnswer.status === RequestStatus.LOADING || inputMessage === ''}
						>
							<FontAwesomeIcon icon={faPaperPlane} color={backgroundColor} size="lg" />
						</button>
					</form>

					<Fade in={showJsonBlock} timeout={300}>
						<div className={cn(styles.jsonBlock, {
							// абсолютное значение по Х со сдвигом от края > (половина ширины экрана - половина ширины блока чата)
							[styles.jsonBlockPositionRight]: (Math.abs(deltaPosition.x) + 40) > (window.innerWidth / 2 - 400 / 2),
						})} style={{ maxWidth: getMaxWidthJsonBlock() }}>
							<button
								className={styles.buttonCloseJsonBlock}
								onClick={() => setShowJsonBlock(false)}
								title={translate('chatWidget_closeTitle')}
							>
								<FontAwesomeIcon icon={faXmark} />
							</button>
							<div className={styles.jsonBlockInner}>
								{(debuggerSession.sessionData || debuggerSession.status === RequestStatus.FAILED) ?
									<ReactJson
										src={debuggerSession.sessionData || (debuggerSession.status === RequestStatus.FAILED && { error: translate(debuggerSession.message || 'chatWidget_errorTitle') }) || { data: null }}
										name={'root'} // имя root объекта
										displayObjectSize={false} // кол-во элементов
										enableClipboard={false} // значок копирования
										displayDataTypes={false} // тип данных
										shouldCollapse={(field) => field.name !== 'root' && !field.namespace.includes('current')} // ограничение на сворачивание объектов
									/>
									:
									<div className={styles.jsonBlockEmpty}>{translate('chatWidget_noDataTitle')}</div>
								}
							</div>
						</div>
					</Fade>
				</div>
			</Fade>
		</Draggable>
	);
};

export default ChatWidget;
