import { useEffect, useState } from 'react';
import { Fade } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { clearState, getTranscriptionList, selectDeletion, selectEditing, selectTranscriptionAudio, selectTranscriptionData, selectTranscriptionList } from '../../store/transcriptionSlice';
import useAccessRight from '../../hooks/useAccessRight';
import useTranslate from '../../hooks/useTranslate';
import { SERVICE, TRANSCRIPTION } from '../../constants/accessRights';
import { RequestStatus, ResponseStatus } from '../../types/statusTypes';
import PageWrapper from '../../HOC/PageWrapper/PageWrapper';
import TranscriptNavbar from '../../components/Navbars/TranscriptNavbar/TranscriptNavbar';
import TranscriptControls from '../../components/Controls/TranscriptControls/TranscriptControls';
import AudioPlayer from '../../components/AudioPlayer/AudioPlayer';
import Stenography from '../../components/Stenography/Stenography';
import InfoRecognition from '../../components/ResultRecognition/InfoRecognition/InfoRecognition';
import Speakers from '../../components/ResultRecognition/Speakers/Speakers';
import ProgressCircle from '../../components/ProgressCircle/ProgressCircle';
import ScreenLock from '../../components/ScreenLock/ScreenLock';
import styles from './Transcript.module.scss';

const Transcript = (): JSX.Element => {
	const [showPage, setShowPage] = useState<boolean>(true); // показ страницы
	const [activeRecordId, setActiveRecordId] = useState<string>(''); // id активной записи
	const [changeFlg, setChangeFlg] = useState<boolean>(false); // флаг, уведомляющий об изменении данных в стенограмме и возможности сохранить эти изменения
	const [showScreenLock, setShowScreenLock] = useState<{ isShow: boolean, title: string }>({ isShow: false, title: '' }); // показ экрана блокировки и подпись
	const [showContextMenu, setShowContextMenu] = useState<{ isShow: boolean, idx: number }>({ isShow: false, idx: -1 }); // показ кастомного контекстного меню

	const isAccess = useAccessRight(); // hook для проверки прав доступа
	const translate = useTranslate(); // hook для перевода текста

	const dispatch = useAppDispatch();
	const transcriptionList = useAppSelector(selectTranscriptionList); // store - список сохранненных записей распознавания
	const audio = useAppSelector(selectTranscriptionAudio); // store - аудио-файл сохраненной записи распознавания
	const transcription = useAppSelector(selectTranscriptionData); // store - данные сохранненной записи распознавания
	const deleteStatus = useAppSelector(selectDeletion); // store -  статус удаления стенограммы
	const editStatus = useAppSelector(selectEditing); // store -  статус сохранения стенограммы

	useEffect(() => {
		isAccess(TRANSCRIPTION.LIST) && dispatch(getTranscriptionList()); // получаем список сохраненных распознаваний

		// при уходе со страницы
		return () => {
			dispatch(clearState()); // очищаем state transcription
		};
	}, []);

	// следим за статусом удаления стенограммы
	useEffect(() => {
		if (deleteStatus.status === RequestStatus.LOADING) setShowScreenLock({ isShow: true, title: 'screenLock_deletionTranscriptTitle' }); // если идет удаление стенограммы - включаем экран блокировки
		else setShowScreenLock({ isShow: false, title: '' }); // иначе выключаем
		// если удаление прошло успешно - получаем заново список распознаваний
		deleteStatus.status === RequestStatus.IDLE && deleteStatus.error === ResponseStatus.SUCCESS && deleteStatus.message === 'success' && dispatch(getTranscriptionList());
	}, [deleteStatus]);

	// следим за статусом изменения стенограммы
	useEffect(() => {
		// если идет сохранения - включаем экран блокировки с подписью
		if (editStatus.status === RequestStatus.LOADING) setShowScreenLock({ isShow: true, title: 'screenLock_saveTranscriptTitle' });
		else setShowScreenLock({ isShow: false, title: '' }); // иначе выключаем
		// если сохранилось успешно - выключаем флаг сохранения данных
		editStatus.status === RequestStatus.IDLE && editStatus.error === ResponseStatus.SUCCESS && editStatus.message === 'success' && setChangeFlg(false);
	}, [editStatus]);

	// обработчик нажатия мыши на всем экране
	const clickHandler = (): void => {
		showContextMenu.isShow && setShowContextMenu({ isShow: false, idx: -1 }); // закрытие кастомного контекстного меню
	};

	return (
		<PageWrapper showPage={showPage} setShowPage={setShowPage} accessToService={[SERVICE.SPR, TRANSCRIPTION.LIST]} additionalFunctionToClickHandler={clickHandler}>
			<>
				<TranscriptNavbar activeRecordId={activeRecordId} setActiveRecordId={setActiveRecordId} setChangeFlg={setChangeFlg} setShowPage={setShowPage} />
				<TranscriptControls changeFlg={changeFlg} activeRecordId={activeRecordId} setShowPage={setShowPage} />

				<Fade in={true} timeout={700} style={{ transitionDelay: '500ms' }}>
					<div className={styles.wrapper}>
						{Array.isArray(transcriptionList.data) && transcriptionList.data.length > 0 ?
							<>
								<div className={styles.wrapperLeft}>
									{(audio.audioStatus !== RequestStatus.IDLE || audio.url) &&
										<Fade in={true} timeout={500}>
											<div className={styles.wrapperAudioTrack}>
												<div>
													{(audio.audioStatus === RequestStatus.LOADING || audio.audioPeakStatus === RequestStatus.LOADING) &&
														<div><ProgressCircle title={translate('progressCircle_audioLoadingTitle')} inLine /></div>
													}
													{audio.audioStatus === RequestStatus.FAILED && <p className={styles.failed}>{translate('transcript_failedLoadAudioTitle')}</p>}
													{audio.url && audio.audioPeakStatus !== RequestStatus.LOADING &&
														<AudioPlayer url={audio.url} sendTimestamp='transcript' timestamp={audio.timestamp} peaks={audio.audioPeaks.length > 0 ? audio.audioPeaks : undefined} />
													}
												</div>
											</div>
										</Fade>
									}

									{(transcription.data !== null || transcription.status !== RequestStatus.IDLE) &&
										<Fade in={true} timeout={500}>
											<div className={styles.wrapperTranscription}>
												<Stenography setChangeFlg={setChangeFlg} showContextMenu={showContextMenu} setShowContextMenu={setShowContextMenu} />
											</div>
										</Fade>
									}
								</div>

								{transcription.data && typeof transcription.data === 'object' && 'speakers' in transcription.data &&
									<div className={styles.wrapperRight}>
										<Fade in={true} timeout={500}>
											<div className={styles.wrapperInfo}>
												<div>
													<InfoRecognition modelName={transcription.data.model} created={transcription.data.created} modified={transcription.data.modified} />
												</div>
											</div>
										</Fade>

										<Fade in={true} timeout={500}>
											<div className={styles.wrapperSpeakers}>
												<Speakers speakers={transcription.data.speakers} speakerNameList={transcription.speakerList} from='transcript' setChangeFlg={setChangeFlg} audioUrl={audio.url ? audio.url : undefined} />
											</div>
										</Fade>
									</div>
								}
							</>
							:
							<div className={styles.noData}><div>{translate('noData')}</div></div>
						}
					</div>
				</Fade>

				{showScreenLock.isShow && <ScreenLock title={translate(showScreenLock.title)} />}
			</>
		</PageWrapper>
	);
};

export default Transcript;
