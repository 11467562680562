import { useEffect, useRef } from 'react';
import { ICheckboxProps } from './TableCheckbox.props';
import styles from './TableCheckbox.module.scss';

const TableCheckbox = ({ indeterminate, ...rest }: ICheckboxProps) => {
	const ref = useRef<HTMLInputElement>(null!); // ссылка на checkbox в строке таблицы

	useEffect(() => {
		if (typeof indeterminate === 'boolean') {
			ref.current.indeterminate = !rest.checked && indeterminate;
		}
	}, [ref, indeterminate]);

	return (
		<input
			type="checkbox"
			ref={ref}
			className={styles.input}
			{...rest}
		/>
	);
};

export default TableCheckbox;
