import { useEffect } from 'react';
import { Fade } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { clearRecognitionDataWebSocket, selectRecognitionData, selectRecognitionDataWebSocket } from '../../store/sprSlice';
import useTranslate from '../../hooks/useTranslate';
import { RequestStatus } from '../../types/statusTypes';
import ProgressCircle from '../ProgressCircle/ProgressCircle';
import styles from './ResultRecognitionWebSocket.module.scss';

const ResultRecognitionWebSocket = (): JSX.Element => {
	const dispatch = useAppDispatch();
	const recognitionData = useAppSelector(selectRecognitionData); // store - данные распознавания речи
	const recognitionDataWebSocket = useAppSelector(selectRecognitionDataWebSocket); // store - данные распознавания речи webSocket
	const translate = useTranslate(); // hook для перевода текста

	// следим за статусом распознавания по http, и при старте - очищаем данные распозвания по webSocket, если были
	useEffect(() => {
		if (recognitionData.status === RequestStatus.LOADING) {
			(recognitionDataWebSocket.data.length > 0 || recognitionDataWebSocket.fileReaderError || recognitionDataWebSocket.recordError || recognitionDataWebSocket.recordStatus !== 'inactive' || recognitionDataWebSocket.webSocketError || recognitionDataWebSocket.webSocketStatus !== 3) && dispatch(clearRecognitionDataWebSocket());
		}
	}, [recognitionData.status]);

	return (
		<div className={styles.checkResult}>
			{recognitionDataWebSocket.webSocketStatus === 0 && <div className={styles.loading}><ProgressCircle title={translate('Соединение с сервером...')} /></div>}

			{recognitionDataWebSocket.webSocketError && <p className={styles.failedText}>{translate('Ошибка соединения с сервером')}</p>}
			{recognitionDataWebSocket.recordError && <p className={styles.failedText}>{translate(recognitionDataWebSocket.recordError)}</p>}
			{recognitionDataWebSocket.fileReaderError && <p className={styles.failedText}>{translate('Ошибка чтения файла аудио-записи')}</p>}

			{(recognitionDataWebSocket.recordStatus === 'recording' || recognitionDataWebSocket.data.length > 0) &&
				<>
					<Fade in={true} timeout={500}>
						<h3 className={styles.checkResultTitle}>
							{translate('recognition_resultTitle')} "Common" (взята напрямую){/* {
							recognitionData.data.model.includes('-new') ? `${recognitionData.data.model.slice(0, -4)}" (${translate('future')})`
							:
							recognitionData.data.model.includes('-planned') ? `${recognitionData.data.model.slice(0, -8)}" (${translate('futureInstall')})`
							:
							allModels.models && Object.keys(allModels.models).includes(recognitionData.data.model) ? `${recognitionData.data.model}" (${translate('current')})`
							:
							recognitionData.data.model + '"'
						} */}
						</h3>
					</Fade>
					<Fade in={recognitionDataWebSocket.recordStatus === 'recording'} timeout={500}>
						<p className={styles.checkResultRecording}>Идет запись, говорите в микрофон</p>
					</Fade>
					<Fade in={recognitionDataWebSocket.data.length > 0} timeout={500}>
						<div className={styles.checkResultText}>
							{recognitionDataWebSocket.data.map((chank, idx) => (
								<div className={styles.checkResultTextInner} key={idx}>--&nbsp;
									{'message' in chank ?
										<span className={styles.checkResultTextError}>Ошибка: {translate(chank.message)}</span>
										:
										<span>{chank.text}</span>
									}
								</div>
							))}
						</div>
					</Fade>
				</>
			}
		</div>
	);
};

export default ResultRecognitionWebSocket;
