import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosError, AxiosResponse } from 'axios';
import { RootState } from './store';
import axiosApi from '../helpers/axios';
import { downloadFile } from '../helpers/downloadFile';
import { ENDPOINTS_MODEL, URL_MODEL } from '../constants/api_endpoints';
import { IApplyModelProps, IDeleteModelProps, IImportModelProps, IGetAllModelsProps, IGetInfoModelProps, IInstallModelProps, IRestoreModelProps, IModelState, AllModelResponseType, IGetLogsModelProps, IGetErrorsModelProps, ModelType, ICreateModelProps, IFullModelResponse, IResponse, IGetHandlerProps, IUploadHandlerProps, IDeleteHandlerProps, IExportModelProps } from '../types/modelTypes';
import { RequestStatus, ResponseStatus } from '../types/statusTypes';

const initialState: IModelState = {
  allModels: {
    models: null,
    status: RequestStatus.IDLE,
    error: ResponseStatus.SUCCESS,
    message: '',
  },
  fullModel: {
    activeType: 'future',
    modelName: null,
    fullModel: {
      previous: null,
      current: null,
      future: null,
    },
    training: false,
    testing: false,
    status: RequestStatus.IDLE,
    error: ResponseStatus.SUCCESS,
    message: '',
  },
  modelExport: {
    status: RequestStatus.IDLE,
    error: ResponseStatus.SUCCESS,
    message: '',
  },
  modelImport: {
    status: RequestStatus.IDLE,
    error: ResponseStatus.SUCCESS,
    message: '',
    newName: null,
    progress: 0,
  },
  modelInstallation: {
    status: RequestStatus.IDLE,
    error: ResponseStatus.SUCCESS,
    message: '',
  },
  modelApply: {
    status: RequestStatus.IDLE,
    error: ResponseStatus.SUCCESS,
    message: '',
  },
  modelRestore: {
    status: RequestStatus.IDLE,
    error: ResponseStatus.SUCCESS,
    message: '',
  },
  modelDelete: {
    status: RequestStatus.IDLE,
    error: ResponseStatus.SUCCESS,
    message: '',
  },
  modelLogs: {
    status: RequestStatus.IDLE,
    logs: [],
  },
  modelErrors: {
    status: RequestStatus.IDLE,
    errors: [],
  },
  handler: {
    status: RequestStatus.IDLE,
    data: null,
  },
  handlerUpload: {
    status: RequestStatus.IDLE,
    error: ResponseStatus.SUCCESS,
    message: '',
  },
  handlerDelete: {
    status: RequestStatus.IDLE,
    error: ResponseStatus.SUCCESS,
    message: '',
  },
};

// получение инфо обо всех моделях
export const getAllModels = createAsyncThunk(
  'model/getAllModels',
  async ({ serviceType }: IGetAllModelsProps, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<AllModelResponseType | IResponse> = await axiosApi.get(`${URL_MODEL}/${ENDPOINTS_MODEL.INFO}/${serviceType}`);
      return response.data;
    } catch (error) {
      if (error) {
        // возвращаем данные ошибки, пришедшие с бэка
        return rejectWithValue(error);
      }
    }
  }
);

// получение инфо о модели
export const getInfoModel = createAsyncThunk(
  'model/getInfoModel',
  async ({ modelName, serviceType }: IGetInfoModelProps, { rejectWithValue, signal }) => {
    try {
      const response: AxiosResponse<IFullModelResponse | IResponse> = await axiosApi.get(`${URL_MODEL}/${ENDPOINTS_MODEL.INFO}/${serviceType}/${modelName}`, {
        signal,
      });
      return response.data;
    } catch (error) {
      if (error) {
        // возвращаем данные ошибки, пришедшие с бэка
        return rejectWithValue(error);
      }
    }
  }
);

// создание модели
export const createModel = createAsyncThunk(
  'model/createModel',
  async ({ modelName, serviceType }: ICreateModelProps, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<IResponse> = await axiosApi.post(`${URL_MODEL}/${ENDPOINTS_MODEL.ADD}/${serviceType}/${modelName}`);
      return response.data;
    } catch (error) {
      if (error) {
        // возвращаем данные ошибки, пришедшие с бэка
        return rejectWithValue(error);
      }
    }
  }
);

// экспорт модели (.zip файл)
export const exportModel = createAsyncThunk(
  'model/exportModel',
  async ({ modelName, modelType, serviceType }: IExportModelProps, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<Blob | IResponse> = await axiosApi.get(`${URL_MODEL}/${ENDPOINTS_MODEL.EXPORT}/${serviceType}/${modelName}/${modelType}`, {
        responseType: 'blob',
      });
      if (response.data instanceof Blob) {
        downloadFile(response.data, `${modelName}.zip`);
      } else return response.data;
    } catch (error) {
      if (error) {
        // возвращаем данные ошибки, пришедшие с бэка
        return rejectWithValue(error);
      }
    }
  }
);

// импортирование модели из .zip файла
export const importModel = createAsyncThunk(
  'model/importModel',
  async ({ modelName, formData, serviceType }: IImportModelProps, { dispatch }): Promise<IResponse> => {
    const response: AxiosResponse<IResponse> = await axiosApi.post(`${URL_MODEL}/${ENDPOINTS_MODEL.IMPORT}/${serviceType}/${modelName}`, { 'zip-model': formData.get('file') }, {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      onUploadProgress: (progressEvent) => {
        if (progressEvent.progress) dispatch(changeProgressImportModel(progressEvent.progress));
      },
    });
    return response.data;
  }
);

// установка модели
export const installModel = createAsyncThunk(
  'model/installModel',
  async ({ modelName, serviceType }: IInstallModelProps): Promise<IResponse> => {
    const response: AxiosResponse<IResponse> = await axiosApi.post(`${URL_MODEL}/${ENDPOINTS_MODEL.INSTALL}/${serviceType}/${modelName}`);
    return response.data;
  }
);

// применение модели
export const applyModel = createAsyncThunk(
  'model/applyModel',
  async ({ modelName, serviceType }: IApplyModelProps): Promise<IResponse> => {
    const response: AxiosResponse<IResponse> = await axiosApi.post(`${URL_MODEL}/${ENDPOINTS_MODEL.APPLY}/${serviceType}/${modelName}`);
    return response.data;
  }
);

// восстановление модели
export const restoreModel = createAsyncThunk(
  'model/restoreModel',
  async ({ modelName, serviceType }: IRestoreModelProps): Promise<IResponse> => {
    const response: AxiosResponse<IResponse> = await axiosApi.post(`${URL_MODEL}/${ENDPOINTS_MODEL.RESTORE}/${serviceType}/${modelName}`);
    return response.data;
  }
);

// удаление модели
export const deleteModel = createAsyncThunk(
  'model/deleteModel',
  async ({ modelName, serviceType }: IDeleteModelProps, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<IResponse> = await axiosApi.delete(`${URL_MODEL}/${ENDPOINTS_MODEL.DELETE}/${serviceType}/${modelName}`);
      return response.data;
    } catch (error) {
      if (error) {
        // возвращаем данные ошибки, пришедшие с бэка
        return rejectWithValue(error);
      }
    }
  }
);

// получение логов модели
export const getLogsModel = createAsyncThunk(
  'model/getLogsModel',
  async ({ modelName, modelType, serviceType }: IGetLogsModelProps): Promise<string[]> => {
    const response: AxiosResponse<string[]> = await axiosApi.get(`${URL_MODEL}/${ENDPOINTS_MODEL.LOG}/${serviceType}/${modelName}/${modelType}`);
    return response.data;
  }
);

// получение ошибок модели
export const getErrorsModel = createAsyncThunk(
  'model/getErrorsModel',
  async ({ modelName, modelType, serviceType, typeErrors = 'train' }: IGetErrorsModelProps): Promise<[string, string, string][]> => {
    const response: AxiosResponse<[string, string, string][]> = await axiosApi.get(`${URL_MODEL}/${ENDPOINTS_MODEL.ERRORS}/${serviceType}/${modelName}/${modelType}`, {
      params: {
        type: typeErrors,
      }
    });
    return response.data;
  }
);

// получение обработчика
export const getHandler = createAsyncThunk(
  'model/getHandler',
  async ({ modelName, serviceType, modelType }: IGetHandlerProps, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<string | IResponse> = await axiosApi.get(`${URL_MODEL}/${ENDPOINTS_MODEL.HANDLER}/${serviceType}/${modelName}/${modelType}`);
      return response.data;
    } catch (error) {
      if (error) {
        // возвращаем данные ошибки, пришедшие с бэка
        return rejectWithValue(error);
      }
    }
  }
);

// загрузка обработчика
export const uploadHandlerPy = createAsyncThunk(
  'model/uploadHandlerPy',
  async ({ modelName, serviceType, modelType, formData }: IUploadHandlerProps, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<IResponse> = await axiosApi.post(`${URL_MODEL}/${ENDPOINTS_MODEL.HANDLER}/${serviceType}/${modelName}/${modelType}`, { handler: formData.get('filePy') }, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      return response.data;
    } catch (error) {
      if (error) {
        // возвращаем данные ошибки, пришедшие с бэка
        return rejectWithValue(error);
      }
    }
  }
);

// удаление обработчика
export const deleteHandlerPy = createAsyncThunk(
  'model/deleteHandlerPy',
  async ({ modelName, serviceType, modelType }: IDeleteHandlerProps, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<IResponse> = await axiosApi.delete(`${URL_MODEL}/${ENDPOINTS_MODEL.HANDLER}/${serviceType}/${modelName}/${modelType}`);
      return response.data;
    } catch (error) {
      if (error) {
        // возвращаем данные ошибки, пришедшие с бэка
        return rejectWithValue(error);
      }
    }
  }
);

const modelSlice = createSlice({
  name: 'model',
  initialState,
  reducers: {
    // добавление имени модели
    addModelName: (state, action: PayloadAction<string | null>) => {
      state.fullModel.modelName = action.payload;
    },
    // добавление имени импортируемой модели
    addImportModelName: (state, action: PayloadAction<string>) => {
      state.modelImport.newName = action.payload;
    },
    // изменение прогресса импорта модели в %
    changeProgressImportModel: (state, action: PayloadAction<number>) => {
      state.modelImport.progress = Math.round(action.payload * 100);
    },
    // импорт loading Resumable
    loadingImportModel: (state) => {
      state.modelImport.status = RequestStatus.LOADING;
    },
    // импорт success Resumable
    successImportModel: (state) => {
      state.modelImport.status = RequestStatus.IDLE;
      state.modelImport.error = ResponseStatus.SUCCESS;
      state.modelImport.message = 'success';
    },
    // импорт failed Resumable
    failedImportModel: (state, action: PayloadAction<string>) => {
      state.modelImport.status = RequestStatus.FAILED;
      state.modelImport.error = ResponseStatus.FAILED;
      state.modelImport.message = action.payload;
    },
    // изменение активного типа модели
    changeActiveType: (state, action: PayloadAction<ModelType>) => {
      state.fullModel.activeType = action.payload;
    },
    // изменение статуса обучения модели
    changeTrainingStatus: (state, action: PayloadAction<boolean>) => {
      state.fullModel.training = action.payload;
    },
    // изменение статуса тестирования модели
    changeTestingStatus: (state, action: PayloadAction<boolean>) => {
      state.fullModel.testing = action.payload;
    },
    // очистка state
    clearState: (state) => {
      state.allModels = initialState.allModels;
      state.fullModel = initialState.fullModel;
      state.modelImport = initialState.modelImport;
      state.modelInstallation = initialState.modelInstallation;
      state.modelApply = initialState.modelApply;
      state.modelRestore = initialState.modelRestore;
      state.modelDelete = initialState.modelDelete;
      state.modelLogs = initialState.modelLogs;
      state.modelErrors = initialState.modelErrors;
    },
    // очистка статуса экспорта модели
    clearExportResponse: (state) => {
      state.modelExport = initialState.modelExport;
    },
    // очистка статуса импорта модели
    clearImportResponse: (state) => {
      state.modelImport = initialState.modelImport;
    },
    // очистка статуса установки модели
    clearInstallResponse: (state) => {
      state.modelInstallation = initialState.modelInstallation;
    },
    // очистка статуса применения модели
    clearApplyResponse: (state) => {
      state.modelApply = initialState.modelApply;
    },
    // очистка статуса восстановления модели
    clearRestoreResponse: (state) => {
      state.modelRestore = initialState.modelRestore;
    },
    // очистка статуса удаления модели
    clearDeleteResponse: (state) => {
      state.modelDelete = initialState.modelDelete;
    },
    // очистка логов модели
    clearLogs: (state) => {
      state.modelLogs.logs = initialState.modelLogs.logs;
    },
    // очистка ошибок модели
    clearErrors: (state) => {
      state.modelErrors.errors = initialState.modelErrors.errors;
    },
    // очистка обработчика
    clearHandler: (state) => {
      state.handler = initialState.handler;
    },
    // очистка статуса загрузки обработчика
    clearHandlerUpload: (state) => {
      state.handlerUpload = initialState.handlerUpload;
    },
    // очистка статуса удаления обработчика
    clearHandlerDelete: (state) => {
      state.handlerDelete = initialState.handlerDelete;
    },
    // очистка модели
    clearModel: (state) => {
      state.fullModel = initialState.fullModel;
    },
    // очистка всех моделей
    clearModels: (state) => {
      state.allModels = initialState.allModels;
      state.fullModel = initialState.fullModel;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllModels.pending, (state) => {
        state.allModels.status = RequestStatus.LOADING;
      })
      .addCase(getAllModels.fulfilled, (state, action) => {
        state.allModels.status = RequestStatus.IDLE;
        if (action.payload && typeof action.payload !== 'string' && !('message' in action.payload)) {
          state.allModels.models = action.payload;
        }
      })
      .addCase(getAllModels.rejected, (state, action: PayloadAction<unknown>) => {
        state.allModels.status = RequestStatus.FAILED;
        if (action.payload instanceof AxiosError) {
          state.allModels.error = action.payload.response?.data.error;
          state.allModels.message = action.payload.response?.data.message;
        }
      })
      .addCase(getInfoModel.pending, (state) => {
        state.fullModel.status = RequestStatus.LOADING;
      })
      .addCase(getInfoModel.fulfilled, (state, action) => {
        state.fullModel.status = RequestStatus.IDLE;
        if (action.payload && typeof action.payload !== 'string' && 'future' in action.payload) {
          state.fullModel.fullModel.previous = action.payload.previous;
          state.fullModel.fullModel.current = action.payload.current;
          state.fullModel.fullModel.future = action.payload.future;
        }
      })
      .addCase(getInfoModel.rejected, (state, action: PayloadAction<unknown>) => {
        state.fullModel.status = RequestStatus.FAILED;
        if (action.payload instanceof AxiosError) {
          state.fullModel.error = action.payload.response?.data.error;
          state.fullModel.message = action.payload.response?.data.message;
        }
      })
      .addCase(createModel.pending, (state) => {
        state.modelImport.status = RequestStatus.LOADING;
      })
      .addCase(createModel.fulfilled, (state, action) => {
        state.modelImport.status = RequestStatus.IDLE;
        if (action.payload) {
          state.modelImport.error = action.payload.error;
          state.modelImport.message = action.payload.message;
        }
      })
      .addCase(createModel.rejected, (state, action: PayloadAction<unknown>) => {
        state.modelImport.status = RequestStatus.FAILED;
        if (action.payload instanceof AxiosError) {
          state.modelImport.error = action.payload.response?.data.error;
          state.modelImport.message = action.payload.response?.data.message;
        }
      })
      .addCase(exportModel.pending, (state) => {
        state.modelExport.status = RequestStatus.LOADING;
      })
      .addCase(exportModel.fulfilled, (state, action) => {
        state.modelExport.status = RequestStatus.IDLE;
        if (action.payload && 'error' in action.payload) {
          state.modelExport.error = action.payload.error;
          state.modelExport.message = action.payload.message;
        }
      })
      .addCase(exportModel.rejected, (state, action: PayloadAction<unknown>) => {
        state.modelExport.status = RequestStatus.FAILED;
        if (action.payload instanceof AxiosError) {
          state.modelExport.error = action.payload.response?.data.error;
          state.modelExport.message = action.payload.response?.data.message;
        }
      })
      .addCase(importModel.pending, (state) => {
        state.modelImport.status = RequestStatus.LOADING;
      })
      .addCase(importModel.fulfilled, (state, action) => {
        state.modelImport.status = RequestStatus.IDLE;
        state.modelImport.error = action.payload.error;
        state.modelImport.message = action.payload.message;
      })
      .addCase(importModel.rejected, (state) => {
        state.modelImport.status = RequestStatus.FAILED;
      })
      .addCase(installModel.pending, (state) => {
        state.modelInstallation.status = RequestStatus.LOADING;
      })
      .addCase(installModel.fulfilled, (state, action) => {
        state.modelInstallation.status = RequestStatus.IDLE;
        state.modelInstallation.error = action.payload.error;
        state.modelInstallation.message = action.payload.message;
      })
      .addCase(installModel.rejected, (state) => {
        state.modelInstallation.status = RequestStatus.FAILED;
      })
      .addCase(applyModel.pending, (state) => {
        state.modelApply.status = RequestStatus.LOADING;
      })
      .addCase(applyModel.fulfilled, (state, action) => {
        state.modelApply.status = RequestStatus.IDLE;
        state.modelApply.error = action.payload.error;
        state.modelApply.message = action.payload.message;
      })
      .addCase(applyModel.rejected, (state) => {
        state.modelApply.status = RequestStatus.FAILED;
      })
      .addCase(restoreModel.pending, (state) => {
        state.modelRestore.status = RequestStatus.LOADING;
      })
      .addCase(restoreModel.fulfilled, (state, action) => {
        state.modelRestore.status = RequestStatus.IDLE;
        state.modelRestore.error = action.payload.error;
        state.modelRestore.message = action.payload.message;
      })
      .addCase(restoreModel.rejected, (state) => {
        state.modelRestore.status = RequestStatus.FAILED;
      })
      .addCase(deleteModel.pending, (state) => {
        state.modelDelete.status = RequestStatus.LOADING;
      })
      .addCase(deleteModel.fulfilled, (state, action) => {
        state.modelDelete.status = RequestStatus.IDLE;
        if (action.payload) {
          state.modelDelete.error = action.payload.error;
          state.modelDelete.message = action.payload.message;
        }
      })
      .addCase(deleteModel.rejected, (state, action: PayloadAction<unknown>) => {
        state.modelDelete.status = RequestStatus.FAILED;
        if (action.payload instanceof AxiosError) {
          state.modelDelete.error = action.payload.response?.data.error;
          state.modelDelete.message = action.payload.response?.data.message;
        }
      })
      .addCase(getLogsModel.pending, (state) => {
        state.modelLogs.status = RequestStatus.LOADING;
      })
      .addCase(getLogsModel.fulfilled, (state, action) => {
        state.modelLogs.status = RequestStatus.IDLE;
        state.modelLogs.logs = action.payload;
      })
      .addCase(getLogsModel.rejected, (state) => {
        state.modelLogs.status = RequestStatus.FAILED;
      })
      .addCase(getErrorsModel.pending, (state) => {
        state.modelErrors.status = RequestStatus.LOADING;
      })
      .addCase(getErrorsModel.fulfilled, (state, action) => {
        state.modelErrors.status = RequestStatus.IDLE;
        state.modelErrors.errors = action.payload;
      })
      .addCase(getErrorsModel.rejected, (state) => {
        state.modelErrors.status = RequestStatus.FAILED;
      })
      .addCase(getHandler.pending, (state) => {
        state.handler.status = RequestStatus.LOADING;
      })
      .addCase(getHandler.fulfilled, (state, action) => {
        if (action.payload && typeof action.payload === 'string' && !(/<!doctype html>/i.test(action.payload))) {
          state.handler.status = RequestStatus.IDLE;
          state.handler.data = action.payload;
        } else state.handler.status = RequestStatus.FAILED;
      })
      .addCase(getHandler.rejected, (state, action: PayloadAction<unknown>) => {
        state.handler.status = RequestStatus.FAILED;
        if (action.payload instanceof AxiosError) {
          state.handler.data = action.payload.response?.data;
        }
      })
      .addCase(uploadHandlerPy.pending, (state) => {
        state.handlerUpload.status = RequestStatus.LOADING;
      })
      .addCase(uploadHandlerPy.fulfilled, (state, action) => {
        state.handlerUpload.status = RequestStatus.IDLE;
        if (action.payload) {
          state.handlerUpload.error = action.payload.error;
          state.handlerUpload.message = action.payload.message;
        }
      })
      .addCase(uploadHandlerPy.rejected, (state, action: PayloadAction<unknown>) => {
        state.handlerUpload.status = RequestStatus.FAILED;
        if (action.payload instanceof AxiosError) {
          state.handlerUpload.error = action.payload.response?.data.error;
          state.handlerUpload.message = action.payload.response?.data.message;
        }
      })
      .addCase(deleteHandlerPy.pending, (state) => {
        state.handlerDelete.status = RequestStatus.LOADING;
      })
      .addCase(deleteHandlerPy.fulfilled, (state, action) => {
        state.handlerDelete.status = RequestStatus.IDLE;
        if (action.payload) {
          state.handlerDelete.error = action.payload.error;
          state.handlerDelete.message = action.payload.message;
        }
      })
      .addCase(deleteHandlerPy.rejected, (state, action: PayloadAction<unknown>) => {
        state.handlerDelete.status = RequestStatus.FAILED;
        if (action.payload instanceof AxiosError) {
          state.handlerDelete.error = action.payload.response?.data.error;
          state.handlerDelete.message = action.payload.response?.data.message;
        }
      });
  },
});

export const { addModelName, addImportModelName, changeProgressImportModel, loadingImportModel, successImportModel, failedImportModel, changeActiveType, changeTrainingStatus, changeTestingStatus, clearState, clearExportResponse, clearImportResponse, clearInstallResponse, clearApplyResponse, clearRestoreResponse, clearDeleteResponse, clearLogs, clearErrors, clearHandler, clearHandlerUpload, clearHandlerDelete, clearModel, clearModels } = modelSlice.actions;

export const selectAllModels = (state: RootState) => state.model.allModels;

export const selectFullModel = (state: RootState) => state.model.fullModel;
export const selectModelName = (state: RootState) => state.model.fullModel.modelName;
export const selectActiveType = (state: RootState) => state.model.fullModel.activeType;

export const selectExportStatus = (state: RootState) => state.model.modelExport;
export const selectImportStatus = (state: RootState) => state.model.modelImport;
export const selectInstallStatus = (state: RootState) => state.model.modelInstallation;
export const selectApplyStatus = (state: RootState) => state.model.modelApply;
export const selectRestoreStatus = (state: RootState) => state.model.modelRestore;
export const selectDeleteStatus = (state: RootState) => state.model.modelDelete;

export const selectLogsModel = (state: RootState) => state.model.modelLogs;
export const selectErrorsModel = (state: RootState) => state.model.modelErrors;

export const selectHandler = (state: RootState) => state.model.handler;
export const selectUploadHandlerStatus = (state: RootState) => state.model.handlerUpload;
export const selectDeleteHandlerStatus = (state: RootState) => state.model.handlerDelete;

export default modelSlice.reducer;
