import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError, AxiosResponse } from 'axios';
import { RootState } from './store';
import axiosApi from '../helpers/axios';
import { ENDPOINTS_SERVER, URL_SERVER } from '../constants/api_endpoints';
import { IGetServersProps, IDataServers, ServerResponseType, IServerState, IGetTrainerServerModelsProps, IGetClusterServerModelsProps, IResponse } from '../types/serverTypes';
import { RequestStatus, ResponseStatus } from '../types/statusTypes';

const initialState: IServerState = {
  dataServers: null,
  trainerServer: {
    data: null,
    status: RequestStatus.IDLE,
  },
  clusterServer: {
    data: null,
    status: RequestStatus.IDLE,
    error: ResponseStatus.SUCCESS,
    message: '',
  },
  clusterServerSmc: {
    data: null,
    status: RequestStatus.IDLE,
    error: ResponseStatus.SUCCESS,
    message: '',
  },
  clusterServerSee: {
    data: null,
    status: RequestStatus.IDLE,
    error: ResponseStatus.SUCCESS,
    message: '',
  },
};

// получение данных по всем серверам сервиса
export const getDataServers = createAsyncThunk(
  'servers/getServers',
  async ({ serviceType }: IGetServersProps): Promise<IDataServers> => {
    const response: AxiosResponse<IDataServers> = await axiosApi.get(`${URL_SERVER}/${ENDPOINTS_SERVER.ADDRESSES}/${serviceType}`);
    return response.data;
  }
);

// получение списка моделей серверов обучения (не используется)
export const getTrainerServerModels = createAsyncThunk(
  'servers/getTrainerServerModels',
  async ({ serviceType }: IGetTrainerServerModelsProps): Promise<ServerResponseType> => {
    const response: AxiosResponse<ServerResponseType> = await axiosApi.get(`${URL_SERVER}/${ENDPOINTS_SERVER.MODELS}/${serviceType}/trainer`);
    return response.data;
  }
);

// получение списка моделей серверов обработки
export const getClusterServerModels = createAsyncThunk(
  'servers/getClusterServerModels',
  async ({ serviceType }: IGetClusterServerModelsProps, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<ServerResponseType | IResponse> = await axiosApi.get(`${URL_SERVER}/${ENDPOINTS_SERVER.MODELS}/${serviceType}/cluster`);
      return response.data;
    } catch (error) {
      if (error) {
        // возвращаем данные ошибки, пришедшие с бэка
        return rejectWithValue(error);
      }
    }
  }
);

// получение доп.списка моделей серверов обработки SMC
export const getClusterServerModelsSmc = createAsyncThunk(
  'servers/getClusterServerModelsSmc',
  async (_, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<ServerResponseType | IResponse> = await axiosApi.get(`${URL_SERVER}/${ENDPOINTS_SERVER.MODELS}/smc/cluster`);
      return response.data;
    } catch (error) {
      if (error) {
        // возвращаем данные ошибки, пришедшие с бэка
        return rejectWithValue(error);
      }
    }
  }
);

// получение доп.списка моделей серверов обработки SEE
export const getClusterServerModelsSee = createAsyncThunk(
  'servers/getClusterServerModelsSee',
  async (_, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<ServerResponseType | IResponse> = await axiosApi.get(`${URL_SERVER}/${ENDPOINTS_SERVER.MODELS}/see/cluster`);
      return response.data;
    } catch (error) {
      if (error) {
        // возвращаем данные ошибки, пришедшие с бэка
        return rejectWithValue(error);
      }
    }
  }
);

const serverSlice = createSlice({
  name: 'servers',
  initialState,
  reducers: {
    // очистка данных по серверам
    clearDataServers: (state) => {
      state.dataServers = initialState.dataServers;
    },
    // очистка списка моделей серверов обучения
    clearTrainerServer: (state) => {
      state.trainerServer = initialState.trainerServer;
    },
    // очистка списка моделей серверов обработки
    clearClusterServer: (state) => {
      state.clusterServer = initialState.clusterServer;
    },
    // очистка доп.списка моделей серверов обработки SMC
    clearClusterServerSmc: (state) => {
      state.clusterServerSmc = initialState.clusterServerSmc;
    },
    // очистка доп.списка моделей серверов обработки SEE
    clearClusterServerSee: (state) => {
      state.clusterServerSee = initialState.clusterServerSee;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDataServers.fulfilled, (state, action) => {
        state.dataServers = action.payload;
      })
      .addCase(getDataServers.rejected, (state) => {
        state.dataServers = null;
      })
      .addCase(getTrainerServerModels.pending, (state) => {
        state.trainerServer.status = RequestStatus.LOADING;
      })
      .addCase(getTrainerServerModels.fulfilled, (state, action) => {
        state.trainerServer.status = RequestStatus.IDLE;
        state.trainerServer.data = action.payload;
      })
      .addCase(getTrainerServerModels.rejected, (state) => {
        state.trainerServer.status = RequestStatus.FAILED;
        state.trainerServer.data = initialState.trainerServer.data;
      })
      .addCase(getClusterServerModels.pending, (state) => {
        state.clusterServer.status = RequestStatus.LOADING;
      })
      .addCase(getClusterServerModels.fulfilled, (state, action) => {
        state.clusterServer.status = RequestStatus.IDLE;
        if (action.payload && typeof action.payload !== 'string' && !('message' in action.payload)) {
          state.clusterServer.data = action.payload;
        }
      })
      .addCase(getClusterServerModels.rejected, (state, action: PayloadAction<unknown>) => {
        state.clusterServer.status = RequestStatus.FAILED;
        if (action.payload instanceof AxiosError) {
          state.clusterServer.error = action.payload.response?.data.error;
          state.clusterServer.message = action.payload.response?.data.message;
        }
      })
      .addCase(getClusterServerModelsSmc.pending, (state) => {
        state.clusterServerSmc.status = RequestStatus.LOADING;
      })
      .addCase(getClusterServerModelsSmc.fulfilled, (state, action) => {
        state.clusterServerSmc.status = RequestStatus.IDLE;
        if (action.payload && typeof action.payload !== 'string' && !('message' in action.payload)) {
          state.clusterServerSmc.data = action.payload;
        }
      })
      .addCase(getClusterServerModelsSmc.rejected, (state, action: PayloadAction<unknown>) => {
        state.clusterServerSmc.status = RequestStatus.FAILED;
        if (action.payload instanceof AxiosError) {
          state.clusterServerSmc.error = action.payload.response?.data.error;
          state.clusterServerSmc.message = action.payload.response?.data.message;
        }
      })
      .addCase(getClusterServerModelsSee.pending, (state) => {
        state.clusterServerSee.status = RequestStatus.LOADING;
      })
      .addCase(getClusterServerModelsSee.fulfilled, (state, action) => {
        state.clusterServerSee.status = RequestStatus.IDLE;
        if (action.payload && typeof action.payload !== 'string' && !('message' in action.payload)) {
          state.clusterServerSee.data = action.payload;
        }
      })
      .addCase(getClusterServerModelsSee.rejected, (state, action: PayloadAction<unknown>) => {
        state.clusterServerSee.status = RequestStatus.FAILED;
        if (action.payload instanceof AxiosError) {
          state.clusterServerSee.error = action.payload.response?.data.error;
          state.clusterServerSee.message = action.payload.response?.data.message;
        }
      });
  },
});

export const { clearDataServers, clearTrainerServer, clearClusterServer, clearClusterServerSmc, clearClusterServerSee } = serverSlice.actions;

export const selectDataServers = (state: RootState) => state.server.dataServers;
export const selectTrainerServer = (state: RootState) => state.server.trainerServer;
export const selectClusterServer = (state: RootState) => state.server.clusterServer;
export const selectClusterServerSmc = (state: RootState) => state.server.clusterServerSmc;
export const selectClusterServerSee = (state: RootState) => state.server.clusterServerSee;

export default serverSlice.reducer;
