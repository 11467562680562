import { Slide } from '@mui/material';
import cn from 'classnames';
import DockPanel from '../DockPanel/DockPanel';
import { IDockPanelCallProps } from './DockPanelCall.props';
import styles from './DockPanelCall.module.scss';

const DockPanelCall = ({ showDockPanel, setShowDockPanel, setShowPage }: IDockPanelCallProps): JSX.Element => {

	return (
		<div className={cn(styles.dockPanel, {
			[styles.dockPanelHover]: !showDockPanel,
		})} onClick={() => setShowDockPanel(true)}
		>
			<Slide direction="up" in={showDockPanel} mountOnEnter unmountOnExit timeout={500}>
				<div><DockPanel setShowDockPanel={setShowDockPanel} setShowPage={setShowPage} /></div>
			</Slide>
			<div className={cn(styles.dockPanelInitiator, {
				[styles.dockPanelInitiatorOpacity]: showDockPanel,
			})}></div>
		</div>
	);
};

export default DockPanelCall;
