import { memo } from 'react';
import TagCloud from 'react-tag-cloud';
import cn from 'classnames';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { getDataElem, getEndpoint, selectDataList, selectEndpointList } from '../../../store/sesSlice';
import { selectActiveRobotId, } from '../../../store/sesRobotSlice';
import useAccessRight from '../../../hooks/useAccessRight';
import { SES } from '../../../constants/accessRights';
import { colorSecondary } from '../../../constants/colors';
import { ICloudTagProps } from './CloudTag.props';
import styles from './CloudTag.module.scss';

const CloudTag = ({ type, setShowElem, filter }: ICloudTagProps): JSX.Element => {
	const dispatch = useAppDispatch();
	const activeRobotId = useAppSelector(selectActiveRobotId); // store - id активного робота
	const dataList = useAppSelector(type === 'data' ? selectDataList : selectEndpointList); // список элементов данных/конечных точек

	const isAccess = useAccessRight(); // hook для проверки прав доступа

	// обработчик нажатия мыши на тег
	const clickHandler = (id: string): void => {
		// для элементов данных
		if (type === 'data') {
			isAccess(SES.DATA_GET) && activeRobotId && dispatch(getDataElem({ robotId: activeRobotId, elementId: id })); // получаем
		}
		// для конечных точек
		if (type === 'endpoints') {
			isAccess(SES.ENDPOINT_GET) && activeRobotId && dispatch(getEndpoint({ robotId: activeRobotId, endpointId: id })); // получаем
		}
		setShowElem(true); // открываем вкладку
	};

	return (
		<TagCloud
			// @ts-ignore
			className={cn(styles.cloud, {
				[styles.cloudRight]: type === 'endpoints',
			})}
			style={{
				fontFamily: 'sans-serif',
				fontSize: (word, _index) => {
					if (word?.children.toLowerCase().includes(filter.toLowerCase()) && filter !== '') return 10;
					else return 8;
				},
				color: (word, _index) => {
					if (word?.props?.children.toLowerCase().includes(filter.toLowerCase()) && filter !== '') return colorSecondary;
					else return 'rgba(0,0,0,0.6)';
				},
				padding: 5,
				fontWeight: 'bold',
			}}
		>
			{dataList.data.map((item) => (
				<div key={item.id} onClick={() => clickHandler(item.id)}>
					{item.name}
				</div>
			))}
		</TagCloud>
	);
};

export default memo(CloudTag);
