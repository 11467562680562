import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError, AxiosResponse } from 'axios';
import { RootState } from './store';
import axiosApi from '../helpers/axios';
import { ENDPOINTS_SEE, URL_SEE } from '../constants/api_endpoints';
import { IManualCheckModelProps, IResponse, ISeeState, IStopTrainModelProps, ITrainModelProps, ManualCheckDataType } from '../types/seeTypes';
import { RequestStatus, ResponseStatus } from '../types/statusTypes';

const initialState: ISeeState = {
  manualCheckModel: {
    data: null,
    status: RequestStatus.IDLE,
  },
  trainModel: {
    status: RequestStatus.IDLE,
    error: ResponseStatus.SUCCESS,
    message: '',
  },
  stopTrainModel: {
    status: RequestStatus.IDLE,
    error: ResponseStatus.SUCCESS,
    message: '',
  },
};

// ручная проверка модели
export const manualCheckModelSee = createAsyncThunk(
  'see/manualCheckModel',
  async ({ modelName, text, similarity }: IManualCheckModelProps): Promise<ManualCheckDataType | IResponse> => {
    const response: AxiosResponse<ManualCheckDataType | IResponse> = await axiosApi.get(`${URL_SEE}/${ENDPOINTS_SEE.MANUAL_CHECK}/${modelName}`, {
      params: {
        text,
        similarity
      }
    });
    return response.data;
  }
);

// обучение модели
export const trainModelSee = createAsyncThunk(
  'see/trainModel',
  async ({ modelName, corpusName, noise }: ITrainModelProps, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<IResponse> = await axiosApi.post(`${URL_SEE}/${ENDPOINTS_SEE.TRAIN}/${modelName}`, {
        corpus: corpusName,
        noise,
      });
      return response.data;
    } catch (error) {
      if (error) {
        // возвращаем данные ошибки, пришедшие с бэка
        return rejectWithValue(error);
      }
    }
  }
);

// остановка обучения модели
export const stopTrainModelSee = createAsyncThunk(
  'see/stopTrainModel',
  async ({ modelName }: IStopTrainModelProps): Promise<IResponse> => {
    const response: AxiosResponse<IResponse> = await axiosApi.post(`${URL_SEE}/${ENDPOINTS_SEE.STOP_TRAIN}/${modelName}`);
    return response.data;
  }
);

const seeSlice = createSlice({
  name: 'see',
  initialState,
  reducers: {
    // очистка результата ручной проверки модели
    clearManualCheckModelSee: (state) => {
      state.manualCheckModel = initialState.manualCheckModel;
    },
    // очистка статуса обучения модели
    clearTrainModelSee: (state) => {
      state.trainModel = initialState.trainModel;
    },
    // очистка статуса остановки обучения модели
    clearStopTrainModelSee: (state) => {
      state.stopTrainModel = initialState.stopTrainModel;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(manualCheckModelSee.pending, (state) => {
        state.manualCheckModel.status = RequestStatus.LOADING;
      })
      .addCase(manualCheckModelSee.fulfilled, (state, action) => {
        state.manualCheckModel.status = RequestStatus.IDLE;
        state.manualCheckModel.data = action.payload;
      })
      .addCase(manualCheckModelSee.rejected, (state) => {
        state.manualCheckModel.status = RequestStatus.FAILED;
      })
      .addCase(trainModelSee.pending, (state) => {
        state.trainModel.status = RequestStatus.LOADING;
      })
      .addCase(trainModelSee.fulfilled, (state, action) => {
        state.trainModel.status = RequestStatus.IDLE;
        if (action.payload) {
          state.trainModel.error = action.payload.error;
          state.trainModel.message = action.payload.message;
        }
      })
      .addCase(trainModelSee.rejected, (state, action: PayloadAction<unknown>) => {
        state.trainModel.status = RequestStatus.FAILED;
        if (action.payload instanceof AxiosError) {
          state.trainModel.error = action.payload.response?.data.error;
          state.trainModel.message = action.payload.response?.data.message;
        }
      })
      .addCase(stopTrainModelSee.pending, (state) => {
        state.stopTrainModel.status = RequestStatus.LOADING;
      })
      .addCase(stopTrainModelSee.fulfilled, (state, action) => {
        state.stopTrainModel.status = RequestStatus.IDLE;
        state.stopTrainModel.error = action.payload.error;
        state.stopTrainModel.message = action.payload.message;
      })
      .addCase(stopTrainModelSee.rejected, (state) => {
        state.stopTrainModel.status = RequestStatus.FAILED;
      });
  },
});

export const { clearManualCheckModelSee, clearTrainModelSee, clearStopTrainModelSee } = seeSlice.actions;

export const selectManualCheckModelSee = (state: RootState) => state.see.manualCheckModel;
export const selectTrainModelSee = (state: RootState) => state.see.trainModel;
export const selectStopTrainModelSee = (state: RootState) => state.see.stopTrainModel;

export default seeSlice.reducer;
