import { memo, useState } from 'react';
// import { FormControl, InputAdornment, TextField } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan, faUpLong } from '@fortawesome/free-solid-svg-icons';
import cn from 'classnames';
import { useAppDispatch } from '../../../store/hooks';
import { deleteAlias, editingAnswerAlias } from '../../../store/qasSlice';
import useAccessRight from '../../../hooks/useAccessRight';
import useTranslate from '../../../hooks/useTranslate';
import { QAS } from '../../../constants/accessRights';
import { colorRed } from '../../../constants/colors';
import { IAliasProps } from './Alias.props';
import styles from './Alias.module.scss';

const Alias = ({ question, idx, setChangeFlg, filterByQuestion, setInputQuestion, selectedListAliasIdx, checkboxChangeHandler, listType }: IAliasProps): JSX.Element => {
	const [inputAlias, setInputAlias] = useState<string>(question.replace(/\r|\n/, '')); // алиас

	const dispatch = useAppDispatch();

	const isAccess = useAccessRight(); // hook для проверки прав доступа
	const translate = useTranslate(); // hook для перевода текста

	// обработчик увода фокуса из поля с вопросом
	const blurHandler = (value: string): void => {
		// если значение поменялось
		if (value !== question) {
			dispatch(editingAnswerAlias({ idx, alias: value, listType })); // изменяем алиас/кандидата
			setChangeFlg(true); // ставим флаг о несохраненных данных
		}
	};

	return (
		<div className={styles.aliasBlock}>
			<input
				className={styles.aliasBlockCheckbox}
				type='checkbox'
				id={idx.toString()}
				checked={selectedListAliasIdx.includes(idx)}
				onChange={checkboxChangeHandler}
			/>

			{/* <FormControl fullWidth>
				<TextField
					variant="outlined"
					multiline
					maxRows={4}
					value={inputAlias}
					onChange={(e) => setInputAlias(e.target.value)}
					onBlur={e => blurHandler(e.target.value)}
					disabled={!isAccess(QAS.QUESTION_EDIT)}
					InputProps={{
						style: {
							padding: '8px',
							fontSize: 12,
							color: colorPrimary,
							backgroundColor: (inputAlias.toLowerCase().includes(filterByQuestion.toLowerCase()) && filterByQuestion !== '') ? colorSecondaryLight3 : undefined,
						},
						endAdornment: (
							<InputAdornment position="end">
								{isAccess(QAS.QUESTION_EDIT) &&
									<div className={styles.aliasBlockButtons}>
										<FontAwesomeIcon
											icon={faUpLong}
											size='sm'
											onClick={() => { setInputQuestion(inputAlias); setChangeFlg(true); }}
											title={translate('alias_makeMainTitle')}
											style={{ cursor: 'pointer' }}
										/>
										<FontAwesomeIcon
											icon={faTrashCan}
											color={colorRed}
											size='sm'
											onClick={() => { dispatch(deleteAlias(idx)); setChangeFlg(true); }}
											title={translate('alias_deleteAliasTitle')}
											style={{ cursor: 'pointer' }}
										/>
									</div>
								}
							</InputAdornment>
						),
					}}
				/>
			</FormControl> */}

			<textarea
				rows={Math.ceil(inputAlias.length / 59) || 1} // примерное кол-во символов в строке
				className={cn(styles.textarea, {
					[styles.textareaBackgroundLighting]: inputAlias.toLowerCase().includes(filterByQuestion.toLowerCase()) && filterByQuestion !== '',
				})}
				value={inputAlias}
				onChange={(e) => setInputAlias(e.target.value)}
				onBlur={e => blurHandler(e.target.value)}
				disabled={!isAccess(QAS.QUESTION_EDIT)}
			/>

			{isAccess(QAS.QUESTION_EDIT) &&
				<div className={styles.textareaBlockButtons}>
					{inputAlias !== '' &&
						<FontAwesomeIcon
							icon={faUpLong}
							size='sm'
							onClick={() => { setInputQuestion(inputAlias); setChangeFlg(true); }}
							title={translate('alias_makeMainTitle')}
							style={{ cursor: 'pointer' }}
						/>
					}
					<FontAwesomeIcon
						icon={faTrashCan}
						color={colorRed}
						size='sm'
						onClick={() => { dispatch(deleteAlias({ idx, listType })); setChangeFlg(true); }}
						title={translate('alias_deleteAliasTitle')}
						style={{ cursor: 'pointer' }}
					/>
				</div>
			}
		</div>
	);
};

export default memo(Alias);
