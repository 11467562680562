import { useAppSelector } from '../store/hooks';
import { selectTranslate } from '../store/langSlice';

// hook для перевода текста
const useTranslate = () => {
	const translate = useAppSelector(selectTranslate); // state - словарь для перевода текста

	return (code: string): string => {
		// если в словаре есть перевод - переводим
		if (translate && translate.hasOwnProperty(code)) return translate[code];
		else return code; // иначе оставляем как есть
	};
};

export default useTranslate;
