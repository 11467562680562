import { FormEvent, useEffect, useState } from 'react';
import { Button, FormControl, TextField } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { addChannel, clearChannelList, getChannelList, selectAddingChannel, selectChannelList } from '../../../store/qasSlice';
import useTranslate from '../../../hooks/useTranslate';
import { RequestStatus, ResponseStatus } from '../../../types/statusTypes';
import ModalFormWindow from '../../../HOC/ModalFormWindow/ModalFormWindow';
import ProgressCircle from '../../ProgressCircle/ProgressCircle';
import { IFormAddingChannelProps } from './FormAddingChannel.props';

const FormAddingChannel = ({ showModal, setShowModal, setShowNotification }: IFormAddingChannelProps): JSX.Element => {
	const [inputChannel, setInputChannel] = useState<string>(''); // название канала
	const [showErrorExistingChannel, setShowErrorExistingChannel] = useState<boolean>(false); // флаг существующего имени канала

	const dispatch = useAppDispatch();
	const channelList = useAppSelector(selectChannelList); // store - список каналов
	const addingChanelStatus = useAppSelector(selectAddingChannel); // store - статус добавления канала

	const translate = useTranslate(); // hook для перевода текста

	// следим за статусом добавления канала
	useEffect(() => {
		// если добавление прошло успешно 
		if (addingChanelStatus.error === ResponseStatus.SUCCESS && addingChanelStatus.status === RequestStatus.IDLE && addingChanelStatus.message !== '') {
			dispatch(clearChannelList()); // очищаем список каналов
			dispatch(getChannelList()); // получаем заново список каналов
		}
		// если были изменения в статусе добавления
		if (addingChanelStatus.error !== ResponseStatus.SUCCESS || addingChanelStatus.status === RequestStatus.FAILED || addingChanelStatus.message !== '') {
			handleClose(); // закрываем форму
			setShowNotification(true); // включаем уведомление
		}
	}, [addingChanelStatus]);

	// обработчик добавления канала
	const submitHandler = (e: FormEvent<HTMLFormElement>): void => {
		e.preventDefault();
		const existingChannelName = channelList.data.find(channel => channel.name === inputChannel); // существующее имя канала
		if (existingChannelName) {
			setShowErrorExistingChannel(true); // подсвечиваем ошибку
		} else {
			showErrorExistingChannel && setShowErrorExistingChannel(false); // убираем ошибку, если была
			dispatch(addChannel(inputChannel)); // добавление канала
		}
	};

	// обработчик закрытия формы
	const handleClose = (): void => {
		// если идет добавление канала - запрещаем покидать форму
		if (addingChanelStatus.status === RequestStatus.LOADING) return;
		setShowModal(false);
	};

	return (
		<ModalFormWindow showModal={showModal} setShowModal={setShowModal} headerTitle='formAddingChannel_header' close={handleClose}>
			<form onSubmit={(e) => submitHandler(e)}>
				<FormControl fullWidth margin='dense'>
					<TextField
						autoFocus
						required
						id="channelName"
						label={translate('formAddingChannel_inputChannel')}
						variant="outlined"
						value={inputChannel}
						onChange={(e) => setInputChannel(e.target.value)}
						disabled={addingChanelStatus.status === RequestStatus.LOADING}
						error={showErrorExistingChannel}
						helperText={showErrorExistingChannel ? translate('formAddingChannel_channelExists') : ''}
						InputProps={{
							style: {
								height: 33,
								fontSize: 13
							},
						}}
						InputLabelProps={{
							style: {
								fontSize: 13,
							},
						}}
						sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}
					/>
				</FormControl>
				<FormControl fullWidth margin='dense'>
					<Button
						variant="outlined"
						type="submit"
						disabled={addingChanelStatus.status === RequestStatus.LOADING}
						sx={{ fontSize: 11 }}
					>
						{addingChanelStatus.status === RequestStatus.LOADING ?
							<>
								{translate('formAddingChannel_btnAdding')}
								<ProgressCircle isBtnDisabled />
							</>
							:
							translate('formAddingChannel_btnAdd')
						}
					</Button>
				</FormControl>
			</form>
		</ModalFormWindow>
	);
};

export default FormAddingChannel;
