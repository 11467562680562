import { FormEvent, useEffect, useState } from 'react';
import { DateTime } from "luxon";
import { Button, FormControl, Slide, TextField } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { getUserLogs, selectUserLogs } from '../../store/userSlice';
import useAccessRight from '../../hooks/useAccessRight';
import useTranslate from '../../hooks/useTranslate';
import { USER } from '../../constants/accessRights';
import { colorPrimary } from '../../constants/colors';
import { RequestStatus, ResponseStatus } from '../../types/statusTypes';
import UserLogsTable from '../Tables/UserLogs/UserLogs';
import ProgressCircle from '../ProgressCircle/ProgressCircle';
import { IUserLogsProps } from './UserLogs.props';
import styles from './UserLogs.module.scss';

const UserLogs = ({ showUserLogs, setShowUserLogs }: IUserLogsProps): JSX.Element => {
	const now = DateTime.now();

	const [startDate, setStartDate] = useState<string>(now.minus({ day: 1 }).toISODate() || ''); // дата начала логов
	const [startTime, setStartTime] = useState<string>('00:00'); // время начала логов
	const [endDate, setEndDate] = useState<string>(now.toISODate() || ''); // дата конца логов
	const [endTime, setEndTime] = useState<string>(now.toISOTime()?.slice(0, 5) || ''); // время конца логов

	const dispatch = useAppDispatch();
	const userLogs = useAppSelector(selectUserLogs); // store - логи пользователей

	const isAccess = useAccessRight(); // hook для проверки прав доступа
	const translate = useTranslate(); // hook для перевода текста

	useEffect(() => {
		dispatch(getUserLogs({ from: `${startDate} ${startTime}`, to: `${endDate} ${endTime}` })); // получаем логи пользователей
	}, []);

	// обработчик запроса логов
	const submitHandler = (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		dispatch(getUserLogs({ from: `${startDate} ${startTime}`, to: `${endDate} ${endTime}` })); // получаем логи пользователей
	};

	// обработчик закрытия вкладки
	const closeHandler = (): void => {
		setShowUserLogs(false);
	};

	return (
		<Slide direction="left" in={showUserLogs} mountOnEnter unmountOnExit>
			<div className={styles.modal} onMouseDown={closeHandler}>
				<div className={styles.userLogs} onMouseDown={(e) => e.stopPropagation()}>
					<form onSubmit={submitHandler}>
						<div className={styles.userLogsSetInterval}>
							<div className={styles.userLogsBlock}>
								<FormControl fullWidth margin='dense'>
									<TextField
										required
										label={translate('userLogs_inputStart')}
										variant="outlined"
										type='date'
										value={startDate}
										onChange={(e) => setStartDate(e.target.value)}
										disabled={userLogs.status === RequestStatus.LOADING}
										InputProps={{
											style: {
												height: 33,
												fontSize: 13,
												color: colorPrimary,
											},
											inputProps: { max: now.toISODate() }
										}}
										InputLabelProps={{
											style: {
												fontSize: 13,
											},
										}}
										sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}
									/>
								</FormControl>
								<FormControl fullWidth margin='dense'>
									<TextField
										required
										// label={translate('Время')}
										variant="outlined"
										type='time'
										value={startTime}
										onChange={(e) => setStartTime(e.target.value)}
										disabled={userLogs.status === RequestStatus.LOADING}
										InputProps={{
											style: {
												height: 33,
												fontSize: 13,
												color: colorPrimary,
											},
										}}
										InputLabelProps={{
											style: {
												fontSize: 13,
											},
										}}
										sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}
									/>
								</FormControl>
							</div>

							<div className={styles.userLogsBlock}>
								<FormControl fullWidth margin='dense'>
									<TextField
										required
										label={translate('userLogs_inputEnd')}
										variant="outlined"
										type='date'
										value={endDate}
										onChange={(e) => setEndDate(e.target.value)}
										disabled={userLogs.status === RequestStatus.LOADING}
										InputProps={{
											style: {
												height: 33,
												fontSize: 13,
												color: colorPrimary,
											},
											inputProps: { max: now.toISODate() }
										}}
										InputLabelProps={{
											style: {
												fontSize: 13,
											},
										}}
										sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}
									/>
								</FormControl>
								<FormControl fullWidth margin='dense'>
									<TextField
										required
										// label={translate('Время')}
										variant="outlined"
										type='time'
										value={endTime}
										onChange={(e) => setEndTime(e.target.value)}
										disabled={userLogs.status === RequestStatus.LOADING}
										InputProps={{
											style: {
												height: 33,
												fontSize: 13,
												color: colorPrimary,
											},
										}}
										InputLabelProps={{
											style: {
												fontSize: 13,
											},
										}}
										sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}
									/>
								</FormControl>
							</div>
							<div className={styles.userLogsBlock}>
								<FormControl fullWidth margin='dense'>
									<Button
										variant="outlined"
										type="submit"
										sx={{ fontSize: 11, height: 33 }}
										disabled={!isAccess(USER.LOGS) || userLogs.status === RequestStatus.LOADING}
									>
										{translate('userLogs_showLogsBtn')}
									</Button>
								</FormControl>
							</div>
						</div>
					</form>

					{/* загрузка логов */}
					{userLogs.status === RequestStatus.LOADING &&
						<div className={styles.loading}>
							<ProgressCircle title={translate('progressCircle_userLogsLoadingTitle')} />
						</div>
					}

					{/* ошибка получения логов */}
					{(userLogs.status === RequestStatus.FAILED || userLogs.error === ResponseStatus.FAILED) &&
						<div className={styles.failed}>
							{translate(userLogs.message || 'userLogs_failedTitle')}
						</div>
					}

					{/* пустой список */}
					{userLogs.status === RequestStatus.IDLE && userLogs.error === ResponseStatus.SUCCESS && userLogs.logs.length === 0 &&
						<div className={styles.empty}>
							{translate('userLogs_emptyTitle')}
						</div>
					}

					{/* логи */}
					{userLogs.status === RequestStatus.IDLE && userLogs.error === ResponseStatus.SUCCESS && userLogs.logs.length > 0 &&
						<UserLogsTable />
					}

					<div className={styles.tagClose} onClick={closeHandler}>
						{translate('tag_close')}
					</div>
				</div>
			</div>
		</Slide>
	);
};

export default UserLogs;
