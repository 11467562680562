import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { Slide } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { clearDeleteSpeaker, clearSpeakers, deleteSpeaker, getSpeakers, selectAddSpeaker, selectDeleteSpeaker, selectEditSpeaker, selectSearchSpeaker, selectSpeakers } from '../../../store/sbsSlice';
import { selectDataServers } from '../../../store/serverSlice';
import useAccessRight from '../../../hooks/useAccessRight';
import useTranslate from '../../../hooks/useTranslate';
import { SBS, SERVER } from '../../../constants/accessRights';
import { SPEAKER_ID } from '../../../constants/cookieNames';
import { RequestStatus, ResponseStatus } from '../../../types/statusTypes';
import AdditionSpeaker from '../Buttons/AdditionSpeaker/AdditionSpeaker';
import Delete from '../Buttons/Delete/Delete';
import UserMenu from '../Buttons/UserMenu/UserMenu';
import Analysis from '../../Analysis/Analysis';
import ServerStatus from '../../ServerStatus/ServerStatus';
import AlertDialog from '../../AlertDialog/AlertDialog';
import Notification from '../../Notification/Notification';
import ScreenLock from '../../ScreenLock/ScreenLock';
import { IBiometryControlsProps } from './BiometryControls.props';
import styles from './BiometryControls.module.scss';

const BiometryControls = ({ setShowPage }: IBiometryControlsProps): JSX.Element => {
	const [showAlertDialogDelete, setShowAlertDialogDelete] = useState<boolean>(false); // показ диалогового окна для удаления спикера
	const [showNotificationDelete, setShowNotificationDelete] = useState<boolean>(false); // показ уведомления удаления спикера

	const [showScreenLock, setShowScreenLock] = useState<{ isShow: boolean, title: string }>({ isShow: false, title: '' }); // показ экрана блокировки и подпись
	const [showAnalysis, setShowAnalysis] = useState<boolean>(false); // показ вкладки анализа
	const [showServers, setShowServers] = useState<boolean>(false); // показ вкладки серверов

	const dispatch = useAppDispatch();
	const speakersList = useAppSelector(selectSpeakers); // store - список спикеров
	const addSpeakerStatus = useAppSelector(selectAddSpeaker); // store - статус добавления спикера
	const editSpeakerStatus = useAppSelector(selectEditSpeaker); // store - статус дополнения спикера
	const deleteSpeakerStatus = useAppSelector(selectDeleteSpeaker); // store - статус удаления спикера
	const searchSpeakerData = useAppSelector(selectSearchSpeaker); // store - поиск спикера
	const dataServers = useAppSelector(selectDataServers); // store - информация о серверах

	const [_cookies, setCookie] = useCookies([SPEAKER_ID]); // hook для работы с cookie
	const isAccess = useAccessRight(); // hook для проверки прав доступа
	const translate = useTranslate(); // hook для перевода текста

	// следим за статусом добавления, дополнения, удаления, поиска спикера
	useEffect(() => {
		if (addSpeakerStatus.status === RequestStatus.LOADING) setShowScreenLock({ isShow: true, title: 'screenLock_speakerAddTitle' }); // если идет добавление спикера
		else if (editSpeakerStatus.status === RequestStatus.LOADING) setShowScreenLock({ isShow: true, title: 'screenLock_speakerAdditionTitle' }); // если идет дополнение спикера
		else if (deleteSpeakerStatus.status === RequestStatus.LOADING) setShowScreenLock({ isShow: true, title: 'screenLock_speakerDeleteTitle' }); // если идет удаление спикера
		else if (searchSpeakerData.status === RequestStatus.LOADING) setShowScreenLock({ isShow: true, title: 'screenLock_searchSpeakerTitle' }); // если идет удаление спикера
		else setShowScreenLock({ isShow: false, title: '' }); // иначе выключаем экран блокировки

		// если добавление прошло успешно
		if (addSpeakerStatus.status === RequestStatus.IDLE && addSpeakerStatus.error === ResponseStatus.SUCCESS && addSpeakerStatus.message !== '' && addSpeakerStatus.id) {
			setCookie(SPEAKER_ID, addSpeakerStatus.id, { path: '/', maxAge: 2_592_000 }); // устанавливаем cookie на месяц
			dispatch(clearSpeakers()); // очищаем список спикеров
			isAccess(SBS.SPEAKERS) && dispatch(getSpeakers()); // получаем список спикеров
		}
		// если удаление прошло успешно
		if (deleteSpeakerStatus.status === RequestStatus.IDLE && deleteSpeakerStatus.error === ResponseStatus.SUCCESS && deleteSpeakerStatus.message !== '') {
			dispatch(clearSpeakers()); // очищаем список спикеров
			isAccess(SBS.SPEAKERS) && dispatch(getSpeakers()); // получаем список спикеров
		}
	}, [addSpeakerStatus, editSpeakerStatus, deleteSpeakerStatus, searchSpeakerData]);

	// обработчик удаления спикера
	const deleteHandler = (): void => {
		setShowAlertDialogDelete(false); // закрываем диалоговое окно
		setShowNotificationDelete(true); // включаем уведомление
		speakersList.activeSpeaker && dispatch(deleteSpeaker(speakersList.activeSpeaker.id)); // удаление спикера
	};

	return (
		<div className={styles.controls}>

			<Slide direction="down" in={true} mountOnEnter unmountOnExit timeout={500}>
				<div className={styles.controlsWrapper}>
					<div className={styles.controlsTitle}>{translate('sbs')}</div>
					<section className={styles.controlsTypeAndFunc}>
						<div className={styles.controlsFunctions}>

							<div className={styles.controlsFunctionsLeftBlock}>
								{isAccess(SBS.SPEAKER_EDIT) &&
									<AdditionSpeaker
										styleNotActive={styles.controlsFunctionBtnNotActive}
										isAvailable={speakersList.activeSpeaker !== null}
									/>
								}
								{isAccess(SBS.SPEAKER_DELETE) &&
									<Delete
										styleNotActive={styles.controlsFunctionBtnNotActive}
										isAvailable={speakersList.activeSpeaker !== null}
										title='controlsBtn_deleteSpeaker'
										setShowAlertDialog={setShowAlertDialogDelete}
									/>
								}
							</div>
						</div>

						<div className={styles.controlsFunctions}>
							<div className={styles.controlsFunctionsRightBlock}>
								<UserMenu setShowPage={setShowPage} />
							</div>
						</div>
					</section>
				</div>
			</Slide>

			{/* бирки */}
			<Slide direction="left" in={true} mountOnEnter unmountOnExit timeout={1000}>
				<section className={styles.tags}>
					{isAccess(SBS.ANALYZE) &&
						<div className={styles.analysisTag} onClick={() => setShowAnalysis(prev => !prev)}>
							<span className={styles.tagTitle}>{translate('tag_analysis')}</span>
						</div>
					}
					{isAccess(SERVER.ADDRESSES) &&
						<div className={styles.serversTag} onClick={() => setShowServers(prev => !prev)}>
							<span className={styles.tagTitle}>
								<span className={dataServers && dataServers.fail && !Object.keys(dataServers?.fail).length ? styles.serversTagStatus : styles.serversTagStatusFailed}></span>
								{translate('tag_servers')}
							</span>
						</div>
					}
				</section>
			</Slide>

			<Analysis showAnalysis={showAnalysis} setShowAnalysis={setShowAnalysis} />
			<ServerStatus showServers={showServers} setShowServers={setShowServers} />

			{showAlertDialogDelete &&
				<AlertDialog
					showAlertDialog={showAlertDialogDelete}
					setShowAlertDialog={setShowAlertDialogDelete}
					submitHandler={deleteHandler}
					title='dialog_deleteSpeaker'
					description='dialog_deleteSpeakerConfirm'
					name={speakersList.activeSpeaker?.name}
				/>
			}
			{showScreenLock.isShow && <ScreenLock title={translate(showScreenLock.title)} />}
			{/* уведомление для удаления спикера */}
			{showNotificationDelete &&
				<Notification
					showNotification={showNotificationDelete}
					setShowNotification={setShowNotificationDelete}
					selectDataResponse={selectDeleteSpeaker}
					clearDataResponse={clearDeleteSpeaker}
					titleFailed='noticeDelete_speakerFailed'
					titleSuccess='noticeDelete_speakerSuccess'
				/>
			}
		</div>
	);
};

export default BiometryControls;
