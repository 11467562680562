import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError, AxiosResponse } from 'axios';
import { RootState } from './store';
import axiosApi from '../helpers/axios';
import { ENDPOINTS_SES, URL_SES } from '../constants/api_endpoints';
import { getPostfixRobotId } from './sesRobotSlice';
import { IAddScriptProps, IAddScriptResponse, IDeleteScriptProps, IEditScriptProps, IGetScriptProps, IResponse, IScriptItem, IScriptResponse, ISesScriptState } from '../types/sesScriptTypes';
import { RequestStatus, ResponseStatus } from '../types/statusTypes';

const initialState: ISesScriptState = {
  scriptList: {
    data: [],
    status: RequestStatus.IDLE,
    error: ResponseStatus.SUCCESS,
    message: '',
  },
  script: {
    name: '',
    script: '',
    status: RequestStatus.IDLE,
    error: ResponseStatus.SUCCESS,
    message: '',
  },
  addingScript: {
    status: RequestStatus.IDLE,
    error: ResponseStatus.SUCCESS,
    message: '',
    id: null,
  },
  editingScript: {
    status: RequestStatus.IDLE,
    error: ResponseStatus.SUCCESS,
    message: '',
  },
  deletingScript: {
    status: RequestStatus.IDLE,
    error: ResponseStatus.SUCCESS,
    message: '',
  },
};

// список скриптов
export const getScriptList = createAsyncThunk(
  'ses/getScriptList',
  async (robotId: string, { getState, rejectWithValue }) => {
    try {
      const response: AxiosResponse<IScriptItem[] | IResponse> = await axiosApi.get(`${URL_SES}/${ENDPOINTS_SES.SCRIPT_LIST}/${getPostfixRobotId(getState() as RootState, robotId)}`);
      return response.data;
    } catch (error) {
      if (error) {
        // возвращаем данные ошибки, пришедшие с бэка
        return rejectWithValue(error);
      }
    }
  }
);

// скрипт
export const getScript = createAsyncThunk(
  'ses/getScript',
  async ({ robotId, scriptId }: IGetScriptProps, { getState, rejectWithValue }) => {
    try {
      const response: AxiosResponse<IScriptResponse | IResponse> = await axiosApi.get(`${URL_SES}/${ENDPOINTS_SES.SCRIPT_GET}/${getPostfixRobotId(getState() as RootState, robotId)}/${scriptId}`);
      return response.data;
    } catch (error) {
      if (error) {
        // возвращаем данные ошибки, пришедшие с бэка
        return rejectWithValue(error);
      }
    }
  }
);

// добавление скрипта
export const addScript = createAsyncThunk(
  'ses/addScript',
  async ({ robotId, name }: IAddScriptProps, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<IAddScriptResponse> = await axiosApi.post(`${URL_SES}/${ENDPOINTS_SES.SCRIPT_ADD}/${robotId}`, {
        data: JSON.stringify({
          name,
        }),
      });
      return response.data;
    } catch (error) {
      if (error) {
        // возвращаем данные ошибки, пришедшие с бэка
        return rejectWithValue(error);
      }
    }
  }
);

// изменение скрипта
export const editScript = createAsyncThunk(
  'ses/editScript',
  async ({ robotId, scriptId, name, code }: IEditScriptProps, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<IResponse> = await axiosApi.post(`${URL_SES}/${ENDPOINTS_SES.SCRIPT_EDIT}/${robotId}/${scriptId}`, {
        data: JSON.stringify({
          name,
          code,
        }),
      });
      return response.data;
    } catch (error) {
      if (error) {
        // возвращаем данные ошибки, пришедшие с бэка
        return rejectWithValue(error);
      }
    }
  }
);

// удаление скрипта
export const deleteScript = createAsyncThunk(
  'ses/deleteScript',
  async ({ robotId, scriptId }: IDeleteScriptProps, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<IResponse> = await axiosApi.delete(`${URL_SES}/${ENDPOINTS_SES.SCRIPT_DELETE}/${robotId}/${scriptId}`);
      return response.data;
    } catch (error) {
      if (error) {
        // возвращаем данные ошибки, пришедшие с бэка
        return rejectWithValue(error);
      }
    }
  }
);

const sesScriptSlice = createSlice({
  name: 'ses',
  initialState,
  reducers: {
    // изменение имени скрипта в списке
    changeNameInScriptList: (state, action: PayloadAction<{ oldName: string, newName: string }>) => {
      state.scriptList.data = state.scriptList.data.map((scriptItem) => {
        if (action.payload.oldName === scriptItem.name) return ({ id: scriptItem.id, name: action.payload.newName });
        else return scriptItem;
      });
    },
    // очистка state
    clearState: (state) => {
      state.scriptList = initialState.scriptList;
      state.script = initialState.script;
      state.addingScript = initialState.addingScript;
      state.editingScript = initialState.editingScript;
      state.deletingScript = initialState.deletingScript;
    },
    // очистка списка скриптов
    clearScriptList: (state) => {
      state.scriptList = initialState.scriptList;
    },
    // очистка скрипта
    clearScript: (state) => {
      state.script = initialState.script;
    },
    // очистка статуса добавления скрипта
    clearAddingScript: (state) => {
      state.addingScript = initialState.addingScript;
    },
    // очистка статуса изменения скрипта
    clearEditingScript: (state) => {
      state.editingScript = initialState.editingScript;
    },
    // очистка статуса удаления скрипта
    clearDeletingScript: (state) => {
      state.deletingScript = initialState.deletingScript;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getScriptList.pending, (state) => {
        state.scriptList.status = RequestStatus.LOADING;
      })
      .addCase(getScriptList.fulfilled, (state, action) => {
        if (Array.isArray(action.payload)) {
          state.scriptList.status = RequestStatus.IDLE;
          state.scriptList.data = action.payload.sort((a, b) => {
            if (a.name > b.name) return 1;
            else if (a.name < b.name) return -1;
            else return 0;
          });
        } else state.scriptList.status = RequestStatus.FAILED;
      })
      .addCase(getScriptList.rejected, (state, action: PayloadAction<unknown>) => {
        state.scriptList.status = RequestStatus.FAILED;
        if (action.payload instanceof AxiosError) {
          state.scriptList.error = action.payload.response?.data.error;
          state.scriptList.message = action.payload.response?.data.message;
        }
      })
      .addCase(getScript.pending, (state) => {
        state.script.status = RequestStatus.LOADING;
      })
      .addCase(getScript.fulfilled, (state, action) => {
        if (action.payload && typeof action.payload === 'object' && 'data' in action.payload) {
          state.script.status = RequestStatus.IDLE;
          state.script.name = action.payload.data.name;
          state.script.script = action.payload.data.code;
        } else state.script.status = RequestStatus.FAILED;
      })
      .addCase(getScript.rejected, (state, action: PayloadAction<unknown>) => {
        state.script.status = RequestStatus.FAILED;
        if (action.payload instanceof AxiosError) {
          state.script.error = action.payload.response?.data.error;
          state.script.message = action.payload.response?.data.message;
        }
      })
      .addCase(addScript.pending, (state) => {
        state.addingScript.status = RequestStatus.LOADING;
      })
      .addCase(addScript.fulfilled, (state, action) => {
        if (action.payload) {
          state.addingScript.status = RequestStatus.IDLE;
          state.addingScript.error = action.payload.error;
          state.addingScript.message = action.payload.message;
          if (action.payload.id) {
            state.addingScript.id = action.payload.id;
          }
        } else state.addingScript.status = RequestStatus.FAILED;
      })
      .addCase(addScript.rejected, (state, action: PayloadAction<unknown>) => {
        state.addingScript.status = RequestStatus.FAILED;
        if (action.payload instanceof AxiosError) {
          state.addingScript.error = action.payload.response?.data.error;
          state.addingScript.message = action.payload.response?.data.message;
        }
      })
      .addCase(editScript.pending, (state) => {
        state.editingScript.status = RequestStatus.LOADING;
      })
      .addCase(editScript.fulfilled, (state, action) => {
        if (action.payload) {
          state.editingScript.status = RequestStatus.IDLE;
          state.editingScript.error = action.payload.error;
          state.editingScript.message = action.payload.message;
        } else state.editingScript.status = RequestStatus.FAILED;
      })
      .addCase(editScript.rejected, (state, action: PayloadAction<unknown>) => {
        state.editingScript.status = RequestStatus.FAILED;
        if (action.payload instanceof AxiosError) {
          state.editingScript.error = action.payload.response?.data.error;
          state.editingScript.message = action.payload.response?.data.message;
        }
      })
      .addCase(deleteScript.pending, (state) => {
        state.deletingScript.status = RequestStatus.LOADING;
      })
      .addCase(deleteScript.fulfilled, (state, action) => {
        if (action.payload) {
          state.deletingScript.status = RequestStatus.IDLE;
          state.deletingScript.error = action.payload.error;
          state.deletingScript.message = action.payload.message;
        } else state.deletingScript.status = RequestStatus.FAILED;
      })
      .addCase(deleteScript.rejected, (state, action: PayloadAction<unknown>) => {
        state.deletingScript.status = RequestStatus.FAILED;
        if (action.payload instanceof AxiosError) {
          state.deletingScript.error = action.payload.response?.data.error;
          state.deletingScript.message = action.payload.response?.data.message;
        }
      });
  },
});

export const { changeNameInScriptList, clearState, clearScriptList, clearScript, clearAddingScript, clearEditingScript, clearDeletingScript } = sesScriptSlice.actions;

export const selectScriptList = (state: RootState) => state.sesScript.scriptList;
export const selectScript = (state: RootState) => state.sesScript.script;
export const selectAddingScript = (state: RootState) => state.sesScript.addingScript;
export const selectEditingScript = (state: RootState) => state.sesScript.editingScript;
export const selectDeletingScript = (state: RootState) => state.sesScript.deletingScript;

export default sesScriptSlice.reducer;
