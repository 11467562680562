import { useState } from 'react';
import { Checkbox, FormControl, ListItemText, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import cn from 'classnames';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { addConditionEntry, selectDataList } from '../../../store/sesSlice';
import useTranslate from '../../../hooks/useTranslate';
import { colorPrimary } from '../../../constants/colors';
import { IFormAddingConditionEntryProps } from './FormAddingConditionEntry.props';
import styles from './FormAddingConditionEntry.module.scss';

const FormAddingConditionEntry = ({ setChangeFlg }: IFormAddingConditionEntryProps): JSX.Element => {
	const translate = useTranslate(); // hook для перевода текста

	const [inputDataElementIds, setInputDataElementIds] = useState<string[]>([translate('formAddingCondition_chooseCondition')]); // список id элементов данных

	const dispatch = useAppDispatch();
	const dataElemList = useAppSelector(selectDataList); // список элементов данных

	// обработчик выбора элементов данных
	const handleChange = (event: SelectChangeEvent<typeof inputDataElementIds>): void => {
		const { target: { value } } = event;
		if (Array.isArray(value) && value.length === 0) setInputDataElementIds([translate('formAddingCondition_chooseCondition')]);
		else setInputDataElementIds(typeof value === 'string' ?
			value.split(',').sort()
			:
			value.sort().filter(elem => elem !== translate('formAddingCondition_chooseCondition'))
		);
	};

	// обработчик закрытия списка
	const closeHandler = (): void => {
		// если изменились данные - пишем в store
		if (!inputDataElementIds.includes(translate('formAddingCondition_chooseCondition'))) {
			dispatch(addConditionEntry(inputDataElementIds));
			setChangeFlg(true); // ставим флаг о несохраненных данных
			setInputDataElementIds([translate('formAddingCondition_chooseCondition')]); // сбрасываем поле
		}
	};

	return (
		<div className={cn(styles.condition, {
			[styles.conditionOpacity]: inputDataElementIds.includes(translate('formAddingCondition_chooseCondition')) || inputDataElementIds.length === 0,
		})}>
			<FormControl fullWidth sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}>
				<Select
					required
					multiple
					value={inputDataElementIds}
					onChange={e => handleChange(e)}
					onClose={closeHandler}
					renderValue={(selected) => selected.map(categoryId => dataElemList.data.find(dataElem => dataElem.id === categoryId)?.name || categoryId).join(', ')}
					style={{ fontSize: 13, height: 33, color: colorPrimary }}
				>
					{dataElemList.data.map((elem) => (
						<MenuItem key={elem.id} value={elem.id} sx={{ textAlign: 'left', padding: 0, color: colorPrimary }}>
							<Checkbox checked={inputDataElementIds.indexOf(elem.id) > -1} size='small' sx={{ height: 25, width: 25 }} />
							<ListItemText primary={elem.name} primaryTypographyProps={{ fontSize: 11 }} />
						</MenuItem>
					))}
				</Select>
			</FormControl>
		</div>
	);
};

export default FormAddingConditionEntry;
