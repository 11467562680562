import { useCookies } from 'react-cookie';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPalette } from '@fortawesome/free-solid-svg-icons';
import cn from 'classnames';
import useTranslate from '../../../../hooks/useTranslate';
import { COLORING_CONFIDENCE } from '../../../../constants/cookieNames';
import { backgroundColor } from '../../../../constants/colors';
import { IToggleColoringProps } from './ToggleColoring.props';
import styles from './ToggleColoring.module.scss';

const ToggleColoring = ({ styleNotActive, isAvailable }: IToggleColoringProps): JSX.Element => {
	const [cookies, setCookie] = useCookies([COLORING_CONFIDENCE]); // hook для работы с cookie
	const translate = useTranslate(); // hook для перевода текста

	// функция изменения состояния раскрашивания достоверности слова в cookie
	const toggleColoring = (): void => {
		if (cookies.coloringConfidence === 'false') {
			setCookie(COLORING_CONFIDENCE, 'true', { path: '/', maxAge: 2_592_000 }); // устанавливаем cookie на месяц
		} else {
			setCookie(COLORING_CONFIDENCE, 'false', { path: '/', maxAge: 2_592_000 }); // устанавливаем cookie на месяц
		}
	};

	return (
		<div
			className={cn({
				[styleNotActive]: !isAvailable,
				[styles.active]: cookies.coloringConfidence !== 'false',
			})}
			onClick={() => isAvailable && toggleColoring()}
			title={translate(cookies.coloringConfidence === 'false' ? 'controlsBtn_toggleColoring_on' : 'controlsBtn_toggleColoring_off')}
		>
			<FontAwesomeIcon
				icon={faPalette}
				size="2xl"
				color={backgroundColor}
			/>
		</div>
	);
};

export default ToggleColoring;
