import { useEffect, useRef, useState } from 'react';
import { useCookies } from 'react-cookie';
import { Fade, FormControl, Slide, TextField } from '@mui/material';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { addActiveSpeaker, clearAddSpeaker, getSpeakers, selectAddSpeaker, selectSearchSpeaker, selectSpeakers } from '../../../store/sbsSlice';
import useAccessRight from '../../../hooks/useAccessRight';
import useTranslate from '../../../hooks/useTranslate';
import { SBS, USER } from '../../../constants/accessRights';
import { SPEAKER_ID } from '../../../constants/cookieNames';
import { RequestStatus } from '../../../types/statusTypes';
import SearchSpeaker from '../../SearchSpeaker/SearchSpeaker';
import FormAddingSpeaker from '../../Forms/FormAddingSpeaker/FormAddingSpeaker';
import ProgressCircle from '../../ProgressCircle/ProgressCircle';
import Notification from '../../Notification/Notification';
import styles from './BiometryNavbar.module.scss';

const BiometryNavbar = (): JSX.Element => {
	const [searchSpeaker, setSearchSpeaker] = useState<string>(''); // поиск спикера
	const [activeSpeakerId, setActiveSpeakerId] = useState<string>(); // id активного спикера
	const [showModal, setShowModal] = useState<boolean>(false); // показ формы добавления спикера
	const [showNotification, setShowNotification] = useState<boolean>(false); // показ уведомлений
	const listRef = useRef<HTMLUListElement | null>(null); // ссылка на список спикеров

	const dispatch = useAppDispatch();
	const speakersList = useAppSelector(selectSpeakers); // store - список спикеров
	const searchSpeakerData = useAppSelector(selectSearchSpeaker); // store - поиск спикера

	const [cookies, setCookie] = useCookies([SPEAKER_ID]); // hook для работы с cookie
	const isAccess = useAccessRight(); // hook для проверки прав доступа
	const translate = useTranslate(); // hook для перевода текста

	// следим за списком спикеров
	useEffect(() => {
		// только если список спикеров не пустой
		if (speakersList.data.length > 0) {
			// если есть запись в cookie и список спикеров содержит эту запись, то передаем ее в обработчик выбора активного спикера, иначе выбираем первого спикера из списка спикеров
			const foundSpeaker = speakersList.data.find(speaker => speaker.id === cookies.speakerId);
			cookies.speakerId && foundSpeaker ?
				speakerHandler(cookies.speakerId, foundSpeaker.name)
				:
				speakerHandler(speakersList.data[0].id, speakersList.data[0].name);
		}
	}, [speakersList.data]);

	// следим за данными поиска спикера
	useEffect(() => {
		// если найден спикер
		if (searchSpeakerData.status === RequestStatus.IDLE && searchSpeakerData.data && 'id' in searchSpeakerData.data) {
			setSearchSpeaker(''); // сбрасываем фильтр
			speakerHandler(searchSpeakerData.data.id, searchSpeakerData.data.name); // передаем ее в обработчик выбора активного спикера
		}
	}, [searchSpeakerData.data]);

	// следим за активным спикером
	useEffect(() => {
		listRef.current?.children[speakersList.data.findIndex(speaker => speaker.id === activeSpeakerId)]?.scrollIntoView({ block: "center" }); // показ активного спикера в центре списка с имеющейся полосой прокрутки
	}, [activeSpeakerId]);

	// обработчик выбора активного спикера
	const speakerHandler = (speakerId: string, speakerName: string): void => {
		dispatch(addActiveSpeaker({ id: speakerId, name: speakerName })); // добавляем активного спикера в store
		setActiveSpeakerId(speakerId); // устанавливаем активного спикера
		setCookie(SPEAKER_ID, speakerId, { path: '/', maxAge: 2_592_000 }); // устанавливаем cookie на месяц
	};

	return (
		<Slide direction="right" in={true} mountOnEnter unmountOnExit timeout={800}>
			<div className={styles.sidebar}>
				{isAccess(SBS.SEARCH) && <SearchSpeaker />}

				<div className={styles.navbar}>
					{/* ошибка загрузки списка спикеров */}
					{speakersList.status === RequestStatus.FAILED &&
						<div className={styles.navbarFailedText}>
							<span>{translate(speakersList.message || 'biometryNavbar_failedTitle')}</span>
							{isAccess(SBS.SPEAKERS) &&
								<span className={styles.navbarFailedUpdate} onClick={() => dispatch(getSpeakers())}>{translate('update')}</span>
							}
						</div>
					}

					{/* пустой список спикеров */}
					{speakersList.status === RequestStatus.IDLE && speakersList.data.length === 0 &&
						<div className={styles.navbarNoSpeakers}>{translate('biometryNavbar_emptyTitle')}</div>
					}

					{/* загрузка списка спикеров */}
					{speakersList.status === RequestStatus.LOADING &&
						<div className={styles.navbarLoading}>
							<ProgressCircle title={translate('progressCircle_speakerListLoadingTitle')} />
						</div>
					}

					{/* поиск спикера */}
					{speakersList.status === RequestStatus.IDLE && speakersList.data.length > 0 &&
						<div className={styles.navbarSearchBlock}>
							<FormControl fullWidth>
								<TextField
									id="speakerSearch"
									label={translate("biometryNavbar_inputSearch")}
									variant="outlined"
									value={searchSpeaker}
									onChange={(e) => setSearchSpeaker(e.target.value)}
									InputProps={{
										style: {
											height: 33,
											fontSize: 13,
										},
									}}
									InputLabelProps={{
										style: {
											fontSize: 13,
										},
									}}
									sx={{
										'.MuiInputLabel-root[data-shrink="false"]': { top: -8 },
										'.MuiInputBase-input': { padding: '0 14px' },
									}}
								/>
							</FormControl>

							{/* пустой список после поиска */}
							{speakersList.data.filter(({ name }) => name.toLowerCase().includes(searchSpeaker.toLowerCase())).length === 0 &&
								<p className={styles.navbarSearchEmpty}>{translate('notFound')}</p>
							}
						</div>
					}

					{/* список спикеров */}
					{speakersList.status === RequestStatus.IDLE && speakersList.data.length > 0 &&
						<ul className={styles.navbarSpeakers} ref={listRef}>
							{speakersList.data
								.filter(({ name }) => name.toLowerCase().includes(searchSpeaker.toLowerCase()))
								.map(({ id, name }) => (
									<li className={styles.navbarSpeakersItem} key={id}>
										<Fade in={true} timeout={500}>
											<div
												className={cn({
													[styles.navbarSpeakersLink]: activeSpeakerId !== id,
													[styles.navbarSpeakersLinkActive]: activeSpeakerId === id,
												})}
												onClick={() => id !== activeSpeakerId && speakerHandler(id, name)}
											>
												<div className={styles.navbarSpeakersLinkLeftBlock} title={name}>
													{name}
												</div>
												<div className={cn(styles.navbarSpeakersLinkIcon, {
													[styles.navbarSpeakersLinkIconActive]: activeSpeakerId === id,
												})}>
													<FontAwesomeIcon icon={faAngleRight} />
												</div>
											</div>
										</Fade>
									</li>
								))}
						</ul>
					}

					<div className={styles.functionButtons}>
						{/* добавление спикера */}
						{isAccess(USER.ADD) && speakersList.status === RequestStatus.IDLE &&
							<Fade in={true} timeout={500}>
								<div className={styles.functionButtonsAddSpeaker} onClick={() => setShowModal(true)} >
									{translate('biometryNavbar_addSpeakerBtn')}
								</div>
							</Fade>
						}
					</div>
				</div>

				{showModal &&
					<FormAddingSpeaker
						showModal={showModal}
						setShowModal={setShowModal}
						setShowNotification={setShowNotification}
					/>
				}

				{showNotification &&
					<Notification
						showNotification={showNotification}
						setShowNotification={setShowNotification}
						selectDataResponse={selectAddSpeaker}
						clearDataResponse={clearAddSpeaker}
						titleFailed={translate('noticeAddSpeaker_failed')}
						titleSuccess={translate('noticeAddSpeaker_success')}
					/>
				}
			</div>
		</Slide>
	);
};

export default BiometryNavbar;
