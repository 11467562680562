import useTranslate from '../../../hooks/useTranslate';
import { IInfoRecognitionProps } from './InfoRecognition.props';
import styles from './InfoRecognition.module.scss';

const InfoRecognition = ({ modelName, created, processed, modified }: IInfoRecognitionProps): JSX.Element => {
	const translate = useTranslate(); // hook для перевода текста

	return (
		<div className={styles.wrapper}>
			<p>
				<span className={styles.keyName}>{translate('infoRecognition_modelTitle')}:</span> "{
					modelName.includes('-new') ? `${modelName.slice(0, -4)}" (${translate('future')})`
						:
						modelName.includes('-planned') ? `${modelName.slice(0, -8)}" (${translate('futureInstall')})`
							:
							modelName + '"'
				}
			</p>
			<p><span className={styles.keyName}>{translate('infoRecognition_createdTitle')}:</span> {created}</p>
			{processed && <p><span className={styles.keyName}>{translate('infoRecognition_processedTitle')}:</span> {processed}</p>}
			{modified &&
				<p><span className={styles.keyName}>{translate('infoRecognition_modifiedTitle')}:</span> {modified}</p>
			}
		</div>
	);
};

export default InfoRecognition;
