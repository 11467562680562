import { FormEvent, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { Button, FormControl, TextField } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { createTranscription, selectCreation } from '../../../store/transcriptionSlice';
import useTranslate from '../../../hooks/useTranslate';
import { SPR_TASK_ID } from '../../../constants/cookieNames';
import { RequestStatus, ResponseStatus } from '../../../types/statusTypes';
import ModalFormWindow from '../../../HOC/ModalFormWindow/ModalFormWindow';
import ProgressCircle from '../../ProgressCircle/ProgressCircle';
import { IFormCreateTranscriptProps } from './FormCreateTranscript.props';

const FormCreateTranscript = ({ showModal, setShowModal, setShowNotification }: IFormCreateTranscriptProps): JSX.Element => {
	const [transcriptName, setTranscriptName] = useState<string>(''); // название стенограммы

	const dispatch = useAppDispatch();
	const transcriptCreationStatus = useAppSelector(selectCreation); // store - статус создания стенограммы

	const [cookies] = useCookies([SPR_TASK_ID]); // hook для работы с cookie
	const translate = useTranslate(); // hook для перевода текста

	// следим за статусом создания стенограммы
	useEffect(() => {
		if (transcriptCreationStatus.error !== ResponseStatus.SUCCESS || transcriptCreationStatus.status === RequestStatus.FAILED || transcriptCreationStatus.message !== '') {
			handleClose(); // закрываем форму
			setShowNotification(true); // включаем уведомление
		}
	}, [transcriptCreationStatus]);

	// обработчик создания стенограммы
	const submitHandler = (e: FormEvent<HTMLFormElement>): void => {
		e.preventDefault();
		dispatch(createTranscription({ transcriptName, taskId: cookies.sprTaskId })); // создаем стенограмму
	};

	// обработчик закрытия формы
	const handleClose = (): void => {
		// если идет создание стенограммы - запрещаем покидать форму
		if (transcriptCreationStatus.status === RequestStatus.LOADING) return;
		setShowModal(false);
	};

	return (
		<ModalFormWindow showModal={showModal} setShowModal={setShowModal} headerTitle='formCreateTranscript_title' close={handleClose}>
			<form onSubmit={(e) => submitHandler(e)}>
				<FormControl fullWidth margin='dense'>
					<TextField
						autoFocus
						required
						id="newTranscript"
						label={translate('formCreateTranscript_inputName')}
						variant="outlined"
						disabled={transcriptCreationStatus.status === RequestStatus.LOADING}
						value={transcriptName}
						onChange={(e) => setTranscriptName(e.target.value)}
						InputProps={{
							style: {
								height: 33,
								fontSize: 13
							},
						}}
						InputLabelProps={{
							style: {
								fontSize: 13,
							},
						}}
						sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}
					/>
				</FormControl>
				<FormControl fullWidth margin='dense'>
					<Button
						variant="outlined"
						type="submit"
						sx={{ fontSize: 11 }}
						disabled={transcriptCreationStatus.status === RequestStatus.LOADING}
					>
						{transcriptCreationStatus.status === RequestStatus.LOADING ?
							<>
								{translate('formCreateTranscript_creationBtn')}
								<ProgressCircle isBtnDisabled />
							</>
							:
							translate('formCreateTranscript_createBtn')
						}
					</Button>
				</FormControl>
			</form>
		</ModalFormWindow>
	);
};

export default FormCreateTranscript;
