import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import useTranslate from '../../hooks/useTranslate';
import { colorRed } from '../../constants/colors';
import { IAlertDialogProps } from './AlertDialogTripleChoice.props';

const AlertDialog = ({ showAlertDialog, setShowAlertDialog, submitHandler, closeHandler, title, description, name, name2 }: IAlertDialogProps): JSX.Element => {
	const translate = useTranslate(); // hook для перевода текста

	// закрываем диалоговое окно
	const closeAlertDialog = (): void => {
		setShowAlertDialog && setShowAlertDialog(false);
	};

	// обработчик нажатия клавиши
	// const keyDownHandler = (e: React.KeyboardEvent<HTMLDivElement>): void => {
	// e.code === 'Enter' && submitHandler('questions');
	// };

	return (
		<Dialog
			open={showAlertDialog}
			onClose={closeHandler || closeAlertDialog}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		// onKeyDown={(e) => keyDownHandler(e)}
		>
			<DialogTitle id="alert-dialog-title" color={colorRed} sx={{ fontSize: 16 }}>
				{translate(title)} {name && `"${name}"`} {name2 && `${translate('dialog_onTitle')} "${name2}"`}
			</DialogTitle>
			<DialogContent sx={{ paddingBottom: 2 }}>
				<DialogContentText id="alert-dialog-description" sx={{ fontSize: 13 }}>
					{translate(description)}
				</DialogContentText>
			</DialogContent>
			<DialogActions sx={{ paddingTop: 0 }}>
				<Button onClick={closeHandler || closeAlertDialog} sx={{ fontSize: 11 }}>{translate('dialog_cancel')}</Button>
				<Button onClick={() => submitHandler('answers')} sx={{ color: colorRed, fontSize: 11 }}>
					{translate('dialog_delOnlyAnswers')}
				</Button>
				<Button onClick={() => submitHandler('questions')} sx={{ color: colorRed, fontSize: 11 }}>
					{translate('dialog_delQuestions')}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default AlertDialog;
