import { useState } from 'react';
import { FormControl, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import cn from 'classnames';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { addConditionRun, selectDataList } from '../../../store/sesSlice';
import useTranslate from '../../../hooks/useTranslate';
import { colorPrimary } from '../../../constants/colors';
import { IFormAddingConditionRunProps } from './FormAddingConditionRun.props';
import styles from './FormAddingConditionRun.module.scss';

const FormAddingConditionRun = ({ setChangeFlg }: IFormAddingConditionRunProps): JSX.Element => {
	const translate = useTranslate(); // hook для перевода текста

	const [inputDataElementIds, setInputDataElementIds] = useState<string>(translate('formAddingCondition_chooseCondition')); // список id элементов данных

	const dispatch = useAppDispatch();
	const dataElemList = useAppSelector(selectDataList); // список элементов данных

	// обработчик выбора условия
	const handleChange = (event: SelectChangeEvent<string>): void => {
		dispatch(addConditionRun(event.target.value)); // добавляем условие
		setChangeFlg(true); // ставим флаг о несохраненных данных
		setInputDataElementIds(translate('formAddingCondition_chooseCondition')); // сбрасываем поле
	};

	return (
		<div className={cn(styles.condition, {
			[styles.conditionOpacity]: inputDataElementIds === translate('formAddingCondition_chooseCondition')
		})}>
			<FormControl fullWidth sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}>
				<Select
					required
					value={inputDataElementIds}
					onChange={e => handleChange(e)}
					style={{ fontSize: 13, height: 33, color: colorPrimary }}
				>
					<MenuItem value={translate('formAddingCondition_chooseCondition')} sx={{ fontSize: 13, color: colorPrimary }} disabled>{translate('formAddingCondition_chooseCondition')}</MenuItem>
					{dataElemList.data.map((elem) => (
						<MenuItem key={elem.id} value={elem.id} sx={{ fontSize: 13, color: colorPrimary }}>{elem.name}</MenuItem>
					))}
				</Select>
			</FormControl>
		</div>
	);
};

export default FormAddingConditionRun;
