import { useEffect } from 'react';
import { Button, Slide } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { clearProcessingQuestionsLog, getLogProcessingQuestions, selectProcessingQuestionsLog, selectProcessingQuestionsProcessStatus } from '../../store/qasSlice';
import useTranslate from '../../hooks/useTranslate';
import { downloadFile } from '../../helpers/downloadFile';
import { RequestStatus, ResponseStatus } from '../../types/statusTypes';
import LogQuestionsTable from './LogQuestionsTable/LogQuestionsTable';
import ProgressCircle from '../ProgressCircle/ProgressCircle';
import { ILogQuestionsProps } from './LogQuestions.props';
import styles from './LogQuestions.module.scss';

const LogQuestions = ({ showLog, setShowLog }: ILogQuestionsProps): JSX.Element => {
	const dispatch = useAppDispatch();
	const processingQuestionsProcessStatus = useAppSelector(selectProcessingQuestionsProcessStatus); // store - статус массовой обработки вопросов
	const logs = useAppSelector(selectProcessingQuestionsLog); // store - логи массовой обработки вопросов

	const translate = useTranslate(); // hook для перевода текста

	// следим за показом вкладки логов
	useEffect(() => {
		(logs.data.length > 0 || logs.status === RequestStatus.FAILED || logs.error === ResponseStatus.FAILED) && dispatch(clearProcessingQuestionsLog()); // если есть данные логов - очищаем
		showLog && dispatch(getLogProcessingQuestions()); // если вкладка логов открыта - получаем логи
		// автозапрос на получение логов каждые 10 сек
		const interval = setInterval(() => {
			dispatch(getLogProcessingQuestions());
		}, 10000);
		showLog === false && clearInterval(interval); // если вкладка логов закрыта - удаляем автозапрос на получение логов
		processingQuestionsProcessStatus.responseStatus === 'stopped' && clearInterval(interval); // если нет обработки - удаляем автозапрос на получение логов

		return () => {
			clearInterval(interval); // при выходе со страницы - удаляем автозапрос на получение логов
		};
	}, [showLog]);

	// экспорт логов в файл .csv
	const exportHandler = (): void => {
		let str: string = 'error\tconfidence\tquestion\tanswer\n';
		logs.data.forEach(log => {
			str += log.error + '\t' + log.confidence + '\t' + log.question.replace(/\r|\n|\t/g, ' ') + '\t' + log.answer.replace(/\r|\n|\t/g, ' ') + '\n';
		});
		downloadFile(str, 'QAS_mass_processing_logs.csv'); // экспорт файла с логами
	};

	return (
		<Slide direction="left" in={showLog} mountOnEnter unmountOnExit>
			<div className={styles.modal} onMouseDown={() => setShowLog(false)}>
				<div className={styles.consoleWrapper} onMouseDown={(e) => e.stopPropagation()}>

					{logs.status === RequestStatus.LOADING && logs.data.length === 0 &&
						<div className={styles.loading}><ProgressCircle title={translate('progressCircle_logLoadingTitle')} /></div>
					}

					<div className={styles.console}>
						{logs.status === RequestStatus.IDLE && logs.data.length === 0 &&
							<div className={styles.noData}>{translate('noData')}</div>
						}
						{(logs.status === RequestStatus.FAILED || logs.error === ResponseStatus.FAILED) &&
							<div className={styles.noData}>{translate(logs.message || 'loadFailed')}</div>
						}
						{logs.data.length > 0 &&
							<div className={styles.consoleInner}>
								<LogQuestionsTable logs={logs.data} responseStatus={processingQuestionsProcessStatus.responseStatus} />
								<div className={styles.consoleInnerBottom}>
									<span>{translate('logQuestions_questionsProcessedTitle')}: {logs.data.length}</span>
									<Button
										variant="outlined"
										onClick={exportHandler}
										sx={{ fontSize: 11 }}
									>
										{translate('logQuestions_exportBtn')}
									</Button>
								</div>
							</div>
						}
					</div>

					<div className={styles.tagClose} onClick={() => setShowLog(false)}>
						{translate('tag_close')}
					</div>
				</div>
			</div>
		</Slide>
	);
};

export default LogQuestions;
