export const colorPrimary = '#918C8C'; // серый

export const colorSecondary = '#1976d2'; // синий
export const colorSecondaryLight = '#4a9bec'; // светло-синий
export const colorSecondaryLight2 = '#b2d1f0'; // еще светлее 0-10%
export const colorSecondaryLight3 = '#e1f0ff'; // вообще светлый
export const colorSecondaryRGB = [25, 118, 210]; // синий RGB

export const colorGreen = '#3A9B17';
export const colorRed = '#db6969';
export const colorRedRGB = [255, 0, 0];

export const backgroundColor = '#F6F6F9'; // светло-серый
export const backgroundColorGradient = 'linear-gradient(145deg, rgba(145, 140, 140, 1) 0%, rgba(25, 118, 210, 1) 30%, rgba(178, 209, 240, 1) 70%, rgba(145, 140, 140, 1) 100%)';
export const backgroundColorSky = 'linear-gradient(rgb(18, 124, 205) 28%, rgb(0, 73, 162) 84%)';
