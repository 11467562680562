import { useEffect, useMemo, useRef, useState } from 'react';
import { flexRender, getCoreRowModel, useReactTable, ColumnDef, getFilteredRowModel, SortingState, getSortedRowModel } from '@tanstack/react-table';
import ReactJson from 'react-json-view';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowUp, faXmark } from '@fortawesome/free-solid-svg-icons';
import cn from 'classnames';
import { useAppSelector } from '../../../store/hooks';
import useTranslate from '../../../hooks/useTranslate';
import { selectUserList, selectUserLogs } from '../../../store/userSlice';
import Filter from './Filter/Filter';
import { UserLogRow } from '../../../types/tableTypes';
import styles from './UserLogs.module.scss';

const UserLogs = () => {
	const [data, setData] = useState<UserLogRow[]>([]); // логи, преобразованные для работы в таблице
	const [sorting, setSorting] = useState<SortingState>([]); // сортированные логи
	const tableContainerRef = useRef<HTMLDivElement>(null); // ссылка на контейнер таблицы
	const tBodyRef = useRef<HTMLTableSectionElement>(null); // ссылка на body таблицы
	const [showPayload, setShowPayload] = useState<{ isShow: boolean, idx: number }>({ isShow: false, idx: 0 }); // показ payload в popUp'е

	const userList = useAppSelector(selectUserList); // store - список пользователей
	const userLogs = useAppSelector(selectUserLogs); // store - логи пользователей

	const translate = useTranslate(); // hook для перевода текста

	// следим за логами пользователей
	useEffect(() => {
		setData(userLogs.logs.map(([date, userId, request, payload]) => {
			return { date, userId: findUsername(userId), request, payload: JSON.stringify(payload) };
		})); // преобразуем в формат таблицы
	}, [userLogs.logs]);

	// поиск имени пользователя по id
	const findUsername = (userId: string): string => {
		if (Array.isArray(userList.data)) {
			const userFound = userList.data.find(user => user.id === userId);
			return userFound ? `${userFound.username} (${userFound.fullname})` : userId;
		} else return userId;
	};

	const columns = useMemo<ColumnDef<UserLogRow, string>[]>(() => [
		{
			id: 'date',
			header: translate('userLogs_dateColumnTitle'),
			accessorKey: 'date',
			// size: 100,
		}, {
			id: 'userId',
			header: translate('userLogs_userColumnTitle'),
			accessorKey: 'userId',
			// size: 100,
		}, {
			id: 'request',
			header: translate('userLogs_requestColumnTitle'),
			accessorKey: 'request',
			// size: 200,
		}, {
			id: 'payload',
			header: translate('userLogs_payloadColumnTitle'),
			accessorKey: 'payload',
			// size: 200,
		}
	], []);

	const table = useReactTable({
		data,
		columns,
		state: {
			sorting,
		},
		filterFns: {
			myCustomFilter: () => {
				return false;
			}
		},
		enableColumnResizing: true, // изменение ширины столбца
		onSortingChange: setSorting, // для сортировки
		getSortedRowModel: getSortedRowModel(), // для сортировки
		getCoreRowModel: getCoreRowModel(),
		getFilteredRowModel: getFilteredRowModel(), // для фильтрации
		columnResizeMode: 'onChange', // изменение ширины столбца в runtime
	});

	return (
		<div className={styles.wrapper}>
			<div className={styles.container} ref={tableContainerRef}>
				<table className={styles.table} style={{ width: table.getCenterTotalSize() }}>
					<thead className={cn(styles.thead, {
						[styles.theadBoxShadow]: tableContainerRef.current && tableContainerRef.current.scrollTop > 0, // тень из под шапки и размытие под ним, когда скроллим таблицу
					})}>
						{table.getHeaderGroups().map(headerGroup => (
							<tr className={styles.tr} key={headerGroup.id}>
								{headerGroup.headers.map(header => (
									<th className={styles.th} {...{
										key: header.id,
										colSpan: header.colSpan,
										style: { width: header.getSize() }
									}}>
										{!header.isPlaceholder &&
											<>
												<div onClick={header.column.getToggleSortingHandler()} className={styles.thTitle}>
													{flexRender(header.column.columnDef.header, header.getContext())}
													{{ asc: <FontAwesomeIcon icon={faArrowUp} />, desc: <FontAwesomeIcon icon={faArrowDown} /> }[header.column.getIsSorted() as string] ?? null}
												</div>
												{header.column.getCanFilter() &&
													<Filter column={header.column} />
												}
											</>
										}
									</th>
								))}
							</tr>
						))}
					</thead>
					<tbody className={styles.tbody} ref={tBodyRef}>
						{table.getRowModel().rows.map((row, rowIdx) => (
							<tr className={styles.tr} key={row.id}>
								{row.getVisibleCells().map(cell => (
									<td
										className={cn(styles.td, {
											[styles.tdPayload]: cell.column.id === 'payload',
										})}
										title={(cell.getValue() !== undefined && cell.column.id !== 'payload') ? String(cell.getValue()) : ''}
										{...{
											key: cell.id,
											style: {
												width: cell.column.getSize()
											},
										}}
										onClick={() => cell.column.id === 'payload' && setShowPayload({ isShow: true, idx: rowIdx })}>
										{flexRender(cell.column.columnDef.cell, cell.getContext())}
									</td>
								)
								)}
							</tr>
						))}
					</tbody>
				</table>

				{table.getRowModel().rows.length === 0 && <div className={styles.notFound}>{translate('notFound')}</div>}

				{showPayload.isShow && data[showPayload.idx].payload !== 'null' &&
					<div className={styles.popup}>
						<button
							className={styles.popupCloseBtn}
							onClick={() => setShowPayload({ isShow: false, idx: 0 })}
							title={translate('userLogs_closeBtn')}
						>
							<FontAwesomeIcon icon={faXmark} />
						</button>
						<div className={styles.popupInner}>
							<ReactJson
								src={JSON.parse(data[showPayload.idx].payload)}
								name={false} // имя root объекта
								displayObjectSize={false} // кол-во элементов
								enableClipboard={false} // значок копирования
								displayDataTypes={false} // тип данных
							/>
						</div>
					</div>
				}
			</div>
		</div>
	);
};

export default UserLogs;
