import { FormEvent, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { Button, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { addQuestion, clearQuestionsList, getQuestionsList, selectAddingQuestion, selectCategoriesList, selectFilter } from '../../../store/qasSlice';
import useAccessRight from '../../../hooks/useAccessRight';
import useTranslate from '../../../hooks/useTranslate';
import { QAS } from '../../../constants/accessRights';
import { QAS_QUESTION_ID } from '../../../constants/cookieNames';
import { RequestStatus, ResponseStatus } from '../../../types/statusTypes';
import ModalFormWindow from '../../../HOC/ModalFormWindow/ModalFormWindow';
import ProgressCircle from '../../ProgressCircle/ProgressCircle';
import { IFormAddingQuestionProps } from './FormAddingQuestion.props';

const FormAddingQuestion = ({ showModal, setShowModal, setShowNotification }: IFormAddingQuestionProps): JSX.Element => {
	const [questionInput, setQuestionInput] = useState<string>(''); // вопрос
	const [categoryIdSelect, setCategoryIdSelect] = useState<string>(''); // id категории

	const dispatch = useAppDispatch();
	const filter = useAppSelector(selectFilter); // store - фильтр
	const categoriesList = useAppSelector(selectCategoriesList); // store - список категорий
	const addingQuestionStatus = useAppSelector(selectAddingQuestion); // store - статус добавления вопроса

	const [_cookies, setCookie] = useCookies([QAS_QUESTION_ID]); // hook для работы с cookie
	const isAccess = useAccessRight(); // hook для проверки прав доступа
	const translate = useTranslate(); // hook для перевода текста

	// следим за статусом добавления вопроса
	useEffect(() => {
		// если вопрос добавился и без ошибок
		if (addingQuestionStatus.error === ResponseStatus.SUCCESS && addingQuestionStatus.status === RequestStatus.IDLE && addingQuestionStatus.message !== '') {
			setCookie(QAS_QUESTION_ID, addingQuestionStatus.id, { path: '/', maxAge: 2_592_000 }); // устанавливаем cookie на месяц
			dispatch(clearQuestionsList()); // очищаем список вопросов
			isAccess(QAS.QUESTION_LIST) && dispatch(getQuestionsList({ answer: filter.answer, question: filter.question })); // получаем заново список вопросов
		}

		// если изменился статус добавления
		if (addingQuestionStatus.error !== ResponseStatus.SUCCESS || addingQuestionStatus.status === RequestStatus.FAILED || addingQuestionStatus.message !== '') {
			handleClose(); // закрываем форму
			setShowNotification(true); // включаем уведомление
		}
	}, [addingQuestionStatus]);

	// обработчик добавления вопроса
	const submitHandler = (e: FormEvent<HTMLFormElement>): void => {
		e.preventDefault();
		dispatch(addQuestion({ question: questionInput, categoryId: categoryIdSelect })); // добавление вопроса
	};

	// обработчик закрытия формы
	const handleClose = (): void => {
		// если идет добавление вопроса - запрещаем покидать форму
		if (addingQuestionStatus.status === RequestStatus.LOADING) return;
		setShowModal(false); // закрытие модального окна
	};

	return (
		<ModalFormWindow showModal={showModal} setShowModal={setShowModal} headerTitle='formAddingQuestion_header' close={handleClose}>
			<form onSubmit={(e) => submitHandler(e)}>
				<FormControl fullWidth margin='dense' sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}>
					<InputLabel id="category-label" sx={{ fontSize: 13 }}>{translate('formAddingQuestion_selectCategory')}</InputLabel>
					<Select
						required
						labelId="category-label"
						id="category"
						label={translate('formAddingQuestion_selectCategory')}
						disabled={addingQuestionStatus.status === RequestStatus.LOADING}
						value={categoryIdSelect}
						onChange={(e) => setCategoryIdSelect(e.target.value)}
						style={{ fontSize: 13, height: 33 }}
					>
						{categoriesList.data.map((category) => (
							<MenuItem key={category.id} value={category.id} sx={{ fontSize: 13 }}>{category.name}</MenuItem>
						))}
					</Select>
				</FormControl>
				<FormControl fullWidth margin='dense'>
					<TextField
						autoFocus
						required
						multiline
						minRows={2}
						maxRows={7}
						disabled={addingQuestionStatus.status === RequestStatus.LOADING}
						id="question"
						label={translate("formAddingQuestion_inputQuestion")}
						variant="outlined"
						value={questionInput}
						onChange={(e) => setQuestionInput(e.target.value)}
						InputProps={{
							style: {
								fontSize: 13,
								padding: '8px 14px',
							},
						}}
						InputLabelProps={{
							style: {
								fontSize: 13,
							},
						}}
						sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}
					/>
				</FormControl>
				<FormControl fullWidth margin='dense'>
					<Button
						variant="outlined"
						type="submit"
						disabled={addingQuestionStatus.status === RequestStatus.LOADING}
						sx={{ fontSize: 11 }}
					>
						{addingQuestionStatus.status === RequestStatus.LOADING ?
							<>
								{translate('formAddingQuestion_addingBtn')}
								<ProgressCircle isBtnDisabled />
							</>
							:
							translate('formAddingQuestion_addBtn')
						}
					</Button>
				</FormControl>
			</form>
		</ModalFormWindow>
	);
};

export default FormAddingQuestion;
