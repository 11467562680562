import { Box, Modal } from '@mui/material';
import useTranslate from '../../hooks/useTranslate';
import { IModalFormWindowProps } from './ModalFormWindow.props';
import styles from './ModalFormWindow.module.scss';

const ModalFormWindow = ({ children, showModal, setShowModal, headerTitle, close }: IModalFormWindowProps): JSX.Element => {
	const translate = useTranslate(); // hook для перевода текста

	const style = {
		position: 'absolute' as 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: 500,
		bgcolor: 'background.paper',
		borderRadius: '4px',
		border: 'none',
		boxShadow: 24,
	};

	// обработчик закрытия модального окна
	const handleClose = (): void => {
		setShowModal(false);
	};

	return (
		<Modal
			open={showModal}
			onClose={close || handleClose}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<Box sx={style}>
				<div className={styles.container}>
					<h2 className={styles.header}>
						{translate(headerTitle)}
					</h2>

					{children}
				</div>
			</Box>
		</Modal>
	);
};

export default ModalFormWindow;
