import cn from 'classnames';
import { useAppSelector } from '../../store/hooks';
import { selectActiveType } from '../../store/modelSlice';
import { IBlockInfoProps } from './BlockInfo.props';
import styles from './BlockInfo.module.scss';

const BlockInfo = ({ children, height, flexGrow }: IBlockInfoProps): JSX.Element => {
	const activeType = useAppSelector(selectActiveType);

	return (
		<section
			className={cn(styles.blockInfo, {
				[styles.shadowFuture]: activeType === 'future',
				[styles.shadowCurrent]: activeType === 'current',
				[styles.shadowPrevious]: activeType === 'previous'
			})}
			style={{ height, flexGrow }}
		>
			{children}
		</section>
	);
};

export default BlockInfo;
