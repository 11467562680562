import { useEffect, useRef, useState } from 'react';
import { useCookies } from 'react-cookie';
import cn from 'classnames';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import useTranslate from '../../hooks/useTranslate';
import { selectActiveType, selectAllModels, selectModelName } from '../../store/modelSlice';
import { changeTimestamp, clearRecognitionData, getAsyncResult, selectRecognitionAsyncData, selectRecognitionData, selectRecognitionDataWebSocket } from '../../store/sprSlice';
import useAccessRight from '../../hooks/useAccessRight';
import { colorizeWord } from '../../helpers/colorizeWord';
import { SPR } from '../../constants/accessRights';
import { COLORING_CONFIDENCE, SPR_TASK_ID } from '../../constants/cookieNames';
import { RequestStatus } from '../../types/statusTypes';
import Replica from '../Replica/Replica';
import ProgressCircle from '../ProgressCircle/ProgressCircle';
import styles from './ResultRecognition.module.scss';

const ResultRecognition = (): JSX.Element => {
	const [activeLineIndexList, setActiveLineIndexList] = useState<number[]>([0]); // список индексов активных реплик
	const listRef = useRef<HTMLUListElement>(null); // ссылка на список реплик

	const dispatch = useAppDispatch();
	const allModels = useAppSelector(selectAllModels); // все модели
	const modelName = useAppSelector(selectModelName); // store - имя активной модели
	const activeType = useAppSelector(selectActiveType); // store - активный тип модели
	const recognitionData = useAppSelector(selectRecognitionData); // store - данные распознавания речи
	const recognitionAsyncData = useAppSelector(selectRecognitionAsyncData); // store - асинхронное распознавание речи
	const recognitionDataWebSocket = useAppSelector(selectRecognitionDataWebSocket); // store - данные распознавания речи webSocket

	const isAccess = useAccessRight(); // hook для проверки прав доступа
	const [cookies] = useCookies([SPR_TASK_ID, COLORING_CONFIDENCE]); // hook для работы с cookie
	const translate = useTranslate(); // hook для перевода текста

	// следим за именем активной модели и типом модели и сбрасываем данные распознавания
	useEffect(() => {
		allModels.models && (recognitionData.data !== null || recognitionData.status === RequestStatus.FAILED) && dispatch(clearRecognitionData());
	}, [modelName, activeType]);

	// следим за списком индексов активной реплики и ставим в центр страницы последнюю
	useEffect(() => {
		listRef.current?.children[activeLineIndexList[activeLineIndexList.length - 1]]?.scrollIntoView({ block: "center", behavior: 'smooth' });
	}, [activeLineIndexList]);

	// сбрасываем список активных индексов при смене статуса
	useEffect(() => {
		setActiveLineIndexList([]);
	}, [recognitionData.status]);

	// следим за статусом распознавания по webSocket, и при старте - очищаем данные распозвания по http, если были
	useEffect(() => {
		if (recognitionDataWebSocket.webSocketStatus !== 3) {
			recognitionData.data && dispatch(clearRecognitionData());
		}
	}, [recognitionDataWebSocket.webSocketStatus]);

	return (
		<div className={styles.checkResult}>
			{recognitionData.status === RequestStatus.LOADING &&
				<div className={styles.loading}><ProgressCircle title={translate('progressCircle_recognitionTitle')} /></div>
			}
			{recognitionAsyncData.requestStatus === RequestStatus.LOADING &&
				<div className={styles.loading}><ProgressCircle title={translate('progressCircle_dataRecognitionLoadingTitle')} /></div>
			}

			{recognitionData.status === RequestStatus.FAILED &&
				<p className={styles.failedText}>{translate('recognition_loadFailedRecognitionTitle')}</p>
			}
			{recognitionAsyncData.requestStatus === RequestStatus.FAILED &&
				<p className={styles.failedText}>{translate('notFound')}</p>
			}
			{recognitionAsyncData.responseStatus && recognitionAsyncData.requestStatus === RequestStatus.IDLE &&
				<div className={styles.failedText}>
					<p>{translate('recognition_statusTitle')}: {translate(recognitionAsyncData.responseStatus)}</p>
					{isAccess(SPR.ASYNC_RESULT) &&
						<button className={styles.btnUpdate} onClick={() => dispatch(getAsyncResult(cookies.sprTaskId))}>
							{translate('recognition_btnUpdate')}
						</button>
					}
				</div>
			}

			{recognitionData.data !== null &&
				<>
					{'message' in recognitionData.data ?
						<p className={styles.failedText}>
							{translate('recognition_errorTitle')}: {translate(recognitionData.data.message || 'recognition_loadFailedRecognitionTitle')}
						</p>
						:
						<div className={styles.checkResultEditorWrapper}>
							<ul className={styles.wrapper} ref={listRef}>
								{recognitionData.data && typeof recognitionData.data === 'object' && 'splitted' in recognitionData.data && recognitionData.data.splitted ?
									recognitionData.data.splitted.map((fragment, idx, array) => {
										// если список не содержал активную запись и временная метка подходит для этого фрагмента - записывааем индекс в state
										if (!activeLineIndexList.includes(idx) && recognitionData.timestamp >= fragment.start_ms && recognitionData.timestamp <= fragment.stop_ms) {
											activeLineIndexList.pop(); // ??
											setActiveLineIndexList(prev => [...prev, idx]);
										}
										return (
											<Replica
												fragment={fragment}
												fragmentIdx={idx}
												fragmentsArray={array}
												speakerList={recognitionData.speakerList}
												timestamp={fragment.start_ms <= recognitionData.timestamp && recognitionData.timestamp <= fragment.stop_ms ? recognitionData.timestamp : -1} // для кэширования (не перерисовывается)
												from='recognition'
												key={idx}
											/>
										);
									})
									:
									<>
										{recognitionData.data && typeof recognitionData.data === 'object' && 'words' in recognitionData.data && Array.isArray(recognitionData.data.words) &&
											<li className={cn(styles.line, {
												[styles.lineActive]: recognitionData.timestamp > 0
											})}>
												<span className={styles.speaker}>{translate('recognition_unknownTitle')}</span>
												{recognitionData.data.words.map((word, idx) =>
													<span
														key={idx}
														className={cn(styles.lineWord, {
															[styles.lineWordActiveRed]: recognitionData.timestamp >= word.start && recognitionData.timestamp <= word.stop && typeof word.confidence === 'number' && word.confidence <= 0.7 && (!cookies.coloringConfidence || cookies.coloringConfidence === 'true'),
															[styles.lineWordActiveBlue]: recognitionData.timestamp >= word.start && recognitionData.timestamp <= word.stop && (!word.confidence || (typeof word.confidence === 'number' && word.confidence > 0.7) || cookies.coloringConfidence === 'false')
														})}
														onClick={() => dispatch(changeTimestamp(word.start + 1))}
														style={typeof word.confidence === 'number' && word.confidence <= 0.7 && (!cookies.coloringConfidence || cookies.coloringConfidence === 'true') ? { color: colorizeWord(word.confidence) } : undefined}
														title={`${translate('recognition_wordStartTitle')}: ${word.start}`}
													>
														{word.word}
													</span>
												)}
											</li>
										}
									</>
								}
							</ul>
						</div>
					}
				</>
			}
		</div>
	);
};

export default ResultRecognition;
