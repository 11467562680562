import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosError, AxiosResponse } from 'axios';
import { RootState } from './store';
import { ENDPOINTS_USER, URL_AUTH, URL_USER } from '../constants/api_endpoints';
import axiosApi from '../helpers/axios';
import { IAddUserProps, IAddUserResponse, ICurrentUser, IGetUserLogsProps, IModifyUserProps, IResponse, IUser, IUserLogsResponse, IUserState } from '../types/userTypes';
import { RequestStatus, ResponseStatus } from '../types/statusTypes';

const initialState: IUserState = {
  currentUserInfo: {
    status: RequestStatus.IDLE,
    id: null,
    userName: null,
    fullName: null,
    role: null,
    dataset: null,
    datasets: [],
    rights: [],
    image: null,
    error: ResponseStatus.SUCCESS,
    message: '',
  },
  userList: {
    status: RequestStatus.IDLE,
    data: null,
  },
  userInfo: {
    status: RequestStatus.IDLE,
    data: null,
    userName: null,
  },
  userInfoForCopy: {
    status: RequestStatus.IDLE,
    data: null,
  },
  addUser: {
    status: RequestStatus.IDLE,
    error: ResponseStatus.SUCCESS,
    message: '',
    id: null,
    newName: null,
  },
  modifyUser: {
    status: RequestStatus.IDLE,
    error: ResponseStatus.SUCCESS,
    message: '',
    myself: false,
  },
  deleteUser: {
    status: RequestStatus.IDLE,
    error: ResponseStatus.SUCCESS,
    message: '',
  },
  changePassword: {
    status: RequestStatus.IDLE,
    error: ResponseStatus.SUCCESS,
    message: '',
  },
  userLogs: {
    logs: [],
    status: RequestStatus.IDLE,
    error: ResponseStatus.SUCCESS,
    message: '',
  },
};

// список пользователей
export const getUserList = createAsyncThunk(
  'user/getUserList',
  async (_, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<IResponse | IUser[]> = await axiosApi.get(`${URL_USER}/${ENDPOINTS_USER.LIST}`);
      return response.data;
    } catch (error) {
      if (error) {
        // возвращаем данные ошибки, пришедшие с бэка
        return rejectWithValue(error);
      }
    }
  }
);

// информация о пользователе
export const getUserInfo = createAsyncThunk(
  'user/getUserInfo',
  async (id: string, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<IResponse | IUser> = await axiosApi.get(`${URL_USER}/${ENDPOINTS_USER.GET}/${id}`);
      return response.data;
    } catch (error) {
      if (error) {
        // возвращаем данные ошибки, пришедшие с бэка
        return rejectWithValue(error);
      }
    }
  }
);

// информация о пользователе для копирования прав доступа
export const getUserInfoForCopyRights = createAsyncThunk(
  'user/getUserInfoForCopyRights',
  async (id: string, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<IResponse | IUser> = await axiosApi.get(`${URL_USER}/${ENDPOINTS_USER.GET}/${id}`);
      return response.data;
    } catch (error) {
      if (error) {
        // возвращаем данные ошибки, пришедшие с бэка
        return rejectWithValue(error);
      }
    }
  }
);

// добавить пользователя
export const addUser = createAsyncThunk(
  'user/addUser',
  async (data: IAddUserProps, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<IAddUserResponse> = await axiosApi.post(`${URL_USER}/${ENDPOINTS_USER.ADD}`, {
        data: JSON.stringify(data),
      });
      return response.data;
    } catch (error) {
      if (error) {
        // возвращаем данные ошибки, пришедшие с бэка
        return rejectWithValue(error);
      }
    }
  }
);

// изменить пользователя
export const modifyUser = createAsyncThunk(
  'user/modifyUser',
  async ({ data, id }: IModifyUserProps, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<IResponse> = await axiosApi.post(`${URL_USER}/${ENDPOINTS_USER.MODIFY}/${id}`, {
        data: JSON.stringify(data),
      });
      return response.data;
    } catch (error) {
      if (error) {
        // возвращаем данные ошибки, пришедшие с бэка
        return rejectWithValue(error);
      }
    }
  }
);

// удалить пользователя
export const deleteUser = createAsyncThunk(
  'user/deleteUser',
  async (id: string, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<IResponse> = await axiosApi.delete(`${URL_USER}/${ENDPOINTS_USER.DELETE}/${id}`);
      return response.data;
    } catch (error) {
      if (error) {
        // возвращаем данные ошибки, пришедшие с бэка
        return rejectWithValue(error);
      }
    }
  }
);

// информация о текущем пользователе
export const getCurrentUserInfo = createAsyncThunk(
  'user/getCurrentUserInfo',
  async (_, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<IResponse | ICurrentUser> = await axiosApi.get(`${URL_USER}/${ENDPOINTS_USER.INFO}`);
      return response.data;
    } catch (error) {
      if (error) {
        // возвращаем данные ошибки, пришедшие с бэка
        return rejectWithValue(error);
      }
    }
  }
);

// изменить пароль текущего пользователя
export const changePassword = createAsyncThunk(
  'user/changePassword',
  async (password: string, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<IResponse> = await axiosApi.post(`${URL_USER}/${ENDPOINTS_USER.PASSWORD}`, {
        password,
      });
      return response.data;
    } catch (error) {
      if (error) {
        // возвращаем данные ошибки, пришедшие с бэка
        return rejectWithValue(error);
      }
    }
  }
);

// логи пользователей
export const getUserLogs = createAsyncThunk(
  'user/getUserLogs',
  async ({ from, to }: IGetUserLogsProps, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<IUserLogsResponse> = await axiosApi.post(`${URL_AUTH}/${ENDPOINTS_USER.USER_LOGS}`, {
        from,
        to,
      });
      return response.data;
    } catch (error) {
      if (error) {
        // возвращаем данные ошибки, пришедшие с бэка
        return rejectWithValue(error);
      }
    }
  }
);

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    // очистка списка пользователей
    clearUserList: (state) => {
      state.userList = initialState.userList;
    },
    // очистка информации о пользователе
    clearUserInfo: (state) => {
      state.userInfo = initialState.userInfo;
    },
    // очистка информации о пользователе для копирования прав доступа
    clearUserInfoForCopyRights: (state) => {
      state.userInfoForCopy = initialState.userInfoForCopy;
    },
    // очистка статуса добавления пользователя
    clearAddUserStatus: (state) => {
      state.addUser = initialState.addUser;
    },
    // очистка статуса изменения данных пользователя
    clearModifyUserStatus: (state) => {
      state.modifyUser = initialState.modifyUser;
    },
    // очистка статуса удаления пользователя
    clearDeleteUserStatus: (state) => {
      state.deleteUser = initialState.deleteUser;
    },
    // очистка информации о текущем пользователе
    clearCurrentUserInfo: (state) => {
      state.currentUserInfo = initialState.currentUserInfo;
    },
    // очистка статуса изменения пароля
    clearChangePasswordStatus: (state) => {
      state.changePassword = initialState.changePassword;
    },
    // очистка логов пользователей
    clearUserLogs: (state) => {
      state.userLogs = initialState.userLogs;
    },
    // очистка всех данных, кроме инфо о текущем пользователе
    clearStateExceptCurrentUserInfo: (state) => {
      state.userList = initialState.userList;
      state.userInfo = initialState.userInfo;
      state.addUser = initialState.addUser;
      state.modifyUser = initialState.modifyUser;
      state.deleteUser = initialState.deleteUser;
      state.userLogs = initialState.userLogs;
    },
    // добавление имени пользователя
    addUserName: (state, action: PayloadAction<string | null>) => {
      state.userInfo.userName = action.payload;
    },
    // добавление имени нового пользователя
    addNewUserName: (state, action: PayloadAction<string | null>) => {
      state.addUser.newName = action.payload;
    },
    // флаг изменения данных самого себя
    modifyMyself: (state, action: PayloadAction<boolean>) => {
      state.modifyUser.myself = action.payload;
    },
    // обновление данных пользователя
    updateUserInfo: (state, action: PayloadAction<{
      userName: string;
      userRoleId: string;
      datasetIdDefault: string;
      datasetsListAvailable: string[];
      avatarBase64: string;
    }>) => {
      if (state.userInfo.data && 'id' in state.userInfo.data) {
        state.userInfo.data.fullname = action.payload.userName;
        state.userInfo.data.role = action.payload.userRoleId;
        state.userInfo.data.dataset = action.payload.datasetIdDefault;
        state.userInfo.data.datasets = action.payload.datasetsListAvailable;
        state.userInfo.data.image = action.payload.avatarBase64;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserList.pending, (state) => {
        state.userList.status = RequestStatus.LOADING;
      })
      .addCase(getUserList.fulfilled, (state, action) => {
        if (action.payload && typeof action.payload !== 'string') {
          state.userList.status = RequestStatus.IDLE;
          state.userList.data = action.payload;
        } else {
          state.userList.status = RequestStatus.FAILED;
        }
      })
      .addCase(getUserList.rejected, (state, action: PayloadAction<unknown>) => {
        state.userList.status = RequestStatus.FAILED;
        if (action.payload instanceof AxiosError) {
          state.userList.data = action.payload.response?.data;
        }
      })
      .addCase(getUserInfo.pending, (state) => {
        state.userInfo.status = RequestStatus.LOADING;
      })
      .addCase(getUserInfo.fulfilled, (state, action) => {
        state.userInfo.status = RequestStatus.IDLE;
        if (action.payload && typeof action.payload !== 'string') {
          state.userInfo.data = action.payload;
        }
      })
      .addCase(getUserInfo.rejected, (state, action: PayloadAction<unknown>) => {
        state.userInfo.status = RequestStatus.FAILED;
        if (action.payload instanceof AxiosError) {
          state.userInfo.data = action.payload.response?.data;
        }
      })
      .addCase(getUserInfoForCopyRights.pending, (state) => {
        state.userInfoForCopy.status = RequestStatus.LOADING;
      })
      .addCase(getUserInfoForCopyRights.fulfilled, (state, action) => {
        if (action.payload && typeof action.payload !== 'string') {
          state.userInfoForCopy.data = action.payload;
          state.userInfoForCopy.status = RequestStatus.IDLE;
        } else {
          state.userInfoForCopy.status = RequestStatus.FAILED;
        }
      })
      .addCase(getUserInfoForCopyRights.rejected, (state, action: PayloadAction<unknown>) => {
        state.userInfoForCopy.status = RequestStatus.FAILED;
        if (action.payload instanceof AxiosError) {
          state.userInfoForCopy.data = action.payload.response?.data;
        }
      })
      .addCase(addUser.pending, (state) => {
        state.addUser.status = RequestStatus.LOADING;
      })
      .addCase(addUser.fulfilled, (state, action) => {
        state.addUser.status = RequestStatus.IDLE;
        if (action.payload) {
          state.addUser.error = action.payload.error;
          state.addUser.message = action.payload.message;
          if (action.payload.user_id) {
            state.addUser.id = action.payload.user_id;
          }
        }
      })
      .addCase(addUser.rejected, (state, action: PayloadAction<unknown>) => {
        state.addUser.status = RequestStatus.FAILED;
        if (action.payload instanceof AxiosError) {
          state.addUser.error = action.payload.response?.data.error;
          state.addUser.message = action.payload.response?.data.message;
        }
      })
      .addCase(modifyUser.pending, (state) => {
        state.modifyUser.status = RequestStatus.LOADING;
      })
      .addCase(modifyUser.fulfilled, (state, action) => {
        state.modifyUser.status = RequestStatus.IDLE;
        if (action.payload) {
          state.modifyUser.error = action.payload.error;
          state.modifyUser.message = action.payload.message;
        }
      })
      .addCase(modifyUser.rejected, (state, action: PayloadAction<unknown>) => {
        state.modifyUser.status = RequestStatus.FAILED;
        if (action.payload instanceof AxiosError) {
          state.modifyUser.error = action.payload.response?.data.error;
          state.modifyUser.message = action.payload.response?.data.message;
        }
      })
      .addCase(deleteUser.pending, (state) => {
        state.deleteUser.status = RequestStatus.LOADING;
      })
      .addCase(deleteUser.fulfilled, (state, action) => {
        state.deleteUser.status = RequestStatus.IDLE;
        if (action.payload) {
          state.deleteUser.error = action.payload.error;
          state.deleteUser.message = action.payload.message;
        }
      })
      .addCase(deleteUser.rejected, (state, action: PayloadAction<unknown>) => {
        state.deleteUser.status = RequestStatus.FAILED;
        if (action.payload instanceof AxiosError) {
          state.deleteUser.error = action.payload.response?.data.error;
          state.deleteUser.message = action.payload.response?.data.message;
        }
      })
      .addCase(getCurrentUserInfo.pending, (state) => {
        state.currentUserInfo.status = RequestStatus.LOADING;
      })
      .addCase(getCurrentUserInfo.fulfilled, (state, action) => {
        if (action.payload && typeof action.payload !== 'string') {
          state.currentUserInfo.status = RequestStatus.IDLE;
          if ('username' in action.payload) {
            state.currentUserInfo.id = action.payload.id;
            state.currentUserInfo.userName = action.payload.username;
            state.currentUserInfo.fullName = action.payload.fullname;
            state.currentUserInfo.role = action.payload.role;
            state.currentUserInfo.dataset = action.payload.dataset;
            state.currentUserInfo.datasets = action.payload.datasets;
            state.currentUserInfo.rights = action.payload.rights;
            if (action.payload.image) state.currentUserInfo.image = action.payload.image;
          } else if ('error' in action.payload) {
            state.currentUserInfo.error = action.payload.error;
            state.currentUserInfo.message = action.payload.message;
          }
        } else {
          state.currentUserInfo.status = RequestStatus.FAILED;
        }
      })
      .addCase(getCurrentUserInfo.rejected, (state, action: PayloadAction<unknown>) => {
        state.currentUserInfo.status = RequestStatus.FAILED;
        if (action.payload instanceof AxiosError) {
          state.currentUserInfo.error = action.payload.response?.data.error;
          state.currentUserInfo.message = action.payload.response?.data.message;
        }
      })
      .addCase(changePassword.pending, (state) => {
        state.changePassword.status = RequestStatus.LOADING;
      })
      .addCase(changePassword.fulfilled, (state, action) => {
        state.changePassword.status = RequestStatus.IDLE;
        if (action.payload) {
          state.changePassword.error = action.payload.error;
          state.changePassword.message = action.payload.message;
        }
      })
      .addCase(changePassword.rejected, (state, action: PayloadAction<unknown>) => {
        state.changePassword.status = RequestStatus.FAILED;
        if (action.payload instanceof AxiosError) {
          state.changePassword.error = action.payload.response?.data.error;
          state.changePassword.message = action.payload.response?.data.message;
        }
      })
      .addCase(getUserLogs.pending, (state) => {
        state.userLogs.status = RequestStatus.LOADING;
      })
      .addCase(getUserLogs.fulfilled, (state, action) => {
        if (action.payload && typeof action.payload === 'object' && 'data' in action.payload && Array.isArray(action.payload.data)) {
          state.userLogs.status = RequestStatus.IDLE;
          state.userLogs.logs = action.payload.data;
        } else state.userLogs.status = RequestStatus.FAILED;
      })
      .addCase(getUserLogs.rejected, (state, action: PayloadAction<unknown>) => {
        state.userLogs.status = RequestStatus.FAILED;
        if (action.payload instanceof AxiosError) {
          state.userLogs.error = action.payload.response?.data.error;
          state.userLogs.message = action.payload.response?.data.message;
        }
      });
  },
});

export const { clearUserList, clearUserInfo, clearUserInfoForCopyRights, clearAddUserStatus, clearModifyUserStatus, clearDeleteUserStatus, clearCurrentUserInfo, clearUserLogs, clearStateExceptCurrentUserInfo, addUserName, addNewUserName, modifyMyself, updateUserInfo, clearChangePasswordStatus } = userSlice.actions;

export const selectUserList = (state: RootState) => state.user.userList;
export const selectUserInfo = (state: RootState) => state.user.userInfo;
export const selectUserInfoForCopyRights = (state: RootState) => state.user.userInfoForCopy;
export const selectAddUserStatus = (state: RootState) => state.user.addUser;
export const selectModifyUserStatus = (state: RootState) => state.user.modifyUser;
export const selectDeleteUserStatus = (state: RootState) => state.user.deleteUser;
export const selectCurrentUserInfo = (state: RootState) => state.user.currentUserInfo;
export const selectChangePasswordStatus = (state: RootState) => state.user.changePassword;
export const selectUserLogs = (state: RootState) => state.user.userLogs;

export default userSlice.reducer;
