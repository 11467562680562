import { ChangeEvent, FormEvent, useEffect, useMemo, useRef, useState } from 'react';
import { Button, FormControl, TextField } from '@mui/material';
import Resumable from 'resumablejs';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { importModel, getAllModels, selectImportStatus, getInfoModel, createModel, addImportModelName, changeProgressImportModel, loadingImportModel, successImportModel, failedImportModel, getHandler, selectModelName, selectActiveType } from '../../../store/modelSlice';
import useAccessRight from '../../../hooks/useAccessRight';
import useTranslate from '../../../hooks/useTranslate';
import { ENDPOINTS_MODEL, URL_MODEL } from '../../../constants/api_endpoints';
import { HANDLER } from '../../../constants/accessRights';
import { RequestStatus, ResponseStatus } from '../../../types/statusTypes';
import ModalFormWindow from '../../../HOC/ModalFormWindow/ModalFormWindow';
import ProgressCircle from '../../ProgressCircle/ProgressCircle';
import ProgressLinear from '../../ProgressLinear/ProgressLinear';
import { IFormImportModelProps } from './FormImportModel.props';

const FormImportModel = ({ showModal, setShowModal, create = false, name, serviceType, setShowNotification }: IFormImportModelProps): JSX.Element => {
	const [nameInput, setNameInput] = useState<string>(''); // имя модели
	const [file, setFile] = useState<File>(); // файл для импортирования модели
	const inputRef = useRef(null); // ссылка на input file

	const r = useMemo(() => {
		return new Resumable({
			target: `${URL_MODEL}/${ENDPOINTS_MODEL.IMPORT}/${serviceType}/${name}`,
			// headers: { 'x-access-token': new Cookies().get(ACCESS_TOKEN)},
		});
	}, []); // возобновляемая загрузка

	const dispatch = useAppDispatch();
	const modelName = useAppSelector(selectModelName); // store - имя модели
	const activeType = useAppSelector(selectActiveType); // store - активный типа модели
	const importStatusModel = useAppSelector(selectImportStatus); // state - статус об импортировании модели
	const isAccess = useAccessRight(); // hook для проверки прав доступа
	const translate = useTranslate(); // hook для перевода текста

	// следим за ссылкой на input file
	useEffect(() => {
		if (inputRef.current) {
			file && r.addFile(file); // если файл есть - добавляем в Resumable
			r.assignBrowse(inputRef.current, false); // предоставление Resumable input'а

			// событие при старте загрузки
			r.on("uploadStart", () => {
				dispatch(loadingImportModel());
			});

			// событие прогресса загрузки
			r.on("fileProgress", (file) => {
				dispatch(changeProgressImportModel(file.progress(false)));
			});

			// событие успеха загрузки
			r.on("fileSuccess", () => {
				dispatch(successImportModel());
			});

			// событие ошибки загрузки
			r.on("fileError", (_, msg) => {
				dispatch(failedImportModel(msg));
			});
		}
	}, [inputRef.current]);

	// следим за статусом импортирования модели
	useEffect(() => {
		// если модель создается и нет ошибок - получаем заново все модели
		if (create) {
			importStatusModel.error === ResponseStatus.SUCCESS && importStatusModel.status === RequestStatus.IDLE && importStatusModel.message !== '' && dispatch(getAllModels({ serviceType }));
		} else {
			// иначе если нет ошибок
			if (importStatusModel.error === ResponseStatus.SUCCESS && importStatusModel.status === RequestStatus.IDLE && importStatusModel.message !== '') {
				name && dispatch(getInfoModel({ modelName: name, serviceType })); // обновляем информацию о модели
				isAccess(HANDLER.GET) && modelName && dispatch(getHandler({ modelName, serviceType, modelType: activeType })); // получаем handler.py
			}
		}
		// если изменился статус модели
		if (importStatusModel.error !== ResponseStatus.SUCCESS || importStatusModel.status === RequestStatus.FAILED || importStatusModel.message !== '') {
			handleClose(); // закрываем форму
			setShowNotification(true); // включаем уведомление
		}
	}, [importStatusModel]);

	// обработчик закрытия формы
	const handleClose = (): void => {
		// если идет импорт модели - запрещаем покидать форму
		if (importStatusModel.status === RequestStatus.LOADING) return;
		setShowModal(false);
	};

	// обработчик импортирования модели
	const submitHandler = (e: FormEvent<HTMLFormElement>): void => {
		e.preventDefault();

		if (create) {
			dispatch(createModel({ modelName: nameInput, serviceType })); // если модель создается - создаем модель
			dispatch(addImportModelName(nameInput)); // добавляем в store имя импортируемой модели
		} else {
			// иначе импортируем в существующую модель Resumable'ом (возобновляемой загрузкой) если поддерживается, иначе axios'ом
			// if (r.support) r.upload();
			// else {
			const formData = new FormData();
			if (file) formData.append('file', file);
			name && dispatch(importModel({ modelName: name, formData, serviceType }));
			// 	console.warn('Возобновляемая загрузка не поддерживается!');
			// }
		}
	};

	return (
		<ModalFormWindow
			showModal={showModal}
			setShowModal={setShowModal}
			headerTitle={create ? "formImportModel_addTitle" : `${translate("formImportModel_importTitle")} "${name}"`}
			close={handleClose}
		>
			<>
				<form onSubmit={(e) => submitHandler(e)}>
					{create ?
						<FormControl fullWidth margin='dense'>
							<TextField
								autoFocus
								required
								id="outlined-basic"
								label={translate("formImportModel_inputModelName")}
								variant="outlined"
								value={nameInput}
								onChange={(e) => setNameInput(e.target.value)}
								InputProps={{
									style: {
										height: 33,
										fontSize: 13
									},
								}}
								InputLabelProps={{
									style: {
										fontSize: 13,
									},
								}}
								sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}
							/>
						</FormControl>
						:
						<FormControl fullWidth margin='dense'>
							<TextField
								required
								inputRef={inputRef}
								id="outlined-basic"
								variant="outlined"
								size="small"
								type="file"
								onChange={(e: ChangeEvent<HTMLInputElement>) => e.target.files && setFile(e.target.files[0])}
								InputProps={{
									style: {
										height: 33,
										fontSize: 13
									},
									inputProps: { accept: "application/zip" }
								}}
								InputLabelProps={{
									style: {
										fontSize: 13,
									},
								}}
								sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}
							/>
						</FormControl>
					}
					<FormControl fullWidth margin='dense'>
						{importStatusModel.status === RequestStatus.LOADING ?
							<Button variant="outlined" type="submit" disabled sx={{ fontSize: 11 }}>
								{translate(create ? 'formImportModel_addingBtn' : 'formImportModel_importingBtn')}
								<ProgressCircle isBtnDisabled />
							</Button>
							:
							<Button variant="outlined" type="submit" sx={{ fontSize: 11 }}>
								{translate(create ? 'formImportModel_addBtn' : 'formImportModel_importBtn')}
							</Button>
						}
					</FormControl>
				</form>
				{!create && importStatusModel.status === RequestStatus.LOADING &&
					<ProgressLinear value={importStatusModel.progress} />
				}
			</>
		</ModalFormWindow>
	);
};

export default FormImportModel;
