import { Fade } from '@mui/material';
import styles from './Layer.module.scss';

const Layer = (): JSX.Element => {

	const getTransformStyle = (): string => {
		let x = 256 - (Math.random() * 512);
		let y = 256 - (Math.random() * 512);
		const z = 100 - (Math.random() * 200);
		const a = Math.random() * 360;
		const s = 0.25 + Math.random();
		x *= 0.2;
		y *= 0.2;
		const t = 'translateX( ' + x + 'px ) translateY( ' + y + 'px ) translateZ( ' + z + 'px ) rotateZ( ' + a + 'deg ) scale( ' + s + ' )';
		return t;
	};

	return (
		<Fade in={true} timeout={1500}>
			<div
				className={styles.cloudLayer}
				style={{ transform: getTransformStyle(), animationDuration: (Math.random() * (400 - 300) + 300) + 's' }}
			></div>
		</Fade>
	);
};

export default Layer;
