import { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { Button, Checkbox, FormControl, FormControlLabel, ListItemText, MenuItem, Select, SelectChangeEvent, TextField, Typography } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { addRole, clearRolesList, getRolesList, selectAccessRights, selectRoleAddStatus, selectRolesList } from '../../../store/authSlice';
import useTranslate from '../../../hooks/useTranslate';
import { findServiceRightByType } from '../../../helpers/findServiceRightByType';
import { LIST_OF_RIGHTS_GROUPS } from '../../../constants/accessRights';
import { ROLE_ID } from '../../../constants/cookieNames';
import { RequestStatus, ResponseStatus } from '../../../types/statusTypes';
import ModalFormWindow from '../../../HOC/ModalFormWindow/ModalFormWindow';
import ProgressCircle from '../../ProgressCircle/ProgressCircle';
import { IFormAddingRoleProps } from './FormAddingRole.props';
import styles from './FormAddingRole.module.scss';

const FormAddingRole = ({ showModal, setShowModal, setShowNotification }: IFormAddingRoleProps): JSX.Element => {
	const [inputRoleName, setInputRoleName] = useState<string>(''); // название роли
	const [rights, setRights] = useState<string[]>([]); // список ролей
	// const [userRights, setUserRights] = useState<string[]>([]); // список прав доступа пользователя
	// const [showUserListForCopyRights, setShowUserListForCopyRights] = useState<boolean>(false); // показ списка пользователей для копирования прав доступа
	// const [userNameForCopy, setUserNameForCopy] = useState<string>(''); // имя пользователя для копирования прав доступа
	const [showErrorExistingRole, setShowErrorExistingRole] = useState<boolean>(false); // флаг существующего имени роли

	const dispatch = useAppDispatch();
	const accessRightsList = useAppSelector(selectAccessRights); // store - список всех прав доступа
	const rolesList = useAppSelector(selectRolesList); // store - список ролей
	const addingRoleStatus = useAppSelector(selectRoleAddStatus); // store - статус добавления роли
	// const userInfoForCopyRights = useAppSelector(selectUserInfoForCopyRights); // store - информация о пользователе для копирования прав доступа

	const [_cookies, setCookie] = useCookies([ROLE_ID]); // hook для работы с cookie
	const translate = useTranslate(); // hook для перевода текста

	// следим за выбранным пользователем и получаем инфо для копирования прав доступа
	// useEffect(() => {
	// 	userNameForCopy !== '' && dispatch(getUserInfoForCopyRights(userNameForCopy));
	// }, [userNameForCopy]);

	// следим за статусом получения информации пользователя для копирования прав доступа
	// useEffect(() => {
	// если нет ошибок и есть данные - пишем в state
	// userInfoForCopyRights.status === RequestStatus.IDLE && userInfoForCopyRights.data && 'id' in userInfoForCopyRights.data && setUserRights(userInfoForCopyRights.data.rights);
	// }, [userInfoForCopyRights]);

	// следим за статусом добавления роли
	useEffect(() => {
		// если добавление прошло успешно 
		if (addingRoleStatus.error === ResponseStatus.SUCCESS && addingRoleStatus.status === RequestStatus.IDLE && addingRoleStatus.message !== '') {
			setCookie(ROLE_ID, addingRoleStatus.id, { path: '/', maxAge: 2_592_000 }); // устанавливаем cookie на месяц
			dispatch(clearRolesList()); // очищаем список ролей
			dispatch(getRolesList()); // получаем заново список ролей
		}
		// если были изменения в статусе добавления
		if (addingRoleStatus.error !== ResponseStatus.SUCCESS || addingRoleStatus.status === RequestStatus.FAILED || addingRoleStatus.message !== '') {
			handleClose(); // закрываем форму
			setShowNotification(true); // включаем уведомление
		}
	}, [addingRoleStatus]);

	// обработчик выбора прав доступа
	const handleChange = (event: SelectChangeEvent<typeof rights>) => {
		const { target: { value } } = event;
		setRights(typeof value === 'string' ? value.split(',') : value);
	};

	// обработчик изменения группы checkbox'ов
	const changeHandlerGroup = (e: ChangeEvent<HTMLInputElement>): void => {
		const regex = new RegExp(`${e.target.id}/`); // регулярка с типом группы для поиска
		const serviceRight = findServiceRightByType(e.target.id); // главное право на вход в сервис, если есть

		// поиск группы прав доступа в полном списке
		const typeGroupSearch = accessRightsList.data?.all.filter(right => {
			// оставляем - что подходит под регулярку, еще нет в правах и не явлеяется общедоступным
			return regex.test(right) && !rights.includes(right) && !accessRightsList.data?.nopassword.includes(right);
		});

		// если выбрана группа сервиса - добавляем в список прав этой группы
		if (serviceRight && e.target.checked) typeGroupSearch?.push(serviceRight);

		// если выбран checkbox и есть список прав доступа - добавляем в список группу прав
		if (e.target.checked && typeGroupSearch) setRights(prevState => [...prevState, ...typeGroupSearch]);
		// иначе убираем группу прав из этого списка
		else setRights(prevState => prevState.filter(right => !regex.test(right) && right !== serviceRight));
	};

	// обработчик выделения группы прав
	const checkedHandlerGroup = (type: string): boolean => {
		const regex = new RegExp(`${type}/`); // регулярка с типом группы для поиска
		const serviceRight = findServiceRightByType(type); // главное право на вход в сервис 

		// поиск группы прав доступа в полном списке
		const typeGroupSearch = accessRightsList.data?.all.filter(right => {
			// оставляем - что подходит под регулярку и не явлеяется общедоступным
			return regex.test(right) && !accessRightsList.data?.nopassword.includes(right);
		});

		// поиск группы прав доступа в списке прав роли (state)
		const typeGroupSearchInUserRights = rights.filter(right => {
			// оставляем - что подходит под регулярку и не явлеяется общедоступным
			return regex.test(right) && !accessRightsList.data?.nopassword.includes(right);
		});

		// если для этой группы есть главное право сервиса - проверяем, есть ли оно в списке прав роли
		if (serviceRight) return rights.includes(serviceRight);
		// иначе проверяем совпадение на количество прав в общем списке и списке роли
		else if (typeGroupSearch) return typeGroupSearch?.length === typeGroupSearchInUserRights.length;
		else return false;
	};

	// обработчик добавления роли
	const submitHandler = (e: FormEvent<HTMLFormElement>): void => {
		e.preventDefault();
		const existingRoleName = rolesList.data.find(role => role.name === inputRoleName); // существующее имя роли
		if (existingRoleName) {
			setShowErrorExistingRole(true); // подсвечиваем ошибку
		} else {
			showErrorExistingRole && setShowErrorExistingRole(false); // убираем ошибку, если была
			dispatch(addRole({ name: inputRoleName, rights })); // добавление роли
		}
	};

	// обработчик закрытия формы
	const handleClose = (): void => {
		// если идет добавление роли - запрещаем покидать форму
		if (addingRoleStatus.status === RequestStatus.LOADING) return;
		setShowModal(false);
		// если есть данные пользователя для копирования прав доступа - очищаем
		// (userInfoForCopyRights.data || userInfoForCopyRights.status !== RequestStatus.IDLE) && dispatch(clearUserInfoForCopyRights());
	};

	return (
		<ModalFormWindow showModal={showModal} setShowModal={setShowModal} headerTitle='formAddingRole_header' close={handleClose}>
			<form onSubmit={(e) => submitHandler(e)}>
				<FormControl fullWidth margin='dense'>
					<TextField
						autoFocus
						required
						id="roleName"
						label={translate('formAddingRole_inputRoleName')}
						variant="outlined"
						value={inputRoleName}
						onChange={(e) => setInputRoleName(e.target.value)}
						disabled={addingRoleStatus.status === RequestStatus.LOADING}
						error={showErrorExistingRole}
						helperText={showErrorExistingRole ? translate('formAddingRole_roleExists') : ''}
						InputProps={{
							style: {
								height: 33,
								fontSize: 13
							},
						}}
						InputLabelProps={{
							style: {
								fontSize: 13,
							},
						}}
						sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}
					/>
				</FormControl>

				<Typography variant="body1" margin={1}>{translate('formAddingRole_accessRightsTitle')}</Typography>
				{/*{isAccess([USER.LIST, USER.USER_INFO]) &&
						(!showUserListForCopyRights ?
							<p className={styles.copyRightsTitle} onClick={() => setShowUserListForCopyRights(true)}>{translate('formAddingUser_copyAccessRightsTitle')}</p>
							:
							<>
								<FormControl fullWidth sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}>
									<InputLabel id="userNameCopy-label" sx={{ fontSize: 13 }}>{translate('formAddingUser_inputNameForCopy')}</InputLabel>
									<Select
										labelId="userNameCopy-label"
										id="userNameCopy"
										label={translate('formAddingUser_inputNameForCopy')}
										value={userNameForCopy}
										onChange={(e) => setUserNameForCopy(e.target.value)}
										style={{ fontSize: 13, height: 33 }}

									>
										{userList.data && Array.isArray(userList.data) && userList.data.map((user) => (
											<MenuItem key={user.id} value={user.id} sx={{ fontSize: 13 }}>{user.username} ({user.fullname})</MenuItem>
										))}
									</Select>
								</FormControl>
								{userInfoForCopyRights.status === RequestStatus.FAILED && <div className={styles.failedText}>{translate('formAddingUser_failedGetUserInfoTitle')}</div>}
							</>
						)
					} */}

				{accessRightsList.data?.all ?
					<div className={styles.rightGroups}>
						{LIST_OF_RIGHTS_GROUPS.map(type => (
							<div className={styles.rightGroup} key={type}>
								<FormControlLabel
									sx={{ '.MuiTypography-root': { fontSize: 13, marginTop: '1px', textAlign: 'left' } }}
									label={translate(type)}
									control={
										<Checkbox
											size='small'
											id={type}
											onChange={e => changeHandlerGroup(e)}
											checked={checkedHandlerGroup(type)}
											disabled={addingRoleStatus.status === RequestStatus.LOADING}
										/>
									}
								/>
								<FormControl margin='dense' sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 }, width: 300 }}>
									<Select
										id={`rightNames-${type}`}
										multiple
										value={rights}
										onChange={e => handleChange(e)}
										renderValue={(selected) => {
											const regex = new RegExp(`${type}/`);
											const filter = selected.filter(right => regex.test(right));
											return filter.join(', ');
										}}
										disabled={addingRoleStatus.status === RequestStatus.LOADING}
										style={{ fontSize: 13, height: 33 }}
									>
										{accessRightsList.data?.all.map(right => {
											const regex = new RegExp(`${type}/`);
											if (regex.test(right)) return (
												<MenuItem key={right} value={right} sx={{ textAlign: 'left', padding: '0 10px 0 0' }} disabled={accessRightsList.data?.nopassword.includes(right)} title={right}>
													<Checkbox checked={rights.indexOf(right) > -1 || accessRightsList.data?.nopassword.includes(right)} size='small' />
													<ListItemText primary={accessRightsList.data?.nopassword.includes(right) ? translate(right) + ' (public)' : translate(right)} primaryTypographyProps={{ fontSize: 11 }} />
												</MenuItem>
											);
											return undefined;
										})}
									</Select>
								</FormControl>
							</div>
						))}
					</div>
					:
					<p className={styles.failedText}>{translate('formAddingRole_failedAccessRights')}</p>
				}

				<FormControl fullWidth margin='dense'>
					<Button
						variant="outlined"
						type="submit"
						disabled={addingRoleStatus.status === RequestStatus.LOADING}
						sx={{ fontSize: 11 }}
					>
						{addingRoleStatus.status === RequestStatus.LOADING ?
							<>
								{translate('formAddingRole_addingBtn')}
								<ProgressCircle isBtnDisabled />
							</>
							:
							translate('formAddingRole_addBtn')
						}
					</Button>
				</FormControl>
			</form>
		</ModalFormWindow>
	);
};

export default FormAddingRole;
