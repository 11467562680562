import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileCirclePlus } from '@fortawesome/free-solid-svg-icons';
import { useAppSelector } from '../../../../store/hooks';
import { selectRecognitionData } from '../../../../store/sprSlice';
import { clearCreation, selectCreation } from '../../../../store/transcriptionSlice';
import useTranslate from '../../../../hooks/useTranslate';
import { backgroundColor } from '../../../../constants/colors';
import { RequestStatus } from '../../../../types/statusTypes';
import FormCreateTranscript from '../../../Forms/FormCreateTranscript/FormCreateTranscript';
import Notification from '../../../Notification/Notification';

const NewTranscript = ({ styleNotActive }: { styleNotActive: string }): JSX.Element => {
	const [showModalCreateTranscript, setShowModalCreateTranscript] = useState<boolean>(false); // показ формы для создания стенограммы
	const [showNotification, setShowNotification] = useState<boolean>(false); // показ уведомления

	const recognitionData = useAppSelector(selectRecognitionData); // store - данные распознавания речи
	const translate = useTranslate(); // hook для перевода текста

	return (
		<>
			<div
				className={recognitionData.status === RequestStatus.IDLE && recognitionData.data && 'speakers' in recognitionData.data ? '' : styleNotActive}
				onClick={() => {
					recognitionData.status === RequestStatus.IDLE && recognitionData.data && 'speakers' in recognitionData.data && setShowModalCreateTranscript(true);
				}}
				title={translate('controlsBtn_createTranscript')}>
				<FontAwesomeIcon icon={faFileCirclePlus} size="2xl" color={backgroundColor} />
			</div>

			{showModalCreateTranscript &&
				<FormCreateTranscript
					showModal={showModalCreateTranscript}
					setShowModal={setShowModalCreateTranscript}
					setShowNotification={setShowNotification}
				/>
			}

			<section style={{ position: 'absolute' }}>
				{showNotification &&
					<Notification
						showNotification={showNotification}
						setShowNotification={setShowNotification}
						selectDataResponse={selectCreation}
						clearDataResponse={clearCreation}
						titleFailed='noticeCreateTranscript_failed'
						titleSuccess='noticeCreateTranscript_success'
					/>
				}
			</section>
		</>
	);
};

export default NewTranscript;
