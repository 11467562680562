import { FormEvent, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faFile, faFolder, faFolderOpen, faPen, faTrashCan, faXmark } from '@fortawesome/free-solid-svg-icons';
import cn from 'classnames';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { addFilterByClass, changeClassToParent, changeParentToNormal, deleteClass, renameClass, selectCorpus } from '../../../store/corpusSlice';
import useAccessRight from '../../../hooks/useAccessRight';
import useTranslate from '../../../hooks/useTranslate';
import { CORPUS } from '../../../constants/accessRights';
import { colorGreen, colorPrimary, colorRed } from '../../../constants/colors';
import AlertDialog from '../../AlertDialog/AlertDialog';
import { INodeProps } from './Node.props';
import styles from './Node.module.scss';

const Node = ({ node, style, dragHandle, setChangeFlg, setShowContextMenu }: INodeProps): JSX.Element => {
	const [inputRenameClass, setInputRenameClass] = useState<string>(''); // имя класса для переименования
	const [renameClassFlg, setRenameClassFlg] = useState<boolean>(false); // флаг для формы переименования класса
	const [renamingWarningFlg, setRenamingWarningFlg] = useState<boolean>(false); // флаг предупреждения о невозможности переименования

	const [showAlertDialogToParent, setShowAlertDialogToParent] = useState<boolean>(false); // показ диалогового окна при изменении класса с обычного на родителя
	const [showAlertDialogToNormal, setShowAlertDialogToNormal] = useState<boolean>(false); // показ диалогового окна при изменении класса с родителя на обычный
	const [showAlertDialogDelClass, setShowAlertDialogDelClass] = useState<boolean>(false); // показ диалогового окна при удалении класса
	const [showAlertDialogRenameClass, setShowAlertDialogRenameClass] = useState<boolean>(false); // показ диалогового окна при переименовании класса на существующий

	const dispatch = useAppDispatch();
	const corpus = useAppSelector(selectCorpus); // store - корпус

	const isAccess = useAccessRight(); // hook для проверки прав доступа
	const translate = useTranslate(); // hook для перевода текста

	// изменение класса на родительский
	const changeClassToParentHandler = (): void => {
		setShowAlertDialogToParent(false); // закрываем диалоговое окно
		dispatch(changeClassToParent(node.data.name));
		setChangeFlg(true); // включаем флаг о не сохраненных данных
	};

	// изменение родительского класса на обычный
	const changeClassToNormalHandler = (): void => {
		setShowAlertDialogToNormal(false); // закрываем диалоговое окно
		const children: string[] = [];
		node.data.children?.forEach((item) => children.push(item.id));
		dispatch(changeParentToNormal({ parent: node.data.name, children }));
		setChangeFlg(true); // включаем флаг о не сохраненных данных
	};

	// обработчик нажатия клавиши в поле переименования класса
	const keyDownRenameClassHandler = (e: React.KeyboardEvent<HTMLInputElement>): void => {
		if (e.code === 'Escape') closeForm();
	};

	// закрытие формы переименования
	const closeForm = (): void => {
		setRenameClassFlg(false);
		renamingWarningFlg && setRenamingWarningFlg(false); // убираем предупреждение если было
	};

	// обработчик нажатия кнопки переименования класса
	const renameClassHandler = (className: string): void => {
		node.edit(); // ставим в режим редактирования
		setRenameClassFlg(true); // открываем форму для переименования
		setInputRenameClass(className); // записываем в input название класса
	};

	// обработчик формы переименования класса
	const submitRenameClassHandler = (e: FormEvent<HTMLFormElement>): void => {
		e.preventDefault();
		// если имя не поменялось
		if (node.data.name === inputRenameClass) {
			setRenameClassFlg(false); // закрываем форму
			renamingWarningFlg && setRenamingWarningFlg(false); // убираем предупреждение если было
		}
		// если переименовываем на существующий класс - включаем предупреждение
		else if (corpus.classes.includes(inputRenameClass)) {
			// если переименовываем родителя
			if (node.level === 0 && !node.isLeaf) {
				const find = corpus.data.classes.find(([_children, parent]) => {
					return parent === inputRenameClass;
				});
				find ? setShowAlertDialogRenameClass(true) : setRenamingWarningFlg(true);
			}
			// если переименовываем потомка
			else if (node.level === 1 && node.isLeaf) {
				const find = corpus.data.classes.find(([children, parent]) => {
					return children.includes(inputRenameClass) && parent === node.parent?.id;
				});
				find ? setShowAlertDialogRenameClass(true) : setRenamingWarningFlg(true);
			}
			// если переименовываем самостоятельный класс
			else if (node.level === 0 && node.isLeaf) {
				const find = corpus.data.classes.find(([children, parent]) => {
					return children.includes(inputRenameClass) || parent === inputRenameClass;
				});
				!find ? setShowAlertDialogRenameClass(true) : setRenamingWarningFlg(true);
			}
		}
		else renameClassFunc();
	};

	// функция переименования класса
	const renameClassFunc = (): void => {
		dispatch(renameClass({
			oldClassName: node.data.name,
			newClassName: inputRenameClass,
			isParent: (node.level === 0 && !node.isLeaf) ? true : false,
		})); // переименование класса
		setRenameClassFlg(false); // закрываем форму переименования класса
		setChangeFlg(true); // включаем флаг о не сохраненных данных
		showAlertDialogRenameClass && setShowAlertDialogRenameClass(false); // закрываем диалоговое окно если было открыто
	};

	// обработчик удаления класса
	const deleteClassHandler = (): void => {
		setShowAlertDialogDelClass(false); // закрываем диалоговое окно
		dispatch(deleteClass(node.data.name)); // удаляем класс из store
		setChangeFlg(true); // включаем флаг о не сохраненных данных
	};

	// обработчик контекстного меню
	const contextMenuHandler = (e: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
		e.preventDefault();
		setShowContextMenu({
			isShow: true,
			x: e.pageX,
			y: e.pageY,
			who: node.data.name,
			from: node.parent?.id || '',
			level: node.level,
			message: !node.isLeaf ? translate('node_dontMoveParentClassTitle') : null,
		}); // пишем параметры для контекстного меню
	};

	return (
		<>
			<div
				className={styles.infoClassesItem}
				style={style}
				ref={!renameClassFlg ? dragHandle : undefined}
				onContextMenu={e => isAccess(CORPUS.SAVE) && contextMenuHandler(e)}
			>
				{renameClassFlg ?
					<form className={styles.renameForm} onSubmit={e => submitRenameClassHandler(e)}>
						<span>
							{node.isLeaf ?
								<FontAwesomeIcon icon={faFile} size='lg' /> :
								node.isOpen ?
									<FontAwesomeIcon icon={faFolderOpen} size='lg' />
									:
									<FontAwesomeIcon icon={faFolder} size='lg' />
							}
						</span>
						{renamingWarningFlg &&
							<div className={styles.renameFormWarn}>{translate('node_renameWarnTitle')}</div>
						}
						<input
							className={cn(styles.renameFormInput, {
								[styles.renameFormInputWarn]: renamingWarningFlg,
							})}
							autoFocus
							value={inputRenameClass}
							onChange={e => setInputRenameClass(e.target.value.replace(/,/g, ''))}
							onKeyDown={(e) => keyDownRenameClassHandler(e)}
						/>
						<button className={styles.renameFormBtn} type="submit" title={translate('node_renameClassBtn')}>
							<FontAwesomeIcon icon={faCheck} style={{ cursor: 'pointer' }} color={colorGreen} size="lg" />
						</button>
						<button className={styles.renameFormBtn} type="reset" title={translate('node_renameCancelBtn')}>
							<FontAwesomeIcon icon={faXmark} style={{ cursor: 'pointer' }} color={colorRed} size="lg" onClick={closeForm} />
						</button>
					</form>
					:
					<>
						<p className={styles.infoClassesItemKey} title={node.data.name}>
							<span onClick={() => !node.isLeaf && node.toggle()}>
								{node.isLeaf ?
									<FontAwesomeIcon icon={faFile} size='lg' /> :
									node.isOpen ?
										<FontAwesomeIcon icon={faFolderOpen} size='lg' style={{ cursor: 'pointer' }} />
										:
										<FontAwesomeIcon icon={faFolder} size='lg' style={{ cursor: 'pointer' }} />
								}
							</span>
							<span className={styles.infoClassesItemName} onClick={() => dispatch(addFilterByClass(node.data.name))}>
								{node.data.name}
							</span>
						</p>
						<p className={styles.infoClassesItemValue}>
							{corpus.classAndCount[node.data.name]}
						</p>
						{isAccess(CORPUS.SAVE) &&
							<div className={styles.infoClassesItemEdit}>
								<FontAwesomeIcon
									icon={faPen}
									size="sm"
									color={colorPrimary}
									onClick={() => renameClassHandler(node.data.name)}
									title={translate('node_renameClassBtn')}
									style={{ cursor: 'pointer' }}
								/>
								{/* удалять можно все, кроме родительских */}
								{node.isLeaf &&
									<FontAwesomeIcon
										icon={faTrashCan}
										size="sm"
										color={colorRed}
										onClick={() => setShowAlertDialogDelClass(true)}
										title={translate('node_deleteClassBtn')}
										style={{ cursor: 'pointer' }}
									/>
								}
								{node.isLeaf && node.level === 0 &&
									<FontAwesomeIcon
										icon={faFolder}
										size='sm'
										color={colorPrimary}
										onClick={() => setShowAlertDialogToParent(true)}
										title={translate('node_makeParentClassBtn')}
										style={{ cursor: 'pointer' }}
									/>
								}
								{!node.isLeaf &&
									<FontAwesomeIcon
										icon={faFile}
										size='sm'
										color={colorPrimary}
										onClick={() => setShowAlertDialogToNormal(true)}
										title={translate('node_makeNormalClassBtn')}
										style={{ cursor: 'pointer' }}
									/>
								}
							</div>
						}
					</>
				}
			</div>

			<AlertDialog
				showAlertDialog={showAlertDialogToParent}
				setShowAlertDialog={setShowAlertDialogToParent}
				submitHandler={changeClassToParentHandler}
				title='dialog_changeTypeClassTitle'
				description='dialog_changeTypeClassParentConfirm'
				name={node.data.name}
			/>
			<AlertDialog
				showAlertDialog={showAlertDialogToNormal}
				setShowAlertDialog={setShowAlertDialogToNormal}
				submitHandler={changeClassToNormalHandler}
				title='dialog_changeTypeClassTitle'
				description='dialog_changeTypeClassNormalConfirm'
				name={node.data.name}
			/>
			<AlertDialog
				showAlertDialog={showAlertDialogDelClass}
				setShowAlertDialog={setShowAlertDialogDelClass}
				submitHandler={deleteClassHandler}
				title={'dialog_deleteClassTitle'}
				description={'dialog_deleteClassConfirm'}
				name={node.data.name}
			/>
			<AlertDialog
				showAlertDialog={showAlertDialogRenameClass}
				setShowAlertDialog={setShowAlertDialogRenameClass}
				submitHandler={renameClassFunc}
				title={'dialog_renameClassTitle'}
				description={'dialog_renameClassConfirm'}
				name={node.data.name}
				name2={inputRenameClass}
			/>
		</>
	);
};

export default Node;
