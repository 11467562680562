import { FormEvent, useEffect, useState } from 'react';
import { Autocomplete, Button, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { appendAliasList, deleteAliases, getQuestionsListForMoving, selectAppendAliasList, selectCategoriesList, selectQuestion, selectQuestionsListForMoving } from '../../../store/qasSlice';
import useTranslate from '../../../hooks/useTranslate';
import { colorPrimary } from '../../../constants/colors';
import { IQuestionItem } from '../../../types/qasTypes';
import { RequestStatus, ResponseStatus } from '../../../types/statusTypes';
import ModalFormWindow from '../../../HOC/ModalFormWindow/ModalFormWindow';
import ProgressCircle from '../../ProgressCircle/ProgressCircle';
import { IFormMoveAliasesProps } from './FormMoveAliases.props';

const FormMoveAliases = ({ showModal, setShowModal, selectedAliasIdxList, setSelectedListAliasIdx, setChangeFlg, setShowNotificationMoveAliases, listType }: IFormMoveAliasesProps): JSX.Element => {
	const [categoryIdSelect, setCategoryIdSelect] = useState<string>(''); // id категории
	const [questionItem, setQuestionItem] = useState<IQuestionItem | null>(null); // вопрос для перемещения

	const dispatch = useAppDispatch();
	const questionsList = useAppSelector(selectQuestionsListForMoving); // store - список вопросов
	const question = useAppSelector(selectQuestion); // store - вопросы/ответы
	const appendAliasListStatus = useAppSelector(selectAppendAliasList); // store - статус добавления алиасов
	const categoriesList = useAppSelector(selectCategoriesList); // store - список категорий

	const translate = useTranslate(); // hook для перевода текста

	useEffect(() => {
		dispatch(getQuestionsListForMoving()); // получаем список всех вопросов
	}, []);

	// следим за фильтрованной категорией
	useEffect(() => {
		// если выбранный вопрос не соответствует выбранной категории или пустой категории - сбрасываем выбранный вопрос
		questionItem?.category !== categoryIdSelect && categoryIdSelect !== '' && setQuestionItem(null);
	}, [categoryIdSelect]);

	// следим за статусом добавления алиасов
	useEffect(() => {
		// если добавилось успешно
		if (appendAliasListStatus.error === ResponseStatus.SUCCESS && appendAliasListStatus.status === RequestStatus.IDLE && appendAliasListStatus.message !== '') {
			setSelectedListAliasIdx([]); // очищаем список выбранных алиасов
			dispatch(deleteAliases({ aliasIdxList: selectedAliasIdxList, listType })); // удаляем выбранные алиасы из текущего вопроса
			setChangeFlg(true); // ставим флаг о несохраненных данных
		}
		// если были изменения в статусе добавления алиасов
		if (appendAliasListStatus.error !== ResponseStatus.SUCCESS || appendAliasListStatus.status === RequestStatus.FAILED || appendAliasListStatus.message !== '') {
			handleClose(); // закрываем форму
			setShowNotificationMoveAliases(true); // включаем уведомление
		}
	}, [appendAliasListStatus]);

	// обработчик добавления алиасов
	const submitHandler = (e: FormEvent<HTMLFormElement>): void => {
		e.preventDefault();
		questionItem && dispatch(appendAliasList({
			questionId: questionItem.id,
			[listType === 'aliases' ? 'aliasList' : 'candidateList']: selectedAliasIdxList.map(aliasIdx => question[listType][aliasIdx]),
		})); // добавление алиасов к другому вопросу
	};

	// обработчик закрытия формы
	const handleClose = (): void => {
		// если идет добавление алиасов - запрещаем покидать форму
		if (appendAliasListStatus.status === RequestStatus.LOADING) return;
		setShowModal(false);
	};

	return (
		<ModalFormWindow showModal={showModal} setShowModal={setShowModal} headerTitle='formMoveAliases_header' close={handleClose}>
			<form onSubmit={submitHandler}>
				<FormControl fullWidth margin='dense' sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}>
					<InputLabel sx={{ fontSize: 13 }}>{translate('formAddingQuestion_selectCategory')}</InputLabel>
					<Select
						label={translate('formAddingQuestion_selectCategory')}
						disabled={appendAliasListStatus.status === RequestStatus.LOADING}
						value={categoryIdSelect}
						onChange={(e) => setCategoryIdSelect(e.target.value)}
						style={{ fontSize: 13, height: 33, color: colorPrimary }}
					>
						<MenuItem value='' sx={{ fontSize: 13 }}>{translate('questionNavbar_selectCategoryNotSelected')}</MenuItem>
						{categoriesList.data.map((category) => (
							<MenuItem key={category.id} value={category.id} sx={{ fontSize: 13, color: colorPrimary }}>{category.name}</MenuItem>
						))}
					</Select>
				</FormControl>
				<FormControl fullWidth margin='dense'>
					<Autocomplete
						options={questionsList.data.filter(questionItem => questionItem.id !== question.questionId && (questionItem.category === categoryIdSelect || categoryIdSelect === ''))}
						value={questionItem}
						onChange={(_, value) => setQuestionItem(value)}
						noOptionsText={<div style={{ fontSize: '13px' }}>{translate('notFound')}</div>}
						renderInput={(params) =>
							<TextField
								{...params}
								required
								label={translate('formMoveAliases_inputQuestion')}
								InputLabelProps={{
									style: {
										fontSize: 13,
									},
								}}
								InputProps={{
									...params.InputProps, // важно прокинуть параметры
									endAdornment: (
										<div style={{ marginTop: '-7px' }}>
											{questionsList.status === RequestStatus.LOADING &&
												<ProgressCircle isBtnDisabled />
											}
											{params.InputProps.endAdornment} {/* важно дописать параметры */}
										</div>
									),
								}}
								sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}
							/>
						}
						sx={{
							".MuiInputBase-root": { height: 33, fontSize: 13, color: colorPrimary },
							".MuiInputBase-input": { marginTop: -1 },
						}}
						getOptionLabel={option => option.question}
						renderOption={(props, option) => {
							return (
								<span {...props} style={{ fontSize: 13, color: colorPrimary, textAlign: 'left' }} key={option.id}>
									{option.question}
								</span>
							);
						}}
					/>
				</FormControl>
				<FormControl fullWidth margin='dense'>
					<Button
						variant="outlined"
						type="submit"
						disabled={appendAliasListStatus.status === RequestStatus.LOADING}
						sx={{ fontSize: 11 }}
					>
						{appendAliasListStatus.status === RequestStatus.LOADING ?
							<>
								{translate('formMoveAliases_movingBtn')}
								<ProgressCircle isBtnDisabled />
							</>
							:
							translate('formMoveAliases_moveBtn')
						}
					</Button>
				</FormControl>
			</form>
		</ModalFormWindow>
	);
};

export default FormMoveAliases;
