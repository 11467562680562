import { useEffect, useState } from 'react';
import { Fade } from '@mui/material';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowsRotate, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import ProgressBar from "@ramonak/react-progress-bar";
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { changeTestingStatus, changeTrainingStatus, clearHandler, deleteModel, getHandler, getInfoModel, selectFullModel } from '../../store/modelSlice';
import useAccessRight from '../../hooks/useAccessRight';
import useTranslate from '../../hooks/useTranslate';
import { MODEL } from '../../constants/accessRights';
import { colorPrimary, colorRed, colorSecondaryRGB } from '../../constants/colors';
import { IModel } from '../../types/modelTypes';
import { ModelStatus, RequestStatus } from '../../types/statusTypes';
import BlockInfo from '../../HOC/BlockInfo/BlockInfo';
import AlertDialog from '../AlertDialog/AlertDialog';
import ProgressCircle from '../ProgressCircle/ProgressCircle';
import { IModelInfoProps } from './ModelInfo.props';
import styles from './ModelInfo.module.scss';

const ModelInfo = ({ setShowNotificationDelete, serviceType }: IModelInfoProps): JSX.Element => {
	const [showAlertDialog, setShowAlertDialog] = useState<boolean>(false); // показ диалогового окна для удаления модели
	const [modelObj, setModelObj] = useState<[string, any][]>([]); // список ключей-значений информации о типе модели
	const dispatch = useAppDispatch();
	const fullModel = useAppSelector(selectFullModel); // store - информация о модели

	const isAccess = useAccessRight(); // hook для проверки прав доступа
	const translate = useTranslate(); // hook для перевода текста

	// функция для раскраски фона и надписи прогресс-бара обучения и тестирования модели
	const coloring = (value: number, isLabel: boolean = false): string => {
		const rgb = colorSecondaryRGB;
		const calc = (v: number, max: number): number => Math.round(255 - (255 - max) * (v / 100.0));
		const r = calc(value, rgb[0]);
		const g = calc(value, rgb[1]);
		const b = calc(value, rgb[2]);
		const w = 0.7 * g + 0.2 * r + 0.1 * b;
		const labelColor = w > 203 ? colorPrimary : "#fff";
		const bgColor = "rgb(" + r + "," + g + "," + b + ")";
		if (isLabel) {
			return labelColor;
		} else {
			return bgColor;
		}
	};

	// обработчик удаления модели
	const deleteHandler = (): void => {
		setShowAlertDialog(false); // закрываем диалоговое окно
		setShowNotificationDelete(true); // включаем уведомление
		fullModel.modelName && dispatch(deleteModel({ modelName: fullModel.modelName, serviceType })); // удаление модели
	};

	// следим за статусом модели
	useEffect(() => {
		// если статус "Обучение"
		if (fullModel.fullModel[fullModel.activeType]?.status === ModelStatus.TRAINING) {
			if (fullModel.training === false) {
				dispatch(changeTrainingStatus(true)); // включаем флаг обучения в store 
				dispatch(clearHandler()); // очищаем данные обработчика
			}
		} else {
			if (fullModel.training !== false) {
				dispatch(changeTrainingStatus(false)); // иначе выключаем флаг
				fullModel.modelName && dispatch(getHandler({ modelName: fullModel.modelName, serviceType, modelType: fullModel.activeType })); // получаем handler.py
			}
		}

		// если статус "Тестирование" - включаем флаг тестирования в store 
		if (fullModel.fullModel[fullModel.activeType]?.status === ModelStatus.TESTING) {
			fullModel.testing === false && dispatch(changeTestingStatus(true));
			// иначе выключаем флаг
		} else fullModel.testing !== false && dispatch(changeTestingStatus(false));
	}, [fullModel.fullModel[fullModel.activeType]?.status]);

	// следим за флагами обучения и тестирования в store
	useEffect(() => {
		let interval: any;
		// если один из флагов включен - запускаем автозапрос получения данных о модели каждые 20 сек
		if (fullModel.training === true || fullModel.testing === true) {
			interval = setInterval(() => {
				fullModel.modelName && dispatch(getInfoModel({ modelName: fullModel.modelName, serviceType }));
			}, 20000);
		} else clearInterval(interval);

		return () => {
			clearInterval(interval); // при выходе со страницы - удаляем автозапрос на получение данных о модели
		};
	}, [fullModel.training, fullModel.testing]);

	// следим за данными активного типа модели и записываем в state ключи и значения
	useEffect(() => {
		setModelObj(Object.entries(fullModel.fullModel[fullModel.activeType] as IModel));
	}, [fullModel.fullModel[fullModel.activeType]]);

	return (
		<div className={styles.info}>
			<Fade in={fullModel.status !== RequestStatus.FAILED} timeout={500}>
				<div className={styles.infoTitle}>
					<BlockInfo>
						<>
							<div className={styles.infoTitleTop}>
								<div className={styles.infoTitleModelName}>
									<span className={styles.infoKey}>{translate('modelInfo_modelTitle')}&nbsp;</span>
									{fullModel.modelName}
								</div>
								{isAccess(MODEL.DELETE) && <div className={styles.infoTrash}>
									<FontAwesomeIcon icon={faTrashCan} size="xs" color={colorRed} onClick={() => fullModel.modelName && setShowAlertDialog(true)} title={translate('modelInfo_deleteBtn')} style={{ cursor: 'pointer' }} />
								</div>}
							</div>
							<Fade in={(fullModel.status !== RequestStatus.LOADING && fullModel.fullModel?.[fullModel.activeType] !== null) || fullModel.training === true || fullModel.testing === true} timeout={500}>
								<p className={styles.infoTitleModelType}>{translate('modelInfo_versionTitle')} {translate(fullModel.activeType)}</p>
							</Fade>
							{fullModel.fullModel?.[fullModel.activeType]?.hasOwnProperty('status') && fullModel.activeType !== 'previous' &&
								<Fade in={(fullModel.status !== RequestStatus.LOADING && fullModel.fullModel?.[fullModel.activeType] !== null) || fullModel.training === true || fullModel.testing === true} timeout={500}>
									<div className={styles.infoTitleModelStatus}>
										<p>
											<span className={styles.infoKey}>
												{translate('modelInfo_statusTitle')}
											</span> {translate(fullModel.fullModel?.[fullModel.activeType]?.status || '...')}
										</p>
										{fullModel.activeType === 'future' && <div className={styles.infoReload}>
											<FontAwesomeIcon icon={faArrowsRotate} title={translate('modelInfo_reloadBtn')} style={{ cursor: 'pointer' }} onClick={() => fullModel.modelName && dispatch(getInfoModel({ modelName: fullModel.modelName, serviceType }))} />
										</div>}
									</div>
								</Fade>
							}
						</>
					</BlockInfo>
				</div>
			</Fade>

			{(fullModel.fullModel[fullModel.activeType]?.trainAccuracy || fullModel.fullModel[fullModel.activeType]?.testAccuracy) &&
				fullModel.fullModel[fullModel.activeType]?.trainAccuracy !== null &&
				fullModel.fullModel[fullModel.activeType]?.trainAccuracy !== 0 &&
				<Fade in={(fullModel.status !== RequestStatus.LOADING && fullModel.fullModel?.[fullModel.activeType] !== null) || fullModel.training === true || fullModel.testing === true} timeout={500}>
					<div className={styles.infoAccuracy}>
						<BlockInfo>
							<>
								<span className={styles.infoAccuracyTitle}>
									{translate('modelInfo_trainAccuracy')}
								</span>
								<ProgressBar
									completed={Number(fullModel.fullModel[fullModel.activeType]?.trainAccuracy?.toFixed(2))}
									bgColor={coloring(fullModel.fullModel[fullModel.activeType]?.trainAccuracy as number)}
									labelColor={coloring(fullModel.fullModel[fullModel.activeType]?.trainAccuracy as number, true)}
									labelAlignment='center'
									margin={(fullModel.fullModel[fullModel.activeType]?.testAccuracy !== null && fullModel.fullModel[fullModel.activeType]?.testAccuracy !== 0 && fullModel.fullModel[fullModel.activeType]?.testAccuracy) ? '12px 0 16px' : '12px 0 0'}
									height='15px'
									labelSize='12px'
								/>

								{(fullModel.fullModel[fullModel.activeType]?.testAccuracy !== null &&
									fullModel.fullModel[fullModel.activeType]?.testAccuracy !== 0 &&
									fullModel.fullModel[fullModel.activeType]?.testAccuracy &&
									fullModel.testing === false) &&
									<>
										<span className={styles.infoAccuracyTitle}>
											{translate('modelInfo_testAccuracy')}
										</span>
										<ProgressBar
											completed={Number(fullModel.fullModel[fullModel.activeType]?.testAccuracy?.toFixed(2))}
											bgColor={coloring(fullModel.fullModel[fullModel.activeType]?.testAccuracy as number)}
											labelColor={coloring(fullModel.fullModel[fullModel.activeType]?.testAccuracy as number, true)}
											labelAlignment='center'
											margin='12px 0 0'
											customLabelStyles={{ marginLeft: fullModel.fullModel[fullModel.activeType]?.testAccuracy as number < 20 ? '40px' : '0' }}
											height='15px'
											labelSize='12px'
										/>
									</>
								}
							</>
						</BlockInfo>
					</div>
				</Fade>
			}

			{fullModel.fullModel[fullModel.activeType]?.status !== ModelStatus.EMPTY && (fullModel.fullModel[fullModel.activeType]?.status !== ModelStatus.FAILED || fullModel.fullModel[fullModel.activeType]?.trainAccuracy) && fullModel.training === false &&
				<Fade in={(fullModel.status !== RequestStatus.LOADING && fullModel.fullModel?.[fullModel.activeType] !== null) || fullModel.testing === true} timeout={500}>
					<div className={cn(styles.infoOther, {
						[styles.shadowFuture]: fullModel.activeType === 'future',
						[styles.shadowCurrent]: fullModel.activeType === 'current',
						[styles.shadowPrevious]: fullModel.activeType === 'previous'
					})}>
						{fullModel.testing ?
							<div className={styles.infoOtherLoading}><ProgressCircle title={translate('progressCircle_testingTitle')} /></div>
							:
							<>
								{modelObj?.map(([key, value]) => {
									if (key === 'classes' || key === 'trainAccuracy' || key === 'testAccuracy' || key === 'status' || key === 'matrix') return undefined;
									return (<div className={styles.infoOtherPair} key={key}>
										<div className={styles.infoKey}>{translate(key)}: </div>
										<div className={styles.infoValue} title={String(value)}>{String(value)}</div>
									</div>);
								})}
							</>
						}
					</div>
				</Fade>
			}

			<AlertDialog showAlertDialog={showAlertDialog} setShowAlertDialog={setShowAlertDialog} submitHandler={deleteHandler} title='dialog_deleteModelTitle' description='dialog_deleteModelConfirm' name={String(fullModel.modelName)} />
		</div>
	);
};

export default ModelInfo;
